import { useEffect, useRef, useState } from "react";
import { Bounce, Id, ToastContainer, ToastOptions } from "react-toastify";
import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { NOTIFY_CAPACITY_LIMIT, NOTIFY_NOTIFICATION } from 'userful-chronos-app-common-js/dist/message/messageTypeDefinitions/notifications/notificationMsgs';
import { AetherNotification, CapacityAlert, interpretErrorTypePreffix, UsageLevel, VEMNotificationData } from 'userful-chronos-app-common-js/dist/models/notifications/notifications';
import i18next from 'i18next';
import React from 'react';
import { NOTIFY_UCLIENT_UPDATED } from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/displays/UClientsMsgDefinations";
import { isUClientOffline } from 'userful-chronos-app-common-js/dist/models/uclient';
import { getMultiWindowGroupName } from "userful-chronos-app-common-js/dist/models/mapping/MappingGroups";
import Cookies from 'js-cookie';
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { isIDNotSet } from "userful-chronos-app-common-js/dist/models/common";
import { UserPermission } from "userful-chronos-app-common-js/dist/models/user-permission";
import { useChoppedAssetSelector } from "userful-chronos-common-store/dist/sourceassets";

const toastOptions: ToastOptions = {
     position: toast.POSITION.TOP_RIGHT,
     autoClose: false,
     style: { width: "500px" },
     className: "notificationTostify",
     containerId: "customContainer",
};

const usageAlertOptions: ToastOptions = {
     ...toastOptions,
}

const COOKIE_KEY_PREFIX = "userful-notification-";
const shouldShow = (key: string): boolean => {
     return !Cookies.get(`${COOKIE_KEY_PREFIX}${key}`)
};
const hideFor1Day = (key: string) => Cookies.set(`${COOKIE_KEY_PREFIX}${key}`, "hide", { expires: 1 });
const hideFor1Hour = (key: string) => Cookies.set(`${COOKIE_KEY_PREFIX}${key}`, "hide", { expires: 1 / 24 });
const hideFor2Hours = (key: string) => Cookies.set(`${COOKIE_KEY_PREFIX}${key}`, "hide", { expires: 1 / 12 });
const hide = (key: string) => Cookies.set(`${COOKIE_KEY_PREFIX}${key}`, "hide", { expires: 365 });

const ToastContent = ({ cookieKey, content, usageLevel }) => {
     const { t } = useTranslation();

     const handleHideFor1Day = (e) => {
          e.preventDefault();
          hideFor1Day(cookieKey);
     }

     const handleHideFor1Hour = (e) => {
          e.preventDefault();
          hideFor1Hour(cookieKey);
     }

     const handleHideFor2Hours = (e) => {
          e.preventDefault();
          hideFor2Hours(cookieKey);
     }

     const handleHide = (e) => {
          e.preventDefault();
          hide(cookieKey);
     }

     return <div className="notificationContentContainer">
          {content}
          <div className="notificationHideActions">
               {(!usageLevel || usageLevel === 2) && (
                    <>
                         <a href="#" onClick={handleHideFor1Day}>{t('Error.hideForADay')}</a>
                         <a href="#" onClick={handleHide}>{t('Error.hideForever')}</a>
                    </>
               )
               }
               {usageLevel === 3 && (
                    <a href="#" onClick={handleHideFor2Hours}>{t('Error.hideFor2Hours')}</a>
               )
               }
               {usageLevel === 4 && (
                    <a href="#" onClick={handleHideFor1Hour}>{t('Error.hideFor1Hour')}</a>
               )
               }
          </div>
     </div>
}

const isAdmin = (permission: UserPermission): boolean =>
     permission.isAdminUser || permission.isAmethystAdmin || permission.isDiamondAdmin ||
     permission.isEmeraldAdmin || permission.isRubyAdmin ||
     permission.isSystemConfigAdmin || permission.isUserManagementAdmin ||
     permission.isTabletCtrlAdmin || permission.isSapphireAdmin;

const NotificationsContainer = () => {

     const usageAlertLevel = useRef<UsageLevel>(null);
     const { t } = useTranslation();
     const userPermission = getGlobalStates().userPermission;

     const choppingRequests = useChoppedAssetSelector(state => state.choppedAssetStore.currentChoppingRequests);
     const choppingRef = useRef(choppingRequests);
     useEffect(() => {
          choppingRef.current = [...choppingRequests];
     }, [choppingRequests])

     const hasPermission = (error: AetherNotification): boolean => {
          if (error.level === 'SYSTEM') {
               return isAdmin(userPermission);
          }
          if (isIDNotSet(error.app)) {
               return true;
          }
          return error.app.value === getGlobalStates().appID.value;
     }

     useEffect(() => {
          let toastId = null; // keep track of the current toast ID
          registerMsgHandler(NOTIFY_CAPACITY_LIMIT, (payload) => {
               if (getGlobalStates().eventBus.rebooting) {
                    // don't show errors on user-triggered reboot
                    return;
               }
               const notificationLevel = JSON.parse(payload) as CapacityAlert;
               const cookieKey = notificationLevel.usageLevel;

               if (choppingRef.current.length > 0) {
                    usageAlertLevel.current = null;
                    toastId = null;
                    toast.dismiss(toastId);
                    return;
               }

               if (!shouldShow(cookieKey)) {
                    usageAlertLevel.current = null;
                    return;
               }

               if (notificationLevel.usageLevel !== usageAlertLevel.current) {
                    usageAlertLevel.current = notificationLevel.usageLevel
                    // update the current toast if it exists
                    if (toastId) {
                         if (notificationLevel.usageLevel === "USAGE_LEVEL_1") {
                              toast.dismiss(toastId);
                              usageAlertLevel.current = notificationLevel.usageLevel;
                              toastId = null;
                              return;
                         }

                    }
                    else {
                         if (notificationLevel.usageLevel === "USAGE_LEVEL_2" && isAdmin(userPermission)) {
                              toastId = toast.error(<ToastContent cookieKey={cookieKey}
                                   content={<>
                                        <>
                                             <span style={{ fontWeight: '600' }}>{t('MonitoringApp.SystemInfo.UL_2Message')}</span>
                                             <span>{t('MonitoringApp.SystemInfo.USAGE_LEVEL_2')}</span>
                                        </>
                                        <p>{t('MonitoringApp.SystemInfo.usageContact')}
                                             <a href="mailto:capacityalert@userful.com">capacityalert@userful.com</a>
                                             {t('MonitoringApp.SystemInfo.usageContact2')}
                                        </p>
                                   </>}
                                   usageLevel={2}
                              />

                                   , {
                                        ...usageAlertOptions,
                                        onClose: () => {
                                             toastId = null; // update the toastId to null when the user manually closes the toast
                                        },
                                        autoClose: false
                                   });

                         }

                         else if (notificationLevel.usageLevel === "USAGE_LEVEL_3") {
                              toastId = toast.error(<ToastContent cookieKey={cookieKey}
                                   content={<>
                                        <>
                                             <span style={{ fontWeight: '600' }}>{t('MonitoringApp.SystemInfo.UL_3Message')}</span>
                                             <span>{t('MonitoringApp.SystemInfo.USAGE_LEVEL_3')}</span>
                                        </>

                                        <p>{t('MonitoringApp.SystemInfo.usageContact')}
                                             <a href="mailto:capacityalert@userful.com">capacityalert@userful.com</a>
                                             {t('MonitoringApp.SystemInfo.usageContact3')}
                                        </p>
                                   </>}
                                   usageLevel={3}
                              />

                                   , {
                                        ...usageAlertOptions,
                                        onClose: () => {
                                             toastId = null;
                                        },
                                        autoClose: false
                                   });
                         }

                         else if (notificationLevel.usageLevel === "USAGE_LEVEL_4") {
                              toastId = toast.error(
                                   <ToastContent
                                        cookieKey={cookieKey}
                                        content={
                                             <>
                                                  <>
                                                       <span style={{ fontWeight: '600' }}>{t('MonitoringApp.SystemInfo.UL_4Message')}</span>
                                                       <span>{t('MonitoringApp.SystemInfo.USAGE_LEVEL_4')}</span>
                                                  </>
                                                  <p>{t('MonitoringApp.SystemInfo.usageContact')}
                                                       <a href="mailto:capacityalert@userful.com">
                                                            capacityalert@userful.com</a>
                                                       {t('MonitoringApp.SystemInfo.usageContact4')}
                                                  </p>
                                             </>
                                        }
                                        usageLevel={4}
                                   />

                                   , {
                                        ...usageAlertOptions,
                                        onClose: () => {
                                             toastId = null;
                                        },
                                        autoClose: false
                                   });
                         }

                    }

               }
          });

          registerMsgHandler(NOTIFY_NOTIFICATION, (payload) => {
               if (getGlobalStates().eventBus.rebooting) {
                    // don't show errors on user-triggered reboot
                    return;
               }
               const errorNotification = JSON.parse(payload) as AetherNotification;
               const errorData = (errorNotification.data as VEMNotificationData);
               if (errorData["@type"] !== 'vem') {
                    // aether error not implemented
                    return;
               }

               const errorType = `${interpretErrorTypePreffix(errorData)}${errorData.errorType}`
               if (errorNotification.severity === 'FATAL') {
                    const translationKey = `Error.notificationErrors.${errorType}`;
                    if (!shouldShow(translationKey) || !hasPermission(errorNotification)) {
                         return;
                    }
                    if (i18next.exists(translationKey)) {
                         const toastId = `${errorType}-${getMultiWindowGroupName(errorNotification.name)}-${errorData.errorData}`;
                         toast.error(<ToastContent cookieKey={translationKey} usageLevel={null}
                              content={t(translationKey, { name: getMultiWindowGroupName(errorNotification.name), 
                                   errorData: errorData?.errorData, 
                                   media: errorData.media?.replace("file:///var/source-content/", "") })} />,
                              { ...toastOptions, toastId, autoClose: false, position: toast.POSITION.TOP_CENTER });
                    } else {
                         console.error(errorNotification.msg)
                    }
               } else if (errorNotification.severity === 'WARN') {
                    const translationKey = `Error.notificationWarnings.${errorType}`;
                    if (!shouldShow(translationKey) || !hasPermission(errorNotification)) {
                         return;
                    }
                    if (i18next.exists(translationKey)) {
                         toast.warn(<ToastContent cookieKey={translationKey} usageLevel={null}
                              content={t(translationKey, { name: getMultiWindowGroupName(errorNotification.name), 
                                   media: errorData.media?.replace("file:///var/source-content/", "") })} />,
                              { ...toastOptions, toastId: errorType, autoClose: false, position: toast.POSITION.TOP_CENTER });
                    } else {
                         console.warn(errorNotification.msg)
                    }
               }
          });
          if (isAdmin(userPermission)) {
               registerMsgHandler(NOTIFY_UCLIENT_UPDATED, (payload) => {
                    if (getGlobalStates().eventBus.rebooting) {
                         // don't show errors on user-triggered reboot
                         return;
                    }
                    const data = JSON.parse(payload);
                    if (isUClientOffline(data) && data.lockStatus === "LOCKED") {
                         const key = "UCLIENT_OFFLINE";
                         if (!shouldShow(key)) {
                              return;
                         }
                         const uClientName = data.transientData.name === null || data.transientData.name === "" ? `${data.transientData.hardwareData.manufacturer}-${data.transientData.hardwareData.serialNumber}` : data.transientData.name;

                         if (!toast.isActive(`${data.id.value}-uClient`))
                              toast.error(<ToastContent cookieKey={key} usageLevel={null}
                                   content={`${t('Shared.uClient')} ${t('Shared.withName')} ${uClientName} ${t('Shared.uClientOffline')}`} />,
                                   { ...toastOptions, toastId: `${data.id.value}-uClient`, autoClose: false, position: toast.POSITION.TOP_RIGHT });
                    }
                    else
                         toast.dismiss(`${data.id.value}-uClient`);
               })
          }
     }, []);

     return <ToastContainer enableMultiContainer transition={Bounce} containerId="customContainer" className="customContainer" />;
};

export default NotificationsContainer;
