import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { requestListUC } from "../displays/msgs/UClientsMsgSender";
import { requestListZC } from "../displays/msgs/ZeroClientsMsgSender";
import destinationStore, { DestinationsContext } from "./destinationStore";
import { requestListDestinations, requestListPhysicalEndpoint, requestListVideoWallCalibration, requestListVideoWallEndpoint } from "./msgs/MsgSender";
import "./msgs/ClientMsgReceiver";
import { requestListOnboards } from "../displays/msgs/OnboardMsgSender";

interface IProps {
    children: any;
}

function DestinationsProvider(props: IProps) {
    useEffect(() => {
        requestListOnboards();
        requestListUC();
        requestListZC();
        requestListPhysicalEndpoint();
        requestListVideoWallEndpoint();
        requestListDestinations();
        requestListVideoWallCalibration();
    }, []);

    return (
        <Provider store={destinationStore} context={DestinationsContext}>
            {props.children}
        </Provider>
    );
}

export default DestinationsProvider;
