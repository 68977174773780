import { getGlobalStates, apiGet } from "userful-chronos-app-common-js/dist/globalstates/globalStates";

import {
    REQUEST_SYSTEM_WORK_IN_PROGRESS_STATUS
} from 'userful-chronos-app-common-js/dist/message/messageTypeDefinitions/config/failoverInProgress';
import { SystemWorkInProgressStatus } from 'userful-chronos-app-common-js/dist/models/config/failover';
import './failoverInProgressMsgReciever';
import { AxiosResponse } from 'axios';
import { FAILOVER_STATUS_API } from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/config/failoverInProgress";
import failoverInProgressStore from "../failoverInProgressStore";
import { failoverInProgressActions } from "../failoverInProgressSlice";

const getError = (response: any) => {
    return ({
        success: false,
        arg: response.data.arg ? response.data.arg : "",
        errorCode: response.data.errorCode ? response.data.errorCode : "",
        moduleID: response.data.moduleID ? response.data.moduleID : "",
        severity: response.data.severity ? response.data.severity : '',
        msg: response.data.unlocalizedMsg ? response.data.unlocalizedMsg : ""
    }
    );
}


export const checkReponseIsValid = (name: string, response: AxiosResponse): boolean => {
    if (response.status >= 400) {
        console.log(`${name} error: ${response.status}`);
        return false;
    }
    if (!response.data) {
        console.log(`${name} empty response`);
        return false;
    }
    return true;
}

export const requestFailoverInProgressStatus = () => {
    // getGlobalStates().eventBus.sendMsg(REQUEST_SYSTEM_WORK_IN_PROGRESS_STATUS);
    const t1 = getGlobalStates().host + FAILOVER_STATUS_API;

    apiGet(t1).then((response) => {
        if (!checkReponseIsValid('failover status', response)) {
            const getErrorMessage = getError(response);
            return;
        }
        console.debug(`receive failover status`, response.data);
        const data = response.data as SystemWorkInProgressStatus;
        failoverInProgressStore.dispatch(failoverInProgressActions.setFailoverInProgress(data));
    }).catch((err) => console.warn("failed", err));
};

