import { configureStore } from "@reduxjs/toolkit";
import React from "react";
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook } from "react-redux";
import uclientsSlice from "../displays/uclientsSlice";
import zeroclientsSlice from "../displays/zeroclientsSlice";
import audioDestinationsSlice from "./audioDestinationsSlice";
import destinationGroupsSlice from "./destinationGroupsSlice";
import displayDestinationsSlice from "./displayDestinationsSlice";
import endpointsSlice from "./endpointsSlice";
import videowallDestinationsSlice from "./videowallDestinationsSlice";
import videoWallCalibrationSlice from "./videoWallCalibrationSlice";
import onboardSlice from "../displays/onboardSlice";

export const DestinationsContext = React.createContext(null);

const destinationStore = configureStore({
    reducer: {
        uclientsStore: uclientsSlice,
        zeroclientsStore: zeroclientsSlice,
        onboardStore: onboardSlice,
        endpointsStore: endpointsSlice,
        displaysStore: displayDestinationsSlice,
        audiosStore: audioDestinationsSlice,
        videoWallCalibration: videoWallCalibrationSlice,
        videowallsStore: videowallDestinationsSlice,
        destinationGroupsStore: destinationGroupsSlice,
    },
});
export default destinationStore;

export type DestinationRootState = ReturnType<typeof destinationStore.getState>;
export type DestinationDispatch = typeof destinationStore.dispatch;
export const useDestinationSelector: TypedUseSelectorHook<DestinationRootState> = createSelectorHook(DestinationsContext);
export const useDestinationDispatch = createDispatchHook<DestinationDispatch>(DestinationsContext);
