import { faClose, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getAppNamesFromUsageData, getPlaylistNamesFromUsageData, SamUsageDataResponse } from 'userful-chronos-app-common-js/dist/models/sam/Common';
import Button from '../Button/Button';

interface Iprops {
    onForceUpdate: () => void;
    show: boolean;
    onCancel: () => void;
    pendingUpdateDate: SamUsageDataResponse;
    itemName: string;
}

export default function ForceUpdateDialog(props: Iprops) {
    const { t } = useTranslation();

    const [show, setShow] = useState(props.show);

    useEffect(() => {
        if (props.show) {
            setShow(true);
        }
    }, [props.show])

    const close = () => {
        props.onCancel();
        setShow(false);
    }
    if (!props.pendingUpdateDate) {
        return null;
    }

    return (
        <Modal
                show={show}
                onHide={close}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <div>
                        <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: ' #D13E2C', marginRight: '8px' }} />{t('CommonUI.forceUpdateTitle')}
                    </div>
                    <div className='custom-close-btn' onClick={close}>
                        <FontAwesomeIcon icon={faClose} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <strong style={{ fontWeight: 600, color: "#D13E2C" }}>{`${props.itemName}`}</strong> {t('CommonUI.forceConfirm')}:
                    {getAppNamesFromUsageData(props.pendingUpdateDate).map(item => <li key={`app-name-${item}`}>{item}</li>)}
                    {getPlaylistNamesFromUsageData(props.pendingUpdateDate).map(item => <li key={`playlist-name-${item}`}>{`${t('Shared.playlist')} "${item}"`}</li>)}
                    {t('CommonUI.forceUpdateConfirm')}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={close}>{t('Shared.cancel')}</Button>
                    <Button variant='danger'
                        onClick={props.onForceUpdate}
                    >
                        {t('Shared.update')} 
                    </Button>
                </Modal.Footer>
            </Modal>
    );
}

