import React from "react";

export const hasChildren = (node: React.ReactNode): boolean => {
    if (node === null || node === undefined) {
        return false;
    }

    if (typeof node === 'boolean') {
        return false;
    }

    if (typeof node === 'string' || typeof node === 'number') {
        return node !== '';
    }

    if (Array.isArray(node)) {
        return node.some(hasChildren);
    }

    if (React.isValidElement(node)) {
        return !!node.props.children;
    }

    return false;
}

export const getComponentsValue = (node: React.ReactNode): string => {
    if (node === null || node === undefined) {
        return "";
    }

    if (typeof node === 'boolean') {
        return node.valueOf().toString();
    }

    if (typeof node === 'string' || typeof node === 'number') {
        return node.valueOf().toString();
    }

    if (Array.isArray(node)) {
        return node.length.toString();
    }

    if (React.isValidElement(node)) {
        const children = React.Children.toArray(node.props.children);
        if (children.length === 1) {
            const child = children[0];
            if (typeof child === 'string') {
                return child;
            } else {
                return getComponentsValue(child);
            }
        } else {
            return children.length + "";
        }
    }

    return "";
}