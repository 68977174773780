import { useEffect, useState } from "react";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { faAngleDown, faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronsLeft, faChevronsRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import Button from "../../Button/Button";
import { ITable } from "../userfulTableInterface";
import React from "react";

export interface IPagination {
    dataPerPage: number;
    pages: number;
    currentPage: number;
}

interface IProps {
    table: ITable;
    replace?: React.ReactNode;
    onRowClick?(id: StringID): void;
    stretch?: boolean; // Stretch the width
    draggable?: boolean; // Allows dragging
    nonInteractive?: boolean; // Do not allow interaction with the row
    pagination?: boolean; // Add pagination
    sorting?: boolean; // Add sorting
    multiSelect?: boolean; // Allow to multiselect
    onPaginationUpdate?(pagination: IPagination): void;
}

export default function UserfulTablePagination(props: IProps) {

    const [pagination, setPagination] = useState<IPagination>({
        dataPerPage: 15,
        pages: Math.ceil(props.table.data.length / 15),
        currentPage: 1
    });

    const handleIncreasePage = () => {
        let currentPage = pagination.currentPage + 1;
        if (currentPage > pagination.pages) currentPage = pagination.pages;

        setPagination({ ...pagination, currentPage: currentPage });
    }
    const handleDecreasePage = () => {
        let currentPage = pagination.currentPage - 1;
        if (currentPage <= 0) currentPage = 1;

        setPagination({ ...pagination, currentPage: currentPage });
    }
    const handleDataPerPageChange = (num: number) => {
        const dataPerPage = num;
        const pages = Math.ceil(props.table.data.length / dataPerPage);
        const currentPage = 1;

        setPagination({
            dataPerPage: dataPerPage,
            pages: pages,
            currentPage: currentPage
        });
    }
    const insertDropdown = () => {
        return <Dropdown className="operationCenterDropdown">
            <Dropdown.Toggle bsPrefix="pagination-dropdown" as={Button}>
                {pagination.dataPerPage} per Page
                <FontAwesomeIcon icon={faAngleDown} />
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdownMenuSwitchActiveSource">
                <Dropdown.Item
                    className="dropdownItem"
                    onClick={() => handleDataPerPageChange(15)}
                >
                    <div className="textInside">15 per Page</div>
                </Dropdown.Item>
                <Dropdown.Item
                    className="dropdownItem"
                    onClick={() => handleDataPerPageChange(30)}
                >
                    <div className="textInside">30 per Page</div>
                </Dropdown.Item>
                <Dropdown.Item
                    className="dropdownItem"
                    onClick={() => handleDataPerPageChange(40)}
                >
                    <div className="textInside">40 per Page</div>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    }

    const insertPages = () => {
        return <div className="common-userful-table-pages">
            <Button iconOnly disabled={pagination.currentPage === 1} variant="secondary" onClick={(e) => setPagination({ ...pagination, currentPage: 1 })}><FontAwesomeIcon icon={faChevronsLeft} /></Button>
            <Button iconOnly disabled={pagination.currentPage - 1 <= 0} variant="secondary" onClick={(e) => handleDecreasePage()}><FontAwesomeIcon icon={faChevronLeft} /></Button>
            <div className="pagination-indicator"><p className="pagination-indicator-main">{pagination.currentPage}</p><p className="pagination-indicator-secondary">/{pagination.pages}</p></div>
            <Button iconOnly disabled={pagination.currentPage + 1 > pagination.pages} variant="secondary" onClick={(e) => handleIncreasePage()}><FontAwesomeIcon icon={faChevronRight} /></Button>
            <Button iconOnly disabled={pagination.currentPage === pagination.pages || pagination.currentPage === pagination.pages + 1} variant="secondary" onClick={(e) => setPagination({ ...pagination, currentPage: pagination.pages })} ><FontAwesomeIcon icon={faChevronsRight} /></Button>
        </div >
    }

    useEffect(() => {
        const dataPerPage = pagination.dataPerPage;
        const pages = Math.ceil(props.table.data.length / dataPerPage);
        let currentPage = pagination.currentPage > pages ? pages : pagination.currentPage;

        if (currentPage <= 0) currentPage = 1;

        setPagination({
            dataPerPage: dataPerPage,
            pages: pages,
            currentPage: currentPage
        });

    }, [props.table]);

    useEffect(() => {
        if (props.onPaginationUpdate) props.onPaginationUpdate(pagination);
    }, [pagination]);


    return (
        <div className="tfoot-wrapper" onClick={(e) => { e.stopPropagation() }}>
            <div className="tfoot-container">
                {insertDropdown()}
                <p>{(pagination.currentPage - 1) * pagination.dataPerPage + 1} - {pagination.currentPage * pagination.dataPerPage > props.table.data.length ? props.table.data.length : pagination.currentPage * pagination.dataPerPage} of {props.table.data.length}</p>
            </div>
            {insertPages()}
        </div>
    );
}