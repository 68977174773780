import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SamSourceSpec } from 'userful-chronos-app-common-js/dist/models/sam/SAMSourceSpec';
import { SourceSpecParam } from 'userful-chronos-app-common-js/dist/models/source';
import { getTranslatedParamName } from '../SourceFormUtil';
import ParamHelpInfo from './ParamHelpInfo';

interface IProps {
    param: SourceSpecParam,
    selectedSourceSpec?: SamSourceSpec,
    input: any;
    onChange: any;
    numberErrors: object;
    requiredErrors: object;
}

export default function NumberWidget(props: IProps) {
    const { t } = useTranslation();


    if (!props.param) {
        return null;
    }

    const findMax = props.param.additionalParams && props.param.additionalParams.find((ele) => ele.name === "maximum");
    const findMin = props.param.additionalParams && props.param.additionalParams.find((ele) => ele.name === "minimum");
    const maxValue = findMax && findMax.value;
    const minValue = findMin && findMin.value;
    const hasError = props.requiredErrors[props.param.name] || props.numberErrors[props.param.name];

    const errorMsg = () => {
        if (props.requiredErrors[props.param.name]) {
            return `${t('CommonUI.Sources.selectError')} ${getTranslatedParamName(props.param, props.selectedSourceSpec, t)}`;
        }
        if (maxValue && minValue) {
            return t('CommonUI.Sources.numberError1', { minValue, maxValue });
        }
        return t('CommonUI.Sources.numberError2');
    }

    return (
        <>
            <Form.Group className="input-wrapper" controlId="formSourceTypeNumber">
                <Form.Label className="heading1">
                    {getTranslatedParamName(props.param, props.selectedSourceSpec, t)}
                    <ParamHelpInfo param={props.param} selectedSourceSpec={props.selectedSourceSpec} className="numberWidgest" />
                </Form.Label>
                <Form.Control
                    type={props.param.type}
                    name={props.param.name}
                    value={props.input[props.param.name]}
                    max={maxValue ? maxValue : ''}
                    min={minValue ? minValue : ''}
                    onChange={props.onChange}
                    isInvalid={!!hasError}
                />
                <Form.Control.Feedback type="invalid">{errorMsg()}</Form.Control.Feedback>
            </Form.Group>
        </>
    );
}