import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import {
    RESPOND_SYSTEM_WORK_IN_PROGRESS_STATUS
} from 'userful-chronos-app-common-js/dist/message/messageTypeDefinitions/config/failoverInProgress';
import {
    SystemWorkInProgressStatus
} from "userful-chronos-app-common-js/dist/models/config/failover";
import failoverInProgressStore from "../failoverInProgressStore";
import { failoverInProgressActions } from "../failoverInProgressSlice";

registerMsgHandler(RESPOND_SYSTEM_WORK_IN_PROGRESS_STATUS, (payload) => {
    console.warn("receive RESPOND_SYSTEM_WORK_IN_PROGRESS_STATUS");
    const data = JSON.parse(payload) as SystemWorkInProgressStatus;
    failoverInProgressStore.dispatch(failoverInProgressActions.setFailoverInProgress(data));
});

