import { faCheck, faEllipsisV, faFolder, faMultiply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCube, faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { SamSourceDef } from "userful-chronos-app-common-js/dist/models/sam/SAMSource";
import { t } from "i18next";
import { APP_IDS, StringID, UserfulSecurityData } from "userful-chronos-app-common-js/dist/models/common";
import { Dropdown } from "react-bootstrap";
import { hasClearanceLevelForAction } from "userful-chronos-app-common-js/dist/authenticataion/roles";
import { AssignOwnerToContent } from "../displays";
import { SamSourceForm, SourceDeletionDialog } from "../sourceassets";
import { UserPermission } from "userful-chronos-app-common-js/dist/models/user-permission";
import SourceActionsMenu from "./sources/SourceActionsMenu";
import { CompGroup } from "userful-chronos-app-common-js/dist/models/group/Groups";
import FolderActionsMenu from "./sources/FolderActionsMenu";
import { AppsConfig } from "../SAM/formUtils";
import UserfulTooltip from "../Widgets/UserfulTooltip";
import { ITable, IRowData } from "./userfulTableInterface";
import React from "react";

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function breakWordsOnCaseChange(string: string) {
    return string.replace(/([A-Z])/g, " $1").trim();
}

export function prettifyString(string: string) {
    let word = t("Prettify." + string.toLowerCase());

    if (word && word.length > 0) {
        return word;
    }

    const brokenString = breakWordsOnCaseChange(string);
    return capitalizeFirstLetter(brokenString);
}

export function prettifyAppIDs(appIDs: StringID[]) {
    return <>
        {appIDs.map((appID, index) => {
            const app = AppsConfig.find((a) => a.id === appID.value);

            if (app)
                return <UserfulTooltip key={index} title={app.name}>{app.titleIcon}</UserfulTooltip>;
        })}
    </>;
}

export function prettifyTags(tags: StringID[]) {
    return <>
        {tags.map((m, index) => {
            return <div key={m.value} className="tagNameObject tagNameObjectNonInteractive">
                {m.value}
            </div>;
        })}
    </>;
}

export const convertSourcesToTable = (objects: { object: SamSourceDef | CompGroup, type: "source" | "folder" }[]): ITable => {
    const table: ITable = { name: "Sources", columns: [], data: [], org: { singular: "Row", plural: "Rows" } };

    // Enter Headers
    table.columns = [
        { header: "Name" },
        { header: "Description", hidden: true },
        { header: "Type" },
        { header: "Resolution" },
        { header: "Tags" },
        { header: "Scaling" },
        { header: "App Permissions" },
        { header: "Source Sharing" },
    ];

    for (let i = 0; i < objects.length; i++) {
        const object = objects[i];

        const row: IRowData = {
            id: { value: object.object.id.value },
            columns: [
                {
                    component: <div className="column-container-l"><FontAwesomeIcon className={`${object.type === "folder" ? "yellow-icon" : "blue-icon"}`} icon={object.type === "folder" ? faFolder : faCube} /> <p>{object.object.name}</p></div>,
                    sortBy: object.object.name,
                },
                {
                    component: <p>{object.object.description}</p>,
                },
                {
                    component: <p>{object.type === "source" ? prettifyString((object.object as SamSourceDef).type.name) : <FontAwesomeIcon icon={faMinus}></FontAwesomeIcon>}</p>,
                },
                {
                    component: object.type === "source" ? <p>{(object.object as SamSourceDef).displayResolution.width} x {(object.object as SamSourceDef).displayResolution.height}</p> : <FontAwesomeIcon icon={faMinus}></FontAwesomeIcon>,
                    sortBy: object.type === "source" ? (object.object as SamSourceDef).displayResolution.width / (object.object as SamSourceDef).displayResolution.height : "",
                },
                {
                    component: <div className="column-container">{object.type === "source" ? prettifyTags((object.object as SamSourceDef).orgData.tagsSet.tags) : <FontAwesomeIcon icon={faMinus}></FontAwesomeIcon>}</div>,
                    sortBy: object.type === "source" ? (object.object as SamSourceDef).orgData.tagsSet.tags.length : "",
                    hideTooltip: true,
                },
                {
                    component: <p>{object.type === "source" ? prettifyString((object.object as SamSourceDef).scaling) : <FontAwesomeIcon icon={faMinus}></FontAwesomeIcon>}</p>,
                },
                {
                    component: <div className="column-container">{object.type === "source" ? prettifyAppIDs((object.object as SamSourceDef).orgData.appIDs.appIDs) : <FontAwesomeIcon icon={faMinus}></FontAwesomeIcon>}</div>,
                    sortBy: object.type === "source" ? (object.object as SamSourceDef).orgData.appIDs.appIDs.length : "",
                    hideTooltip: true,
                },
                {
                    component: <p>{object.type === "source" ? prettifyString((object.object as SamSourceDef).sharingMode) : <FontAwesomeIcon icon={faMinus}></FontAwesomeIcon>}</p>,
                },
            ],
            actionsComponent: object.type === "source" ? <SourceActionsMenu value={(object.object as SamSourceDef)}></SourceActionsMenu> : <FolderActionsMenu value={(object.object as CompGroup)} />,
            isDraggable: true,
            isDroppable: object.type === "folder" ? true : false,
        };
        table.data.push(row);
    }

    return table;
};

export const convertDestinationOptionsToTable = (destinationOptions: { value: string, label: string, type: string }[], selectedDestinations: {
    value: string;
    label: string;
    type: string;
}[]): ITable => {
    const table: ITable = { name: "Destinations", columns: [], data: [] };

    // Enter Headers
    table.columns = [
        { header: "Name", width: "calc(100% - 40px)", minWidth: 76 },
        { header: "", width: 40, minWidth: 40 },
    ];

    for (let i = 0; i < destinationOptions.length; i++) {
        const destinationOption = destinationOptions[i];
        const foundInSelected = selectedDestinations.find((d) => d.value === destinationOption.value);

        const row: IRowData = {
            id: { value: destinationOption.value },
            columns: [
                {
                    component: <p className="limit">{destinationOption.label}</p>,

                },
                {
                    component: <>
                        {foundInSelected && <FontAwesomeIcon className="green-icon" icon={faCheck} />}
                        {!foundInSelected && <FontAwesomeIcon className="def-icon" icon={faPlus} />}
                    </>

                },

            ],
            isChecked: foundInSelected ? true : false
        };
        table.data.push(row);
    }

    return table;
};

export const convertCanvasesOptionsToTable = (destinationOptions: { value: string, label: string }[], selectedDestinations: {
    value: string;
    label: string;
}[]): ITable => {
    const table: ITable = { name: "Canvases", columns: [], data: [] };

    // Enter Headers
    table.columns = [
        { header: "Name", width: "calc(100% - 40px)", minWidth: 76 },
        { header: "", width: 40, minWidth: 40 },
    ];

    for (let i = 0; i < destinationOptions.length; i++) {
        const destinationOption = destinationOptions[i];
        const foundInSelected = selectedDestinations.find((d) => d.value === destinationOption.value);

        const row: IRowData = {
            id: { value: destinationOption.value },
            columns: [
                {
                    component: <p className="limit">{destinationOption.label}</p>,

                },
                {
                    component: <>
                        {foundInSelected && <FontAwesomeIcon className="green-icon" icon={faCheck} />}
                        {!foundInSelected && <FontAwesomeIcon className="def-icon" icon={faPlus} />}
                    </>

                },

            ],
            isChecked: foundInSelected ? true : false
        };
        table.data.push(row);
    }

    return table;
};

