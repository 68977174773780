import React from 'react';
import { Form } from 'react-bootstrap';
import { HelpInfo } from '../../../sourceassets';
import { useTranslation } from 'react-i18next';

interface IProps {
    name: string;
    checked: string;
    onChange: Function;
    label: string;
    description: string;
    helpInfo?: string;
}

const CheckboxWidget = (props: IProps) => {

    return (
        <Form.Group className="input-wrapper checkbox-text-style" controlId="formSourceType">
            <Form.Check
                type="checkbox"
                name={props.name}
                checked={props.checked === "true"}
                onChange={(e) => props.onChange(e)}
                className="checkbox-select"
            />
            <div className='checkbox-label-wrapper'>
                <span style={{display: "flex"}}>
                    <span className='form-label' style={{ marginBottom: '4px' }}>{props.label}</span>
                    <HelpInfo className='powerBIHelpInfo' title={props.helpInfo} />
                </span>
               
                <span className='selectHelpText'>{props.description}</span>
            </div>
        </Form.Group>
    );
}

export default CheckboxWidget;