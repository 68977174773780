import React, { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGroup, faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { UserManagementData } from 'userful-chronos-app-common-js/dist/models/usermgt/usermgt';
import { UserfulSecurityData } from "userful-chronos-app-common-js/dist/models/common";
import _ from 'lodash';
import Form from 'react-bootstrap/Form';
import { useUsermgtSelector } from 'userful-chronos-common-store/dist/usermgt';
import { NOT_SET_VALUE } from 'userful-chronos-app-common-js/dist/models/common';
import './AssignOwnerToContent.scss';
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";

interface IProps {

    onHide(data: UserfulSecurityData): void;
    securityData: UserfulSecurityData;
    hideIcon?: boolean;

};

export function sortArray(data: any, key: string): any {

    return [...data].sort((a, b) => {
        if (a[key] < b[key]) {
            return -1;
        } else if (a[key] > b[key]) {
            return 1;
        } else {
            return 0;
        }
    });

}

export default function AssignOwnerToContent(props: IProps) {
    const { t } = useTranslation();

    const globalStates = getGlobalStates();
    const ownershipPermissions = globalStates.userPermission.canOverrideSecurityDataOwnerShip;
    const allData = useUsermgtSelector(state => state.usermgtStore.umgData);

    // it will be empty if there is no owner of the content. If there is owner then it will be array with one user (owner)
    const fetchSelectedUser = allData.users.filter((user) => user.id.value === props.securityData.userID);

    // list of all the users with check property
    const getUsersListArray = allData.users.map((user) => {
        return ({ name: user.userProfile.userName, id: user.id, check: fetchSelectedUser.length > 0 ? fetchSelectedUser[0].id.value === user.id.value : false });

    })
    // sorted list of all users with check property
    const getUsersList = sortArray(getUsersListArray, "name");
    // storing the list of users in a state
    const [getListUsersCheck, setListUsersCheck] = useState(getUsersList);

    // when any user is checked or unchecked
    const onChangeCheck = (name: string, checkBool: boolean) => {
        const newCheckList = getListUsersCheck.map((user) => ({ ...user, check: user.name === name ? checkBool : false }));
        setListUsersCheck(newCheckList);
    }


    const onHide = (expanded: boolean) => {
        if (_.isEqual(getUsersList, getListUsersCheck))
            return;
        let getSelectedUser = getListUsersCheck.filter((user) => user.check);
        const updatedSecurityData: UserfulSecurityData = {
            ...props.securityData,
            userName: getSelectedUser.length > 0 ? getSelectedUser[0].name : NOT_SET_VALUE,
            userID: getSelectedUser.length > 0 ? getSelectedUser[0].id.value : NOT_SET_VALUE
        };
        if (!expanded) {
            props.onHide(updatedSecurityData);
        }
    }

    useEffect(() => {

        // it will be empty if there is no owner of the content. If there is owner then it will be array with one user (owner)
        const fetchSelectedUser = allData.users.filter((user) => user.id.value === props.securityData.userID);
        // list of all the users with check property
        const getUsersListArray = allData.users.map((user) => {
            return ({ name: user.userProfile.userName, id: user.id, check: fetchSelectedUser.length > 0 ? fetchSelectedUser[0].id.value === user.id.value : false });

        })
        // sorted list of all users with check property
        const getUsersList = sortArray(getUsersListArray, "name");
        // storing the list of users in a state
        setListUsersCheck(getUsersList);

    }, [allData]);

    // if there is no clearance level permissions, that means the content is open
    if (props.securityData.clearanceLevelPermissions.length === 0 || !ownershipPermissions)
        return <></>;

    return (

        <NavDropdown title={<>
            {!props.hideIcon && <div className="iconDiv" > <FontAwesomeIcon icon={faUserGroup} className="mr-2" /></div>}
            {t('Shared.SetOwner')}
            {/* style is added only for dropdown in mapping group, thats why hideIcon condition is there. Currently, icon is only being displayed 
            only in mapping group dropdown. This component needs to be changed if dropdown in sources, playlist or destination is changed. */}
            <FontAwesomeIcon icon={faAngleDown} className="mr-2" style={{marginLeft: !props.hideIcon ? "98px" :""}}/>
        </>} id="nav-dropdown-assign-user" drop="end" onToggle={(expanded) => onHide(expanded)}>
            <div style={{ overflowY: "auto", maxHeight: "400px" }}>
                {getListUsersCheck.map((group, index) => (
                    <NavDropdown.Item eventKey="1" key={index} onClick={(e) => {
                        onChangeCheck(group.name, !group.check);
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                        <div className="checkOwnerUserName">
                            <Form.Check
                                aria-label="groupName"
                                id={`${group.name}${group.check ? "-checked" : ""}`}
                                onChange={(e) => {
                                    onChangeCheck(group.name, !group.check);
                                }}
                                name={group.name}
                                checked={group.check}
                                onClick={(e) => e.stopPropagation()} />
                            <div className="checkOwnerName">{group.name}</div>
                        </div>
                    </NavDropdown.Item>
                ))}
            </div>

        </NavDropdown>

    );
}