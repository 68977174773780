import React from 'react';
import { useTranslation } from 'react-i18next';
import { SamSourceSpec } from 'userful-chronos-app-common-js/dist/models/sam/SAMSourceSpec';
import { SourceSpecParam } from 'userful-chronos-app-common-js/dist/models/source';
import { getTranslatedParamHelp, getTranslatedParamName } from '../SourceFormUtil';
import CustomSwitch from '../../../CustomSwitch/CustomSwitch';

interface IProps {
    selectedSourceSpec?: SamSourceSpec,
    param: SourceSpecParam,
    input: any;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function CheckboxWidget(props: IProps) {
    const { t } = useTranslation();

    const currentValue = props.input[props.param.name] === 'true' || props.input[props.param.name] === true;
    const handleToggle = () => {
        const updatedValue = !currentValue;
        props.onChange({
            target: {
                checked: updatedValue,
                name: props.param.name,
                type: "checkbox",
            }
        } as React.ChangeEvent<HTMLInputElement>)
    }

    return (<>
        <CustomSwitch
            check={currentValue}
            name={getTranslatedParamName(props.param, props.selectedSourceSpec, t)}
            description={getTranslatedParamHelp(props.param, props.selectedSourceSpec, t)}
            handleClick={handleToggle}
        />
    </>
    );
}