import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SamSourceDef } from "userful-chronos-app-common-js/dist/models/sam/SAMSource";
import Button from "../../Button/Button";
import { useTranslation } from "react-i18next";
import { sourceSliceActions, useSourceAssetDispatch, useSourceAssetSelector } from "userful-chronos-common-store/dist/sourceassets";
import { faCircleNotch, faClose, faTrashCan, faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { getAppNamesFromUsageData, getPlaylistNamesFromUsageData } from "userful-chronos-app-common-js/dist/models/sam/Common";
import UserfulTooltip from "../../Widgets/UserfulTooltip";
import "./Source.scss";
import { Dropdown } from 'react-bootstrap';


interface IProps {
    source: SamSourceDef;
    onCard?: boolean;
}

export default function SourceDeletionDialog(props: IProps) {
    const { t } = useTranslation();
    const pendingDeleteSource = useSourceAssetSelector((state) => state.sourceStore.pendingDeleteSource);

    const dispatch = useSourceAssetDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => {
        dispatch(sourceSliceActions.clearPendingUpdateOrDelete());
        setShow(false);
    };
    const handleShow = () => {
        setShow(true);
    };

    useEffect(() => {
        if (pendingDeleteSource && pendingDeleteSource.receivedResponse && pendingDeleteSource.usageData.length < 1) {
            handleClose();
        }
    }, [pendingDeleteSource]);

    const handleDelete = () => {
        dispatch(sourceSliceActions.deleteSourceToServer(props.source.id.value));
    };

    const handleForceDelete = () => {
        dispatch(sourceSliceActions.forceDeleteSourceToServer(props.source.id.value));
        setShow(false);
    };

    const isDeleting = pendingDeleteSource && !pendingDeleteSource.receivedResponse;
    const showForceDeleteConfirm = pendingDeleteSource && pendingDeleteSource.receivedResponse && pendingDeleteSource.usageData.length > 0;

    return (
        <>
            {props.onCard ? (
                <UserfulTooltip placement="top" title={t("Shared.delete")}>
                    <div className="cardButton delete-source-button" onClick={handleShow}>
                        <FontAwesomeIcon icon={faTrashCan} />
                    </div>
                </UserfulTooltip>
            ) : (
                <Dropdown.Item as="li" eventKey="2" id={`${props.source.id.value}-source-delete`} onClick={handleShow}>
                    <div >{t("Shared.delete")}</div>
                </Dropdown.Item>
            )}
            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <div>
                        <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: " #D13E2C", marginRight: "8px" }} />
                        {t("CommonUI.Sources.deleteTitle")}
                    </div>
                    <div className="custom-close-btn" onClick={handleClose}>
                        <FontAwesomeIcon icon={faClose} />
                    </div>
                </Modal.Header>
                {!showForceDeleteConfirm && (
                    <Modal.Body>
                        {t("CommonUI.Sources.deleteConfirm")} <strong style={{ fontWeight: 600, color: "#D13E2C" }}>{`${props.source.name}`}</strong>?
                    </Modal.Body>
                )}
                {showForceDeleteConfirm && (
                    <Modal.Body>
                        <strong style={{ fontWeight: 600, color: "#D13E2C" }}>{`${props.source.name}`}</strong> {t("CommonUI.forceConfirm")}:
                        {getAppNamesFromUsageData(pendingDeleteSource).map((item) => (
                            <li key={`app-name-${item}`}>{t(item)}</li>
                        ))}
                        {getPlaylistNamesFromUsageData(pendingDeleteSource).map((item) => (
                            <li key={`playlist-name-${item}`}>{`${t("Shared.playlist")} "${item}"`}</li>
                        ))}
                        {t("CommonUI.forceDeleteConfirm")}
                    </Modal.Body>
                )}
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {t("Shared.cancel")}
                    </Button>
                    <Button variant="danger" disabled={isDeleting} onClick={showForceDeleteConfirm ? handleForceDelete : handleDelete}>
                        {t("Shared.delete")} {isDeleting && <FontAwesomeIcon icon={faCircleNotch} spin />}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
