import { faEllipsisVertical } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Button } from "react-bootstrap";
import RemoteManagementSetup from "./RemoteManagementSetup";
import { IClusterInfo } from "../../../../model/CarbonExternalModels";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";

interface IProps {
    clusterID: StringID;
    constellationID: StringID;
}

export default function ClusterCardDropdown(props: IProps) {

    return (
        <Dropdown
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <Dropdown.Toggle bsPrefix="standard-dropdown-toggle" as={Button}>
                <FontAwesomeIcon icon={faEllipsisVertical} />
            </Dropdown.Toggle>

            <Dropdown.Menu
                className="dropdownMenuSwitchShort"
                onKeyDown={(e) => e.stopPropagation()}
                onKeyUp={(e) => e.stopPropagation()}
            >
                <RemoteManagementSetup clusterID={props.clusterID} constellationID={props.constellationID}/>
            </Dropdown.Menu>
        </Dropdown>
    );
}
