import { useState } from "react";
import React from "react";
import { faSort, faSortAsc, faSortDesc } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IRowData } from "../userfulTableInterface";
import ColumnResizeBar from "../components/ColumnResizeBar";
import UserfulTooltip from "../../Widgets/UserfulTooltip";

interface IProps {
    header: string;
    index: number;
    rows: IRowData[];
    selectedRows: IRowData[];
    tempSelected: IRowData;
    pagination: { currentPage: number, dataPerPage: number };
    columnSelector: { [name: string]: boolean };
    width: number;
    replace?: React.ReactNode;
    onWidthChange?(width: number): void;
    onRowClick?(event, row: IRowData): void;
    onCheckboxClick?(event, row: IRowData): void;
    onMouseUp?(event, row: IRowData): void;
    onHeaderClick?(position: any): void;
    onDraggingUpdate?(position: any, row: any): void;
    selected?: boolean;
    dragging?: boolean;
    sortable?: boolean;
    sort?: { column: number, direction: "asc" | "desc" };
}
export default function UserfulTableColumn(props: IProps) {

    const [flyOver, setFlyOver] = useState<boolean>(false);

    const onMouseDown = (e, row) => {
        e.preventDefault();

        if (e.button === 0) {
            const onMouseMove = (e) => {
                if (props.onDraggingUpdate) props.onDraggingUpdate({ x: e.clientX, y: e.clientY }, row);
            };

            const onMouseUp = () => {

                if (props.onDraggingUpdate) props.onDraggingUpdate(null, row);

                document.removeEventListener("mousemove", onMouseMove);
                document.removeEventListener("mouseup", onMouseUp);
            };

            document.addEventListener("mousemove", onMouseMove);
            document.addEventListener("mouseup", onMouseUp);
        }
    };


    return <div className="userful-table-content-column-wrapper">
        <div className="userful-table-content-column">
            <div
                className="userful-table-header"
                key={"header_" + props.index}
                onClick={() => {
                    if (props.sortable && props.onHeaderClick) props.onHeaderClick(props.index);
                }}
            >
                <p>{props.header}</p>
                {props.sortable ? props.sort ?
                    <FontAwesomeIcon icon={props.sort.column !== props.index ? faSort : props.sort.direction === "asc" ? faSortAsc : faSortDesc} />
                    : <FontAwesomeIcon icon={faSort} />
                    : null}
            </div>
            {props.rows.map((row, rowIndex) => {

                const indexLowerBoundary = rowIndex + 1 >= (props.pagination.currentPage - 1) * props.pagination.dataPerPage + 1;
                const indexUpperBoundary = rowIndex + 1 <= props.pagination.currentPage * props.pagination.dataPerPage;
                const selected = props.tempSelected ? props.tempSelected?.id.value === row.id.value ? true : false : props.selectedRows.find((i) => i.id.value === row.id.value) ? true : false;

                if (indexLowerBoundary && indexUpperBoundary) {
                    return <div key={"row_" + rowIndex}
                        className={`userful-table-content-row ${row.isChecked ? "row-checked" : ""} ${selected ? "row-selected" : ""} ${flyOver ? "flyover-object" : ""}`}
                        onMouseDown={(e) => onMouseDown(e, row)}
                        style={{ width: props.width }}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (props.onRowClick) props.onRowClick(e, row);
                        }}
                        onMouseEnter={(e) => { if (row.isDroppable && !props.selected && props.dragging && e.buttons === 1) setFlyOver(true) }}
                        onMouseLeave={(e) => { if (row.isDroppable && !props.selected && props.dragging) setFlyOver(false) }}
                        onMouseOut={(e) => { if (row.isDroppable && !props.selected && props.dragging) setFlyOver(false) }}
                        onMouseUp={(e) => {
                            if (row.isDroppable && !props.selected && props.dragging) {
                                setFlyOver(false);
                                if (props.onMouseUp) props.onMouseUp(e, row);
                                return;
                            }
                        }}
                    >
                        <UserfulTooltip title={row.columns[props.index].component} >
                            <div
                                className="userful-table-content-row-column"
                                key={"column_" + props.index}
                            >
                                {row.columns[props.index].component}
                            </div>
                        </UserfulTooltip>
                    </div>
                }



            })}

        </div>
        <ColumnResizeBar width={props.width} onResize={props.onWidthChange} />
    </div>
}