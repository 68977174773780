"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPOND_SYSTEM_WORK_IN_PROGRESS_STATUS = exports.REQUEST_SYSTEM_WORK_IN_PROGRESS_STATUS = exports.FAILOVER_STATUS_API = void 0;
var _messageModel = require("../../messageModel");
var AETHER_SYSTEM = 2027;
var REQUEST_SYSTEM_WORK_IN_PROGRESS_STATUS = exports.REQUEST_SYSTEM_WORK_IN_PROGRESS_STATUS = new _messageModel.RequestMessageDefinition(AETHER_SYSTEM, 0);
var RESPOND_SYSTEM_WORK_IN_PROGRESS_STATUS = exports.RESPOND_SYSTEM_WORK_IN_PROGRESS_STATUS = new _messageModel.RespondMessageDefinition(AETHER_SYSTEM, 0);
var FAILOVER_STATUS_API = exports.FAILOVER_STATUS_API = '/aether/system';