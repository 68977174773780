import React from "react";
import { Form, FormCheck } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { SamSourceSpec } from "userful-chronos-app-common-js/dist/models/sam/SAMSourceSpec";
import { SourceSpecParam } from "userful-chronos-app-common-js/dist/models/source";
import { getTranslatedParamInlineHelp, getTranslatedParamName, getTranslatedParamRadioOption } from "../SourceFormUtil";
import ParamHelpInfo from "./ParamHelpInfo";

interface Iprops {
    param: SourceSpecParam,
    selectedSourceSpec?: SamSourceSpec,
    paramOptions: Array<string>
    handleRadioChange: any
    paramState: string
};

export default function RadioWidget(props: Iprops) {
    const { t } = useTranslation();
    const { param, paramOptions, handleRadioChange, paramState } = props;
    const paramName = param.name;
    if (!paramOptions || paramOptions.length < 1) {
        return null;
    }

    const inlineHelp = getTranslatedParamInlineHelp(props.param, props.selectedSourceSpec, t)

    return (
        <>
            <Form.Group className="input-wrapper" controlId="formSourceType">
                <div className="labelWithDescription">
                    <Form.Label className="heading1">
                        {getTranslatedParamName(param, props.selectedSourceSpec, t)}
                        {!inlineHelp && <ParamHelpInfo param={props.param} selectedSourceSpec={props.selectedSourceSpec} className="radioWidgest" />}
                    </Form.Label>
                    {inlineHelp && <span className='selectHelpText'>{inlineHelp}</span>}
                </div>
                {
                    paramOptions.map((option, index) => {
                        return (
                            <FormCheck
                                name={paramName}
                                type="radio"
                                label={getTranslatedParamRadioOption(param, props.selectedSourceSpec, t, option)}
                                value={option}
                                id={`${index}-${option}`}
                                onChange={handleRadioChange}
                                checked={paramState === option}
                                key={`source-param-radio-${paramName}-option-${index}`}
                            />
                        )
                    })
                }
            </Form.Group>
        </>
    );
}
