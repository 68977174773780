import React from 'react';
import { Form } from 'react-bootstrap';
import { SourceSpecParam } from 'userful-chronos-app-common-js/dist/models/source';

interface IProps {
    param: SourceSpecParam,
}

export default function InstructionWidget(props: IProps) {
    if (!props.param.default) {
        return null;
    }
    return (
        <Form.Group className="input-wrapper" controlId="formSourceType">
            <div dangerouslySetInnerHTML={{__html: props.param.default}}/>
        </Form.Group>
    );
}