import React, {useState} from 'react';
import { SamSourceDef } from "userful-chronos-app-common-js/dist/models/sam/SAMSource";
import { useTranslation } from "react-i18next";
import DropdownWidget from "../shared/DropdownWidget";
import { GetListPassportForDropdown, GetSelectedWorkspace, GetSelectedReport, GetSelectedPassport, GetSelectedFramerate, GetListWorkspacesForDropdown, GetListReportsForDropdown } from "../shared/Computation";
import { frameRateOptionsForEpicSourceForm } from 'userful-chronos-app-common-js/dist/models/integration/epic';
import EpicNumberWidget from "../shared/EpicNumberWidget";
import { powerbiActions, useIntegrationDispatch, useIntegrationSelector } from "userful-chronos-common-store/dist/integration";
import { EmbedURLRequest } from "userful-chronos-app-common-js/dist/models/integration/powerbi";
import { useEffect } from "react";
import { SourceDefParam } from "userful-chronos-app-common-js/dist/models/source";
import CustomSwitch from "../../../CustomSwitch/CustomSwitch";
interface IProps {
    params: SourceDefParam[];
    handleParamChange: (name: string, value) => void,
    // handleValueChange?: (name: string, value) => void,
    requiredErrors,
    show: boolean;
}

export default function PowerBISourceFormInner(props: IProps) {
    const refreshIntervalParam = props.params.find((item) => item.name === "Refresh Interval (min)");
    const reloadIntervalParam = props.params.find((item) => item.name === "Reload Interval (min)");
    const timeOutIntervalParam = props.params.find((item) => item.name === "Timeout (min)");

    const dispathPowerbi = useIntegrationDispatch();

    const passports = useIntegrationSelector((state) => state.powerbiStore.powerbiPassports);
    const workspaces = useIntegrationSelector((state) => state.powerbiStore.powerBIWorkspaces);
    const reports = useIntegrationSelector((state) => state.powerbiStore.powerbiReports);

    const { t } = useTranslation();

    const [isRefreshIntervalChecked, setRefreshIntervalChecked] = useState(Number(refreshIntervalParam.value) > 0);
    const [isReloadIntervalChecked, setReloadIntervalChecked] = useState(Number(reloadIntervalParam.value) > 0);
    const [isTimeoutChecked, setTimeoutChecked] = useState(Number(timeOutIntervalParam.value) > 0);

    const handlePassportChange = (name, value) => {
        dispathPowerbi(powerbiActions.setWorkspaces([]));
        dispathPowerbi(powerbiActions.setReports([]));
        dispathPowerbi(powerbiActions.requestWorkspaces({ value: value }));
        props.handleParamChange(name, value);
    }

    const handleWorkspaceChange = (name, value) => {
        dispathPowerbi(powerbiActions.setReports([]));
        const payload: EmbedURLRequest = { id: { value: props.params.filter((para) => para.name === "Power BI passport")[0].value }, groupID: value };
        dispathPowerbi(powerbiActions.requestReports(payload));
        props.handleParamChange(name, value);
    }

    useEffect(() => {
        if (props.show) {
            dispathPowerbi(powerbiActions.setWorkspaces([]));
            dispathPowerbi(powerbiActions.setReports([]));
            const passportID = props.params.filter((para) => para.name === "Power BI passport")[0].value;
            const workspaceID = props.params.filter((para) => para.name === "Workspace ID")[0].value;
            if (passportID && workspaceID) {
                const reportPayload: EmbedURLRequest = { id: { value: passportID }, groupID: workspaceID };
                dispathPowerbi(powerbiActions.requestWorkspaces({ value: passportID }));
                dispathPowerbi(powerbiActions.requestReports(reportPayload));
            }
        }
    }, [props.show])

    const toggleTimeOut = () => {
        const value = isTimeoutChecked ? "0" : "2"
        setTimeoutChecked(!isTimeoutChecked);
        props.handleParamChange("Timeout (min)", value);
    }

    const toggleReloadInterval = () => {
        const value = isReloadIntervalChecked ? "0" : "120"
        setReloadIntervalChecked(!isReloadIntervalChecked);
        props.handleParamChange("Reload Interval (min)", value);
    }

    const toggleRefreshInterval = () => {
        const value = isRefreshIntervalChecked ? "0" : "10"
        setRefreshIntervalChecked(!isRefreshIntervalChecked);
        props.handleParamChange("Refresh Interval (min)", value);
    }


    return (
        <>
            <DropdownWidget
                options={GetListPassportForDropdown(passports)}
                onChange={(value) => handlePassportChange("Power BI passport", value.id)}
                selectedValue={GetSelectedPassport(passports, props.params.filter((para) => para.name === "Power BI passport")[0].value)}
                width={528}
                title={t(`Integrations.Epic.SelectPassport`)}
                placeholder={t('Integrations.Epic.Select')}
                disabled={passports.length === 0}
                showHelpInfo={false}
                invalidState={!!props.requiredErrors["Power BI passport"]}
                invalidText={t('Integrations.Epic.SourceForm.SelectPassport')}
                helpInfo={t("Integrations.PowerBI.SelectPassportInfoText")}
            />
            <DropdownWidget
                options={GetListWorkspacesForDropdown(workspaces)}
                onChange={(value) => handleWorkspaceChange("Workspace ID", value.id)}
                selectedValue={GetSelectedWorkspace(workspaces, props.params.filter((para) => para.name === "Workspace ID")[0].value)}
                width={528}
                title={t(`Integrations.PowerBI.Workspace`)}
                placeholder={t('Integrations.PowerBI.ChooseWorkspacePlaceholder')}
                disabled={workspaces.length === 0}
                showHelpInfo={false}
                invalidState={!!props.requiredErrors["Workspace ID"]}
                invalidText={t('Integrations.PowerBI.ChooseWorkspaceError')}
                helpInfo={t("Integrations.PowerBI.WorkspaceInfoText")}
            />
            <DropdownWidget
                options={GetListReportsForDropdown(reports)}
                onChange={(value) => props.handleParamChange("Report ID", value.id)}
                selectedValue={GetSelectedReport(reports, props.params.filter((para) => para.name === "Report ID")[0].value)}
                width={528}
                title={t(`Integrations.PowerBI.Report`)}
                placeholder={t('Integrations.PowerBI.ChooseReportPlaceholder')}
                disabled={reports.length === 0}
                showHelpInfo={false}
                invalidState={!!props.requiredErrors["Report ID"]}
                invalidText={t('Integrations.PowerBI.ChooseReportError')}
                helpInfo={t("Integrations.PowerBI.ReportInfoText")}
            />
            {/* Refresh Interval */}
            <CustomSwitch
                check={isRefreshIntervalChecked}
                name={t('Integrations.PowerBI.RefreshInterval')}
                className={isRefreshIntervalChecked ? "selectInput":""}
                description={t('Integrations.PowerBI.RefreshIntervalDesc')}
                handleClick={toggleRefreshInterval}
            />

            {isRefreshIntervalChecked &&
                <EpicNumberWidget
                    label={t('Integrations.PowerBI.RefreshInterval')}
                    name="Refresh Interval (min)"
                    onChange={(e) => { 
                        const value = e.target.value.replace(/^0+|\.+/g, '');
                        props.handleParamChange(e.target.name, value); 
                    }}
                    isInvalid={!!props.requiredErrors["Refresh Interval (min)"]}
                    value={props.params.filter((item) => item.name === "Refresh Interval (min)")[0].value}
                    error={t("Integrations.PowerBI.RefreshIntervalError")}
                    min={1}
                    helpInfo={t("Integrations.PowerBI.RefreshIntervalInfoText")}
                
            />
            }
            {/* Reload Interval */}
            <CustomSwitch
                check={isReloadIntervalChecked}
                name={t('Integrations.PowerBI.ReloadInterval')}
                className={isReloadIntervalChecked ? "selectInput" : ""}
                description={t('Integrations.PowerBI.ReloadIntervalDesc')}
                handleClick={toggleReloadInterval}
            />

            {isReloadIntervalChecked &&
                <EpicNumberWidget
                    label={t('Integrations.PowerBI.ReloadInterval')}
                    name="Reload Interval (min)"
                    onChange={(e) => { 
                        const value = e.target.value.replace(/^0+|\.+/g, '');
                        props.handleParamChange(e.target.name, value); 
                    }}
                    isInvalid={!!props.requiredErrors["Reload Interval (min)"]}
                    value={props.params.filter((item) => item.name === "Reload Interval (min)")[0].value}
                    error={t("Integrations.PowerBI.ReloadIntervalError")}
                    min={1}
                    helpInfo={t("Integrations.PowerBI.ReloadIntervalInfoText")}
                />
            }
            {/* TimeOut */}
            <CustomSwitch
                check={isTimeoutChecked}
                name={t('Integrations.PowerBI.Timeout')}
                className={isTimeoutChecked ? "selectInput" : ""}
                description={t('Integrations.PowerBI.TimeoutDesc')}
                handleClick={toggleTimeOut}
            />

            {isTimeoutChecked &&
                <EpicNumberWidget
                    label={t('Integrations.PowerBI.Timeout')}
                    name="Timeout (min)"
                    onChange={(e) => { 
                        const value = e.target.value.replace(/^0+|\.+/g, '');
                        props.handleParamChange(e.target.name, value); 
                    }}
                    isInvalid={!!props.requiredErrors["Timeout (min)"]}
                    value={props.params.find((item) => item.name === "Timeout (min)").value}
                    error={t("Integrations.PowerBI.TimeoutError")}
                    min={2}
                    helpInfo={t("Integrations.PowerBI.TimeoutInfoText")}
                />
            }
            <DropdownWidget
                options={frameRateOptionsForEpicSourceForm}
                onChange={(value) => props.handleParamChange("framerate", value.id)}
                selectedValue={GetSelectedFramerate(props.params.filter((item) => item.name === "framerate")[0].value)}
                width={528}
                title={t(`Integrations.Epic.Framerate`)}
                placeholder={t('Integrations.Epic.Select')}
                helpInfo={t("Integrations.PowerBI.FrameRateInfoText")}
            />
        </>
    );
}
