"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSource = exports.updatePlaylist = exports.removeSource = exports.removePlaylist = exports.getStandaloneSources = exports.getStandaloneSourceSpecs = exports.getStandalonePlaylists = exports.getStandaloneAssets = exports.addSource = exports.addPlaylist = void 0;
var _common = require("../../models/common");
var _SAMUtils = require("../../models/sam/SAMUtils");
var _store = require("../data/store");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var getStandaloneAssets = exports.getStandaloneAssets = function getStandaloneAssets() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().sourceAssets);
};
var getStandaloneSourceSpecs = exports.getStandaloneSourceSpecs = function getStandaloneSourceSpecs() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().sourceSpecs);
};
var getStandaloneSources = exports.getStandaloneSources = function getStandaloneSources() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().sources);
};
var getStandalonePlaylists = exports.getStandalonePlaylists = function getStandalonePlaylists() {
  return _toConsumableArray((0, _store.getStandaloneDataStore)().playlists);
};
var findAsset = function findAsset(id) {
  var found = getStandaloneAssets().find(function (item) {
    return item.id.value === id.value;
  });
  if (found) {
    return found;
  }
  return {
    id: _common.STRINGID_NOT_SET,
    name: "",
    description: "",
    type: 'NONE',
    orgData: (0, _SAMUtils.initSamOrgData)(),
    auditData: (0, _SAMUtils.initAuditData)(),
    assetData: {
      '@type': "none"
    }
  };
};
var sourceCreatorToSource = function sourceCreatorToSource(creator) {
  return _objectSpread(_objectSpread({}, creator), {}, {
    assets: creator.assets.map(function (item) {
      return {
        paramName: item.paramName,
        sourceAssets: item.sourceAssets.map(function (assetID) {
          return findAsset(assetID);
        })
      };
    }),
    auditData: (0, _SAMUtils.initAuditData)()
  });
};
var addSource = exports.addSource = function addSource(source) {
  var sources = getStandaloneSources();
  var item = sourceCreatorToSource(source);
  sources.push(item);
  (0, _store.updateStandaloneDataStore)({
    sources: sources
  });
  return item;
};
var updateSource = exports.updateSource = function updateSource(source) {
  var sources = getStandaloneSources();
  var item = sourceCreatorToSource(source);
  var foundIndex = sources.findIndex(function (item) {
    return item.id.value === source.id.value;
  });
  if (foundIndex >= 0) {
    sources.splice(foundIndex, 1, item);
    (0, _store.updateStandaloneDataStore)({
      sources: sources
    });
  }
  return item;
};
var removeSource = exports.removeSource = function removeSource(id) {
  var sources = getStandaloneSources();
  var foundIndex = sources.findIndex(function (item) {
    return item.id.value === id.id.value;
  });
  if (foundIndex >= 0) {
    var result = sources.splice(foundIndex, 1)[0];
    (0, _store.updateStandaloneDataStore)({
      sources: sources
    });
    return result;
  }
  return null;
};
var addPlaylist = exports.addPlaylist = function addPlaylist(playlist) {
  var playlists = getStandalonePlaylists();
  playlists.push(playlist);
  (0, _store.updateStandaloneDataStore)({
    playlists: playlists
  });
  return playlist;
};
var updatePlaylist = exports.updatePlaylist = function updatePlaylist(playlist) {
  var playlists = getStandalonePlaylists();
  var foundIndex = playlists.findIndex(function (item) {
    return item.id.value === playlist.id.value;
  });
  if (foundIndex >= 0) {
    playlists.splice(foundIndex, 1, playlist);
    (0, _store.updateStandaloneDataStore)({
      playlists: playlists
    });
  }
  return playlist;
};
var removePlaylist = exports.removePlaylist = function removePlaylist(id) {
  var playlists = getStandalonePlaylists();
  var foundIndex = playlists.findIndex(function (item) {
    return item.id.value === id.value;
  });
  if (foundIndex >= 0) {
    var result = playlists.splice(foundIndex, 1)[0];
    (0, _store.updateStandaloneDataStore)({
      playlists: playlists
    });
    return result;
  }
  return null;
};