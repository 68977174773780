import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OnboardComp } from 'userful-chronos-app-common-js/dist/models/display';

const initialState: {
    displays: OnboardComp[];
    ready: boolean;
} = {
    displays: [],
    ready: false,
};

export const onboardSlice = createSlice({
    name: 'onboardSlice',
    initialState,
    reducers: {
        setDisplays: (state, action: PayloadAction<Array<OnboardComp>>) => {
            state.displays = [...action.payload];
            state.ready = true;
        },
        addDisplay: (state, action: PayloadAction<OnboardComp>) => {
            const updateIDValue = action.payload.id.value;
            const existingIndex = state.displays.findIndex(item => item.id.value === updateIDValue);
            if (existingIndex < 0) {
                state.displays = [
                    ...state.displays,
                    { ...action.payload },
                ];
            }
        },
        updateDisplay: (state, action: PayloadAction<OnboardComp>) => {
            const updateIDValue = action.payload.id.value;
            const existingIndex = state.displays.findIndex(item => item.id.value === updateIDValue);
            if (existingIndex >= 0) {
                state.displays = [
                    ...state.displays.slice(0, existingIndex),
                    { ...action.payload },
                    ...state.displays.slice(existingIndex + 1),
                ];
            }
        },

        deleteDisplay: (state, action: PayloadAction<OnboardComp>) => {
            state.displays = state.displays.filter(item => item.id.value !== action.payload.id.value);
        },
    },
})


export const onboardSliceActions = onboardSlice.actions;

export default onboardSlice.reducer
