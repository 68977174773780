import './DepartmentIDType.scss';
import Form from 'react-bootstrap/Form';
import Button from '../../../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMultiply, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GetIDTypePayload, FetchDepartmentsFromString } from './Computation';
import React from 'react';
import { HelpInfo } from '../../../sourceassets';

interface IProps {
    formValue: string;
    onChange: Function;
    width?: string;
    helpInfo?: string;
    requiredErrors?: any;
}

const findID = (num) => {
    const existingIDs = num.map(item => item.id); // Extract existing IDs

    for (let i = 1; i <= num.length + 1; i++) {
        if (!existingIDs.includes(i)) { // Check if the ID is not in use
            return i;
        }
    }
}

const DepartmentIDType = (props: IProps) => {

    const { t } = useTranslation();

    const [num, setNum] = useState(props.formValue === "" ? [{ departmentID: "", departmentType: "", id: 1 }] : FetchDepartmentsFromString(props.formValue));

    const addOne = () => {
        const newID = findID(num);
        setNum([...num, { departmentID: "", departmentType: "", id: newID }])
    }


    const removeItem = (id: number) => {
        // dont allow removal of last row
        if (num.length === 1)
            return;
        const newNum = num.filter((item) => item.id !== id);
        props.onChange(JSON.stringify(GetIDTypePayload(newNum)));
        setNum(newNum);
    }

    const onChange = (value: string, type: string, id: number) => {
        const newNum = num.map((item) => item.id === id ? { ...item, [type]: value } : item);
        props.onChange(JSON.stringify(GetIDTypePayload(newNum)));
        setNum(newNum);
    }

    const getIsInvalid = (errors, index, place) => {
        if (!errors['Departments'])
            return false;
        const findEle = errors['Departments'].find((dep) => dep[2] === index);
        if (!findEle)
            return false;
        return findEle[place];
    }

    const isAnyError = (errors) => {
        if (!errors['Departments'])
            return false;
        let isTrue = false;
        errors['Departments'].map((dep) => {
            if (dep[0] || dep[1])
                isTrue = true;
        });
        return isTrue;
    }

    return (

        <div className='twoFieldsInOneRow'>
            <div className="headerRow">
                <div className="problemCanvasTitleOne">
                    {t('Integrations.Epic.SourceForm.DepartmentID')}
                    <HelpInfo className='powerBIHelpInfo' title={props.helpInfo} />
                </div>
                <div className="problemCanvasTitleOne">
                    {t('Integrations.Epic.SourceForm.DepartmentType')}
                    <HelpInfo className='powerBIHelpInfo' title={props.helpInfo} />
                </div>
            </div>
            <div className="fieldsComp">
                <div className="fieldsCompField">
                    {num.map((item, index) => {
                        return (
                            <div className='fieldsDiv' key={`${index}-idtype`}>
                                <div className='formcontrolTextWidget'>
                                    <Form.Group className='form-group'>
                                        <Form.Control
                                            as={"input"}
                                            placeholder={t('Integrations.Epic.SourceForm.DepartmentIDPlaceholder')}
                                            value={item.departmentID}
                                            onChange={(e) => onChange(e.target.value, e.target.name, item.id)}
                                            name="departmentID"
                                            width={props.width}
                                            className={"custom-control-height"}
                                            // isInvalid={getIsInvalid(props.requiredErrors, index, 0)} />
                                            isInvalid={false} />
                                        <Form.Control.Feedback type='invalid' className='invalidInputOne'>
                                            {t('Integrations.Epic.SourceForm.DepartmentIDInvalid')}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className='formcontrolTextWidget'>
                                    <Form.Group className='form-group'>
                                        <Form.Control
                                            as={"input"}
                                            placeholder={t('Integrations.Epic.SourceForm.DepartmentTypePlaceholder')}
                                            value={item.departmentType}
                                            onChange={(e) => onChange(e.target.value, e.target.name, item.id)}
                                            name="departmentType"
                                            width={props.width}
                                            className={"custom-control-height"}
                                            // isInvalid={getIsInvalid(props.requiredErrors, index, 1)} />
                                            isInvalid={false} />
                                        <Form.Control.Feedback type='invalid' className='invalidInputOne'>
                                            {t('Integrations.Epic.SourceForm.DepartmentTypeInvalid')}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </div>
                                <div className={`customFieldButton ${num.length === 1 && 'disableButton'}`} onClick={() => removeItem(item.id)} >
                                    <FontAwesomeIcon icon={faMultiply} className='iconButton' />
                                </div>
                            </div>
                        )
                    })}
                </div>
                {isAnyError(props.requiredErrors) && <div className="invalidDepartment">{t('Integrations.Epic.EpicDepartmentError')}</div>}
            </div>

            <Button variant='secondary' onClick={() => addOne()}>
                <FontAwesomeIcon icon={faPlus} />
            </Button>

        </div>
    );
}
export default DepartmentIDType;