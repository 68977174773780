import Form from 'react-bootstrap/Form';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import DateTimePickerCustom from '../../../Calendar/forms/SubComponents/DateTimePickercustom';
import './IntegrationDateRange.scss';
import UserfulTooltip from '../../../Widgets/UserfulTooltip';
interface IProps {

    title: string;
    width: number;
    showHelpInfo?: boolean;
    helpText?: string;
    handleDateChange;
    requiredErrors;
    dateRange
}

function IntegrationDateRange(props: IProps) {

    return (

        <div className='userManagementTextWidget' style={{ width: props.width }}>
            <div className='userManagementTextWidgetHeader'>
                <div className='userManagementTextWidgetHeaderOne'>
                    <div className='problemCanvasTitleOne'>
                        {props.title}
                    </div>

                    {props.showHelpInfo && <UserfulTooltip title={props.helpText}>
                        <FontAwesomeIcon icon={faCircleInfo} className='problemCanvasQuestionIconOne' />
                    </UserfulTooltip>}
                </div>

            </div>
            <div className="integrationDateRange">
                <DateTimePickerCustom
                    onChange={props.handleDateChange}
                    valid={!!props.requiredErrors["Servicenow date"]}
                    value={props.dateRange}
                    dateFormat={"yyyy-MM-dd"}
                    showDateOnly
                />
            </div>

        </div >
    );
}

export default IntegrationDateRange;
