import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { NOTIFY_CHANNEL_ADDED, NOTIFY_CHANNEL_DELETED, NOTIFY_CHANNEL_UPDATED, RESPOND_LIST_CHANNELS } from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/ruby/rubyMsgDefinitions";
import { MutableChannel } from "userful-chronos-app-common-js/dist/models/ruby/channel";
import { channelActions } from "../channelSlice";
import rubyStore from "../rubyStore";


// 
registerMsgHandler(RESPOND_LIST_CHANNELS, (payload) => {
    console.debug('receive RESPOND_LIST_CHANNELS');
    const data = JSON.parse(payload) as MutableChannel[];
    rubyStore.dispatch(channelActions.setChannels(data));
})

registerMsgHandler(NOTIFY_CHANNEL_ADDED, (payload) => {
    console.debug('receive NOTIFY_CHANNEL_ADDED');
    const data = JSON.parse(payload) as MutableChannel;
    rubyStore.dispatch(channelActions.addChannel(data));
})

registerMsgHandler(NOTIFY_CHANNEL_UPDATED, (payload) => {
    console.debug('receive NOTIFY_CHANNEL_UPDATED');
    const data = JSON.parse(payload) as MutableChannel;
    rubyStore.dispatch(channelActions.updateChannel(data));
})

registerMsgHandler(NOTIFY_CHANNEL_DELETED, (payload) => {
    console.debug('receive NOTIFY_CHANNEL_DELETED');
    const data = JSON.parse(payload) as MutableChannel;
    rubyStore.dispatch(channelActions.removeChannel(data));
})
