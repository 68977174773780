
import { Dropdown } from "react-bootstrap";
import Button from "../../Button/Button";
import "./SourceActionsMenu.scss";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CompGroup } from "userful-chronos-app-common-js/dist/models/group/Groups";
import DeleteFolder from "../folder/DeleteFolder";
import CreateFolder from "../folder/CreateFolder";

interface IProps {
    value: CompGroup;
}

export default function FolderActionsMenu({ value }: IProps) {

    return <Dropdown onMouseDown={((e) => { e.stopPropagation() })} onClick={((e) => { e.stopPropagation() })} style={{ justifyContent: "right" }}>
        <Dropdown.Toggle className='source-actions-menu-toggle' as={Button}>
            <FontAwesomeIcon icon={faEllipsisV} />
        </Dropdown.Toggle>

        <Dropdown.Menu className='custom-dropdown-menu' popperConfig={{ strategy: "fixed" }} renderOnMount={true}>
            <CreateFolder type={"DROPDOWN"} comp={value} />
            <DeleteFolder folder={value} />
        </Dropdown.Menu>
    </Dropdown >
}