import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StringID } from 'userful-chronos-app-common-js/dist/models/common';
import { MutableChannel, MutableRubySource, MutableRubyUser } from 'userful-chronos-app-common-js/dist/models/ruby/channel';
import { requestAddChannel, requestAddOrUpdateChannelUser, requestAddOrUpdateChnnaleSource, requestDeleteChannel, requestRemoveChannelSource, requestRemoveChannelUser, requestUpdateChannel } from './msgs/MsgSender';

const initialState: {
    channels: MutableChannel[];
    ready: boolean;
    pendingChannel: StringID;
} = {
    channels: [],
    ready: false,
    pendingChannel: null,
};

export const channelSlice = createSlice({
    name: 'channelSlice',
    initialState,
    reducers: {
        setChannels: (state, action: PayloadAction<MutableChannel[]>) => {
            state.channels = [...action.payload];
            state.ready = true;
        },
        addChannel: (state, action: PayloadAction<MutableChannel>) => {
            const foundIndex = state.channels.findIndex(item => item.id.value === action.payload.id.value);
            if (foundIndex < 0) {
                state.channels = [
                    ...state.channels,
                    { ...action.payload },
                ];
            }
            if (state.pendingChannel?.value === action.payload.id.value) {
                state.pendingChannel = null;
            }
        },
        updateChannel: (state, action: PayloadAction<MutableChannel>) => {
            const foundIndex = state.channels.findIndex(item => item.id.value === action.payload.id.value);
            if (foundIndex >= 0) {
                state.channels = [
                    ...state.channels.slice(0, foundIndex),
                    { ...action.payload },
                    ...state.channels.slice(foundIndex + 1),
                ];
            }
            if (state.pendingChannel?.value === action.payload.id.value) {
                state.pendingChannel = null;
            }
        },
        removeChannel: (state, action: PayloadAction<MutableChannel>) => {
            if (action.payload?.id) {
                state.channels = state.channels.filter(item => item.id.value !== action.payload.id.value);
                if (state.pendingChannel?.value === action.payload.id.value) {
                    state.pendingChannel = null;
                }
            }
        },

        createChannelToServer: (state, action: PayloadAction<MutableChannel>) => {
            state.pendingChannel = action.payload.id;
            requestAddChannel(action.payload);
        },
        updateChannelToServer: (state, action: PayloadAction<MutableChannel>) => {
            state.pendingChannel = action.payload.id;
            requestUpdateChannel(action.payload);
        },
        removeChannelToServer: (state, action: PayloadAction<StringID>) => {
            state.pendingChannel = action.payload;
            requestDeleteChannel(action.payload);
        },
        addOrUpdateChannelSourceToServer: (state, action: PayloadAction<{
            channelID: StringID,
            source: MutableRubySource,
        }>) => {
            state.pendingChannel = action.payload.channelID;
            requestAddOrUpdateChnnaleSource({
                id: action.payload.channelID,
                source: action.payload.source,
            })
        },
        removeChannelSourceToServer: (state, action: PayloadAction<StringID>) => {
            requestRemoveChannelSource(action.payload);
        },
        addOrUpdateChannelUserToServer: (state, action: PayloadAction<{
            channelID: StringID,
            user: MutableRubyUser,
        }>) => {
            state.pendingChannel = action.payload.channelID;
            requestAddOrUpdateChannelUser({
                id: action.payload.channelID,
                user: action.payload.user,
            })
        },
        removeChannelUserToServer: (state, action: PayloadAction<StringID>) => {
            requestRemoveChannelUser(action.payload);
        },
    },
})

export const channelActions = channelSlice.actions;

export default channelSlice.reducer

