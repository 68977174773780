"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initTableauPassportFromHeader = exports.initTableauPassport = exports.initPATCredentials = exports.initConnectedAppCredentials = exports.initBasicCredentials = exports.TableauAuthOptions = void 0;
var _uuid = require("uuid");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var initBasicCredentials = exports.initBasicCredentials = function initBasicCredentials() {
  return {
    username: "",
    password: ""
  };
};
var initPATCredentials = exports.initPATCredentials = function initPATCredentials() {
  return {
    username: "",
    tokenName: "",
    tokenSecret: ""
  };
};
var TableauAuthOptions = exports.TableauAuthOptions = [{
  value: 'ADMIN_BASIC',
  label: 'Admin Password',
  id: 'ADMIN_BASIC'
}, {
  value: 'ADMIN_PAT',
  label: 'Admin PAT',
  id: 'ADMIN_PAT'
}, {
  value: 'CONNECTED_APP',
  label: 'Connected App',
  id: 'CONNECTED_APP'
}, {
  value: 'PUBLIC',
  label: 'Public',
  id: 'PUBLIC'
}];
var initConnectedAppCredentials = exports.initConnectedAppCredentials = function initConnectedAppCredentials() {
  return {
    username: "",
    clientID: "",
    secretID: "",
    secretValue: ""
  };
};
var initTableauPassportFromHeader = exports.initTableauPassportFromHeader = function initTableauPassportFromHeader(data) {
  return _objectSpread(_objectSpread({}, data), {}, {
    isConnected: true,
    serverInfo: {
      productVersionValue: "NOT-SET",
      productVersionBuild: "NOT-SET",
      restAPIVersion: "NOT-SET"
    },
    tableauSiteID: "NOT-SET"
  });
};
var initTableauPassport = exports.initTableauPassport = function initTableauPassport() {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "",
    description: "",
    isConnected: false,
    serverInfo: {
      productVersionValue: "",
      productVersionBuild: "",
      restAPIVersion: ""
    },
    tableauServer: "",
    tableauSiteName: "",
    tableauSiteID: "",
    authenticationType: 'CONNECTED_APP',
    adminBasicCreds: {
      username: "",
      password: ""
    },
    adminPATCreds: {
      username: "",
      tokenName: "",
      tokenSecret: ""
    },
    connectedAppCreds: {
      username: "",
      clientID: "",
      secretID: "",
      secretValue: ""
    }
  };
};