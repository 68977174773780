import { on } from "events";
import React, { useState } from "react";
import "./MultiActionDropdown.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import Checkmark, { TSelected } from "../../../checkmark/Checkmark";
import { IPagination } from "../../Pagination/UserfulTablePagination";
import Button from "../../../Button/Button";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { IRowData } from "../../userfulTableInterface";

interface IProps {
    rows: IRowData[];
    selectedRows: IRowData[];
    pagination: IPagination;
    onMultiselectChange(rows: IRowData[]): void;
}

export default function UserfulTableMultiselect({ rows, selectedRows, pagination, onMultiselectChange }: IProps) {
    const rowsAfterPagination: IRowData[] = rows.slice((pagination.currentPage - 1) * pagination.dataPerPage, pagination.currentPage * pagination.dataPerPage);


    let selected: TSelected = "unselected";
    if (selectedRows.length === rowsAfterPagination.length) selected = "selected";
    else if (selectedRows.length > 0) selected = "indeterminate";

    const onCheckboxClicked = (event) => {
        event.stopPropagation();

        let selected: TSelected = "unselected";
        if (selectedRows.length === rowsAfterPagination.length) selected = "selected";
        else if (selectedRows.length > 0) selected = "indeterminate";

        if (selected === "selected" || selected === "indeterminate") {
            if (onMultiselectChange) onMultiselectChange([]);
        } else {
            if (onMultiselectChange) onMultiselectChange(rowsAfterPagination);
        }
    }

    return (

        <Dropdown className="multi-action-dropdown" onClick={(e) => e.stopPropagation()}>
            <Dropdown.Toggle as={Button} bsPrefix="multi-select-container" id="dropdownToggle">
                <Checkmark selected={selected} onClick={onCheckboxClicked} />
                <FontAwesomeIcon icon={faChevronDown} />
            </Dropdown.Toggle>

            <Dropdown.Menu className="multi-action-menu" onKeyDown={(e) => e.stopPropagation()} onKeyUp={(e) => e.stopPropagation()}>
                <Dropdown.Item
                    className="multi-action-dropdown-item"
                    onClick={(e) => {
                        e.stopPropagation();
                        onMultiselectChange(rowsAfterPagination);
                    }}
                >
                    <div className="textInside">All ({rowsAfterPagination.length})</div>
                </Dropdown.Item>
                <Dropdown.Item
                    className="multi-action-dropdown-item"
                    onClick={(e) => {
                        e.stopPropagation();
                        onMultiselectChange([]);
                    }}
                >
                    <div className="textInside">None (0)</div>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>


    );
}
