import { Form } from 'react-bootstrap';
import { HelpInfo } from '../../../sourceassets';
import React from 'react';
interface IProps {

    label: string;
    value: string;
    name: string;
    onChange: Function;
    isInvalid: boolean;
    error: string;
    max?: number;
    min?: number;
    helpInfo?: string;

}

const EpicNumberWidget = (props: IProps) => {
    return (
        <Form.Group className="input-wrapper" controlId="formSourceTypeNumber">
            <Form.Label className="heading1">
                {props.label}
                <HelpInfo className='powerBIHelpInfo' title={props.helpInfo} />
            </Form.Label>
            <Form.Control
                type="number"
                name={props.name}
                value={props.value}
                onChange={(e) => props.onChange(e)}
                isInvalid={props.isInvalid}
                min={props.min}
                max={props.max}
            />
            <Form.Control.Feedback type="invalid">{props.error}</Form.Control.Feedback>
        </Form.Group>
    );
}

export default EpicNumberWidget;