import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import { hasClearanceLevelForAction } from "userful-chronos-app-common-js/dist/authenticataion/roles";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { UserfulSecurityData, APP_IDS } from "userful-chronos-app-common-js/dist/models/common";
import { SamSourceDef } from "userful-chronos-app-common-js/dist/models/sam/SAMSource";
import { UserPermission } from "userful-chronos-app-common-js/dist/models/user-permission";
import { AssignOwnerToContent } from "../../displays";
import { convertSourceDefToAssetsData, SamSourceForm, SourceDeletionDialog } from "../../sourceassets";
import { sourceSliceActions, useSourceAssetDispatch, useSourceAssetSelector } from "userful-chronos-common-store/dist/sourceassets";
import Button from "../../Button/Button";
import "./SourceActionsMenu.scss";

interface IProps {
    value: SamSourceDef;
}

export default function SourceActionsMenu({ value }: IProps) {

    const globalStates = getGlobalStates();
    const userPermission = getGlobalStates().userPermission as UserPermission;
    const sourceSpecs = useSourceAssetSelector(state => state.sourceStore.sourceSpecs);

    const dispatch = useSourceAssetDispatch();

    const canEdit = (securityData: UserfulSecurityData) => {
        return hasClearanceLevelForAction(securityData, globalStates.userData.userID, globalStates.userPermission, globalStates.actions, 'edit');
    }

    const sanitizeparamAsset = (item) => {
        const paramAsset = convertSourceDefToAssetsData(item);
        const selectedSourceSpec = sourceSpecs.find((spec) => spec.header.id.value === item.type.value);
        if (!selectedSourceSpec) {
            return [];
        }
        return paramAsset.filter((param) => selectedSourceSpec.assetSpecs.findIndex((asset) => asset.sourceParamName === param.paramName) >= 0);
    };

    const onSave = (value, data: UserfulSecurityData) => {
        const update = {
            ...value,
            userfulSecurityData: data,
            assets: sanitizeparamAsset(value),
            appInstanceID: {
                value: getGlobalStates().systemID,
                appID: {
                    value: getGlobalStates().appID.value,
                }
            }
        };
        delete update.auditData;
        dispatch(sourceSliceActions.forceUpdateSourceToServer(update));
    };

    return <Dropdown onClick={((e) => { e.stopPropagation() })} style={{ display: `${(!(canEdit(value.userfulSecurityData) && userPermission.isSourceSuppressor) && !userPermission.canOverrideSecurityDataOwnerShip) && 'none'}`, justifyContent: "right" }}>
        <Dropdown.Toggle className='source-actions-menu-toggle' as={Button}>
            <FontAwesomeIcon icon={faEllipsisV} />
        </Dropdown.Toggle>

        <Dropdown.Menu className='custom-dropdown-menu' popperConfig={{ strategy: "fixed" }} renderOnMount={true}>
            {(canEdit(value.userfulSecurityData) && userPermission.isSourceSuppressor) &&
                <SamSourceForm source={value} noIconInDropdown dropdownItem appID={APP_IDS.Config} />
            }
            {(canEdit(value.userfulSecurityData) && userPermission.isSourceSuppressor) &&
                <SourceDeletionDialog source={value} />
            }
            {userPermission.canOverrideSecurityDataOwnerShip && <AssignOwnerToContent hideIcon securityData={(value as SamSourceDef).userfulSecurityData} onHide={(data: UserfulSecurityData) => onSave(value, data)} />}
        </Dropdown.Menu>
    </Dropdown >
}