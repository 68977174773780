import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getMsFromDuration, getDurationFromMs, StringID } from 'userful-chronos-app-common-js/dist/models/common';
import { GPU, VideoEngineComp } from 'userful-chronos-app-common-js/dist/models/ve/videoEngines';
import { requestUpdateGPU } from './msgs/MsgSender';

const initialState: {
    videoEngines: VideoEngineComp[],
    selectedVideoEngine: VideoEngineComp,
    pendingGPUUpdates: GPU[],
    ready: boolean;
} = {
    videoEngines: [],
    pendingGPUUpdates: [],
    selectedVideoEngine: null,
    ready: false,
};

export const videoEngineSlice = createSlice({
    name: 'videoEngineSlice',
    initialState,
    reducers: {
        setVEs: (state, action: PayloadAction<VideoEngineComp[]>) => {
            state.ready = true;
            state.videoEngines = [...action.payload];
            state.selectedVideoEngine = state.videoEngines[0];
        },

        reset: (state) => {
            state.ready = false;
            state.videoEngines = [];
            state.selectedVideoEngine = null;
        },

        addOrUpdateVE: (state, action: PayloadAction<VideoEngineComp>) => {
            const idValue = action.payload.id.value;
            const existingIndex = state.videoEngines.findIndex(item => item.id.value === idValue);
            if (existingIndex < 0) {
                state.videoEngines = [
                    ...state.videoEngines,
                    { ...action.payload },
                ]
                if (!state.selectedVideoEngine) {
                    state.selectedVideoEngine = { ...action.payload };
                }
            } else {
                state.videoEngines = [
                    ...state.videoEngines.slice(0, existingIndex),
                    { ...action.payload },
                    ...state.videoEngines.slice(existingIndex + 1),
                ]
                if (state.selectedVideoEngine && state.selectedVideoEngine.id.value === action.payload.id.value) {
                    state.selectedVideoEngine = { ...action.payload };
                }
            }
        },

        updateGPU: (state, action: PayloadAction<GPU>) => {
            const veID = action.payload.id.videoEngineID.value;
            const existingIndex = state.videoEngines.findIndex(item => item.id.value === veID);
            state.pendingGPUUpdates = state.pendingGPUUpdates.filter(item =>
                item.id.gpuSlotID.value !== action.payload.id.gpuSlotID.value ||
                item.id.videoEngineID.value !== veID);
            if (existingIndex >= 0) {
                const gpus = state.videoEngines[existingIndex].gpus;
                const foundGPU = gpus.find(item => item.id.gpuSlotID.value === action.payload.id.gpuSlotID.value);
                // action.payload.latency === "" this condition is added to display the empty value in the form
                // if we dont do this, it shows 0 in the form always and prevents the field value from getting empty
                if (foundGPU && ((getMsFromDuration(foundGPU.latency) !== getMsFromDuration(action.payload.latency) || action.payload.latency === "")) &&
                // below condition is added because backend has by default negative values, and in UI we only show 0 and positive values. So, if backend value is -1,
                // UI will show 0. Now if user goes and change the input value to say 123 and then back to 0, front end compares -2 and 0 and enables save button.
                // To avoid that, we are checking if user inputs 0 and actual value is negative then dont show pending GPus 
                    !(getMsFromDuration(foundGPU.latency) < 0 && action.payload.latency === 0)) {
                    state.pendingGPUUpdates = [
                        ...state.pendingGPUUpdates,
                        action.payload,
                    ];
                }
            }
        },

        updatePendingGPUsToServer: (state) => {
            state.pendingGPUUpdates.forEach((gpu, index) => {
                // since we are not changing the duration, here we have to change the duration to ms, using that function
                const latency = getDurationFromMs(getMsFromDuration(gpu.latency));
                const update = JSON.parse(JSON.stringify({
                    ...gpu,
                    latency,
                })); // something weird with proxy
                setTimeout(() => requestUpdateGPU(update), index*200);
            })
            state.pendingGPUUpdates = [];
        },

        clearPendingGPUsToServer: (state) => {
            state.pendingGPUUpdates = [];
        },
        deleteVE: (state, action: PayloadAction<VideoEngineComp>) => {
            state.videoEngines = state.videoEngines.filter(item => item.id.value !== action.payload.id.value);
            if (state.selectedVideoEngine && state.selectedVideoEngine.id.value === action.payload.id.value) {
                state.selectedVideoEngine = state.videoEngines[0];
            }
        },

        setSelectedVideoEngine: (state, action: PayloadAction<StringID>) => {
            state.selectedVideoEngine = state.videoEngines.find(item => item.id.value === action.payload.value);
        },
    },
})

export const videoEngineActions = videoEngineSlice.actions;

export default videoEngineSlice.reducer