import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IClusterInfo, ClusterDeviceInfo, KeycloakGroup, KeycloakUser, BackendHardwareInfo, MemoryInfo, BackendCaptureCardInfo, BackupData, GpuInfo } from "../../../model/CarbonExternalModels";
import { IRowData, ITable } from "./userfulTableInterface";
import { faCheck, faCircleNodes, faExclamationCircle, faMultiply, faRefresh, faServer, faSpinner, faTimes, faUserGroup } from "@fortawesome/pro-regular-svg-icons";
import carbonStore from "../../../store/carbonStore";
import { AppSliceActions } from "../../../store/app/appSlice";
import { UserfulTooltip } from "userful-chronos-common-ui/dist";
import UserActions from "../../../components/users/userActions/UserActions";
import UserGroupActions from "../../../components/userGroups/userGroupActions/UserGroupActions";
import { formatMemory, formatGPU, formatCPU, formatStorage, formatTimestamp } from "../../functions/formatters";
import BackupActions from "../../../components/backupAndRestore/BackupGroupActions";

export const convertKeycloakUserToTable = (users: KeycloakUser[], groups: KeycloakGroup[]): ITable => {
    const table: ITable = { columns: [], data: [] };

    // Enter Headers
    table.columns = [
        { header: "Username", width: "14%", minWidth: 100 },
        { header: "First Name", width: "10%", minWidth: 100 },
        { header: "Last Name", width: "10%", minWidth: 100 },
        { header: "Email Address", width: "18%", minWidth: 100 },
        { header: "Status", width: "8%", minWidth: 100 },
        { header: "Permission Sets", width: "36%", minWidth: 200 },
        { header: "", width: 60, minWidth: 60 },
    ];

    for (let i = 0; i < users.length; i++) {
        const user: KeycloakUser = users[i];


        const row: IRowData = {
            id: user.id,
            columns: [
                {
                    component: (
                        <div className="cluster normalGap">
                            <div className="circleTag">{user.userProfile.userName[0].toUpperCase()}</div>
                            <p className="limit">{user.userProfile.userName}</p>
                        </div>
                    ),
                    limit: true,
                },
                {
                    component: <p className="limit">{user.userProfile.firstName ? user.userProfile.firstName : ""}</p>,
                    limit: true,
                },
                {
                    component: <p className="limit">{user.userProfile.lastName ? user.userProfile.lastName : ""}</p>,
                    limit: true,
                },
                { component: <p className="limit">{user.userProfile.email}</p>, limit: true },
                {
                    component: (
                        <div>
                            {user.userProfile.enabled ? (
                                <div className="green normal-font">
                                    <FontAwesomeIcon icon={faCheck} /> Active
                                </div>
                            ) : (
                                <div className="red normal-font">
                                    <FontAwesomeIcon icon={faTimes} /> Inactive
                                </div>
                            )}
                        </div>
                    ),
                    limit: true,
                },
                {
                    component: (
                        <div className="tagNameContainer">
                            {user.groups.map((g, index) => {
                                const group = groups.find((group) => group.id.value === g.value);

                                if (!group) return null;

                                return (
                                    <UserfulTooltip title={"Go to"} key={g.value}>
                                        <div
                                            className="tagNameObject"
                                            onClick={(e) => {
                                                e.stopPropagation();

                                                carbonStore.dispatch(
                                                    AppSliceActions.setAppView({ type: "USER_GROUP", id: g })
                                                );
                                            }}
                                        >
                                            {group.name}
                                        </div>
                                    </UserfulTooltip>
                                );
                            })}
                            {/* {user.groups.length > 1 && <div className="tagNameObject">+{user.groups.length - 1}</div>} */}
                        </div>
                    ),
                },
                { component: <UserActions user={user} />, hideTooltip: true },
            ],
            zIndex: 50,
        };
        table.data.push(row);

    }

    return table;
};

export const convertKeycloakGroupToTable = (groups: KeycloakGroup[], users: KeycloakUser[]): ITable => {
    const table: ITable = { columns: [], data: [] };

    // Enter Headers
    table.columns = [
        { header: "Name", width: "20%", minWidth: 100 },
        { header: "Description", width: "30%" },
        { header: "Assigned Users", width: "50%" },
        { header: "", width: 60 },
    ];

    for (let i = 0; i < groups.length; i++) {
        const group: KeycloakGroup = groups[i];

        const row: IRowData = {
            id: group.id,
            columns: [
                {
                    component: (
                        <div className="cluster normalGap limit">
                            <FontAwesomeIcon className="icon blue" icon={faUserGroup} />
                            <p>{group.name}</p>
                        </div>
                    ),
                    limit: true,
                },
                {
                    component: <p className="limit">{group.description}</p>,
                    limit: true,
                },
                {
                    component: (

                        group.members.length > 0 ? <div className="tagNameContainer">
                            {group.members.map((m, index) => {
                                const member = users.find((u) => u.id.value === m.value);

                                if (!member) return null;

                                return (
                                    <div key={m.value} className="tagNameObject tagNameObjectNonInteractive">
                                        {member.userProfile.userName}
                                    </div>
                                );
                            })}
                        </div> : null

                    ),
                    limit: true,
                },
                { component: <UserGroupActions group={group} />, hideTooltip: true },
            ],
            zIndex: 50,
        };
        table.data.push(row);
    }

    return table;
};

export const convertIClusterInfoToTable = (clusters: IClusterInfo[]): ITable => {
    const table: ITable = { columns: [], data: [] };

    // Enter Headers
    table.columns = [
        { header: "Name", width: "10%", minWidth: 100 },
        { header: "IP Address", width: "9%", minWidth: 100 },
        { header: "Version", width: "9%", minWidth: 100 },
        { header: "Status", width: "9%", minWidth: 100 },
        { header: "Failover Status", width: "9%", minWidth: 100 },
        { header: "License Status", width: "9%", minWidth: 100 },
        { header: "Mapping Groups", width: "9%", minWidth: 100 },
        { header: "Nodes", width: "9%", minWidth: 100 },
        { header: "End-Points", width: "9%", minWidth: 100 },
        { header: "Active Sources", width: "9%", minWidth: 100 },
        { header: "Active Destinations", width: "9%", minWidth: 100 },
    ];

    for (let i = 0; i < clusters.length; i++) {
        const cluster: IClusterInfo = clusters[i];

        let totalUClients = 0;
        // let totalGPULoad = 0;
        // let totalCPUUsage = 0;

        // let averageGPULoad = 0;
        // let averageCPUUsage = 0;

        // for (let i = 0; i < cluster.clusterDeviceInfo.length; i++) {
        //     const clusterDeviceInfo = cluster.clusterDeviceInfo[i];

        //     totalUClients += clusterDeviceInfo.endpoint.offline + clusterDeviceInfo.endpoint.online;
        //     totalGPULoad += getGPUUsage(clusterDeviceInfo.deviceUsage.gpu);
        //     totalCPUUsage += getCPUUsage(clusterDeviceInfo.deviceUsage.cpu);
        // }

        // averageGPULoad = Math.round(totalGPULoad / cluster.clusterDeviceInfo.length);
        // averageCPUUsage = Math.round(totalCPUUsage / cluster.clusterDeviceInfo.length);


        const row: IRowData = {
            id: cluster.id,
            columns: [
                {
                    component: (

                        <div className="cluster normalGap limit">
                            <FontAwesomeIcon className="icon blue" icon={faCircleNodes} />
                            <p>{cluster.name}</p>
                        </div>

                    ),
                    limit: true,
                },
                {
                    component: <p className="limit">{cluster.ip}</p>,
                    limit: true,
                },
                {
                    component: <p className="limit">Userful {cluster.version.length >= 1 ? cluster.version : "X.X"}</p>,
                    limit: true,
                },
                {
                    component: (
                        <div className="limit">
                            {cluster.status === "CONNECTED" &&  <div className="cluster normalGap limit"><FontAwesomeIcon className="green" icon={faCheck} /><p>Online</p></div>}
                            {cluster.status === "DISCONNECTED" &&  <div className="cluster normalGap limit"><FontAwesomeIcon className="red" icon={faMultiply} /><p>Offline</p></div>}
                        </div>
                    ),
                    limit: true,
                },
                {
                    component: (
                        <div className="limit">
                            {cluster.license.failOver && <FontAwesomeIcon className="green" icon={faCheck} />}
                            {!cluster.license.failOver && <FontAwesomeIcon className="red" icon={faMultiply} />}
                        </div>
                    ),
                    limit: true,
                },
                {
                    component: (
                        <div className="limit">
                            {cluster.license.active && <FontAwesomeIcon className="green" icon={faCheck} />}
                            {!cluster.license.active && <FontAwesomeIcon className="red" icon={faMultiply} />}
                        </div>
                    ),
                    limit: true,
                },
                {
                    component: <p>{cluster.mappingGroups}</p>,
                    limit: true,
                },
                {
                    component: <p>{cluster.clusterDeviceInfo.length}</p>,
                    limit: true,
                },
                {
                    component: <p>{totalUClients}</p>,
                    limit: true,
                },
                {
                    component: <p>{cluster.activeSources}</p>,
                    limit: true,
                },
                {
                    component: <p>{cluster.activeDestinations}</p>,
                    limit: true,
                },
            ],
        };
        if (cluster.license.active) table.data.push(row);
    }

    return table;
};

export const convertIBackupAndRestoreToTable = (backupAndRestores: BackupData[]): ITable => {
    const table: ITable = { columns: [], data: [] };

    // Enter Headers
    table.columns = [
        { header: "Name", width: "calc(24% - 60px)", minWidth: 100 },
        { header: "Backup Status", width: "24%", minWidth: 100 },
        { header: "Cluster Name", width: "18%", minWidth: 100 },
        { header: "Cluster Type", width: "18%", minWidth: 100 },
        { header: "Backup Type", width: "18%", minWidth: 100 },
        { header: "", width: 60 },
    ];

    for (let i = 0; i < backupAndRestores.length; i++) {
        const backupAndRestore: BackupData = backupAndRestores[i];

        const row: IRowData = {
            id: backupAndRestore.id,
            columns: [
                {
                    component: (
                        <div className="table-row-column"><FontAwesomeIcon icon={faRefresh} /><p className="limit">{backupAndRestore.metadata ? backupAndRestore.metadata.name : backupAndRestore.id.value}</p></div>
                    ),
                    limit: true,
                },
                {
                    component: <div className="table-row-column">
                        {backupAndRestore.status === "IN_PROGRESS" && <FontAwesomeIcon className="spin-animation blue" icon={faSpinner} />}
                        {backupAndRestore.status === "COMPLETED" && <FontAwesomeIcon className="green" icon={faCheck} />}
                        {backupAndRestore.status === "ERROR" && <FontAwesomeIcon className="red" icon={faExclamationCircle} />}
                        <p className="limit">{backupAndRestore.metadata ? formatTimestamp(backupAndRestore.metadata.date) : "Generating Backup"}</p></div>,
                    limit: true,
                },
                {
                    component: (
                        <p className="limit">{backupAndRestore.metadata ? backupAndRestore.metadata.clusterName : "-"}</p>
                    ),
                    limit: true,
                },
                {
                    component: (
                        <p className="limit">{backupAndRestore.metadata ? backupAndRestore.metadata.clusterType === "AETHER_MANAGER" ? "Aether Manager" : backupAndRestore.metadata.clusterType : "-"}</p>
                    ),
                    limit: true,
                },
                {
                    component: (
                        <p className="limit">{backupAndRestore.metadata ? backupAndRestore.metadata.backupType === "MANUAL" ? "Manual" : backupAndRestore.metadata.backupType : "-"}</p>
                    ),
                    limit: true,
                },
                { component: backupAndRestore.metadata.clusterType !== "IN_PROGRESS" ? <BackupActions backup={backupAndRestore} /> : null, hideTooltip: true },
            ],
        };
        table.data.push(row);
    }

    return table;
};

export const convertClusterDeviceInfoToTable = (devices: ClusterDeviceInfo[]): ITable => {
    const table: ITable = { columns: [], data: [] };

    // Enter Headers
    table.columns = [
        { header: "Name", width: "15%", minWidth: 100 },
        { header: "End-Points", width: "15%", minWidth: 100 },
        { header: "RAM", width: "20%", minWidth: 100 },
        { header: "GPU", width: "20%", minWidth: 100 },
        { header: "CPU", width: "15%", minWidth: 100 },
        { header: "Storage", width: "15%", minWidth: 100 },
    ];

    for (let i = 0; i < devices.length; i++) {
        const device: ClusterDeviceInfo = devices[i];

        const row: IRowData = {
            id: device.id,
            columns: [
                {
                    component: (
                        <div className="cluster normalGap limit">
                            <FontAwesomeIcon className="icon blue" icon={faServer} />
                            <p>{device.name}</p>
                        </div>
                    ),
                    limit: true,
                },
                {
                    component: (
                        <>
                            <div className="greenBadge">
                                <p>{device.endpoint.online} Online</p>
                            </div>
                            <div className="redBadge">
                                <p>{device.endpoint.offline} Offline</p>
                            </div>
                        </>
                    ),
                    limit: true,
                },
                {
                    component: <p className="limit">{formatMemory(device.deviceUsage.memory)}</p>,
                    limit: true,
                },
                {
                    component: <p className="limit">{formatGPU(device.deviceUsage.gpu)}</p>,
                    limit: true,
                },
                {
                    component: <p className="limit">{formatCPU(device.deviceUsage.cpu)}</p>,
                    limit: true,
                },
                {
                    component: <p className="limit">{formatStorage(device.deviceUsage.storage)}</p>,
                    limit: true,
                },
            ],
        };
        if (device.active) table.data.push(row);
    }

    return table;
};

export const convertBackendHardwareInfoToTable = (hardwareInfo: BackendHardwareInfo): ITable => {
    const table: ITable = { columns: [], data: [] };

    // Enter Headers
    table.columns = [
        { header: "Summary", width: "20%", minWidth: 100 },
        { header: "", width: "80%", minWidth: 100 },

    ];


    let row: IRowData = {
        id: { value: "1" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Manufacturer</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.systemInfo.manufacturer}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    row = {
        id: { value: "2" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Product Name</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.systemInfo.productName}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    row = {
        id: { value: "3" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Serial Number</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.systemInfo.serialNumber}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    row = {
        id: { value: "4" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Family</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.systemInfo.family}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    row = {
        id: { value: "5" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>System ID</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.systemInfo.systemID}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    row = {
        id: { value: "6" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>SKU Number</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.systemInfo.skuNumber}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    row = {
        id: { value: "7" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Maximum Capacity</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.memoryInfoList.maxCapacity}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    row = {
        id: { value: "8" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Memory Slots</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.memoryInfoList.numberOfDevices}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    return table;
};


export const convertGPUToTable = (gpu: GpuInfo[]): ITable => {
    const table: ITable = { columns: [], data: [] };

    // Enter Headers
    table.columns = [
        { header: "GPU", width: "30%", minWidth: 100 },
        { header: "", width: "35%", minWidth: 100 },
        { header: "", width: "35%", minWidth: 100 },

    ];

    const row: IRowData = {
        id: { value: "header" },
        columns: [
            {
                component: <p className="limit">Model</p>,
                limit: true,
            },
            {
                component: <p className="limit">Type</p>,
                limit: true,
            },
            {
                component: <p className="limit">Slot ID</p>,
                limit: true,
            },
        ],
    };
    table.data.push(row);

    for (let i = 0; i < gpu.length; i++) {
        const device: GpuInfo = gpu[i];

        const row: IRowData = {
            id: { value: device.driver },
            columns: [
                {
                    component: <p className="limit">{device.model}</p>,
                    limit: true,
                },
                {
                    component: <p className="limit">{device.type}</p>,
                    limit: true,
                },
                {
                    component: <p className="limit">{device.slotID}</p>,
                    limit: true,
                },
            ],
        };
        table.data.push(row);
    }

    return table;
};


export const convertMemoryInfoToTable = (hardwareInfo: BackendHardwareInfo): ITable => {
    const table: ITable = { columns: [], data: [] };

    // Enter Headers
    table.columns = [
        { header: "Memory Info", width: "25%", minWidth: 100 },
        { header: "", width: "25%", minWidth: 100 },
        { header: "", width: "25%", minWidth: 100 },
        { header: "", width: "25%", minWidth: 100 },
    ];

    const row: IRowData = {
        id: { value: "header" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Manufacturer</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Size</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: <div className="cluster normalGap limit">
                    <p>Serial Number</p>
                </div>,
                limit: true,
            },
            {
                component: <div className="cluster normalGap limit">
                    <p>Speed</p>
                </div>,
                limit: true,
            },

        ],
    };
    table.data.push(row);

    for (let i = 0; i < hardwareInfo.memoryInfoList.memoryList.length; i++) {
        const memory: MemoryInfo = hardwareInfo.memoryInfoList.memoryList[i];

        const row: IRowData = {
            id: { value: i + "" },
            columns: [
                {
                    component: (
                        <div className="cluster normalGap limit">
                            <p>{memory.manufacturer}</p>
                        </div>
                    ),
                    limit: true,
                },
                {
                    component: (
                        <div className="cluster normalGap limit">
                            <p>{memory.size}</p>
                        </div>
                    ),
                    limit: true,
                },
                {
                    component: <div className="cluster normalGap limit">
                        <p>{memory.serialNumber}</p>
                    </div>,
                    limit: true,
                },
                {
                    component: <div className="cluster normalGap limit">
                        <p>{memory.speed}</p>
                    </div>,
                    limit: true,
                },

            ],
        };
        table.data.push(row);
    }

    return table;
};


export const convertProcessorInfoToTable = (hardwareInfo: BackendHardwareInfo): ITable => {
    const table: ITable = { columns: [], data: [] };

    // Enter Headers
    table.columns = [
        { header: "Processor Info", width: "25%", minWidth: 100 },
        { header: "", width: "75%", minWidth: 100 },

    ];


    let row: IRowData = {
        id: { value: "1" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Vendor</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.processorInfo.vendor}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    row = {
        id: { value: "2" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Core Count</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.processorInfo.coreCount}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    row = {
        id: { value: "3" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Core Enabled</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.processorInfo.coreEnabled}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    row = {
        id: { value: "4" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Version</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.processorInfo.version}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    row = {
        id: { value: "5" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Thread Count</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.processorInfo.threadCount}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    row = {
        id: { value: "6" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Current Speed</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.processorInfo.currentSpeed}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    return table;
};


export const convertChassisInfoToTable = (hardwareInfo: BackendHardwareInfo): ITable => {
    const table: ITable = { columns: [], data: [] };

    // Enter Headers
    table.columns = [
        { header: "Chassis Info", width: "25%", minWidth: 100 },
        { header: "", width: "75%", minWidth: 100 },

    ];


    let row: IRowData = {
        id: { value: "1" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Manufacturer</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.chassisInfo.manufacturer}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    row = {
        id: { value: "2" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Asset Tag</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.chassisInfo.assetTag}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    row = {
        id: { value: "3" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Serial Number</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.chassisInfo.serialNumber}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    return table;
};


export const convertCaptureCardToTable = (captureCardInfo: BackendCaptureCardInfo): ITable => {
    const table: ITable = { columns: [], data: [] };

    // Enter Headers
    table.columns = [
        { header: "Capture Card", width: "25%", minWidth: 100 },
        { header: "", width: "75%", minWidth: 100 },

    ];


    let row: IRowData = {
        id: { value: "1" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Inputs</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{captureCardInfo.inputs.length > 0 ? captureCardInfo.inputs.length : "Not Detected"}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    row = {
        id: { value: "2" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Ports</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{captureCardInfo.ports.length > 0 ? captureCardInfo.ports.length : "Not Detected"}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    return table;
};

export const convertBIOSInfoToTable = (hardwareInfo: BackendHardwareInfo): ITable => {
    const table: ITable = { columns: [], data: [] };

    // Enter Headers
    table.columns = [
        { header: "BIOS Info", width: "25%", minWidth: 100 },
        { header: "", width: "75%", minWidth: 100 },

    ];


    let row: IRowData = {
        id: { value: "1" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Version</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.biosInfo.version}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    row = {
        id: { value: "2" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Release Date</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.biosInfo.releaseDate}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    row = {
        id: { value: "3" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>ROM Size</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.biosInfo.romSize}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    row = {
        id: { value: "4" },
        columns: [
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>Vendor</p>
                    </div>
                ),
                limit: true,
            },
            {
                component: (
                    <div className="cluster normalGap limit">
                        <p>{hardwareInfo.biosInfo.vendor}</p>
                    </div>
                ),
                limit: true,
            },

        ],
    };
    table.data.push(row);

    return table;
};
