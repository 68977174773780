import { ReactNode, useEffect } from "react";
import { Provider } from "react-redux";
import groupStore, { GroupContext } from "./groupStore";
import { requestAllGroups } from 'userful-chronos-app-common-js/dist/models/group/messages/GroupMsgSender';
import "./msgs/groupsMsgReceiver.ts"

interface IProps {
    appID?: string;
    children: ReactNode;
}

function GroupsProvider(props: IProps) {
    useEffect(() => {
        requestAllGroups();
    }, []);

    return (
        <Provider store={groupStore} context={GroupContext}>
            {props.children}
        </Provider>
    );
}

export default GroupsProvider;
