import React, { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { HasOrgData, SamOrgData, Tag } from "userful-chronos-app-common-js/dist/models/sam/Common";
import { useTranslation } from "react-i18next";
import { AppsConfig } from "./formUtils";
import "./SamOrgForm.scss";
import UserfulTooltip from "../Widgets/UserfulTooltip";

interface IProps {
    data: HasOrgData;
    onChange: (update: SamOrgData) => void;
    hideApps?: string[];
    tags?: Tag[];
    disabled?: boolean;
}

export default function AppPermissionForm(props: IProps) {
    const checkboxRef = useRef(null);
    const [checked, setChecked] = useState(props.data.orgData.appIDs.appIDs.length === AppsConfig.length);
    const [allApps, setAllApps] = useState([...AppsConfig]);

    useEffect(() => {
        if (props.hideApps) {
            setAllApps(AppsConfig.filter(item => !props.hideApps.includes(item.id)));
        }
    }, [props.hideApps])

    const appIsAllowed = (appID: string) =>
        props.data.orgData.appIDs.appIDs.findIndex((item) => item.value === "*" || item.value === appID) >= 0;

    const onToggleApp = (appID: string) => {
        const updatedIDs = appIsAllowed(appID)
            ? props.data.orgData.appIDs.appIDs.filter((item) => item.value !== appID)
            : [...props.data.orgData.appIDs.appIDs, { value: appID }];
        props.onChange({
            ...props.data.orgData,
            appIDs: {
                valid: true,
                appIDs: updatedIDs,
            },
        });

        const updatedIDsLength = updatedIDs?.length || 0;

        if (updatedIDsLength === 0) {
            setChecked(false);
            checkboxRef.current.indeterminate = false;
            return;
        }

        if (updatedIDsLength === allApps.length) {
            checkboxRef.current.indeterminate = false;
            setChecked(true);
            return;
        }

        const x = (checkboxRef.current.indeterminate = true);
    };

    const handleSelectAllGemstones = (e) => {
        const res = [];
        allApps.map((item) => {
            if (appIsAllowed(item.id)) {
                return;
            }
            res.push({ value: item.id });
        });
        let updatedIDs = [];
        if (e.target.checked) {
            updatedIDs = [...props.data.orgData.appIDs.appIDs, ...res];
            setChecked(true);
        } else {
            setChecked(false);
        }
        props.onChange({
            ...props.data.orgData,
            appIDs: {
                valid: true,
                appIDs: updatedIDs,
            },
        });
    };

    const { t } = useTranslation();

    useEffect(() => {

        if (checkboxRef.current) {
            let lengthApps = props.data.orgData.appIDs.appIDs.length;

            if (lengthApps === 0) {
                setChecked(false);
                checkboxRef.current.indeterminate = false;
                return;
            }

            if (lengthApps === allApps.length) {
                checkboxRef.current.indeterminate = false;
                setChecked(true);
                return;
            }

            checkboxRef.current.indeterminate = true;

        }

    }, [props.data]);


    return (
        <div className="samOrgAppSelectContainer input-wrapper">
            <div className="hero-text-wrapper">
                <div className="info-heading">{t("CommonUI.Sources.GemstonePermissions")}</div>
                <span className="heading2">{t("CommonUI.Sources.GemstonePermissionsDescription")}</span>
            </div>
            <Form>
                <div className="gemstone-select">
                    <Form.Check
                        inline
                        label="Select all Apps"
                        name="allGemstones"
                        type="checkbox"
                        id="allGemstones"
                        ref={checkboxRef}
                        checked={checked}
                        disabled={props.disabled}
                        onClick={handleSelectAllGemstones}
                        onChange={() => { }}
                    />
                </div>
            </Form>
            <div className="gemstone-wrapper">
                {allApps.map((item) => {
                    if (item.id !== "GCM") {
                        return <UserfulTooltip key={`sam-app-select-${item.id}`} title={t(item.title, { appName: item.name })}>
                            {props.disabled ? (
                                <div key={`sam-app-select-${item.id}`}
                                    className={`appSelectItem${appIsAllowed(item.id) ? " active" : ""}`}> {item.titleIcon}</div>
                            ) : (
                                <div
                                    key={`sam-app-select-${item.id}`}
                                    className={`appSelectItem${appIsAllowed(item.id) ? " active" : ""}`}
                                    onClick={() => onToggleApp(item.id)}
                                >
                                    {item.titleIcon}
                                </div>
                            )}
                        </UserfulTooltip>
                    }
                })}
            </div>
        </div>
    );
}
