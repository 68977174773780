import React, { LegacyRef } from "react";
import Select from 'react-select'

import "./Dropdown.scss";
import { faCircleQuestion, faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserfulTooltip from "../Widgets/UserfulTooltip";
import { customStylesSelect } from "../Calendar/view/customStylesSelect";

interface IBaseValues {
    label: string;
    value: string;
    [key: string]: any
}

interface IProps {
    options: IBaseValues[] | [];
    values: IBaseValues[] | [];
    onChange: (e) => void;
    isMulti?: boolean;
    iconOnly?: boolean;
    closeMenuOnSelect?: boolean;
    error?: boolean | undefined;
    success?: boolean | undefined;
    filedTitle?: React.ReactNode | undefined;
    help?: React.ReactNode | undefined;
    postChildren?: React.ReactNode | undefined;
    preChildren?: React.ReactNode | undefined;
    insidePreChildren?: React.ReactNode | undefined;
    insidePostChildren?: React.ReactNode | undefined;
}

const Dropdown = React.forwardRef((props: IProps, ref: LegacyRef<HTMLButtonElement>) => {

    // const className = `userful-dropdown ${props.className || ''}`

    const insertTitleOrHelp = props.filedTitle || props.help;
    const insertPreChildren = props.preChildren || insertTitleOrHelp;

    const handleOnChange = (e) => {
        props.onChange(e);
    }

    return (
        <div className="dropdown-field-main-wrapper">
            {insertPreChildren && <div className={`dropdown-field-header ${props.error ? "dropdown-field-error" : ""}${props.success ? "dropdown-field-success" : ""}`}>
                {insertTitleOrHelp && <div className={`dropdown-field-title-container`}>
                    {props.filedTitle && <p>{props.filedTitle}</p>}{props.help && <UserfulTooltip title={props.help}><FontAwesomeIcon icon={faCircleQuestion} /></UserfulTooltip>}</div>}
                {props.preChildren}
            </div>}
            <div className="dropdown-field-wrapper">
                {props.insidePreChildren}
                <Select
                    menuPosition='fixed'
                    isMulti={props.isMulti}
                    options={props.options}
                    onChange={handleOnChange}
                    value={props.values}
                    closeMenuOnSelect={props.closeMenuOnSelect}
                    styles={customStylesSelect}
                    components={{ IndicatorSeparator: () => null }}
                    instanceId="generic-select"
                    inputId="generic-select"
                />
            </div>
            {props.postChildren && <div className={`dropdown-field-container ${props.error ? "dropdown-field-error" : ""}${props.success ? "dropdown-field-success" : ""}`}>
                {props.error && <FontAwesomeIcon icon={faCircleQuestion} />}
                {props.success && <FontAwesomeIcon icon={faCheckCircle} />}
                {props.postChildren}
            </div>}

        </div>
    );
});
export default Dropdown;