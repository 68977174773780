import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { NOTIFY_NOTIFICATION } from 'userful-chronos-app-common-js/dist/message/messageTypeDefinitions/notifications/notificationMsgs';
import { AetherNotification } from 'userful-chronos-app-common-js/dist/models/notifications/notifications';
import { DiagnosticNotificationData } from "userful-chronos-app-common-js/dist/models/notifications/diagnostics";
import diagnosticStore from "../diagnosticStore";
import { diagnosticActions } from "../diagnosticSlice";

registerMsgHandler(NOTIFY_NOTIFICATION, (payload) => {
    const data = JSON.parse(payload) as AetherNotification;
    if (data.data["@type"]=== 'diagnostic') {
        console.debug('Receive Diagnostic results', payload);
        const update = data.data as DiagnosticNotificationData;
        diagnosticStore.dispatch(diagnosticActions.updateDiagnostics(update));
    }
})
