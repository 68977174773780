import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { ZeroClientData } from "userful-chronos-app-common-js/dist/models/zeroClient";
import displaysStore from "../displaysStore";
import { zeroclientsSliceActions } from "../zeroclientsSlice";
import { NOTIFY_ZERO_CLIENT_ADDED, NOTIFY_ZERO_CLIENT_REMOVED, NOTIFY_ZERO_CLIENT_UPDATED, RESPONSE_LIST_ZERO_CLIENTS, RESPONSE_LIST_ZERO_CLIENTS_FOR_VE } from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/displays/ZeroClientsMsgDefinations";


registerMsgHandler(RESPONSE_LIST_ZERO_CLIENTS, (payload) => {
    console.debug('Receive RESPONSE_LIST_ZERO_CLIENTS');
    const data = JSON.parse(payload) as Array<ZeroClientData>;
    displaysStore.dispatch(zeroclientsSliceActions.setDisplays(data));
})

registerMsgHandler(RESPONSE_LIST_ZERO_CLIENTS_FOR_VE, (payload) => {
    console.debug('Receive RESPONSE_LIST_ZERO_CLIENTS_FOR_VE');
    const data = JSON.parse(payload) as Array<ZeroClientData>;
    displaysStore.dispatch(zeroclientsSliceActions.setDisplaysForVE(data));
})

registerMsgHandler(NOTIFY_ZERO_CLIENT_ADDED, (payload) => {
    console.debug('Receive NOTIFY_ZERO_CLIENT_ADDED');
    const data = JSON.parse(payload) as ZeroClientData;
    displaysStore.dispatch(zeroclientsSliceActions.addOrUpdateDisplays(data));
})

registerMsgHandler(NOTIFY_ZERO_CLIENT_UPDATED, (payload) => {
    console.debug('Receive NOTIFY_ZERO_CLIENT_UPDATED');
    const data = JSON.parse(payload) as ZeroClientData;
    displaysStore.dispatch(zeroclientsSliceActions.addOrUpdateDisplays(data));
})

registerMsgHandler(NOTIFY_ZERO_CLIENT_REMOVED, (payload) => {
    console.debug('Receive NOTIFY_ZERO_CLIENT_REMOVED');
    const data = JSON.parse(payload) as StringID;
    displaysStore.dispatch(zeroclientsSliceActions.deleteDisplay(data));
})
