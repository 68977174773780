import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { UClientActionComp } from "userful-chronos-app-common-js/dist/models/uclient";
import displaysStore from "../displaysStore";
import {
    NOTIFY_UCLIENT_ACTION_ADDED, NOTIFY_UCLIENT_ACTION_REMOVED,
    NOTIFY_UCLIENT_ACTION_UPDATED, RESPONSE_LIST_UCLIENT_ACTIONS
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/displays/UClientsMsgDefinations";
import { uclientActionsActions } from "../uclientActionsSlice";

registerMsgHandler(RESPONSE_LIST_UCLIENT_ACTIONS, (payload) => {
    console.debug('Receive RESPONSE_LIST_UCLIENT_ACTIONS');
    const data = JSON.parse(payload) as Array<UClientActionComp>;
    displaysStore.dispatch(uclientActionsActions.setActions(data));
})

registerMsgHandler(NOTIFY_UCLIENT_ACTION_ADDED, (payload) => {
    const data = JSON.parse(payload) as UClientActionComp;
    console.debug("Receive NOTIFY_UCLIENT_ACTION_ADDED");
    displaysStore.dispatch(uclientActionsActions.addOrUpdateActions(data));
});

registerMsgHandler(NOTIFY_UCLIENT_ACTION_UPDATED, (payload) => {
    const data = JSON.parse(payload) as UClientActionComp;
    console.debug("Receive NOTIFY_UCLIENT_ACTION_UPDATED");
    displaysStore.dispatch(uclientActionsActions.addOrUpdateActions(data));
});

registerMsgHandler(NOTIFY_UCLIENT_ACTION_REMOVED, (payload) => {
    const data = JSON.parse(payload) as StringID;
    console.debug("Receive NOTIFY_UCLIENT_ACTION_REMOVED");
    displaysStore.dispatch(uclientActionsActions.deleteAction(data));
});

