import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { CompGroup } from "userful-chronos-app-common-js/dist/models/group/Groups";

export interface VideoWallsState {
    groups: Array<CompGroup>;
}

const initialState: VideoWallsState = {
    groups: [],
};

const groupSlice = createSlice({
    name: "group",
    initialState,
    reducers: {
        setGroups(state: VideoWallsState, action: PayloadAction<CompGroup[]>) {
            state.groups = [...action.payload];
        },
        addGroup(state: VideoWallsState, action: PayloadAction<CompGroup>) {
            state.groups = [...state.groups, { ...action.payload }];
        },
        updateGroup(state: VideoWallsState, action: PayloadAction<CompGroup>) {
            const mapperLayout = action.payload;

            const index = state.groups.findIndex((l) => l.id.value === mapperLayout.id.value);

            if (index >= 0) {
                state.groups[index] = mapperLayout;
            }
        },
        removeGroup(state: VideoWallsState, action: PayloadAction<StringID>) {
            const index = state.groups.findIndex((l) => l.id.value === action.payload.value);

            if (index >= 0) {
                state.groups.splice(index, 1);
            }
        }
    }
});

export const groupSliceActions = groupSlice.actions;

export default groupSlice.reducer;
