import React, { useEffect, useMemo, useState, useRef } from "react";
import { Dropdown, Form, Offcanvas, Tab, Tabs } from "react-bootstrap";
import validator from "email-validator";
import "./sourcesform.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faCopy, faPenToSquare, faPlus, faXmark } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import Switch from 'react-switch';
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { APP_IDS, EMPTY_USERFUL_SECURITY_DATA, StringID } from "userful-chronos-app-common-js/dist/models/common";
import { getFilteredAppsBasedOnSourceType } from 'userful-chronos-app-common-js/dist/models/sam/AppSourceTypeFilter';
import { SamOrgData, Scaling, Tag } from "userful-chronos-app-common-js/dist/models/sam/Common";
import { SamSourceAssetIDParam } from "userful-chronos-app-common-js/dist/models/sam/SAMAsset";
import { BetaSources, SamSourceDef, SamSourceDefCreator } from "userful-chronos-app-common-js/dist/models/sam/SAMSource";
import { initSamOrgData, initSamSource } from "userful-chronos-app-common-js/dist/models/sam/SAMUtils";
import { sourceSliceActions, useSourceAssetDispatch, useSourceAssetSelector } from "userful-chronos-common-store/dist/sourceassets";
import Button from "../../Button/Button";
import { customStyles } from "../../Calendar/view/customStyles";
import UploadFile from "../../UploadFile/UploadFile";
import { trimValue } from "../../Utils/UiUtils";
import HelpInfo from "../../Widgets/HelpInfo/HelpInfo";
import UserfulTooltip from "../../Widgets/UserfulTooltip";
import AppPermissionForm from "../AppPermissionForm";
import ForceUpdateDialog from "../ForceUpdateDialog";
import SourcePermissionsForm from "../SourcePermissionsForm";
import { filterSpecParams } from "../SourceSpecCustomRules";
import TagForm from "../TagForm";
import SamSourceFormParamPanel from "./SamSourceFormParamPanel";
import "./Source.scss";
import { GetListSources, RESTRICTED_SOURCE_SPECS, convertFormDataInputToSourceParam, convertSourceDefToAssetsData, convertSourceDefToFormData, findDefaultParamValue, getBlockedSourceSpecByAppIDs, getRestrictedSourceSpecs } from "./SourceFormUtil";
import HelpWidget from "./SourcesFormSubComponents/HelpWidget";
import NoSourcePlaceholder from "./noSourcePlaceholder";
import { AppsConfig } from "../formUtils";
import CreatePassportWarning from "../CreatePassportWarning";
import { useIntegrationSelector } from "userful-chronos-common-store/dist/integration";
import { AddCustomCheckForEpic, AddCustomCheckForTableau, AddCustomCheckForServicenow } from './CustomValidation';
import { getSecurityData } from "./SourceFormUtil";
import { useUsermgtSelector } from "userful-chronos-common-store/dist/usermgt";
interface Iprops {
    source?: SamSourceDef;
    buttonVariant?: string;
    appID?: string;
    noSourceBool?: boolean;
    sourcesData?: any;
    fromSourceBar?: boolean;
    disableSourceTypeSelection?: boolean;
    onCard?: boolean;
    borderTopRightZero?: boolean;
    duplicate?: boolean;
    dropdownItem?: boolean;
    noIconInDropdown?: boolean;
    onCreate?: (sourceID: StringID) => void;
}

export default function SamSourceForm(props: Iprops) {
    const dispatch = useSourceAssetDispatch();
    const sourceSpecs = useSourceAssetSelector((state) => state.sourceStore.sourceSpecs);
    const allSources = useSourceAssetSelector((state) => state.sourceStore.sources);
    const otherSources = allSources.filter((item) => !props.source || props.source.id.value !== item.id.value);
    const allTags: Tag[] = allSources.flatMap((s) => s.orgData.tagsSet).flatMap((t) => t.tags);
    const [show, setShow] = useState(false);
    const source = useRef(initSamSource(props.appID !== APP_IDS.Config && props.appID));
    const clearanceLevelsUserHasAccess = useUsermgtSelector((state) => state.levelStore.clearanceLevelsThatUserHasAccess);

    const tableauPassports = useIntegrationSelector((state) => state.tableauStore.tableauPassports);
    const servicenowPassports = useIntegrationSelector((state) => state.servicenowStore.servicenowPassports);

    const paramInput = useRef({});
    const paramAsset = useRef<SamSourceAssetIDParam[]>([]);
    const [requiredErrors, setRequiredErrors] = useState({});
    const [duplicateNameError, setDuplicateNameError] = useState<string>(null);
    const [numberErrors, setNumberErrors] = useState({});
    const [validate, setValidate] = useState(false);
    const pendingUpdateSource = useSourceAssetSelector((state) => state.sourceStore.pendingUpdateSource);

    // powerbi passports
    const passportsPowerBI = useIntegrationSelector((state) => state.powerbiStore.powerbiPassports);
    // epic passports
    const passportsEpic = useIntegrationSelector((state) => state.epicStore.epicServers);
    // epic ADT passport
    const adtPassportsEpic = useIntegrationSelector((state) => state.epicStore.epicADTPassports);

    const { t } = useTranslation();

    const handleShow = () => {
        const currentSource = props.source || initSamSource(props.appID !== APP_IDS.Config && props.appID);
        source.current = currentSource;
        setDuplicateNameError(null);
        setNumberErrors({});
        setValidate(false);
        setRequiredErrors({});
        paramInput.current = convertSourceDefToFormData(currentSource, sourceSpecs);
        paramAsset.current = convertSourceDefToAssetsData(currentSource);
        setShow(true);
    };

    const createDefaultSourceName = (name: string) => {
        if (otherSources.findIndex((item) => name === item.name) < 0) {
            return name;
        }
        let duplicateIndex = 1;
        let purposedName = `${name} (${duplicateIndex})`;
        while (otherSources.findIndex((item) => purposedName === item.name) >= 0) {
            duplicateIndex++;
            purposedName = `${name} (${duplicateIndex})`;
        }
        return purposedName;
    };

    const handleTypeChange = (e: { value: string; label: string }) => {
        const selectedSourceSpecID = e.value;
        const name = source.current.name || createDefaultSourceName(e.label);

        source.current = {
            ...source.current,
            name,
            type: {
                value: selectedSourceSpecID,
                name: selectedSourceSpecID,
            },
            orgData: { ...initSamOrgData(props.appID !== APP_IDS.Config && props.appID) },
        };

        const selectedSourceSpec = sourceSpecs.find((spec) => spec.header.id.value === selectedSourceSpecID);
        if (!selectedSourceSpec) {
            paramInput.current = {};
            return;
        }
        let res = {};
        selectedSourceSpec.params.forEach((param) => {
            res[param.name] = findDefaultParamValue(param);
        });
        paramInput.current = res;
        onValidate("name");
    };

    useEffect(() => {
        if (pendingUpdateSource && pendingUpdateSource.receivedResponse && pendingUpdateSource.usageData.length < 1) {
            dispatch(sourceSliceActions.clearPendingUpdateOrDelete());
            handleCloseForm();
        }
    }, [pendingUpdateSource]);

    const sourceSelectStyles = useMemo(
        () => ({
            option: (provided, state) => ({
                ...provided,
                color: "#1F2747",
                maxHeight: "176px",
                height: "50px",
                padding: "0px 16px",
                background: state.isSelected ? "#F3F4F5" : state.isFocused ? "#F3F4F5" : undefined,
                display: "flex",
                alignItems: "center",
                marginBottom: "2px",
                "&:active": {
                    background: "#F3F4F5",
                },
            }),
            control: (base, state) => ({
                // none of react-select's styles are passed to <Control />
                ...base,
                width: "100%",
                height: "36px",
                color: "#1F2747",
                background: " #FFFFFF",
                boxShadow: "inset 0px 2px 4px rgba(31, 39, 71, 0.1)",
                borderRadius: "8px",
                transition: "none",
                display: "flex",
                border: !source.current.type.value ? "1px solid #dc3545" : "1px solid #C6C8CE",
                "&:hover": {
                    border: "1px solid #4B5168",
                },
            }),
        }),
        [source]
    );

    const onResolutionChange = (e) => {
        const update = {
            ...source.current,
            displayResolution: {
                ...source.current.displayResolution,
                [e.target.name]: e.target.value,
            },
        };

        source.current = update;
        onValidate(e.target.name);
    };

    const handleCloseForm = () => {
        setValidate(false);
        setShow(false);
        source.current = props.source || initSamSource();
    };

    const sanitizeparamAsset = () => {
        const selectedSourceSpec = sourceSpecs.find((spec) => spec.header.id.value === source.current.type.value);
        if (!selectedSourceSpec) {
            return [];
        }
        return paramAsset.current.filter(
            (param) =>
                selectedSourceSpec.assetSpecs.findIndex((asset) => asset.sourceParamName === param.paramName) >= 0
        );
    };

    const handleSubmitForm = () => {
        if (onValidate().hasErrors || !!duplicateNameError) {
            setValidate(true);
            return;
        }
        setValidate(false);

        const update = {
            ...source.current,
            name: trimValue(source.current.name),
            description: trimValue(source.current.description),
            params: convertFormDataInputToSourceParam(paramInput.current),
            assets: sanitizeparamAsset(),
            orgData: {
                ...source.current.orgData,
                appIDs: {
                    valid: true,
                    appIDs: source.current.orgData.appIDs.appIDs.filter(item => !blockedAppPermissions.includes(item.value))
                }

            },
            outputType:
                paramInput.current["Disable audio"] === "true" || paramInput.current["Disable audio"] === true
                    ? "VIDEO_ONLY"
                    : "VIDEO_AND_AUDIO",
            userfulSecurityData: getSecurityData(source.current.userfulSecurityData, clearanceLevelsUserHasAccess, !props.source, getGlobalStates),
            appInstanceID: {
                value: getGlobalStates().systemID,
                appID: {
                    value: getGlobalStates().appID.value,
                }
            }
        };
        delete update.auditData;

        if (props.onCreate) props.onCreate(update.id)

        dispatch(sourceSliceActions.addOrUpdateSourceToServer(update as SamSourceDefCreator));
    };

    const handleForceSubmit = () => {
        const update: any = {
            ...source.current,
            name: trimValue(source.current.name),
            description: trimValue(source.current.description),
            params: convertFormDataInputToSourceParam(paramInput.current),
            assets: sanitizeparamAsset(),
            outputType:
                paramInput.current["Disable audio"] === "true" || paramInput.current["Disable audio"] === true
                    ? "VIDEO_ONLY"
                    : "VIDEO_AND_AUDIO",
            userfulSecurityData: getSecurityData(source.current.userfulSecurityData, clearanceLevelsUserHasAccess, !props.source, getGlobalStates),
            appInstanceID: {
                value: getGlobalStates().systemID,
                appID: {
                    value: getGlobalStates().appID.value,
                }
            }
        };
        delete update.auditData;
        dispatch(sourceSliceActions.forceUpdateSourceToServer(update as SamSourceDefCreator));
        handleCloseForm();
    };

    const onChange = (e) => {
        const update = {
            ...source.current,
            [e.target.name]: e.target.value,
        };
        source.current = update;

        onValidate("name");
    };

    const onChangePermission = (checkBool: boolean, value: string) => {
        const updatedSource = JSON.parse(JSON.stringify(source.current));

        const updatedClearanceLevelPermissions = [...updatedSource.userfulSecurityData.clearanceLevelPermissions];

        if (checkBool) {
            // If checkBool is true, add the value to the array if it doesn't already exist
            if (!updatedClearanceLevelPermissions.some((item) => item.value === value)) {
                updatedClearanceLevelPermissions.push({ value: value });
            }
        } else {
            // If checkBool is false, remove the value from the array if it exists
            const indexToRemove = updatedClearanceLevelPermissions.findIndex((item) => item.value === value);
            if (indexToRemove !== -1) {
                updatedClearanceLevelPermissions.splice(indexToRemove, 1);
            }
        }
        // Update the clearanceLevelPermissions in the updated state
        updatedSource.userfulSecurityData.clearanceLevelPermissions = updatedClearanceLevelPermissions;

        source.current = updatedSource;

        onValidate("name");
    };

    const handleParamChange = (e) => {
        if (e.value) {
            paramInput.current = {
                ...paramInput.current,
                [e.name]: e.value,
            };

            onValidate(e.name);
        } else if (e.target.type === "checkbox") {
            paramInput.current = {
                ...paramInput.current,
                [e.target.name]: e.target.checked,
            };
            onValidate(e.target.name);
        } else if (e.target.type === "radio") {
            paramInput.current = {
                ...paramInput.current,
                [e.target.name]: e.target.value,
            };
            onValidate(e.target.name);
        } else {
            // Input type "TEXT"
            const { name, value } = e.target;
            paramInput.current = {
                ...paramInput.current,
                [name]: value,
            };
            onValidate(e.target.name);
        }
    };

    const handleAssetChange = (update: SamSourceAssetIDParam) => {
        const foundIndex = paramAsset.current.findIndex((item) => item.paramName === update.paramName);
        if (foundIndex >= 0) {
            paramAsset.current = [
                ...paramAsset.current.slice(0, foundIndex),
                { ...update },
                ...paramAsset.current.slice(foundIndex + 1),
            ];
        } else {
            paramAsset.current = [...paramAsset.current, { ...update }];
        }

        onValidate(update.paramName);
    };

    const updateAssetOnFileUpload = (id: string) => {
        const foundParams = paramAsset.current.find((item) => item.paramName === "Playlist");
        let updateParams = [];
        if (foundParams) updateParams = [...foundParams.sourceAssets, { value: id }];
        else updateParams = [{ value: id }];

        handleAssetChange({ paramName: "Playlist", sourceAssets: updateParams });
    };

    const onOrgDataChange = (orgData: SamOrgData) => {
        source.current = {
            ...source.current,
            orgData,
        };
        onValidate("name");
    };

    const sourceTypeOptions = GetListSources(sourceSpecs, props.appID, source.current, props.disableSourceTypeSelection)
        .map((sourceSpec) => {
            const translationKey = `SourceSpecs.type.${sourceSpec.header.id.value.replaceAll(".", "_")}`;
            return { value: sourceSpec.header.id.value, label: t(translationKey) };
        })
        .sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true, sensitivity: "base" }));
    const selectedSourceSpec = filterSpecParams(
        paramInput.current,
        sourceSpecs.find((item) => item.header.id.value === source.current.type.value)
    );
    const selectedSourceSpecOption =
        selectedSourceSpec && sourceTypeOptions.find((item) => item.value === selectedSourceSpec.header.id.value);
    const blockedAppPermissions = [
        APP_IDS.Engage,
        APP_IDS.Remote,
        APP_IDS.Spaces,
        ...getFilteredAppsBasedOnSourceType(selectedSourceSpecOption?.value),
    ];
    const formatSourceTypeLabel = ({ value, label }, { context }) => {
        if (context === "value") {
            return <div className="sourceTypeName">{label}</div>;
        }
        return (
            <div className="sourceTypeOption">
                <div className="sourceTypeName">{label}</div>
                <div className="sourceTypeDescription">{t(`SourceSpecs.type.${value.replaceAll(".", "_")}_help`)}</div>
            </div>
        );
    };

    const onValidate = (checkField?: string) => {
        const newRequiredErrors = {};
        const newNumberErrors = {};
        const validateAll = validate || !checkField;
        if (
            (isNaN(source.current.displayResolution.width) || Number(source.current.displayResolution.width) < 1) &&
            (checkField === "width" || validateAll)
        ) {
            newNumberErrors["displayResolutionWidth"] = "invalid number";
        }
        if (
            (isNaN(source.current.displayResolution.height) || Number(source.current.displayResolution.height) < 1) &&
            (checkField === "height" || validateAll)
        ) {
            newNumberErrors["displayResolutionHeight"] = "invalid number";
        }
        if (!trimValue(source.current.name) && (checkField === "name" || validateAll)) {
            newRequiredErrors["name"] = "missing";
        }
        if (checkField === "name" || validateAll) {
            if (source.current.name && otherSources.findIndex((item) => item.name === source.current.name) >= 0) {
                setDuplicateNameError(t("CommonUI.Sources.Sources.nameDuplicate"));
            } else {
                setDuplicateNameError(null);
            }
        }
        const selectedSourceSpec = filterSpecParams(
            paramInput.current,
            sourceSpecs.find((item) => item.header.id.value === source.current.type.value)
        );
        // dont run this for epic as its not required
        if (selectedSourceSpec && source.current.type.value !== 'Epic') {
            selectedSourceSpec.params.forEach((param) => {
                if (param.required && (checkField === param.name || validateAll)) {
                    // check if required field is empty
                    if (!paramInput.current[param.name]) {
                        newRequiredErrors[param.name] = "missing";
                    } else if (param.type === "FILE_DIR_SELECT") {
                        // check file dir select widget
                        let itemCount = 0;
                        if (paramInput.current[param.name].file) {
                            itemCount += paramInput.current[param.name].file.length;
                        }
                        if (paramInput.current[param.name].dir) {
                            itemCount += paramInput.current[param.name].dir.length;
                        }
                        if (itemCount < 1) {
                            newRequiredErrors[param.name] = "missing";
                        }
                    }
                }
                selectedSourceSpec.assetSpecs.forEach((assetSpec) => {
                    if (assetSpec.required && (checkField === assetSpec.sourceParamName || validateAll)) {
                        if (paramAsset.current.findIndex((item) => item.paramName === assetSpec.sourceParamName && item.sourceAssets.length > 0) < 0) {
                            newRequiredErrors[assetSpec.sourceParamName] = "missing";
                        }
                    }
                });
                if (param.type === "NUMBER" && (checkField === param.name || validateAll)) {
                    // check if number field is valid
                    const stringValue = paramInput.current[param.name];
                    if (isNaN(stringValue)) {
                        newNumberErrors[param.name] = "not a number";
                    } else {
                        const numValue = Number(stringValue);
                        const findMax = param.additionalParams.find((ele) => ele.name === "maximum");
                        const findMin = param.additionalParams.find((ele) => ele.name === "minimum");
                        if (
                            (findMax && numValue > Number(findMax.value)) ||
                            (findMin && numValue < Number(findMin.value))
                        ) {
                            newNumberErrors[param.name] = "out of range";
                        }
                    }
                }
                if (param.type.toUpperCase() === "EMAIL" && (checkField === param.name || validateAll)) {
                    // check for email
                    const stringValue = paramInput.current[param.name];
                    if (!validator.validate(stringValue)) {
                        newRequiredErrors[param.name] = "not a valid email";
                    }
                }
                if (param.type.toUpperCase() === "URL" && (checkField === param.name || validateAll)) {
                    // check for url
                    const stringValue = paramInput.current[param.name];
                    try {
                        const url = new URL(stringValue);
                    } catch (_) {
                        newRequiredErrors[param.name] = "not a valid url";
                    }
                }
            });
        }
        const tableauErrors = AddCustomCheckForTableau(source.current, paramInput.current, tableauPassports, validateAll, checkField);
        const epicErrors = AddCustomCheckForEpic(source.current, paramInput.current, passportsEpic, adtPassportsEpic, validateAll, checkField);
        const servicenowErrors = AddCustomCheckForServicenow(source.current, paramInput.current, servicenowPassports, validateAll, checkField);
        Object.assign(newRequiredErrors, tableauErrors); // add tableau errors to the newRequiredError
        Object.assign(newRequiredErrors, epicErrors); // add epic errors to the newRequirederror
        Object.assign(newRequiredErrors, servicenowErrors); // add servicenow errors to the newRequirederror
        setRequiredErrors(newRequiredErrors);
        setNumberErrors(newNumberErrors);
        const hasErrors = Object.keys(newNumberErrors).length > 0 || Object.keys(newRequiredErrors).length > 0;
        return { newRequiredErrors, newNumberErrors, hasErrors };
    };

    const scalingOptions = [
        { label: t("CommonUI.Sources.scalingFull"), value: "FULL" },
        { label: t("CommonUI.Sources.scalingInside"), value: "INSIDE" },
        { label: t("CommonUI.Sources.scalingOutside"), value: "OUTSIDE" },
    ];

    const selectedScalingOption = scalingOptions.find((item) => item.value === source.current.scaling);

    const handleScalingChange = (newValues: { label: string; value: string }) => {
        const update = {
            ...source.current,
            scaling: newValues.value as Scaling,
        };

        source.current = update;
        onValidate("name");
    };

    const toggleSharing = () => {
        const update: SamSourceDef | SamSourceDefCreator = {
            ...source.current,
            sharingMode: source.current.sharingMode === "UNIQUE_SOURCE" ? "ALWAYS_SHARE_SOURCE" : "UNIQUE_SOURCE",
        };
        source.current = update;
        onValidate("name");
    };

    const isUpdating = pendingUpdateSource && !pendingUpdateSource.receivedResponse;
    const showForceUpdateConfirm =
        pendingUpdateSource &&
        pendingUpdateSource.receivedResponse &&
        pendingUpdateSource.usageData.length > 0 &&
        pendingUpdateSource.itemID === props.source?.id?.value;

    const goToPBPassport = () => {
        window.location.href = '/source-destination-management?goToPBPassport=true';
    }

    const goToEpicPassport = () => {
        window.location.href = '/source-destination-management?goToEpicPassport=true';
    }

    const goToTabPassport = () => {
        window.location.href = '/source-destination-management?goToTabPassport=true';
    }

    const goToSNPassport = () => {
        window.location.href = '/source-destination-management?goToSNPassport=true';
    }

    return (
        <>
            {!props.noSourceBool && (
                <>
                    {props.source ? (
                        props.dropdownItem ? (
                            <Dropdown.Item className="dropdownItem" onClick={handleShow}>
                                {!props.noIconInDropdown && <div className="iconInside">
                                    <FontAwesomeIcon icon={props.duplicate ? faCopy : faPenToSquare} />
                                </div>}
                                <div className="textInside">
                                    {props.duplicate ? t("Shared.duplicate") : t("Shared.edit")}
                                </div>
                            </Dropdown.Item>
                        ) : !props.onCard ? (
                            <div onClick={handleShow} id="edit-source-button">
                                {props.duplicate ? t("Shared.duplicate") : t("Shared.edit")}
                            </div>
                        ) : (
                            <UserfulTooltip placement="top" title={t("Shared.edit")}>
                                <div
                                    className={`cardButton edit-source-button ${props.borderTopRightZero && "noBorderRadiusPlaylistDelete"
                                        }`}
                                    onClick={handleShow}
                                >
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </div>
                            </UserfulTooltip>
                        )
                    ) : (
                        <Button
                            variant={props.buttonVariant || "success"}
                            onClick={handleShow}
                            id="create-source-menu-button"
                        >
                            <FontAwesomeIcon icon={faPlus} />
                            {t("CommonUI.Sources.title")}
                        </Button>
                    )}
                </>
            )}

            {props.noSourceBool && (
                <NoSourcePlaceholder
                    headerName={t("CommonUI.Sources.NoSource.headerName")}
                    headerDescription={t("CommonUI.Sources.NoSource.headerDescription")}
                    buttonName={t("CommonUI.Sources.NoSource.buttonName")}
                    buttonNameShow={true}
                    headerNameShow={true}
                    headerDescriptionShow={true}
                    onClick={handleShow}
                />
            )}

            <Offcanvas show={show} onHide={handleCloseForm} placement="end">
                <Offcanvas.Body>
                    <div
                        className="overlay-main-container"
                        onMouseDown={(e) => e.stopPropagation()}
                        // onMouseUp={(e) => e.stopPropagation()} !!! this breaks React-Switch !!!
                        onKeyDown={(e) => e.stopPropagation()}
                        // onClick={(e) => e.stopPropagation()} !!! this breaks dropdown auto close !!!
                        onDoubleClick={(e) => e.stopPropagation()}
                    >
                        <div className="source-creation-wrapper">
                            <div className="hero-wrapper" style={{ marginBottom: "24px" }}>
                                <div>
                                    <div className="hero-text-wrapper">
                                        <div className="hero-text">
                                            {(props.source && !props.duplicate)
                                                ? t("CommonUI.Sources.editTitle")
                                                : t("CommonUI.Sources.title")}
                                        </div>
                                        <span className="heading2" style={{ fontWeight: "500" }}>
                                            {t("CommonUI.Sources.description")}
                                        </span>
                                    </div>
                                    <div className="custom-close-btn" onClick={handleCloseForm}>
                                        <FontAwesomeIcon icon={faXmark} />
                                    </div>
                                </div>
                            </div>
                            {/**This is for displaying the warning message if no passport is available*/}
                            {source.current.type.value === "Epic" && (passportsEpic.length + adtPassportsEpic.length) === 0 &&
                                <CreatePassportWarning preText={t('Integrations.Common.WarningPreEpic')} onClick={() => goToEpicPassport()} />}
                            {source.current.type.value === "Power_BI" && passportsPowerBI.length === 0 &&
                                <CreatePassportWarning preText={t('Integrations.Common.WarningPrePB')} onClick={() => goToPBPassport()} />}
                            {source.current.type.value === "Tableau" && tableauPassports.length === 0 &&
                                <CreatePassportWarning preText={t('Integrations.Common.WarningPreTab')} onClick={() => goToTabPassport()} />}
                            {source.current.type.value === "Servicenow" && servicenowPassports.length === 0 &&
                                <CreatePassportWarning preText={t('Integrations.Common.WarningPreSN')} onClick={() => goToSNPassport()} />}
                            <Tabs defaultActiveKey="first">
                                <Tab title={t("CommonUI.Sources.SourceSettings")} eventKey="first">
                                    <div className="source-form-wrapper">
                                        <Form className="source-form" onSubmit={(e) => e.preventDefault()}>
                                            <div>
                                                <div className="info-heading">
                                                    {t("CommonUI.Sources.SourceDetails")}
                                                </div>
                                                <span className="heading2">
                                                    {t("CommonUI.Sources.DetailDescription")}
                                                </span>
                                            </div>

                                            <Form.Group className="input-wrapper" controlId="formSourceName">
                                                <div className="text-char-wrapper">
                                                    <Form.Label className="heading1">{t("Shared.name")}</Form.Label>
                                                    <span>{source.current.name.length}/60</span>
                                                </div>
                                                <Form.Control
                                                    as="input"
                                                    name="name"
                                                    placeholder={t("CommonUI.Sources.SourceNamePlaceholder")}
                                                    onChange={onChange}
                                                    value={source.current.name}
                                                    isInvalid={!!requiredErrors["name"] || !!duplicateNameError}
                                                    maxLength={60}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {t("CommonUI.Sources.SourceNameRequired")}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="input-wrapper" controlId="formSourceDescription">
                                                <div className="text-char-wrapper">
                                                    <Form.Label className="heading1">
                                                        {t("Shared.description")}
                                                    </Form.Label>
                                                    <span>{source.current.description.length}/200</span>
                                                </div>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={5}
                                                    name="description"
                                                    placeholder={t("CommonUI.Sources.SourceDescriptionPlaceholder")}
                                                    onChange={onChange}
                                                    value={source.current.description}
                                                    maxLength={200}
                                                />
                                            </Form.Group>
                                            <TagForm
                                                data={source.current}
                                                onChange={onOrgDataChange}
                                                tags={allTags}
                                                hideApps={blockedAppPermissions}
                                            />
                                            <Form.Group
                                                className="input-wrapper source-type-select"
                                                controlId="formSourceType"
                                            >
                                                <Form.Label className="heading1">
                                                    {t("CommonUI.Sources.Type")}
                                                    <HelpInfo
                                                        className="sourceTypeHelp"
                                                        title={t("CommonUI.Sources.TypeHelp")}
                                                    />
                                                </Form.Label>
                                                <Select
                                                    styles={sourceSelectStyles}
                                                    options={sourceTypeOptions}
                                                    onChange={handleTypeChange}
                                                    placeholder={t("CommonUI.Sources.SourceTypePlaceholder")}
                                                    value={selectedSourceSpecOption || null}
                                                    formatOptionLabel={formatSourceTypeLabel}
                                                    instanceId="source-type-select"
                                                    inputId="source-type-select-select"
                                                    menuPosition="fixed"
                                                />
                                                <HelpWidget
                                                    param={
                                                        selectedSourceSpec &&
                                                        selectedSourceSpec.params.find((param) => param.type === "HELP")
                                                    }
                                                />
                                            </Form.Group>

                                            {source.current.type.value === "Signage_Player" && (
                                                <>
                                                    <div>
                                                        <div className="displayInRow">
                                                            <div className="info-heading">
                                                                {t("Sources&Destinations.Sources.UploadFile.title")}
                                                            </div>
                                                            <HelpInfo
                                                                className="sourceHelp"
                                                                title={t("CommonUI.Sources.allowedExtensions")}
                                                            />
                                                        </div>
                                                        <span className="heading2">
                                                            {t("Sources&Destinations.Sources.UploadFile.description")}
                                                        </span>
                                                    </div>
                                                    <UploadFile
                                                        inSource={true}
                                                        updateSourceAsset={(id: string) => updateAssetOnFileUpload(id)}
                                                    />
                                                </>
                                            )}

                                            <SamSourceFormParamPanel
                                                selectedSourceSpec={selectedSourceSpec}
                                                paramInput={paramInput.current}
                                                sourceAssets={paramAsset.current}
                                                handleParamChange={handleParamChange}
                                                handleAssetChange={handleAssetChange}
                                                requiredErrors={requiredErrors}
                                                numberErrors={numberErrors}
                                                show={show}
                                                beta={BetaSources.includes(source.current.type.value)}
                                            />
                                        </Form>
                                    </div>
                                </Tab>
                                <Tab title={t("CommonUI.Sources.Advanced")} eventKey="second">
                                    <div className="source-form-wrapper">
                                        <Form className="source-form" onSubmit={(e) => e.preventDefault()}>
                                            <div>
                                                <div className="info-heading">{t("Shared.resolution")}</div>
                                                <span className="heading2">{t("Shared.resolutionDescription3")}</span>
                                            </div>
                                            <div className="custom-input-group" style={{ marginBottom: "36px" }}>
                                                <Form.Group>
                                                    <Form.Label className="heading1">
                                                        {t("Shared.resolutionWidth")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        onChange={onResolutionChange}
                                                        name="width"
                                                        value={source.current.displayResolution.width}
                                                        // isInvalid={!!numberErrors['displayResolutionWidth']}
                                                        isInvalid={
                                                            !source.current.displayResolution.width ||
                                                            source.current.displayResolution.width < 1
                                                        }
                                                        min={1}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t("CommonUI.Sources.WidthPrompt")}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="input-wrapper">
                                                    <Form.Label className="heading1">
                                                        {t("Shared.resolutionHeight")}
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        onChange={onResolutionChange}
                                                        name="height"
                                                        value={source.current.displayResolution.height}
                                                        isInvalid={
                                                            !source.current.displayResolution.height ||
                                                            source.current.displayResolution.height < 1
                                                        }
                                                        min={1}
                                                    // isInvalid={!!numberErrors['displayResolutionHeight']}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {t("CommonUI.Sources.HeightPrompt")}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <Form.Group className="input-wrapper" controlId="scalingSelection">
                                                <div>
                                                    <div className="info-heading">{t("Shared.scaling")}</div>
                                                    <span className="heading2">
                                                        {t("CommonUI.Sources.scalingHelp")}
                                                    </span>
                                                </div>
                                            </Form.Group>
                                            <Select
                                                options={scalingOptions}
                                                onChange={handleScalingChange}
                                                value={selectedScalingOption}
                                                menuPosition="fixed"
                                                maxMenuHeight={300}
                                                styles={customStyles}
                                                instanceId="source-scaling-select"
                                                inputId="source-scaling-select"
                                                components={{ IndicatorSeparator: () => null }}
                                            />

                                            {!getRestrictedSourceSpecs(props.appID).includes(selectedSourceSpec?.header.id.value) && (
                                                <div style={{ marginTop: "36px" }}>
                                                    <Form.Group
                                                        className="input-wrapper"
                                                        controlId="sourceSharing"
                                                        style={{ marginBottom: "16px" }}
                                                    >
                                                        <div>
                                                            <div className="info-heading">
                                                                {t("CommonUI.Sources.SourceSharing")}
                                                            </div>
                                                            <span className="heading2">
                                                                {t("CommonUI.Sources.SourceSharingDes")}
                                                            </span>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group
                                                        className="input-wrapper source-option-toggle"
                                                        controlId="shareToggle"
                                                    >
                                                        <Switch
                                                            onChange={toggleSharing}
                                                            checked={source.current.sharingMode !== "UNIQUE_SOURCE"}
                                                            uncheckedIcon={false}
                                                            checkedIcon={false}
                                                            height={16}
                                                            width={32}
                                                            onHandleColor="#FFFFFF"
                                                            onColor="#23A866"
                                                            offColor="#C6C8CE"
                                                            offHandleColor="#FFFFFF"
                                                            className="toggle-switch"
                                                            borderRadius={99}
                                                            boxShadow="0px 2px 2px rgba(31, 39, 71, 0.1)"
                                                            handleDiameter={12}
                                                            draggable={false}
                                                        />
                                                        <Form.Label className="heading1">
                                                            {t("CommonUI.Sources.sharing")}
                                                            <HelpInfo title={t("CommonUI.Sources.sharingHelp")} />
                                                        </Form.Label>
                                                    </Form.Group>
                                                </div>
                                            )}
                                        </Form>
                                    </div>
                                </Tab>


                                <Tab title={t("CommonUI.Sources.Permissions")} eventKey="third">
                                    {!props.disableSourceTypeSelection &&
                                        !RESTRICTED_SOURCE_SPECS.includes(selectedSourceSpec?.header.id.value) && (
                                            <AppPermissionForm
                                                data={source.current}
                                                onChange={onOrgDataChange}
                                                tags={allTags}
                                                hideApps={blockedAppPermissions}
                                            />
                                        )}
                                    <SourcePermissionsForm
                                        data={source.current.userfulSecurityData.clearanceLevelPermissions}
                                        onChange={(checkBool, value) => onChangePermission(checkBool, value)}
                                        isSourceOrDestination
                                    />

                                </Tab>
                            </Tabs>
                        </div>
                        <div className="button-wrapper">
                            <Button
                                variant="primary"
                                disabled={!source.current.type.value || isUpdating}
                                onClick={handleSubmitForm}
                                id="save-source-button"
                            >
                                {t("Shared.save")} {isUpdating && <FontAwesomeIcon icon={faCircleNotch} spin />}
                            </Button>
                            <Button variant="secondary" onClick={handleCloseForm}>
                                {t("Shared.cancel")}
                            </Button>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
            {pendingUpdateSource && source && source.current.name && showForceUpdateConfirm && (
                <ForceUpdateDialog
                    onForceUpdate={handleForceSubmit}
                    show={showForceUpdateConfirm}
                    pendingUpdateDate={pendingUpdateSource}
                    itemName={source.current.name}
                    onCancel={() => dispatch(sourceSliceActions.clearPendingUpdateOrDelete())}
                />
            )}
        </>
    );
}
