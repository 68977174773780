import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import {
    RESPOND_LIST_SERVERS, RESPOND_ADD_SERVER, RESPOND_UPDATE_SERVER, RESPOND_REMOVE_SERVER, NOTIFY_SERVER_UPDATE,
    RESPOND_LIST_PASSPORTS, RESPOND_ADD_PASSPORT, RESPOND_UPDATE_PASSPORT, RESPOND_REMOVE_PASSPORT, RESPOND_SEARCHPARAMS, NOTIFY_PASSPORT_UPDATE
} from 'userful-chronos-app-common-js/dist/message/messageTypeDefinitions/integration/epicMsgDefinitions';
import { EpicServer, EpicServerHeader, EpicServerID } from "userful-chronos-app-common-js/dist/models/integration/epic";
import { EpicADTPassport, EpicADTPassportID} from "userful-chronos-app-common-js/dist/models/integration/epicADT";
import integrationStore from "../integrationStore";
import { epicActions } from "../epicSlice";
import { registerErrorMsgHandlerByMsgDefinition } from "userful-chronos-app-common-js/dist/message/messageRegistery";

registerMsgHandler(RESPOND_LIST_SERVERS, (payload) => { //wait time
    console.debug("receive RESPOND_LIST_SERVERS");
    const data = JSON.parse(payload) as EpicServer[];
    integrationStore.dispatch(epicActions.setServers(data));
});

registerMsgHandler(RESPOND_LIST_PASSPORTS, (payload) => { //ADT
    console.debug("receive EPIC RESPOND_LIST_PASSPORTS");
    const data = JSON.parse(payload) as EpicADTPassport[];
    integrationStore.dispatch(epicActions.setADTPassports(data));
});

registerMsgHandler(RESPOND_ADD_SERVER, (payload) => { // wait time
    console.debug("receive RESPOND_ADD_SERVER");
    const data = JSON.parse(payload) as EpicServerHeader;
    integrationStore.dispatch(epicActions.changeLoadState("RENDER"));
    integrationStore.dispatch(epicActions.setOpenForm(false));
});

registerMsgHandler(RESPOND_ADD_PASSPORT, (payload) => { //  ADT
    console.debug("receive RESPOND_ADD_PASSPORT");
    const data = JSON.parse(payload) as EpicADTPassport;
    integrationStore.dispatch(epicActions.addOrUpdateADTPassport(data));
    integrationStore.dispatch(epicActions.changeLoadState("RENDER"));
    integrationStore.dispatch(epicActions.setOpenForm(false));
});

registerErrorMsgHandlerByMsgDefinition(RESPOND_ADD_SERVER, (data) => { // wait time
    integrationStore.dispatch(epicActions.changeLoadState("RENDER"));
});

registerErrorMsgHandlerByMsgDefinition(RESPOND_ADD_PASSPORT, (data) => { // ADT
    integrationStore.dispatch(epicActions.changeLoadState("RENDER"));
});

registerMsgHandler(RESPOND_UPDATE_SERVER, (payload) => { // wait time
    console.debug("receive RESPOND_UPDATE_SERVER");
    const data = JSON.parse(payload) as EpicServerHeader;
    integrationStore.dispatch(epicActions.changeLoadState("RENDER"));
    integrationStore.dispatch(epicActions.setOpenForm(false));
});

registerMsgHandler(RESPOND_UPDATE_PASSPORT, (payload) => { // ADT
    console.debug("receive RESPOND_UPDATE_PASSPORT");
    const data = JSON.parse(payload) as EpicADTPassport;
    integrationStore.dispatch(epicActions.addOrUpdateADTPassport(data));
    integrationStore.dispatch(epicActions.changeLoadState("RENDER"));
    integrationStore.dispatch(epicActions.setOpenForm(false));
});

registerErrorMsgHandlerByMsgDefinition(RESPOND_UPDATE_SERVER, (data) => { //wait time
    integrationStore.dispatch(epicActions.changeLoadState("RENDER"));
});

registerErrorMsgHandlerByMsgDefinition(RESPOND_UPDATE_PASSPORT, (data) => { //ADT
    integrationStore.dispatch(epicActions.changeLoadState("RENDER"));
});

registerMsgHandler(RESPOND_REMOVE_SERVER, (payload) => { // wait time
    console.debug("receive RESPOND_UPDATE_SERVER"); 
    const data = JSON.parse(payload) as EpicServerID;
    integrationStore.dispatch(epicActions.removeServer(data));
});

registerMsgHandler(RESPOND_REMOVE_PASSPORT, (payload) => { // ADT
    console.debug("receive RESPOND_REMOVE_PASSPORT"); 
    const data = JSON.parse(payload) as EpicADTPassportID;
    integrationStore.dispatch(epicActions.removePassport(data));
});

registerMsgHandler(NOTIFY_SERVER_UPDATE, (payload) => { // wait time
    console.debug("receive NOTIFY_SERVER_UPDATE");
    const data = JSON.parse(payload) as EpicServer;
    integrationStore.dispatch(epicActions.addOrUpdateServer(data));
});

registerMsgHandler(NOTIFY_PASSPORT_UPDATE, (payload) => { // ADT
    console.debug("receive NOTIFY_PASSPORT_UPDATE");
    const data = JSON.parse(payload) as EpicADTPassport;
    integrationStore.dispatch(epicActions.addOrUpdateADTPassport(data));
});

registerMsgHandler(RESPOND_SEARCHPARAMS, (payload) => { //ADT
    console.debug("receive RESPOND_SEARCHPARAMS");
    const data = JSON.parse(payload) as string;
    integrationStore.dispatch(epicActions.setSearchParams(data));
});

