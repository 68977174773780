import React from "react";

import "./App.scss";
import "./index.scss";

import Authenticated from "./auth/GCMAuthenticationProvider";
import Main from "./components/Main";
import { initGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { ToastContainer } from "react-toastify";

export default function App() {
    initGlobalStates("gcm");
    return (
        <Authenticated>
            <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Main />
        </Authenticated>
    );
}
