import { configureStore } from '@reduxjs/toolkit'
import React from 'react'
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook } from 'react-redux'
import channelSlice from './channelSlice'

export const RubyContext = React.createContext(null);

const rubyStore = configureStore({
  reducer: {
      channelStore: channelSlice,
  },
})
export default rubyStore;

export type State = ReturnType<typeof rubyStore.getState>;
export type Dispatch = typeof rubyStore.dispatch;
export const useRubySelector: TypedUseSelectorHook<State> = createSelectorHook(RubyContext);
export const useRubyDispatch = createDispatchHook<Dispatch>(RubyContext)
