import React from 'react'
import RadioWidget from './SourcesFormSubComponents/RadioWidget';
import './sourcesform.scss';
import SelectWidget from './SourcesFormSubComponents/SelectWidget';
import TextWidget from './SourcesFormSubComponents/TextWidget';
import CheckboxWidget from './SourcesFormSubComponents/CheckboxWidget';
import PasswordWidget from './SourcesFormSubComponents/PasswordWidget';
import TextAreaWidget from './SourcesFormSubComponents/TextAreaWidget';
import NumberWidget from './SourcesFormSubComponents/NumberWidget';
import InstructionWidget from './SourcesFormSubComponents/InstructionWidget';
import ImageSelectWidget from './SourcesFormSubComponents/ImageSelectWidget';
import FileDirSelectWidget from './SourcesFormSubComponents/FileDirSelect/FileDirSelectWidget';
import Select from 'react-select';
import { SamSourceSpec, SourceSpecAssetSpec } from 'userful-chronos-app-common-js/dist/models/sam/SAMSourceSpec';
import { SamSourceAssetIDParam } from 'userful-chronos-app-common-js/dist/models/sam/SAMAsset';
import SortableMultiSelect from '../../Widgets/SortableMultiSelect/SortableMultiSelect';
import { useTranslation } from 'react-i18next';
import { convertFormDataInputToSourceParam, getTranslatedParamNameFromName } from './SourceFormUtil';
import ParamHelpInfo from './SourcesFormSubComponents/ParamHelpInfo';
import { useSourceAssetSelector } from 'userful-chronos-common-store/dist/sourceassets';
import PowerBISourceFormInner from '../integrationSources/PowerBI/PowerBISourceFormInner';
import EpicSourceFormInner from '../integrationSources/Epic/EpicSourceFormInner';
import { IntegrationProvider } from 'userful-chronos-common-store/dist/integration';
import TableauFormInner from '../integrationSources/tableau/TableauFormInner';
import ServicenowFormInner from '../integrationSources/servicenow/ServicenowFormInner';


interface Iprops {
    selectedSourceSpec?: SamSourceSpec,
    paramInput: object,
    handleParamChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    handleAssetChange: (update: SamSourceAssetIDParam) => void,
    requiredErrors: object,
    numberErrors: object,
    sourceAssets: Array<SamSourceAssetIDParam>;
    initialOptions?: any;
    show: boolean;
    beta:boolean;
}

export default function SamSourceFormParamPanel(props: Iprops) {
    const { t } = useTranslation();
    const assets = useSourceAssetSelector(state => state.assetStore.assets);

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: '#1F2747',
            maxHeight: '176px',
            height: '32px',
            padding: '0px 16px',
            background: state.isSelected ? '#F3F4F5' : state.isFocused ? '#F3F4F5' : undefined,
            display: 'flex',
            alignItems: 'center',
            "&:active": {
                background: '#F3F4F5',
            }

        }),
        control: (base, state) => ({
            // none of react-select's styles are passed to <Control />
            ...base,
            width: "100%",
            height: '36px',
            color: '#1F2747',
            background: ' #FFFFFF',
            boxShadow: 'inset 0px 2px 4px rgba(31, 39, 71, 0.1)',
            borderRadius: '8px',
            transition: 'none',
            display: 'flex',
            border: state.menuIsOpen ? '1px solid #6C89E8' : '1px solid #C6C8CE',
            '&:hover': {
                border: '1px solid #4B5168',
            }
        }),
        valueContainer: base => ({
            ...base,
            display: 'flex',
            padding: '0px 16px',
        }),

    }

    const getFilteredAssets = (type) => {
        return assets.filter(asset => asset.type === type).map(asset => ({
            value: asset.id.value,
            label: `${asset.name} (${type})`,
            name: asset.name,
        }))
    }

    const getDefaultAssetValue = (assetSpec: SourceSpecAssetSpec) => {
        const foundAssets = props.sourceAssets.find(asset => asset.paramName === assetSpec.sourceParamName);
        if (!foundAssets) {
            return [];
        }

        // array with id and value
        return foundAssets.sourceAssets
            .map(item => assets.find(current => current.id.value === item.value))
            .filter(d => d)
            .map(item => ({
                label: item.name,
                value: item.id.value,
                name: item.name,
            }))

    }

    const handleAssetChange = (paramName, newValue) => {
        const updatedAssetIDs = Array.isArray(newValue) ?
            newValue.map(item => ({ value: item.value })) : [{ value: newValue.value }]
        props.handleAssetChange({
            paramName,
            sourceAssets: updatedAssetIDs,
        })
    }

    if (!props.selectedSourceSpec) {
        return null;
    }

    const iconSelect = () => {
        const foundParam = props.selectedSourceSpec?.params.find(p => p.type === 'IMAGE_SELECT');
        if (foundParam) {
            return (
                <ImageSelectWidget
                    param={foundParam}
                    input={props.paramInput}
                    onChange={props.handleParamChange}
                    key={`source-${props.selectedSourceSpec.header.name}-param-icon-${foundParam.name}`}
                    beta={props.beta}
                />
            );
        }
        return null;
    }


    if (props.selectedSourceSpec.header.id.value === "Power_BI") {
        return <>{iconSelect()}
            <PowerBISourceFormInner
                params={convertFormDataInputToSourceParam(props.paramInput)}
                handleParamChange={(name, value) => {
                    props.handleParamChange({
                        target: { name, value }
                    } as React.ChangeEvent<HTMLInputElement>)
                }}
                requiredErrors={props.requiredErrors}
                show={props.show} />
        </>
    }

    if (props.selectedSourceSpec.header.id.value === "Epic") {
        return <>{iconSelect()}
            <EpicSourceFormInner
                params={convertFormDataInputToSourceParam(props.paramInput , true)}
                handleParamChange={(name, value) => {
                    props.handleParamChange({
                        target: { name, value }
                    } as React.ChangeEvent<HTMLInputElement>)
                }}
                requiredErrors={props.requiredErrors}
            />
        </>
    }

    if (props.selectedSourceSpec.header.id.value === "Tableau") {
        return <>{iconSelect()}
            <IntegrationProvider>
                <TableauFormInner
                    params={convertFormDataInputToSourceParam(props.paramInput)}
                    handleParamChange={(name, value) => {
                        props.handleParamChange({
                            target: { name, value }
                        } as React.ChangeEvent<HTMLInputElement>)
                    }}
                    requiredErrors={props.requiredErrors}
                    show={props.show}
                />
            </IntegrationProvider>
        </>
    }

    if (props.selectedSourceSpec.header.id.value === "Servicenow") {
        return <>{iconSelect()}
            <IntegrationProvider>
                <ServicenowFormInner
                    params={convertFormDataInputToSourceParam(props.paramInput, true)}
                    handleParamChange={(name, value) => {
                        props.handleParamChange({
                            target: { name, value }
                        } as React.ChangeEvent<HTMLInputElement>)
                    }}
                    requiredErrors={props.requiredErrors}
                    show={props.show}
                />
            </IntegrationProvider>
        </>
    }

    return (<>
        {iconSelect()}
        {
            props.selectedSourceSpec?.assetSpecs.map((assetSpec, index) => {
                const availableOptions = getFilteredAssets(assetSpec.sourceAssetType);
                return (
                    <div className='sourceAssetSelect' key={index}>
                        <label className='heading1 form-label'>
                            {getTranslatedParamNameFromName(assetSpec.sourceParamName, props.selectedSourceSpec, t)}
                            <ParamHelpInfo paramName={assetSpec.sourceParamName} className="sourceAsset" selectedSourceSpec={props.selectedSourceSpec} />
                        </label>
                        {assetSpec.cardinality === 'MANY' ?
                            <SortableMultiSelect
                                key={`sourceAssetSelect-${assetSpec.sourceParamName}`}
                                initialOptions={getDefaultAssetValue(assetSpec)}
                                appendOptions={getDefaultAssetValue(assetSpec)}
                                availableOptions={getFilteredAssets(assetSpec.sourceAssetType)}
                                onChange={(newValue) => {
                                    handleAssetChange(assetSpec.sourceParamName, newValue);
                                }}
                                canDelete
                            />
                            :
                            <Select
                                key={`sourceAssetSelect-${assetSpec.sourceParamName}`}
                                options={availableOptions}
                                defaultValue={getDefaultAssetValue(assetSpec)}
                                onChange={(newValue) => handleAssetChange(assetSpec.sourceParamName, newValue)}
                                isDisabled={availableOptions.length < 1}
                                components={{ IndicatorSeparator: () => null }}
                                instanceId="source-asset-select"
                                inputId="source-asset-select"
                                menuPosition='fixed'
                            />
                        }
                        {props.requiredErrors[assetSpec.sourceParamName] && <span style={{ 'fontSize': '12px', 'color': 'rgb(244, 67, 54)' }}>{`${t('CommonUI.Sources.fileError')} ${getTranslatedParamNameFromName(assetSpec.sourceParamName, props.selectedSourceSpec, t)}`}</span>}
                    </div>
                );
            })}

        {props.selectedSourceSpec?.params.map(param => {
            if (props.selectedSourceSpec?.assetSpecs.findIndex(asset => asset.sourceParamName.toUpperCase() === param.name.toUpperCase()) >= 0) {
                // replaced by asset
                return null;
            }
            switch (param.type) {
                case 'SELECT':
                    return (
                        <SelectWidget
                            selectedSourceSpec={props.selectedSourceSpec}
                            param={param}
                            input={props.paramInput}
                            onChange={props.handleParamChange}
                            customStyles={customStyles}
                            requiredErrors={props.requiredErrors}
                            key={`source-${props.selectedSourceSpec.header.name}-param-select-${param.name}`}
                        />
                    );
                case 'TEXT':
                case 'EMAIL':
                case 'URL':
                    return (
                        <TextWidget
                            selectedSourceSpec={props.selectedSourceSpec}
                            param={param}
                            input={props.paramInput}
                            onChange={props.handleParamChange}
                            requiredErrors={props.requiredErrors}
                            key={`source-${props.selectedSourceSpec.header.name}-param-text-${param.name}`}
                        />
                    );

                case 'CHECKBOX':
                    return (
                        <CheckboxWidget
                            selectedSourceSpec={props.selectedSourceSpec}
                            param={param}
                            input={props.paramInput}
                            onChange={props.handleParamChange}
                            key={`source-${props.selectedSourceSpec.header.name}-param-check-${param.name}`}
                        />
                    );

                case 'RADIO':
                    return (
                        <RadioWidget
                            selectedSourceSpec={props.selectedSourceSpec}
                            param={param}
                            paramOptions={param.options}
                            handleRadioChange={props.handleParamChange}
                            paramState={props.paramInput[param.name]}
                            key={`source-${props.selectedSourceSpec.header.name}-param-radio-${param.name}`}
                        />
                    );

                case 'PASSWORD':
                    return (
                        <PasswordWidget
                            selectedSourceSpec={props.selectedSourceSpec}
                            param={param}
                            input={props.paramInput}
                            onChange={props.handleParamChange}
                            requiredErrors={props.requiredErrors}
                            key={`source-${props.selectedSourceSpec.header.name}-param-password-${param.name}`}
                        />
                    );

                case 'TEXT_AREA':
                    return (
                        <TextAreaWidget
                            selectedSourceSpec={props.selectedSourceSpec}
                            param={param}
                            input={props.paramInput}
                            onChange={props.handleParamChange}
                            requiredErrors={props.requiredErrors}
                            key={`source-${props.selectedSourceSpec.header.name}-param-text-area-${param.name}`}
                        />
                    );

                case 'NUMBER':
                    return (
                        <NumberWidget
                            selectedSourceSpec={props.selectedSourceSpec}
                            param={param}
                            input={props.paramInput}
                            onChange={props.handleParamChange}
                            numberErrors={props.numberErrors}
                            requiredErrors={props.requiredErrors}
                            key={`source-${props.selectedSourceSpec.header.name}-param-number-${param.name}`}
                        />
                    );

                case 'INSTRUCTION':
                    return (
                        <InstructionWidget
                            param={param}
                            key={`source-${props.selectedSourceSpec.header.name}-param-instruction-${param.name}`}
                        />
                    );


                case 'FILE_DIR_SELECT':
                    return (
                        <FileDirSelectWidget
                            selectedSourceSpec={props.selectedSourceSpec}
                            param={param}
                            input={props.paramInput}
                            onChange={props.handleParamChange}
                            requiredErrors={props.requiredErrors}
                            key={`source-${props.selectedSourceSpec.header.name}-param-file-dir-${param.name}`}
                        />
                    );
                default:
                // assume it's a text variante e.g. url

            }
            return null;
        })
        }
    </>);
}

