export const customStylesSelect = {
    option: (provided, state) => ({
        ...provided,
        color: '#1F2747',
        maxHeight: '176px',
        height: '32px',
        padding: '0px 16px',
        background: state.isSelected ? '#F3F4F5' : state.isFocused ? '#F3F4F5' : undefined,
        display: 'flex',
        alignItems: 'center',
        "&:active": {
            background: '#F3F4F5',
        }

    }),
    control: (base, state) => ({
        // none of react-select's styles are passed to <Control />
        ...base,
        width: "100%",
        // height: '36px',
        color: '#1F2747',
        background: ' #FFFFFF',
        boxShadow: 'inset 0px 2px 4px rgba(31, 39, 71, 0.1)',
        borderRadius: '8px',
        transition: 'none',
        display: 'flex',
        border: state.menuIsOpen ? '1px solid #6C89E8' : '1px solid #C6C8CE',
        '&:hover': {
            border: '1px solid #4B5168',
        }
    }),
    valueContainer: base => ({
        ...base,
        display: 'flex',
        padding: '0px 16px',
    }),
}