import { RequestMessageDefinition, RespondMessageDefinition } from "userful-chronos-app-common-js/dist/message/messageModel";

const CDM_CONFIG = 4500;
const VIDEO_WALL = 2101;
const CDM_VIDEOWALL_DEST = 4501;
const CDM_PHYSICAL_AUDIO_DEST = 4502;
const CDM_PHYSICAL_VIDEO_DEST = 4503;
const CDM_DEST_GROUPS = 4504;
const CDM_PHYSICAL_ENDPOINT = 4505;
const CDM_VIDEOWALL_ENDPOINT = 4506;
const CDM_DEST_SET = 4507;

// Video Wall Calibration
export const REQUEST_LIST_VIDEOWALLS = new RequestMessageDefinition(VIDEO_WALL, 3);
export const RESPONSE_LIST_VIDEOWALLS = new RespondMessageDefinition(VIDEO_WALL, 3);

export const NOTIFY_VIDEO_WALL_CREATED = new RespondMessageDefinition(VIDEO_WALL, 0);
export const NOTIFY_VIDEO_WALL_REMOVED = new RespondMessageDefinition(VIDEO_WALL, 1);
export const NOTIFY_VIDEO_WALL_UPDATED = new RespondMessageDefinition(VIDEO_WALL, 2);

//phsycal endpoints
export const REQUEST_LIST_PHYSICAL_ENDPOINT = new RequestMessageDefinition(CDM_PHYSICAL_ENDPOINT, 0);
export const REQUEST_REMOVE_ENDPOINT_FROM_CDM = new RequestMessageDefinition(CDM_PHYSICAL_ENDPOINT, 1);

export const NOTIFY_PHYSICAL_ENDPOINT_ADDED = new RespondMessageDefinition(CDM_PHYSICAL_ENDPOINT, 0);
export const NOTIFY_PHYSICAL_ENDPOINT_UPDATED = new RespondMessageDefinition(CDM_PHYSICAL_ENDPOINT, 1);
export const NOTIFY_PHYSICAL_ENDPOINT_REMOVED = new RespondMessageDefinition(CDM_PHYSICAL_ENDPOINT, 2);
export const RESPONSE_LIST_PHYSICAL_ENDPOINTS = new RespondMessageDefinition(CDM_PHYSICAL_ENDPOINT, 3);

// videowall endpoints
export const REQUEST_LIST_VIDEOWALL_ENDPOINT = new RequestMessageDefinition(CDM_VIDEOWALL_ENDPOINT, 0);
export const REQUEST_REMOVE_VIDEOWALL_ENDPOINT_FROM_CDM = new RequestMessageDefinition(CDM_VIDEOWALL_ENDPOINT, 1);

export const NOTIFY_VIDEOWALL_ENDPOINT_ADDED = new RespondMessageDefinition(CDM_VIDEOWALL_ENDPOINT, 0);
export const NOTIFY_VIDEOWALL_ENDPOINT_UPDATED = new RespondMessageDefinition(CDM_VIDEOWALL_ENDPOINT, 1);
export const NOTIFY_VIDEOWALL_ENDPOINT_REMOVED = new RespondMessageDefinition(CDM_VIDEOWALL_ENDPOINT, 2);
export const RESPONSE_LIST_VIDEOWALL_ENDPOINTS = new RespondMessageDefinition(CDM_VIDEOWALL_ENDPOINT, 3);

//dest groups
export const REQUEST_LIST_DESTINATION_GROUPS = new RequestMessageDefinition(CDM_DEST_GROUPS, 0);
export const REQUEST_QUERY_DESTINATION_GROUPS = new RequestMessageDefinition(CDM_DEST_GROUPS, 1);
export const REQUEST_CREATE_DESTINATION_GROUP = new RequestMessageDefinition(CDM_DEST_GROUPS, 2);
export const REQUEST_REMOVE_DESTINATION_GROUP = new RequestMessageDefinition(CDM_DEST_GROUPS, 3);
export const REQUEST_UPDATE_DESTINATION_GROUP_HEADER = new RequestMessageDefinition(CDM_DEST_GROUPS, 4);
export const REQUEST_UPDATE_DESTINATION_GROUP_ORG_DATA = new RequestMessageDefinition(CDM_DEST_GROUPS, 5);
export const REQUEST_UPDATE_DESTINATION_GROUP_CONTENT = new RequestMessageDefinition(CDM_DEST_GROUPS, 6);

export const NOTIFY_DESTINATION_GROUP_ADDED = new RespondMessageDefinition(CDM_DEST_GROUPS, 0);
export const NOTIFY_DESTINATION_GROUP_UPDATED = new RespondMessageDefinition(CDM_DEST_GROUPS, 1);
export const NOTIFY_DESTINATION_GROUP_REMOVED = new RespondMessageDefinition(CDM_DEST_GROUPS, 2);
export const RESPONSE_LIST_DESTINATION_GROUPS = new RespondMessageDefinition(CDM_DEST_GROUPS, 3);
export const RESPONSE_QUERY_DESTINATION_GROUPS = new RespondMessageDefinition(CDM_DEST_GROUPS, 4);

//audio dest
export const REQUEST_LIST_PHYSICAL_AUDIO_DEST = new RequestMessageDefinition(CDM_PHYSICAL_AUDIO_DEST, 0);
export const REQUEST_QUERY_PHYSICAL_AUDIO_DEST = new RequestMessageDefinition(CDM_PHYSICAL_AUDIO_DEST, 1);
export const REQUEST_CREATE_PHYSICAL_AUDIO_DEST = new RequestMessageDefinition(CDM_PHYSICAL_AUDIO_DEST, 2);
export const REQUEST_REMOVE_PHYSICAL_AUDIO_DEST = new RequestMessageDefinition(CDM_PHYSICAL_AUDIO_DEST, 3);
export const REQUEST_UPDATE_PHYSICAL_AUDIO_DEST_HEADER = new RequestMessageDefinition(CDM_PHYSICAL_AUDIO_DEST, 4);
export const REQUEST_UPDATE_PHYSICAL_AUDIO_DEST_ORG_DATA = new RequestMessageDefinition(CDM_PHYSICAL_AUDIO_DEST, 5);
export const REQUEST_UPDATE_PHYSICAL_AUDIO_DEST_USERFUL_SECUIRTY_DATA = new RequestMessageDefinition(CDM_PHYSICAL_AUDIO_DEST, 5);

export const NOTIFY_PHYSICAL_AUDIO_DEST_ADDED = new RespondMessageDefinition(CDM_PHYSICAL_AUDIO_DEST, 0);
export const NOTIFY_PHYSICAL_AUDIO_DEST_UPDATED = new RespondMessageDefinition(CDM_PHYSICAL_AUDIO_DEST, 1);
export const NOTIFY_PHYSICAL_AUDIO_DEST_REMOVED = new RespondMessageDefinition(CDM_PHYSICAL_AUDIO_DEST, 2);
export const RESPONSE_LIST_PHYSICAL_AUDIO_DEST = new RespondMessageDefinition(CDM_PHYSICAL_AUDIO_DEST, 3);

//video dest
export const REQUEST_LIST_PHYSICAL_VIDEO_DEST = new RequestMessageDefinition(CDM_PHYSICAL_VIDEO_DEST, 0);
export const REQUEST_QUERY_PHYSICAL_VIDEO_DEST = new RequestMessageDefinition(CDM_PHYSICAL_VIDEO_DEST, 1);
export const REQUEST_CREATE_PHYSICAL_VIDEO_DEST = new RequestMessageDefinition(CDM_PHYSICAL_VIDEO_DEST, 2);
export const REQUEST_REMOVE_PHYSICAL_VIDEO_DEST = new RequestMessageDefinition(CDM_PHYSICAL_VIDEO_DEST, 3);
export const REQUEST_UPDATE_PHYSICAL_VIDEO_DEST_HEADER = new RequestMessageDefinition(CDM_PHYSICAL_VIDEO_DEST, 4);
export const REQUEST_UPDATE_PHYSICAL_VIDEO_DEST_ORG_DATA = new RequestMessageDefinition(CDM_PHYSICAL_VIDEO_DEST, 5);
export const REQUEST_UPDATE_PHYSICAL_VIDEO_DEST_USERFUL_SECURITY_DATA = new RequestMessageDefinition(CDM_PHYSICAL_VIDEO_DEST, 6);
export const REQUEST_UPDATE_PHYSICAL_VIDEO_DEST_STREAM_TYPE = new RequestMessageDefinition(CDM_PHYSICAL_VIDEO_DEST, 7);


export const NOTIFY_PHYSICAL_VIDEO_DEST_ADDED = new RespondMessageDefinition(CDM_PHYSICAL_VIDEO_DEST, 0);
export const NOTIFY_PHYSICAL_VIDEO_DEST_UPDATED = new RespondMessageDefinition(CDM_PHYSICAL_VIDEO_DEST, 1);
export const NOTIFY_PHYSICAL_VIDEO_DEST_REMOVED = new RespondMessageDefinition(CDM_PHYSICAL_VIDEO_DEST, 2);
export const RESPONSE_LIST_PHYSICAL_VIDEO_DEST = new RespondMessageDefinition(CDM_PHYSICAL_VIDEO_DEST, 3);
export const RESPONSE_QUERY_PHYSICAL_VIDEO_DEST = new RespondMessageDefinition(CDM_PHYSICAL_VIDEO_DEST, 4);

//videowall dest
export const REQUEST_LIST_VIDEOWALL_DEST = new RequestMessageDefinition(CDM_VIDEOWALL_DEST, 0);
export const REQUEST_QUERY_VIDEOWALL_DEST = new RequestMessageDefinition(CDM_VIDEOWALL_DEST, 1);
export const REQUEST_CREATE_VIDEOWALL_DEST = new RequestMessageDefinition(CDM_VIDEOWALL_DEST, 2);
export const REQUEST_REMOVE_VIDEOWALL_DEST = new RequestMessageDefinition(CDM_VIDEOWALL_DEST, 3);
export const REQUEST_UPDATE_VIDEOWALL_DEST_HEADER = new RequestMessageDefinition(CDM_VIDEOWALL_DEST, 4);
export const REQUEST_UPDATE_VIDEOWALL_DEST_ORG_DATA = new RequestMessageDefinition(CDM_VIDEOWALL_DEST, 5);
export const REQUEST_UPDATE_VIDEOWALL_DEST_USERFUL_SECURITY_DATA = new RequestMessageDefinition(CDM_VIDEOWALL_DEST, 6);
export const REQUEST_UPDATE_VIDEOWALL_DEST_STREAM_TYPE = new RequestMessageDefinition(CDM_VIDEOWALL_DEST, 7);


export const NOTIFY_VIDEOWALL_DEST_ADDED = new RespondMessageDefinition(CDM_VIDEOWALL_DEST, 0);
export const NOTIFY_VIDEOWALL_DEST_UPDATED = new RespondMessageDefinition(CDM_VIDEOWALL_DEST, 1);
export const NOTIFY_VIDEOWALL_DEST_REMOVED = new RespondMessageDefinition(CDM_VIDEOWALL_DEST, 2);
export const RESPONSE_LIST_VIDEOWALL_DEST = new RespondMessageDefinition(CDM_VIDEOWALL_DEST, 3);
export const RESPONSE_QUERY_VIDEOWALL_DEST = new RespondMessageDefinition(CDM_VIDEOWALL_DEST, 4);

// dest set
export const REQUEST_LIST_DESTINATION_SET = new RequestMessageDefinition(CDM_DEST_SET, 0);
export const REQUEST_QUERY_DESTINATION_SET = new RequestMessageDefinition(CDM_DEST_SET, 1);
export const REQUEST_FIND_DESTINATION_SET = new RequestMessageDefinition(CDM_DEST_SET, 2);
export const REQUEST_FLATTENED_DEST_SET = new RequestMessageDefinition(CDM_DEST_SET, 3);

export const RESPONSE_LIST_DESTINATION_SET = new RespondMessageDefinition(CDM_DEST_SET, 0);
export const RESPONSE_QUERY_DESTINATION_SET = new RespondMessageDefinition(CDM_DEST_SET, 1);
export const RESPONSE_FIND_DESTINATION_SET = new RespondMessageDefinition(CDM_DEST_SET, 2);
export const RESPONSE_FLATTENED_DEST_SET = new RespondMessageDefinition(CDM_DEST_SET, 3);
