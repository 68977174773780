import React, { ReactNode, useEffect } from 'react';
import { Provider } from 'react-redux';
import './msgs/ClientMsgReceiver';
import { requestListVideoEngines } from './msgs/MsgSender';
import videoEngineStore, { VideoEngineContext } from './videoEngineStore';

interface IProps {
  appID?: string;
  children: ReactNode,
}

function VideoEnginesProvider(props: IProps) {
  useEffect(() => {
    requestListVideoEngines();
  }, []);

  return (
    <Provider store={videoEngineStore} context={VideoEngineContext}>
      {props.children}
    </Provider>
  );
}

export default VideoEnginesProvider;