"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPONSE_LIST_GROUPS = exports.REQUEST_UPDATE_GROUP = exports.REQUEST_REMOVE_GROUP = exports.REQUEST_LIST_GROUPS = exports.REQUEST_CREATE_GROUP = exports.NOTIFY_UPDATE_GROUP = exports.NOTIFY_REMOVE_GROUP = exports.NOTIFY_CREATE_GROUP = void 0;
var _messageModel = require("../../../message/messageModel");
// CATEGORIES:
var GROUP = 3033;

// REQUESTS:
var REQUEST_LIST_GROUPS = exports.REQUEST_LIST_GROUPS = new _messageModel.RequestMessageDefinition(GROUP, 0);
var REQUEST_CREATE_GROUP = exports.REQUEST_CREATE_GROUP = new _messageModel.RequestMessageDefinition(GROUP, 1);
var REQUEST_UPDATE_GROUP = exports.REQUEST_UPDATE_GROUP = new _messageModel.RequestMessageDefinition(GROUP, 2);
var REQUEST_REMOVE_GROUP = exports.REQUEST_REMOVE_GROUP = new _messageModel.RequestMessageDefinition(GROUP, 3);

// RESPONSE:
var RESPONSE_LIST_GROUPS = exports.RESPONSE_LIST_GROUPS = new _messageModel.RespondMessageDefinition(GROUP, 0);
var NOTIFY_CREATE_GROUP = exports.NOTIFY_CREATE_GROUP = new _messageModel.RespondMessageDefinition(GROUP, 1);
var NOTIFY_UPDATE_GROUP = exports.NOTIFY_UPDATE_GROUP = new _messageModel.RespondMessageDefinition(GROUP, 2);
var NOTIFY_REMOVE_GROUP = exports.NOTIFY_REMOVE_GROUP = new _messageModel.RespondMessageDefinition(GROUP, 3);