import React from 'react';
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SourceSpecParam } from 'userful-chronos-app-common-js/dist/models/source';
import { useTranslation } from 'react-i18next';
import UserfulTooltip from '../../../Widgets/UserfulTooltip';
import { getRootPath } from 'userful-chronos-app-common-js/dist/routing';
import { BetaTag } from '../../../widgets';

interface IProps {
    param: SourceSpecParam,
    input: any;
    onChange: any;
    beta:boolean;
}

export default function ImageSelectWidget(props: IProps) {
    const { t } = useTranslation();


    const handleIconChange = (name, value) => {
        props.onChange({
            name,
            value,
        });
    }

    return (
        <div className="sourceIconsContainer">
            <span className='heading1 form-label'>{t('CommonUI.Sources.selectIcon')}</span>
            <div className='content'>
                {props.param.options.map((paramOption, index) => {
                    const filePath = paramOption.split("/");
                    const fileName = filePath[filePath.length - 1];
                    const pluginDir = filePath[filePath.length - 3];
                    const newFormatPath = `${getRootPath()}html/icons/${pluginDir}/${fileName}`;

                    return (
                        <UserfulTooltip title={fileName} key={index}>
                            <div
                                onClick={() => handleIconChange(props.param.name, paramOption)}
                                className={props.input[props.param.name] === paramOption ? "icon-select-class" : 'betaClass'}
                                key={`source-icon-select-${fileName}`}
                            >
                                <Image src={newFormatPath} alt={fileName} />
                               {props.beta && <div className='absoluteBeta'>
                                    <BetaTag />
                                </div>}
                            </div>
                        </UserfulTooltip>
                    );
                })}
            </div>
        </div>)
}