import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { SamSourceSpec } from 'userful-chronos-app-common-js/dist/models/sam/SAMSourceSpec';
import { SourceSpecParam } from 'userful-chronos-app-common-js/dist/models/source';
import { getTranslatedParamName } from '../SourceFormUtil';
import ParamHelpInfo from './ParamHelpInfo';

interface IProps {
    param: SourceSpecParam,
    selectedSourceSpec?: SamSourceSpec,
    input: any;
    customStyles: any;
    onChange: any;
    requiredErrors: object;
}

export default function SelectWidget(props: IProps) {
    const { t } = useTranslation();

    const getDefaultValue = () =>
        (props.input[props.param.name] && { label: props.input[props.param.name], value: props.input[props.param.name] }) ||
        (props.param.default && { label: props.param.default, value: props.param.default });

    const isValid = () => {
        return !props.requiredErrors[props.param.name];
    }

    const getValidationStyle = () => {
        if (isValid()) {
            return props.customStyles;
        }
        const customStyles = { ...props.customStyles };
        customStyles.control = (base, state) => ({
            // none of react-select's styles are passed to <Control />
            ...base,
            width: "100%",
            height: '36px',
            color: '#1F2747',
            background: ' #FFFFFF',
            boxShadow: 'inset 0px 2px 4px rgba(31, 39, 71, 0.1)',
            borderRadius: '8px',
            transition: 'none',
            display: 'flex',
            border: '1px solid #b94a48',
            '&:hover': {
                border: '1px solid #b94a48',
            }
        });

        customStyles.valueContainer = base => ({
            ...base,
            display: 'flex',
            padding: '0px 16px',
        });

        return customStyles;
    }

    return (
        <>
            <Form.Group
                className="input-wrapper"
                controlId="formSourceType"
                key={`source-select-options-${props.param.name}`}
            >
                <Form.Label className="heading1">
                    {getTranslatedParamName(props.param, props.selectedSourceSpec, t)}
                    <ParamHelpInfo param={props.param} selectedSourceSpec={props.selectedSourceSpec} className="selectWidgest" />
                </Form.Label>
                <Select
                    styles={getValidationStyle()}
                    name={props.param.name}
                    options={props.param.optionValues.map(
                        (value, index) => ({ name: props.param.name, label: value, value })
                    )}
                    placeholder={`${t('CommonUI.Sources.selectPlaceholder')} ${getTranslatedParamName(props.param, props.selectedSourceSpec, t)}...`}
                    defaultValue={getDefaultValue()}
                    onChange={props.onChange}
                    components={{ IndicatorSeparator: () => null }}
                    instanceId={`source-param-${props.param.name}-select`}
                    inputId={`source-param-${props.param.name}-select`}
                    menuPosition='fixed'
                />
                {!isValid() && <span style={{ 'fontSize': '12px', 'color': 'rgb(244, 67, 54)' }}>{`${t('CommonUI.Sources.selectError')} ${getTranslatedParamName(props.param, props.selectedSourceSpec, t)}`}</span>}
            </Form.Group>
        </>
    );
}