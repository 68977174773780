import { faBars, faBarsFilter, faBorderAll, faChevronRight, faRefresh } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserfulSearchBar, UserfulTooltip } from "userful-chronos-common-ui/dist";
import { IClusterInfo, IConstellationInfo, ClusterRemoteManagement } from "../../../model/CarbonExternalModels";
import { useCarbonDispatch, useCarbonSelector, selectFiltersVisibility, selectSearchInput, selectClusterFilters, selectClusterView } from "../../../store/carbonStore";
import { FiltersActions } from "../../../store/filters/filtersSlice";
import PairingCode from "../../pairingCode/PairingCode";
import { filterClusters } from "../../../store/filters/filtersManager";
import EmptyPlaceholder from "../../../common/components/placeholders/EmptyPlaceholder";
import { AppSliceActions } from "../../../store/app/appSlice";
import { convertIClusterInfoToTable } from "../../../common/components/UserfulTable/UserfulTableConverter";
import { ITable } from "../../../common/components/UserfulTable/userfulTableInterface";
import UserfulTable from "../../../common/components/UserfulTable/UserfulTable";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { apiGetConstellations } from "../../../messages/api/gcmAPICallers";
import ClustersCard from "../cards/ClustersCard";
import { GcmUserPermission } from "userful-chronos-app-common-js/dist/models/gcm-user-permission";

interface IProps {
    constellation: IConstellationInfo;
    permissions: GcmUserPermission;
    clusterRemoteManagements:  { [id: string]: ClusterRemoteManagement }
}

export default function ClustersTable(props: IProps) {
    const dispatch = useCarbonDispatch();

    const allClusterData: IClusterInfo[] = props.constellation.cluster;
    const crms = props.clusterRemoteManagements;

    // Visibility
    const filtersVisibility = useCarbonSelector(selectFiltersVisibility);
    const clusterView = useCarbonSelector(selectClusterView);

    // Filtering
    const clusterFilters = useCarbonSelector(selectClusterFilters);
    const searchInput = useCarbonSelector(selectSearchInput);

    const filteredClusters = filterClusters(clusterFilters, searchInput.clusters, allClusterData);

    const table: ITable = convertIClusterInfoToTable(filteredClusters);

    // Functions
    const onSearchBarChange = function (input: string) {
        dispatch(FiltersActions.setSearchInput({ ...searchInput, clusters: input }));
    };

    // Renders
    const insertBody = () => {
        if (allClusterData.length > 0) {
            if (filteredClusters.length > 0) {
                return (
                    <div className="constellationCardsWrapper">
                        {filteredClusters.map((c) => {
                            return (
                                <ClustersCard
                                    key={c.id.value}
                                    foundObject={{ cluster: c, constellation: props.constellation }}
                                    crm={{ value: crms[c.id.value] }}
                                    onRowClick={(id: StringID) => {
                                        dispatch(AppSliceActions.setAppView({ type: "CLUSTER", id: id }));
                                    }}
                                />
                            );
                        })}
                    </div>
                );
            } else {
                return <EmptyPlaceholder img="./images/notFound.png" title={"No Clusters Found"} description={"Please refine your search/filter criteria and try again."} />;
            }
        } else {
            return (
                <EmptyPlaceholder
                    img="./images/notFound.png"
                    title={"Add a Cluster"}
                    description={"Pair a Userful Cluster to this Constellation. A Cluster is a group of connected servers running on the same network."}
                    additionalComponents={props.permissions.isClusterSetup ? <PairingCode type={"SECONDARY"} constellation={props.constellation} /> : null}
                />
            );
        }
    };

    return (
        <div className="tableWrapper">
            <div className="tableHeader">
                <div className="horizontalWrapper">
                    <div className="cluster normalGap">
                        {/* <button className="iconButton">
                            <FontAwesomeIcon icon={faBarsFilter} />
                        </button> */}
                        <UserfulSearchBar forceValue={searchInput.clusters} onChange={onSearchBarChange} />
                        <UserfulTooltip placement="bottom" title="Refresh Data">
                            <button
                                className="iconButton"
                                onClick={(e) => {
                                    apiGetConstellations(getGlobalStates().keycloak.token);
                                }}
                            >
                                <FontAwesomeIcon icon={faRefresh} />
                            </button>
                        </UserfulTooltip>
                    </div>
                    <div className="cluster normalGap">
                        <div className="multiStateWrapper">
                            <UserfulTooltip title={"Table View"}>
                                <div
                                    className={`mSbutton ${clusterView === "TABLE" ? "active" : ""}`}
                                    onClick={(e) => {
                                        dispatch(AppSliceActions.setClusterView("TABLE"));
                                    }}
                                >
                                    <FontAwesomeIcon icon={faBars} className="mSicon" />
                                </div>
                            </UserfulTooltip>

                            <UserfulTooltip title={"Card View"}>
                                <div
                                    className={`mSbutton ${clusterView === "CARDS" ? "active" : ""}`}
                                    onClick={(e) => {
                                        dispatch(AppSliceActions.setClusterView("CARDS"));
                                    }}
                                >
                                    <FontAwesomeIcon icon={faBorderAll} className="mSicon" />
                                </div>
                            </UserfulTooltip>
                        </div>
                        {props.permissions.isClusterSetup && <PairingCode type="PRIMARY" constellation={props.constellation} />}
                    </div>
                </div>
                {filtersVisibility.constellations && <div className="horizontalWrapper"></div>}
            </div>

            <div className="tableBodyWrapper">
                <div className="tableSubHeader">
                    <div className="tableSubHeaderGroup">
                        <div
                            className="currentTextSelectable"
                            onClick={(e) => {
                                dispatch(
                                    AppSliceActions.setAppView({
                                        type: "CONSTELLATIONS",
                                        id: { value: "NOT_SET" },
                                    })
                                );
                            }}
                        >
                            Index
                        </div>
                        <FontAwesomeIcon icon={faChevronRight} />
                        <div className="currentTextSelected">{props.constellation.definition.name}</div>
                    </div>
                </div>

                <div className="constellationCardsWrapper">
                    {clusterView === "TABLE" && (
                        <UserfulTable
                            table={table}
                            replace={filteredClusters.length === 0 && insertBody()}
                            onRowClick={(id: StringID) => {
                                dispatch(AppSliceActions.setAppView({ type: "CLUSTER", id: id }));
                            }}
                            stretch={filteredClusters.length === 0}
                        />
                    )}
                    {clusterView === "CARDS" && insertBody()}
                </div>
            </div>
        </div>
    );
}
