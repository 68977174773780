import { registerErrorMsgHandlerByMsgDefinition, registerHideErrorPopup, registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { UclientData } from "userful-chronos-app-common-js/dist/models/uclient";
import displaysStore from "../displaysStore";
import { uclientsSliceActions } from "../uclientsSlice";
import {
    NOTIFY_UCLIENT_ADDED, NOTIFY_UCLIENT_REMOVED, NOTIFY_UCLIENT_UPDATED, RESPONSE_LIST_UCLIENTS,
    RESPONSE_LIST_UCLIENTS_FOR_VE, RESPONSE_FIRMWARE_CONFIG, RESPONSE_UCLIENT_APP_VERSION, RESPONSE_UCLIENT_PROTOCOL_DEFAULTS, RESPONSE_UCLIENT_STORAGE_DATA, RESPONSE_FIRMWARE_UPDATE, RESPONSE_UCLIENT_FILES_DATA
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/displays/UClientsMsgDefinations";
import { UClientFirmwareConfigData, UClientAppVersions } from 'userful-chronos-app-common-js/dist/models/uclient';
import { uClientFirmwareConfigAction } from "../UClientFirmwareConfigSlice";
import { uClientRequiredAPPVersionAction } from "../UClientRequiredAPPVersionSlice";
import { UClientStorageDataMsg } from "userful-chronos-app-common-js/dist/models/uclient/uclientStorage";

registerMsgHandler(RESPONSE_LIST_UCLIENTS, (payload) => {
    console.debug('Receive RESPONSE_LIST_UCLIENTS');
    const data = JSON.parse(payload) as Array<UclientData>;
    displaysStore.dispatch(uclientsSliceActions.setDisplays(data));
})

registerMsgHandler(RESPONSE_LIST_UCLIENTS_FOR_VE, (payload) => {
    const data = JSON.parse(payload) as Array<UclientData>;
    console.debug("Receive RESPONSE_LIST_UCLIENTS_FOR_VE");
    displaysStore.dispatch(uclientsSliceActions.setDisplaysForVE(data));
});

registerMsgHandler(NOTIFY_UCLIENT_ADDED, (payload) => {
    console.debug('Receive NOTIFY_UCLIENT_ADDED');
    const data = JSON.parse(payload) as UclientData;
    displaysStore.dispatch(uclientsSliceActions.addOrUpdateDisplays(data));
})

registerMsgHandler(NOTIFY_UCLIENT_UPDATED, (payload) => {
    console.debug('Receive NOTIFY_UCLIENT_UPDATED');
    const data = JSON.parse(payload) as UclientData;
    displaysStore.dispatch(uclientsSliceActions.addOrUpdateDisplays(data));
})

registerMsgHandler(NOTIFY_UCLIENT_REMOVED, (payload) => {
    console.debug('Receive NOTIFY_UCLIENT_REMOVED');
    const data = JSON.parse(payload) as StringID;
    displaysStore.dispatch(uclientsSliceActions.deleteDisplay(data));
})

registerMsgHandler(RESPONSE_FIRMWARE_CONFIG, (payload) => {
    console.debug('Receive RESPONSE_FIRMWARE_CONFIG');
    const data = JSON.parse(payload) as UClientFirmwareConfigData;
    displaysStore.dispatch(uClientFirmwareConfigAction.setFirmwareConfig(data));
})

registerMsgHandler(RESPONSE_UCLIENT_APP_VERSION, (payload) => {
    console.debug('Receive RESPONSE_UCLIENT_APP_VERSION');
    const data = JSON.parse(payload) as UClientAppVersions;
    displaysStore.dispatch(uClientRequiredAPPVersionAction.setRequiredAPPVersion(data));
})

registerMsgHandler(RESPONSE_UCLIENT_PROTOCOL_DEFAULTS, (payload) => {
    const data = JSON.parse(payload);
    console.debug("Receive RESPONSE_UCLIENT_PROTOCOL_DEFAULTS");
    displaysStore.dispatch(uclientsSliceActions.setVideoEncodingCodec(data));
    displaysStore.dispatch(uclientsSliceActions.setCopyVideoEncodingCodec(data));

});

registerMsgHandler(RESPONSE_UCLIENT_STORAGE_DATA, (payload) => {
    const data = JSON.parse(payload) as UClientStorageDataMsg;
    console.debug("Receive RESPONSE_UCLIENT_STORAGE_DATA");
    displaysStore.dispatch(uclientsSliceActions.receiveUClientStorageData(data));
});

// don't show error popup
registerHideErrorPopup(RESPONSE_UCLIENT_STORAGE_DATA);
registerHideErrorPopup(RESPONSE_UCLIENT_FILES_DATA);

registerErrorMsgHandlerByMsgDefinition(RESPONSE_FIRMWARE_UPDATE, (err) => {
    console.debug("Receive error for RESPONSE_FIRMWARE_UPDATE");
    const seatIDs = err.params.filter(p => p.name === 'seat-id').map(p => p.value);
    displaysStore.dispatch(uclientsSliceActions.clearUpdatingStatus(seatIDs));
});