import { configureStore } from '@reduxjs/toolkit'
import React from 'react';
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook } from 'react-redux'
import UClientFirmwareConfigSlice from './UClientFirmwareConfigSlice';
import UClientRequiredAPPVersionSlice from './UClientRequiredAPPVersionSlice';
import uclientsSlice from './uclientsSlice';
import zeroclientsSlice from './zeroclientsSlice';
import onboardSlice from './onboardSlice';
import uclientActionsSlice from './uclientActionsSlice';

export const DisplaysContext = React.createContext(null);


const displaysStore = configureStore({
  reducer: {
      uclientsStore: uclientsSlice,
      uclientActionsStore: uclientActionsSlice,
      zeroclientsStore: zeroclientsSlice,
      onboardStore: onboardSlice,
      uClientFirmwareConfigStore: UClientFirmwareConfigSlice,
      uClientRequiredAPPVersionStore: UClientRequiredAPPVersionSlice,
  },
})
export default displaysStore;

export type DisplaysState = ReturnType<typeof displaysStore.getState>;
export type DisplaysDispatch = typeof displaysStore.dispatch;
export const useDisplaysSelector: TypedUseSelectorHook<DisplaysState> = createSelectorHook(DisplaysContext);
export const useDisplaysDispatch = createDispatchHook<DisplaysDispatch>(DisplaysContext);
