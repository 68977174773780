import { faTrashAlt, faCircleExclamation, faMultiply, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { CompGroup } from "userful-chronos-app-common-js/dist/models/group/Groups";
import "./ConfirmationDialog.scss"
import { requestRemoveGroup } from "userful-chronos-app-common-js/dist/models/group/messages/GroupMsgSender";
import { useTranslation } from "react-i18next";

interface IProps {
    folder: CompGroup;
}

export default function DeleteFolder(props: IProps) {

    const { t } = useTranslation();

    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    return (
        <>
            <Dropdown.Item as="li" eventKey="2" id={`${props.folder.id.value}-source-delete`} onClick={(e) => setShowConfirmation(true)}>
                <div >{t("Shared.delete")}</div>
            </Dropdown.Item>

            <Modal
                centered
                show={showConfirmation}
                onHide={() => {
                    setShowConfirmation(false);
                }}
            >
                <div className="confirmationDialogVerticalWrapper" style={{ gap: 24 }}>
                    <div className="confirmationDialogVerticalWrapper">
                        <div className="confirmationDialogHorizontalWrapper edged">
                            <div className="confirmationDialogHorizontalWrapper">
                                <FontAwesomeIcon icon={faCircleExclamation} />
                                <div className="title">Delete Folder</div>
                            </div>
                            <button
                                className="userfulIconButton"
                                onClick={() => {
                                    setShowConfirmation(false);
                                }}
                            >
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="description">
                            {`Are you sure you want to delete ${props.folder.name} ?`}
                        </div>
                    </div>
                    <div className="confirmationDialogHorizontalWrapper reversed">
                        <button
                            className="warningUserfulButton"
                            onClick={(e) => {
                                setShowConfirmation(false);
                                requestRemoveGroup(props.folder.id)
                            }}
                        >
                            Delete
                        </button>

                        <button
                            className="secondaryUserfulButton"
                            onClick={(e) => {
                                setShowConfirmation(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
