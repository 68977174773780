"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initEpicServerFromHeader = exports.initEpicForm = exports.frameRateOptionsForEpicSourceForm = exports.epicThumbnailImages = exports.allIntegrationNames = exports.NOT_SET = exports.EpicServerHeaderDefault = exports.EpicPassportOptions = exports.DefaultBasicCredentials = void 0;
var _uuid = require("uuid");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var allIntegrationNames = exports.allIntegrationNames = ["Power BI", "Tableau", "Epic Systems", "Servicenow", "RoomOS"];
var NOT_SET = exports.NOT_SET = "NOT_SET";
var initEpicServerFromHeader = exports.initEpicServerFromHeader = function initEpicServerFromHeader(data) {
  return _objectSpread(_objectSpread({}, data), {}, {
    isConnected: true,
    wellKnown: {
      authEndpoint: {
        value: "https://vendorservices.epic.com/interconnect-xxxxxxxxx-oauth/oauth2/authorize"
      },
      tokenEndpoint: {
        value: "https://vendorservices.epic.com/interconnect-xxxxxxxxx-oauth/oauth2/token"
      },
      supportedAuthMethods: ["client_secret_post", "client_secret_basic", "private_key_jwt"],
      supportedScopes: ["epic.scanning.dmsusername", "fhirUser", "launch", "openid", "profile"],
      supportedResponse: ["code"],
      capabilities: ["launch-ehr", "launch-standalone", "client-public", "client-confidential-symmetric", "client-confidential-asymmetric", "context-banner", "context-style", "context-ehr-patient", "context-ehr-encounter", "context-standalone-patient", "permission-offline", "permission-patient", "permission-user", "permission-v1", "permission-v2", "sso-openid-connect", "authorize-post"]
    },
    metadata: {
      resourceType: "CapabilityStatement",
      status: "active",
      experimental: true,
      copyright: "Copyright Epic 1979-2024",
      kind: "instance",
      softwareName: "Epic",
      softwareVersion: "August 2024",
      softwareReleaseDate: "2024-08-06",
      fhirVersion: "4.0.1"
    }
  });
};
// default functions

var DefaultBasicCredentials = exports.DefaultBasicCredentials = {
  username: NOT_SET,
  password: NOT_SET
};
var EpicServerHeaderDefault = exports.EpicServerHeaderDefault = function EpicServerHeaderDefault() {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "",
    description: "",
    isSandbox: false,
    usePasswordAuthentication: false,
    credentials: DefaultBasicCredentials,
    address: {
      value: ""
    }
  };
};
var frameRateOptionsForEpicSourceForm = exports.frameRateOptionsForEpicSourceForm = [{
  value: "0.1",
  label: "0.1",
  id: "0.1"
}, {
  value: "0.5",
  label: "0.5",
  id: "0.5"
}, {
  value: "1",
  label: "1",
  id: "1"
}, {
  value: "5",
  label: "5",
  id: "5"
}, {
  value: "10",
  label: "10",
  id: "10"
}, {
  value: "25",
  label: "25",
  id: "25"
}, {
  value: "30",
  label: "30",
  id: "30"
}, {
  value: "45",
  label: "45",
  id: "45"
}, {
  value: "60",
  label: "60",
  id: "60"
}];
var epicThumbnailImages = exports.epicThumbnailImages = [{
  value: "/usr/share/userful-plugin-epic/icons/epic-thumbnail.png",
  fileName: "epic-thumbnail.png"
}, {
  value: "/usr/share/userful-plugin-epic/icons/system-logo.png",
  fileName: "system-logo.png"
}];
var initEpicForm = exports.initEpicForm = function initEpicForm() {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "",
    description: "",
    isSandbox: false,
    usePasswordAuthentication: false,
    credentials: DefaultBasicCredentials,
    address: {
      value: ""
    },
    type: "Department Wait Time",
    instanceID: "",
    totalBeds: 0,
    occupiedBeds: 0
  };
};
var EpicPassportOptions = exports.EpicPassportOptions = [{
  value: 'Department Wait Time',
  label: 'Department Wait Time',
  id: 'Department Wait Time'
}, {
  value: 'Bed Occupancy Rate',
  label: 'Bed Occupancy Rate',
  id: 'Bed Occupancy Rate'
}];