import React, { ReactNode, useEffect } from 'react';
import { Provider } from 'react-redux';
import rubyStore, { RubyContext } from './rubyStore';
import './msgs/ClientMsgReceiver';
import { requestListChannels } from './msgs/MsgSender';

interface IProps {
  children: ReactNode,
}

function RubyProvider(props: IProps) {

  useEffect(() => {
    requestListChannels();
  }, [])

  return (
    <Provider store={rubyStore} context={RubyContext}>
      {props.children}
    </Provider>
  );
}

export default RubyProvider;