"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDestinationTypeKey = exports.DESTINATION_TYPES = exports.DESTINATION_FORM_TYPE = void 0;
var DESTINATION_TYPES = exports.DESTINATION_TYPES = {
  group: "groups",
  videowall: "videoWalls",
  display: "physicalVideo",
  audio: "physicalAudio"
};
var DESTINATION_FORM_TYPE = exports.DESTINATION_FORM_TYPE = {
  display: 'Display',
  audio: 'Audio',
  videowall: 'Videowall',
  group: "Group"
};
var getDestinationTypeKey = exports.getDestinationTypeKey = function getDestinationTypeKey(type, destination) {
  switch (type) {
    case DESTINATION_FORM_TYPE.group:
      return "Sources&Destinations.Destinations.groupDestination";
    case DESTINATION_FORM_TYPE.videowall:
      return "Sources&Destinations.Destinations.videoWallDestination";
    case DESTINATION_FORM_TYPE.display:
      var display = destination;
      switch (display.endPoint.type) {
        case 'ONBOARD':
          return 'CommonUI.displayCommon.displayTypeOnboard';
        case 'UCLIENT':
          return 'CommonUI.displayCommon.displayTypeUclient';
        case 'ZERO_CLIENT':
          return 'CommonUI.displayCommon.displayTypeZeroClient';
      }
      break;
    case DESTINATION_FORM_TYPE.audio:
      return "Sources&Destinations.Destinations.audioDestination";
  }
  return "";
};