import { configureStore } from '@reduxjs/toolkit'
import React from 'react'
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook } from 'react-redux'
import videoEngineSlice from './videoEngineSlice'

export const VideoEngineContext = React.createContext(null);

const videoEngineStore = configureStore({
  reducer: {
    videoEngineStore: videoEngineSlice,
  },
})
export default videoEngineStore;

type State = ReturnType<typeof videoEngineStore.getState>;
type Dispatch = typeof videoEngineStore.dispatch;
export const useVideoEngineSelector: TypedUseSelectorHook<State> = createSelectorHook(VideoEngineContext);
export const useVideoEngineDispatch = createDispatchHook<Dispatch>(VideoEngineContext)
