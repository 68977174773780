import { TableauPassport } from "userful-chronos-app-common-js/dist/models/integration/tableau";
import { SamSourceDef } from "userful-chronos-app-common-js/dist/models/sam/SAMSource";
import { FetchDepartmentsFromString } from "../integrationSources/shared/Computation";
export const AddCustomCheckForTableau = (source: SamSourceDef, paramInput, tableauPassports: TableauPassport[], validateAll: boolean, checkField?: string) => {
    const tableauErrors = {};
    if (source.type.value !== "Tableau")
        return tableauErrors;
    if (source.type.value === "Tableau") {
        const passportIDValue = paramInput['Tableau passport'];
        const authType = passportIDValue === "" ? null : tableauPassports.find((passport) => passport.id.value === passportIDValue).authenticationType;
        if ((validateAll || checkField === "Public Visualization URL") && authType === "PUBLIC") {
            const stringValue = paramInput['Public Visualization URL'];
            try {
                const url = new URL(stringValue);
            } catch (_) {
                tableauErrors['Public Visualization URL'] = 'missing';

            }
        }
        if (authType && authType !== "PUBLIC") {
            if (paramInput['View ID'] === "" && (validateAll || checkField === "View ID"))
                tableauErrors['View ID'] = 'missing';
            if (paramInput['Workbook ID'] === "" && (validateAll || checkField === "Workbook ID"))
                tableauErrors['Workbook ID'] = 'missing';
        }
        if ((validateAll || checkField === "Refresh (mins)") &&
            (Number(paramInput['Refresh (mins)']) < 0 || Number(paramInput['Refresh (mins)']) % 1 !== 0))
            tableauErrors['Refresh (mins)'] = 'missing';
    }

    return tableauErrors;
}


export const AddCustomCheckForEpic = (source: SamSourceDef, paramInput, passportsEpic, adtPassportsEpic, validateAll: boolean, checkField?: string) => {
    const epicErrors = {};
    if (source.type.value !== "Epic")
        return epicErrors;
    if (source.type.value === "Epic") {
        const passportIDValue = paramInput['EPIC passport'];
        // finding if it wait time or adt or not selected yet
        const authType = passportIDValue === "" ? null : passportsEpic.findIndex((pass) => pass.id.value === passportIDValue) > -1 ? 'waitTime' : 'adt';

        // checks if the passport is selected or not
        if ((validateAll || checkField === "EPIC passport")) {
            if (paramInput['EPIC passport'] === "")
                epicErrors["EPIC passport"] = "missing";
        }
        // refresh rate 
        if (validateAll || checkField === "Data refresh rate") {
            const stringValue = paramInput['Data refresh rate'];
            if (Number(stringValue) < 10)
                epicErrors["Data refresh rate"] = "missing";
        }
        // check only if its wait time
        if ((validateAll || checkField === "Minutes to look back") && authType === "waitTime") {
            const stringValue = paramInput['Minutes to look back'];
            if (!(Number(stringValue) >= 1 && Number(stringValue) <= 1440))
                epicErrors["Minutes to look back"] = "missing";
        }
        // check for department id and type
        if ((validateAll || checkField === "Departments") && authType === "waitTime") {
            if (paramInput['Departments'] === "") {
                const departmentErrors = [];
                const obj = [true, true, 0];
                departmentErrors.push(obj);
                epicErrors['Departments'] = departmentErrors;
            }
            else {
                const stringValue = FetchDepartmentsFromString(paramInput['Departments']);
                const departmentErrors = [];
                stringValue.map((val, index) => {
                    const obj = [];
                    val.departmentID === "" ? obj.push(true) : obj.push(false);
                    val.departmentType === "" ? obj.push(true) : obj.push(false);
                    if (obj[0] || obj[1]) {
                        obj.push(index);
                        departmentErrors.push(obj);
                    }
                });
                if (departmentErrors.length > 0)
                    epicErrors['Departments'] = departmentErrors;
            }
        }
    }
    return epicErrors;
}


export const AddCustomCheckForServicenow = (source: SamSourceDef, paramInput, passportsServicenow, validateAll: boolean, checkField?: string) => {
    const servicenowErrors = {};
    if (source.type.value !== "Servicenow")
        return servicenowErrors;
    if (source.type.value === "Servicenow") {
        const passportIDValue = paramInput['Servicenow passport'];

        // checks if the passport is selected or not
        if ((validateAll || checkField === "Servicenow passport")) {
            if (paramInput['Servicenow passport'] === "")
                servicenowErrors["Servicenow passport"] = "missing";
        }
        // refresh rate 
        if (validateAll || checkField === "Data refresh rate") {
            const stringValue = paramInput['Data refresh rate'];
            if (Number(stringValue) < 10)
                servicenowErrors["Data refresh rate"] = "missing";
        }
        if (validateAll || checkField === "Servicenow date") {
            const stringValue = paramInput['Servicenow date'];
            // Split the date string by '*'
            const [startDateStr, endDateStr] = stringValue.split('*');

            // Convert the strings to Date objects
            const startDate = new Date(startDateStr);
            const endDate = new Date(endDateStr);

            // Check if startDate is later than endDate
            if (startDate > endDate) {
                servicenowErrors["Servicenow date"] = "missing";
            }
        }

    }
    return servicenowErrors;
}