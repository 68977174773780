import { SamSourceSpec } from "userful-chronos-app-common-js/dist/models/sam/SAMSourceSpec";
import { SourceSpecParam } from "userful-chronos-app-common-js/dist/models/source";



export const filterSpecParams = (paramInput: object, sourceSpec: SamSourceSpec): SamSourceSpec => {
    if (!sourceSpec) {
        return sourceSpec;
    }

    switch(sourceSpec.header.id.value) {
        case 'Tableau':
            var authType = paramInput['Authentication Type'];
            const optionalParams: SourceSpecParam[] = [];
            const coreParams = sourceSpec.params.filter(item =>
                item.name !== 'Account Email / Username'&&
                item.name !== 'Account Password' &&
                item.name !== 'PAT Token Name' &&
                item.name !== 'PAT Token Secret' &&
                item.name !== 'Connected App\'s Client ID' &&
                item.name !== 'Connected App\'s Secret ID' &&
                item.name !== 'Connected App\'s Secret Value')
            if (authType === 'Tableau Personal Access Token') {
                optionalParams.push(sourceSpec.params.find(item => item.name === 'Account Email / Username'));
                optionalParams.push(sourceSpec.params.find(item => item.name === 'PAT Token Name'));
                optionalParams.push(sourceSpec.params.find(item => item.name === 'PAT Token Secret'));
            } else if (authType === 'Tableau Password') {
                optionalParams.push(sourceSpec.params.find(item => item.name === 'Account Email / Username'));
                optionalParams.push(sourceSpec.params.find(item => item.name === 'Account Password'));
            } else if (authType === 'Connected App') {
                optionalParams.push(sourceSpec.params.find(item => item.name === 'Account Email / Username'));
                optionalParams.push(sourceSpec.params.find(item => item.name === 'Connected App\'s Client ID'));
                optionalParams.push(sourceSpec.params.find(item => item.name === 'Connected App\'s Secret ID'));
                optionalParams.push(sourceSpec.params.find(item => item.name === 'Connected App\'s Secret Value'));
            }
            const sanizedParams = [
                ...coreParams,
                ...optionalParams,
            ];
            const sortedParams = sourceSpec.params.filter(param =>
                sanizedParams.findIndex(item => item?.name === param.name) >= 0); // so params are in their original order
            return {
                ...sourceSpec,
                params: sortedParams,
            }
        case 'Sapphire_Spaces_-_Beta':
            const spacesParamsFiltered = sourceSpec.params.filter(item =>
                item.name !== 'Meeting Room Name'&&
                item.name !== 'Simplified'&&
                item.name !== 'Air Gapped'&&
                item.name !== 'Link' &&
                item.name !== 'Message' &&
                item.name !== 'Background' &&
                item.name !== 'RoomOS Device' &&
                item.name !== 'Webex Auth Token' &&
                item.name !== 'Webex Unique ID' &&
                item.name !== 'Global State Link' &&
                item.name !== 'Turn Server ID' &&
                item.name !== 'Turn Server Url' &&
                item.name !== 'Turn Server Username' &&
                item.name !== 'Turn Server Password' &&
                item.name !== 'IMAGE_SELECT' &&
                item.name !== 'Signalling Server');
            const sortedSpacesParams = sourceSpec.params.filter(param =>
                spacesParamsFiltered.findIndex(item => item?.name === param.name) >= 0); // so params are in their original order
            return {
                ...sourceSpec,
                params: sortedSpacesParams,
            }
            case 'Power_BI':
                var authType = paramInput['Authentication Type'];
                const pbOptionalParams: SourceSpecParam[] = [];
                const pbCoreParams = sourceSpec.params.filter(item =>
                    item.name !== 'Tenant ID' &&
                    item.name !== 'Client Secret' &&
                    item.name !== 'Account Email' &&
                    item.name !== 'Account Password')
                if (authType === 'Service Principal') {
                    pbOptionalParams.push(sourceSpec.params.find(item => item.name === 'Tenant ID'));
                    pbOptionalParams.push(sourceSpec.params.find(item => item.name === 'Client Secret'));
                } else if (authType === 'Master User') {
                    pbOptionalParams.push(sourceSpec.params.find(item => item.name === 'Account Email'));
                    pbOptionalParams.push(sourceSpec.params.find(item => item.name === 'Account Password'));
                }
                const pbSanizedParams = [
                    ...pbCoreParams,
                    ...pbOptionalParams,
                ];
                const pbSortedParams = sourceSpec.params.filter(param =>
                    pbSanizedParams.findIndex(item => item?.name === param.name) >= 0); // so params are in their original order
                return {
                    ...sourceSpec,
                    params: pbSortedParams,
                }
        default:
            return sourceSpec;
    }
}
