import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UClientAppVersions } from 'userful-chronos-app-common-js/dist/models/uclient';

let updating: { [key: string]: Array<Function>; } = {};

const initialState: {
    version: UClientAppVersions;
} = {
    version: {uClient1Version:"2.2.6", uClient2Version:"2.2.6"},
};


export const UClientRequiredAPPVersionSlice = createSlice({
    name: 'UClientRequiredAPPVersionSlice',
    initialState,
    reducers: {
        setRequiredAPPVersion: (state, action: PayloadAction<UClientAppVersions>) => {
            state.version = action.payload;
        },
    },
})

export const uClientRequiredAPPVersionAction = UClientRequiredAPPVersionSlice.actions;

export default UClientRequiredAPPVersionSlice.reducer