import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getGlobalStates } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';
import {
    getMicrosoftDataFromServer, addMicrosoftDataToServer, updateMicrosoftDataToServer, deleteMicrosoftDataToServer, 
    syncMicrosoftDataToServer
} from './msgs/identityProviderAPICallers';
import {
     UpdateMicrosoftProvider, DeleteMicrosoftProvider,
    AddMicrosoftProvider, SyncMicrosoft,
    MicrosoftData
} from 'userful-chronos-app-common-js/dist/models/usermgt/identityProviders';

export interface InitialStateMicrosoft {
    microsoftData: MicrosoftData;
    loadBool: boolean;
    saveBool: boolean;
    overlay: boolean;
}

const initialStateMicrosoft: InitialStateMicrosoft = {
    microsoftData: {microsoftProvider: []},
    loadBool: true,
    saveBool: false,
    overlay: false
};

export const identityProviderSlice = createSlice({
    name: 'identityProviderSlice',
    initialState: initialStateMicrosoft,
    reducers: {
        getMicrosoftDataFromServer: (state, action: PayloadAction<{}>) => {
            getMicrosoftDataFromServer(getGlobalStates().keycloak.token);
        },
        setMicrosoftData: (state, action: PayloadAction<MicrosoftData>) => {
            state.microsoftData = action.payload;
        },
        setLoadBool: (state, action: PayloadAction<boolean>) => {
            state.loadBool = action.payload;
        },
        addMicrosoftDataToServer: (state, action: PayloadAction<AddMicrosoftProvider>) => {
            state.saveBool = true;
            addMicrosoftDataToServer(getGlobalStates().keycloak.token, action.payload);
        },
        updateMicrosoftDataToServer: (state, action: PayloadAction<UpdateMicrosoftProvider>) => {
            state.saveBool = true;
            updateMicrosoftDataToServer(getGlobalStates().keycloak.token, action.payload);
        },
        deleteMicrosoftDataToServer: (state, action: PayloadAction<DeleteMicrosoftProvider>) => {
            state.saveBool = true;
            deleteMicrosoftDataToServer(getGlobalStates().keycloak.token, action.payload);
        },
        syncMicrosoftDataToServer: (state, action: PayloadAction<SyncMicrosoft>) => {
            state.saveBool = true;
            syncMicrosoftDataToServer(getGlobalStates().keycloak.token, action.payload);
        },
        setSaveBool: (state, action: PayloadAction<boolean>) => {
            state.saveBool = action.payload;
        },
        setOverlay: (state, action: PayloadAction<boolean>) => {
            state.overlay = action.payload;
        }

    }
})

export const identityProviderSliceActions = identityProviderSlice.actions;

export default identityProviderSlice.reducer;