import { SamSourceDef } from "userful-chronos-app-common-js/dist/models/sam/SAMSource";
import { useTranslation } from "react-i18next";
import DropdownWidget from "../shared/DropdownWidget";
import { GetListPassportForDropdown, GetSelectedPassport, GetListWorkbooksForDropdown, GetSelectedWorkbook, GetSelectedView, GetListViewsForDropdown } from "./ComputationTableau";
import EpicNumberWidget from "../shared/EpicNumberWidget";
import { tableauActions, useIntegrationDispatch, useIntegrationSelector } from "userful-chronos-common-store/dist/integration";
import { TableauViewListRequest } from "userful-chronos-app-common-js/dist/models/integration/tableau";
import { useEffect } from "react";
import { SourceDefParam } from "userful-chronos-app-common-js/dist/models/source";
import React from 'react';
import TextWidget from "../shared/TexWidget";
interface IProps {
    params: SourceDefParam[];
    handleParamChange: (name: string, value) => void,
    requiredErrors,
    show: boolean;
}

export default function TableauFormInner(props: IProps) {

    const dispatchTableau = useIntegrationDispatch();

    const passports = useIntegrationSelector((state) => state.tableauStore.tableauPassports);
    const tableauViews = useIntegrationSelector((state) => state.tableauStore.tableauViews);
    const tableauWorkbooks = useIntegrationSelector((state) => state.tableauStore.tableauWorkbooks);

    // to fetch the type of authentication
    const selectedPassportValue = props.params.filter((para) => para.name === "Tableau passport")[0].value;
    const selectedAuthType = selectedPassportValue === "" ? null : passports.find((passport) => passport.id.value === selectedPassportValue).authenticationType;

    const { t } = useTranslation();

    const handlePassportChange = (name, value) => {
        dispatchTableau(tableauActions.setWorkbooks([]));
        dispatchTableau(tableauActions.setViews([]));
        if (passports.find((passport) => passport.id.value === value).authenticationType !== "PUBLIC")
            dispatchTableau(tableauActions.requestWorkbooks({ value: value }));
        props.handleParamChange(name, value);
    }

    const handleWorkbookChange = (name, value) => {
        dispatchTableau(tableauActions.setViews([]));
        const payload: TableauViewListRequest = { passportID: { value: props.params.filter((para) => para.name === "Tableau passport")[0].value }, workbookID: value };
        dispatchTableau(tableauActions.requestViews(payload));
        props.handleParamChange(name, value);
    }

    useEffect(() => {
        if (props.show) {
            dispatchTableau(tableauActions.setWorkbooks([]));
            dispatchTableau(tableauActions.setViews([]));
            const passportID = props.params.filter((para) => para.name === "Tableau passport")[0].value;
            const workbookID = props.params.filter((para) => para.name === "Workbook ID")[0].value;
            if (passportID && workbookID) {
                const viewPayload: TableauViewListRequest = { passportID: { value: passportID }, workbookID: workbookID };
                dispatchTableau(tableauActions.requestWorkbooks({ value: passportID }));
                dispatchTableau(tableauActions.requestViews(viewPayload));
            }
        }
    }, [props.show])

    return (
        <>
            <DropdownWidget
                options={GetListPassportForDropdown(passports)}
                onChange={(value) => handlePassportChange("Tableau passport", value.id)}
                selectedValue={GetSelectedPassport(passports, props.params.filter((para) => para.name === "Tableau passport")[0].value)}
                width={528}
                title={t(`Integrations.Tableau.SelectPassport`)}
                placeholder={t('Integrations.Tableau.Select')}
                disabled={passports.length === 0}
                showHelpInfo={false}
                invalidState={!!props.requiredErrors["Tableau passport"]}
                invalidText={t('Integrations.Tableau.TableauPassportError')}
                helpInfo={t("Integrations.Tableau.SelectPassportInfoText")}
            />
            {selectedAuthType && selectedAuthType !== "PUBLIC" && <>
                <DropdownWidget
                    options={GetListWorkbooksForDropdown(tableauWorkbooks)}
                    onChange={(value) => handleWorkbookChange("Workbook ID", value.id)}
                    selectedValue={GetSelectedWorkbook(tableauWorkbooks, props.params.filter((para) => para.name === "Workbook ID")[0].value)}
                    width={528}
                    title={t(`Integrations.Tableau.Workbook`)}
                    placeholder={t('Integrations.Tableau.ChooseWorkbookPlaceholder')}
                    disabled={tableauWorkbooks.length === 0}
                    showHelpInfo={false}
                    invalidState={!!props.requiredErrors["Workbook ID"]}
                    invalidText={t('Integrations.Tableau.ChooseWorkbookError')}
                    helpInfo={t("Integrations.Tableau.WorkbookInfoText")}
                />
                <DropdownWidget
                    options={GetListViewsForDropdown(tableauViews)}
                    onChange={(value) => props.handleParamChange("View ID", value.id)}
                    selectedValue={GetSelectedView(tableauViews, props.params.filter((para) => para.name === "View ID")[0].value)}
                    width={528}
                    title={t(`Integrations.Tableau.View`)}
                    placeholder={t('Integrations.Tableau.ChooseViewPlaceholder')}
                    disabled={tableauViews.length === 0}
                    showHelpInfo={false}
                    invalidState={!!props.requiredErrors["View ID"]}
                    invalidText={t('Integrations.Tableau.ChooseViewError')}
                    helpInfo={t("Integrations.Tableau.ViewInfoText")}
                />
            </>}
            {selectedAuthType && selectedAuthType === "PUBLIC" &&<TextWidget
                title={t('Integrations.Tableau.TableauURL')}
                displayLength={false}
                placeholder={t('Integrations.Tableau.TableauURLPlaceholder')}
                keys="Public Visualization URL"
                formValue={props.params.filter((para) => para.name === "Public Visualization URL")[0].value}
                invalidState={!!props.requiredErrors['Public Visualization URL']}
                invalidText={t('Integrations.Tableau.URLError')}
                width={528}
                id="Public Visualization URL"
                onChange={(e) => props.handleParamChange("Public Visualization URL", e.target.value)}
                disabled={false}
                onBlur={(e) => {}} />}
            <EpicNumberWidget
                label={t('Integrations.Tableau.RefreshInterval')}
                name="Refresh (mins)"
                onChange={(e) => { props.handleParamChange(e.target.name, e.target.value) }}
                isInvalid={!!props.requiredErrors["Refresh (mins)"]}
                value={props.params.filter((item) => item.name === "Refresh (mins)")[0].value}
                error={t("Integrations.Tableau.RefreshIntervalError")}
                min={0}
                helpInfo={t("Integrations.Tableau.RefreshIntervalInfoText")}

            />
        </>
    );
}
