import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SourceSpecParam } from 'userful-chronos-app-common-js/dist/models/source';
import { useTranslation } from 'react-i18next';
import { SamSourceSpec } from 'userful-chronos-app-common-js/dist/models/sam/SAMSourceSpec';
import { getTranslatedParamHelp, getTranslatedParamHelpFromName, getTranslatedParamName } from '../SourceFormUtil';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';

interface IProps {
    selectedSourceSpec?: SamSourceSpec,
    param?: SourceSpecParam,
    paramName?: string;
    className?: string;
}

export default function ParamHelpInfo(props: IProps) {
    const { t } = useTranslation();

    const helpText = props.paramName ?
        getTranslatedParamHelpFromName(props.paramName, props.selectedSourceSpec, t)
        :
        getTranslatedParamHelp(props.param, props.selectedSourceSpec, t);
    if (!helpText) {
        return null;
    }
    const popover = (
        <Tooltip id={`popover-source-info-icon-${props.paramName || props.param.name}`}>
                {helpText}
        </Tooltip>
    );

    return (
        <OverlayTrigger trigger="hover" overlay={popover}>
            <div className={`sourceInfoIcon  ${props.className ? props.className : ''}`}>
                <FontAwesomeIcon icon={faCircleInfo} />
            </div>
        </OverlayTrigger>
    );
}