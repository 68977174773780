"use strict";

var _epicMsgDefinitions = require("../../../message/messageTypeDefinitions/integration/epicMsgDefinitions");
var _powerbiMsgDefinitions = require("../../../message/messageTypeDefinitions/integration/powerbiMsgDefinitions");
var _tableauMsgDefinitions = require("../../../message/messageTypeDefinitions/integration/tableauMsgDefinitions");
var _StandaloneMsgHandler = require("../../eventbus/StandaloneMsgHandler");
var _integrations = require("../../functions/integrations");
// Epic
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_epicMsgDefinitions.REQUEST_LIST_SERVERS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_epicMsgDefinitions.RESPOND_LIST_SERVERS, (0, _integrations.getStandaloneEpicServers)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_epicMsgDefinitions.REQUEST_ADD_SERVER, function (payload) {
  var result = (0, _integrations.addEpicServer)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_epicMsgDefinitions.RESPOND_ADD_SERVER, result);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_epicMsgDefinitions.NOTIFY_SERVER_UPDATE, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_epicMsgDefinitions.REQUEST_UPDATE_SERVER, function (payload) {
  var result = (0, _integrations.updateEpicServer)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_epicMsgDefinitions.RESPOND_UPDATE_SERVER, result);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_epicMsgDefinitions.NOTIFY_SERVER_UPDATE, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_epicMsgDefinitions.REQUEST_REMOVE_SERVER, function (payload) {
  var result = (0, _integrations.removeEpicServer)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_epicMsgDefinitions.RESPOND_REMOVE_SERVER, payload);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_epicMsgDefinitions.REQUEST_LIST_PASSPORTS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_epicMsgDefinitions.RESPOND_LIST_PASSPORTS, (0, _integrations.getStandaloneEpicPassports)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_epicMsgDefinitions.REQUEST_ADD_PASSPORT, function (payload) {
  var result = (0, _integrations.addEpicPassport)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_epicMsgDefinitions.RESPOND_ADD_PASSPORT, result);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_epicMsgDefinitions.NOTIFY_PASSPORT_UPDATE, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_epicMsgDefinitions.REQUEST_UPDATE_PASSPORT, function (payload) {
  var result = (0, _integrations.updateEpicPassport)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_epicMsgDefinitions.RESPOND_UPDATE_PASSPORT, result);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_epicMsgDefinitions.NOTIFY_PASSPORT_UPDATE, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_epicMsgDefinitions.REQUEST_REMOVE_PASSPORT, function (payload) {
  var result = (0, _integrations.removeEpicPassport)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_epicMsgDefinitions.RESPOND_REMOVE_PASSPORT, payload);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_epicMsgDefinitions.REQUEST_SERCHPARAMS, function (payload) {
  var result = (0, _integrations.getEpicInstanceID)();
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_epicMsgDefinitions.RESPOND_SEARCHPARAMS, payload);
});

// PowerBI
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_powerbiMsgDefinitions.REQUEST_LIST_PASSPORTS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_powerbiMsgDefinitions.RESPOND_LIST_PASSPORTS, (0, _integrations.getStandalonePowerbiPassports)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_powerbiMsgDefinitions.REQUEST_ADD_PASSPORT, function (payload) {
  var result = (0, _integrations.addPowerbiPassport)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_powerbiMsgDefinitions.RESPOND_ADD_PASSPORT, result);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_powerbiMsgDefinitions.NOTIFY_PASSPORT_UPDATE, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_powerbiMsgDefinitions.REQUEST_UPDATE_PASSPORT, function (payload) {
  var result = (0, _integrations.updatePowerbiPassport)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_powerbiMsgDefinitions.RESPOND_UPDATE_PASSPORT, result);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_powerbiMsgDefinitions.NOTIFY_PASSPORT_UPDATE, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_powerbiMsgDefinitions.REQUEST_REMOVE_PASSPORT, function (payload) {
  var result = (0, _integrations.removePowerbiPassport)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_powerbiMsgDefinitions.RESPOND_REMOVE_PASSPORT, payload);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_powerbiMsgDefinitions.REQUEST_CODE_CHALLENGE, function (payload) {
  var result = (0, _integrations.getPowerbiCodeChallange)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_powerbiMsgDefinitions.RESPOND_CODE_CHALLENGE, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_powerbiMsgDefinitions.REQUEST_LIST_WORKSPACE, function (payload) {
  var result = (0, _integrations.getStandalonePowerbiWorkspaces)();
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_powerbiMsgDefinitions.RESPOND_LIST_WORKSPACE, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_powerbiMsgDefinitions.REQUEST_LIST_REPORT, function (payload) {
  var result = (0, _integrations.getStandalonePowerbiReports)();
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_powerbiMsgDefinitions.RESPOND_LIST_REPORT, result);
});

// Tableau
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_tableauMsgDefinitions.REQUEST_LIST_PASSPORTS, function (payload) {
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_tableauMsgDefinitions.RESPOND_LIST_PASSPORTS, (0, _integrations.getStandaloneTableauPassports)());
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_tableauMsgDefinitions.REQUEST_ADD_PASSPORT, function (payload) {
  var result = (0, _integrations.addTableauPassport)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_tableauMsgDefinitions.RESPOND_ADD_PASSPORT, result);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_tableauMsgDefinitions.NOTIFY_PASSPORT_UPDATE, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_tableauMsgDefinitions.REQUEST_UPDATE_PASSPORT, function (payload) {
  var result = (0, _integrations.updateTableauPassport)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_tableauMsgDefinitions.RESPOND_UPDATE_PASSPORT, result);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_tableauMsgDefinitions.NOTIFY_PASSPORT_UPDATE, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_tableauMsgDefinitions.REQUEST_REMOVE_PASSPORT, function (payload) {
  var result = (0, _integrations.removeTableauPassport)(payload);
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_tableauMsgDefinitions.RESPOND_REMOVE_PASSPORT, payload);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_tableauMsgDefinitions.REQUEST_GET_JWT, function (payload) {
  var result = (0, _integrations.getTableauJWTToken)();
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_tableauMsgDefinitions.RESPOND_GET_JWT, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_tableauMsgDefinitions.REQUEST_WORKBOOK_LIST, function (payload) {
  var result = (0, _integrations.getStandaloneTableauWorkbooks)();
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_tableauMsgDefinitions.RESPOND_WORKBOOK_LIST, result);
});
(0, _StandaloneMsgHandler.registerStandaloneMsgHandler)(_tableauMsgDefinitions.REQUEST_VIEW_LIST, function (payload) {
  var result = (0, _integrations.getStandaloneTableauViews)();
  (0, _StandaloneMsgHandler.respondStandaloneRequest)(_tableauMsgDefinitions.RESPOND_VIEW_LIST, result);
});