import { AxiosResponse } from 'axios';
import { apiGet, apiPost, apiDelete, apiPut, getGlobalStates } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';
import {
    GET_MICROSOFT_INTEGRATIONS,
    PUT_MICROSOFT_INTEGRATIONS,
    POST_MICROSOFT_INTEGRATIONS,
    DELETE_MICROSOFT_INTEGRATIONS,
    GET_MICROSOFT_SYNC_USERS
} from './identityProviderPath';
import usermgtStore from '../usermgtStore';
import { usermgtSliceActions } from '../usermgtSlice';
import { identityProviderSliceActions } from '../identityProviderSlice';
import {
    AddMicrosoftProvider, UpdateMicrosoftProvider, DeleteMicrosoftProvider,
    SyncMicrosoft, MicrosoftProviderStatus
} from 'userful-chronos-app-common-js/dist/models/usermgt/identityProviders';
import { toast } from "react-toastify";
import { ConfirmationToastContentStore } from '../../../Container/ConfirmationToastContentStore';

const errorHandler = (err) => {
    console.debug("Error calling init app api ");
    console.debug(err);
}

const getError = (response: any) => {
    return ({
        success: false,
        arg: response.data.arg ? response.data.arg : "",
        errorCode: response.data.errorCode ? response.data.errorCode : "",
        moduleID: response.data.moduleID ? response.data.moduleID : "",
        severity: response.data.severity ? response.data.severity : '',
        msg: response.data.unlocalizedMsg ? response.data.unlocalizedMsg : ""
    }
    );
}


export const checkReponseIsValid = (name: string, response: AxiosResponse): boolean => {
    if (response.status >= 400) {
        console.log(`${name} error: ${response.status}`);
        return false;
    }
    if (!response.data || response.data.severity !== "SUCCESS") {
        console.log(`${name} empty response`);
        return false;
    }
    return true;
}

export const getMicrosoftDataFromServer = (token: string) => {

    const t1 = getGlobalStates().host + GET_MICROSOFT_INTEGRATIONS;

    apiGet(t1, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            usermgtStore.dispatch(identityProviderSliceActions.setSaveBool(false));
            return;
        }
        usermgtStore.dispatch(identityProviderSliceActions.setMicrosoftData(response.data.arg));
        usermgtStore.dispatch(identityProviderSliceActions.setLoadBool(false));
        usermgtStore.dispatch(identityProviderSliceActions.setSaveBool(false));
        console.debug(`receive authorize`, response.data);
    }).catch(() => console.warn("failed"));
}

export const addMicrosoftDataToServer = (token: string, data: AddMicrosoftProvider) => {

    const t1 = getGlobalStates().host + PUT_MICROSOFT_INTEGRATIONS;

    apiPut(t1, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('configure Microsoft ', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            usermgtStore.dispatch(identityProviderSliceActions.setSaveBool(false));
            return;
        }
        usermgtStore.dispatch(identityProviderSliceActions.setMicrosoftData(response.data.arg));
        usermgtStore.dispatch(identityProviderSliceActions.setLoadBool(false));
        usermgtStore.dispatch(identityProviderSliceActions.setOverlay(false));
        usermgtStore.dispatch(identityProviderSliceActions.setSaveBool(false));

        const displaySuccess = ConfirmationToastContentStore(data.alias, "Save");
        toast(displaySuccess, { containerId: 'confirmationContainer' });
    }).catch(() => console.warn("failed"));
}

export const updateMicrosoftDataToServer = (token: string, data: UpdateMicrosoftProvider) => {

    const t1 = getGlobalStates().host + POST_MICROSOFT_INTEGRATIONS;

    apiPost(t1, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('update Microsoft ', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            usermgtStore.dispatch(identityProviderSliceActions.setSaveBool(false));
            return;
        }
        usermgtStore.dispatch(identityProviderSliceActions.setMicrosoftData(response.data.arg));
        usermgtStore.dispatch(identityProviderSliceActions.setLoadBool(false));
        usermgtStore.dispatch(identityProviderSliceActions.setOverlay(false));
        usermgtStore.dispatch(identityProviderSliceActions.setSaveBool(false));

        const displaySuccess = ConfirmationToastContentStore(data.alias, "Edit");
        toast(displaySuccess, { containerId: 'confirmationContainer' });

    }).catch(() => console.warn("failed"));
}

export const deleteMicrosoftDataToServer = (token: string, data: DeleteMicrosoftProvider) => {

    const t1 = getGlobalStates().host + DELETE_MICROSOFT_INTEGRATIONS;

    apiDelete(t1, {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
        data: data
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            usermgtStore.dispatch(identityProviderSliceActions.setSaveBool(false));
            return;
        }
        usermgtStore.dispatch(identityProviderSliceActions.setMicrosoftData(response.data.arg));
        usermgtStore.dispatch(identityProviderSliceActions.setLoadBool(false));
        usermgtStore.dispatch(identityProviderSliceActions.setSaveBool(false));
        console.debug(`receive authorize`, response.data);
    }).catch(() => console.warn("failed"));
}

export const syncMicrosoftDataToServer = (token: string, data: SyncMicrosoft) => {

    const t1 = getGlobalStates().host + GET_MICROSOFT_SYNC_USERS;

    apiPost(t1, data, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    }).then((response) => {
        if (!checkReponseIsValid('authorize', response)) {
            const getErrorMessage = getError(response);
            usermgtStore.dispatch(usermgtSliceActions.changeError(getErrorMessage));
            usermgtStore.dispatch(usermgtSliceActions.changeErrorBool(true));
            usermgtStore.dispatch(identityProviderSliceActions.setSaveBool(false));
            return;
        }
        usermgtStore.dispatch(identityProviderSliceActions.setMicrosoftData(response.data.arg));
        usermgtStore.dispatch(identityProviderSliceActions.setLoadBool(false));
        usermgtStore.dispatch(identityProviderSliceActions.setSaveBool(false));
        usermgtStore.dispatch(identityProviderSliceActions.setOverlay(false));

        // for displaying if there is error while syncing users
        const syncData: MicrosoftProviderStatus = response.data.arg.microsoftProvider.filter((d: MicrosoftProviderStatus) => d.microsoftProvider.alias === data.alias)[0];
        const syncResult = syncData.error == true ? "sync failed" : "sync successfull";
        const displayError = ConfirmationToastContentStore(syncData.microsoftProvider.alias, "Custom", syncResult);
        toast(displayError, { containerId: 'confirmationContainer' });


    }).catch(() => console.warn("failed"));
}