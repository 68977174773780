import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import displaysStore, { DisplaysContext } from './displaysStore';
import './msgs/OnboardMsgReceiver';
import { requestListOnboards } from './msgs/OnboardMsgSender';
import './msgs/UClientsMsgReceiver';
import { requestListUC, requestFirmwareConfig, requestRequiredAPPVersion } from './msgs/UClientsMsgSender';
import './msgs/ZeroClientsMsgReceiver';
import { requestListZC } from './msgs/ZeroClientsMsgSender';
import './msgs/UClientActionsMsgReceiver';
import { requestListUCAction } from './msgs/UClientActionsMsgSender';

interface IProps {
  children: any,
}

function DisplaysProvider(props: IProps) {
  useEffect(() => {
    requestListOnboards();
    requestListUC();
    requestListZC();
    requestFirmwareConfig();
    requestRequiredAPPVersion();
    requestListUCAction();
  }, []);

  return (
    <Provider store={displaysStore} context={DisplaysContext}>
      {props.children}
    </Provider>
  );
}

export default DisplaysProvider;
