import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CdmVideoWallDest, CdmVideoWallDestCreator } from 'userful-chronos-app-common-js/dist/models/cdm/CdmVideoWall';
import { DestStreamType, HasCdmOrgData } from 'userful-chronos-app-common-js/dist/models/cdm/Common';
import { sortVideoWallDestinations } from 'userful-chronos-app-common-js/dist/models/cdm/DestinationsUtils';
import { HasID, HasNameAndDescription, HasUserfulSecurityData, StringID } from 'userful-chronos-app-common-js/dist/models/common';

import { addOrUpdateReducer, removeReducer, sanitizeCdmOrgData, sanitizeCdmOrgDatas } from './CdmStoreUtils';
import { requestCreateVideoWallDest, requestRemoveVideoWallDest, requestUpdateVideoWallDestHeader, requestUpdateVideoWallDestOrgData, requestUpdateVideoWallDestSecurityData, requestUpdateVideoWallDestStreamType } from './msgs/MsgSender';

const initialState: {
    videowallDestinations: CdmVideoWallDest[],
    ready: boolean;
} = {
    videowallDestinations: [],
    ready: false,
};

export const videowallDestinationsSlice = createSlice({
    name: 'videowallDestinationsSlice',
    initialState,
    reducers: {
        setVideoWallDestinations: (state, action: PayloadAction<CdmVideoWallDest[]>) => {
            state.ready = true;
            state.videowallDestinations = sortVideoWallDestinations(sanitizeCdmOrgDatas(action.payload));
        },
        addOrUpdateVideoWallDestination: (state, action: PayloadAction<CdmVideoWallDest>) => {
            addOrUpdateReducer(state, sanitizeCdmOrgData(action.payload), 'videowallDestinations');
            state.videowallDestinations = sortVideoWallDestinations(state.videowallDestinations)
        },
        removeVideoWallDestination: (state, action: PayloadAction<CdmVideoWallDest>) => {
            removeReducer(state, action.payload, 'videowallDestinations');
            state.videowallDestinations = sortVideoWallDestinations(state.videowallDestinations)
        },
        createVideoWallDestinationToServer: (state, action: PayloadAction<CdmVideoWallDestCreator>) => {
            requestCreateVideoWallDest({ ...action.payload });
        },
        updateVideoWallDestinationToServer: (state, action: PayloadAction<HasID & HasNameAndDescription &
            HasCdmOrgData & HasUserfulSecurityData & { destStreamType: DestStreamType }>) => {
            const { id, name, description, orgData, userfulSecurityData, destStreamType } = action.payload;
            const foundExisting = state.videowallDestinations.find(i => i.id.value === id.value);
            if (foundExisting) {
                if (foundExisting.name !== name || foundExisting.description !== description) {
                    requestUpdateVideoWallDestHeader({ id, name, description });
                }
                requestUpdateVideoWallDestOrgData({ id, orgData, });
                requestUpdateVideoWallDestSecurityData({ id, userfulSecurityData, });
                if (foundExisting.destStreamType !== destStreamType) {
                    requestUpdateVideoWallDestStreamType({ id, destStreamType });
                }
            }
        },
        removeVideoWallDestinationToServer: (state, action: PayloadAction<StringID>) => {
            requestRemoveVideoWallDest({ ...action.payload });
        },
    },
})

export const videowallDestinationsSliceActions = videowallDestinationsSlice.actions;

export default videowallDestinationsSlice.reducer