import { CalibrationVideoWall } from "userful-chronos-app-common-js/dist/models/display";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";

export interface IVideoWallCalibrationState {
    videoWallCalibrations: CalibrationVideoWall[];
}

const initialState: IVideoWallCalibrationState = {
    videoWallCalibrations: [],
};

const videoWallCalibrationSlice = createSlice({
    name: "videoWallCalibration",
    initialState,
    reducers: {
        setVideoWallCalibrations(state: IVideoWallCalibrationState, action: PayloadAction<CalibrationVideoWall[]>) {
            state.videoWallCalibrations = action.payload;
        },
        addVideoWallCalibrations(state: IVideoWallCalibrationState, action: PayloadAction<CalibrationVideoWall[]>) {
            const elements = action.payload;
            for (let i = 0; i < elements.length; i++) {
                state.videoWallCalibrations.push(elements[i]);
            }
        },
        removeVideoWallCalibrations(state: IVideoWallCalibrationState, action: PayloadAction<StringID[]>) {
            const elements = action.payload;
            for (let i = 0; i < elements.length; i++) {
                const index = state.videoWallCalibrations.findIndex((v) => v.id.value === elements[i].value);

                if (index >= 0) {
                    state.videoWallCalibrations.splice(index, 1);
                }
            }
        },
        updateVideoWallCalibrations(state: IVideoWallCalibrationState, action: PayloadAction<CalibrationVideoWall[]>) {
            const elements = action.payload;
            for (let i = 0; i < elements.length; i++) {
                const index = state.videoWallCalibrations.findIndex((v) => v.id.value === elements[i].id.value);

                if (index >= 0) {
                    state.videoWallCalibrations[index] = elements[i];
                }
            }
        },
    },
});

export const videoWallCalibrationActions = videoWallCalibrationSlice.actions;

export default videoWallCalibrationSlice.reducer;
