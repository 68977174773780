import { configureStore } from '@reduxjs/toolkit'
import React from 'react'
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook } from 'react-redux'
import diagnosticSlice from './diagnosticSlice'

export const DiagnosticContext = React.createContext(null);

const diagnosticStore = configureStore({
  reducer: {
      diagnosticStore: diagnosticSlice,
  },
})
export default diagnosticStore;

export type State = ReturnType<typeof diagnosticStore.getState>;
export type Dispatch = typeof diagnosticStore.dispatch;
export const useDiagnosticSelector: TypedUseSelectorHook<State> = createSelectorHook(DiagnosticContext);
export const useDiagnosticDispatch = createDispatchHook<Dispatch>(DiagnosticContext)
