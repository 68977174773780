import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates"
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { REQUEST_LIST_ONBOARDS, REQUEST_LIST_ONBOARDS_FOR_VE } from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/displays/OnboardMsgDefinations";

export const requestListOnboards = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_ONBOARDS);
};

export const requestListOnboardsForVE = (veID: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_ONBOARDS_FOR_VE, veID);
};

