import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {SystemWorkInProgressStatus} from 'userful-chronos-app-common-js/dist/models/config/failover';
import {requestFailoverInProgressStatus} from './msgs/failoverInProgressSender';

const initStatus = (): SystemWorkInProgressStatus => {
    return {
        workInProgress: false,
        messageKey:"",
        message:""
    }
}

const initialState: {
    isFailoverInProgress: SystemWorkInProgressStatus;
} = {
    isFailoverInProgress: initStatus()
};

export const failoverInProgressSlice = createSlice({
    name: 'failoverInProgressSlice',
    initialState,
    reducers: {
        setFailoverInProgress: (state, action: PayloadAction<SystemWorkInProgressStatus>) => {
            state.isFailoverInProgress = action.payload;
        },
        requestFailoverInProgressStatus: (state) => {
            requestFailoverInProgressStatus();
        }
    },
})

export const failoverInProgressActions = failoverInProgressSlice.actions;

export default failoverInProgressSlice.reducer