import { configureStore } from "@reduxjs/toolkit";
import React from "react";
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook } from "react-redux";
import groupSlice from "./groupSlice";

export const GroupContext = React.createContext(null);

const groupStore = configureStore({
    reducer: {
        group: groupSlice,
    },
});
export default groupStore;

export type GroupRootState = ReturnType<typeof groupStore.getState>;
export type GroupDispatch = typeof groupStore.dispatch;
export const useGroupSelector: TypedUseSelectorHook<GroupRootState> = createSelectorHook(GroupContext);
export const useGroupDispatch = createDispatchHook<GroupDispatch>(GroupContext);
