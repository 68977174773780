"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getHLSStreamIDForMapper = exports.getHLSStreamDataForMapper = exports.createDefaultHLSStreamRequestForMapper = exports.STREAM_ID_PREFIX = void 0;
var _CdmAudio = require("../cdm/CdmAudio");
var _display = require("../display");
var _MappingGroups = require("./MappingGroups");
var createDefaultHLSStreamRequestForMapper = exports.createDefaultHLSStreamRequestForMapper = function createDefaultHLSStreamRequestForMapper(mapper) {
  return {
    id: mapper.id,
    resolution: _display.DEFAULT_RESOLUTION,
    audioSettings: _CdmAudio.GWTAUDIO_100
  };
};
var STREAM_ID_PREFIX = exports.STREAM_ID_PREFIX = "HLS-";
var getHLSStreamIDForMapper = exports.getHLSStreamIDForMapper = function getHLSStreamIDForMapper(mapper) {
  return {
    value: "".concat(STREAM_ID_PREFIX).concat(mapper.id.value)
  };
};
var getHLSStreamDataForMapper = exports.getHLSStreamDataForMapper = function getHLSStreamDataForMapper(mapper) {
  if ((0, _MappingGroups.isEdgeMapper)(mapper)) {
    return null;
  }
  var mg = mapper;
  var streams = mg.streams.map(function (s) {
    return s.streamData;
  });
  return streams.find(function (item) {
    return item.type === 'HLS';
  });
};