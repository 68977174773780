import React from 'react';
import { useTranslation } from 'react-i18next';
import './BetaTag.scss';

export const BetaTag = () => {

    const { t } = useTranslation();

    return (

        <div className='betaDiv'>
            <div className='betaText'>{t('Integrations.Common.Beta')}</div>
        </div>
    );
}

