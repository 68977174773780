import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { groupSliceActions } from "../groupSlice";
import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { CompGroup } from "userful-chronos-app-common-js/dist/models/group/Groups";
import { MapperLayout } from "userful-chronos-app-common-js/dist/models/layouts/GlobalLayouts";
import groupStore from "../groupStore";
import {RESPONSE_LIST_GROUPS, NOTIFY_CREATE_GROUP, NOTIFY_UPDATE_GROUP, NOTIFY_REMOVE_GROUP} from "userful-chronos-app-common-js/dist/models/group/messages/msgTypes";

registerMsgHandler(RESPONSE_LIST_GROUPS, (payload) => {
    const data = JSON.parse(payload) as CompGroup[];

    groupStore.dispatch(groupSliceActions.setGroups(data));
});
registerMsgHandler(NOTIFY_CREATE_GROUP, (payload) => {
    const data = JSON.parse(payload) as CompGroup;

    groupStore.dispatch(groupSliceActions.addGroup(data));
});
registerMsgHandler(NOTIFY_UPDATE_GROUP, (payload) => {
    const data = JSON.parse(payload) as CompGroup;

    groupStore.dispatch(groupSliceActions.updateGroup(data));
});
registerMsgHandler(NOTIFY_REMOVE_GROUP, (payload) => {
    const data = JSON.parse(payload) as StringID;

    groupStore.dispatch(groupSliceActions.removeGroup(data));
});