import React, {useEffect, useState} from 'react';
import {Modal, Form, FormControl} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

export default function AddOrEditDir(props) {
    const { t } = useTranslation();

    const [dirName, setDirName] = useState("");
    const [dirPath, setDirPath] = useState("");
    const [error, setError] = useState("");

    const orgDirName = props.editDirValue.name;
    const orgDirPath = props.editDirValue.path;

    useEffect(() => {
        setDirName(orgDirName);
        setDirPath(orgDirPath);
    }, [orgDirName, orgDirPath]);
    
    const handleDirNameChange = (e) => {
        setDirName(e.target.value);
        setError("");
    };

    const handleDirPathChange = (e) => {
        const {value} = e.target;
        setDirPath(value);
        setError("");
    };

    const hideModal = () => {
        props.hideDirModal();
        setDirName("");
        setDirPath("");
        setError("");
    };

    const handleSubmitDir = () => {
        const dir = props.data.Playlist.dir || [];
        
        if(!dirName) {
            setError(t('CommonUI.Sources.Sources.nameError'));
            return;
        } 

        const dirNames = dir.map((item) => item.name);

        if(dirNames.includes(dirName)) {
            setError(t('CommonUI.Sources.Sources.nameDuplicate'));
            return;
        }
        setError("");

        if(orgDirName) { 
            const index = (props.editDirValue && props.editDirValue.index) || "";
            props.handleEditDir(dirName, dirPath, props.name, index);
            setDirName("");
            setDirPath("");
            return;
        } else { 
            props.handleAddDir(dirName, dirPath, props.name);
            setDirName("");
            setDirPath("");
        }
    };

    return(
         <Modal
            show={props.dirModalShow}
            onHide={hideModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
                <div className='modal-header-title'>
                    <Modal.Header>
                            <div className='modal-title'>{t('CommonUI.Sources.Sources.configDir')}</div>
                             <div className='modal-close-btn'>
                                <FontAwesomeIcon icon={faTimes} onClick={hideModal} className="custom-close-btn"/>
                            </div>
                    </Modal.Header>
                </div>

                <Modal.Body>
                    <Form.Group className="input-wrapper">
                        <Form.Label className="heading1">*{t('CommonUI.Sources.Sources.dirName')}</Form.Label>
                        <Form.Control 
                            as="input" 
                            value={dirName}
                            isInvalid={!!error}
                            onChange={handleDirNameChange} 
                         />
                         <FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
                    </Form.Group>
                    
                    <Form.Group className="input-wrapper">
                        <Form.Label className="heading1">{t('CommonUI.Sources.Sources.dirPath')}</Form.Label>
                    <Form.Control 
                        as="input" 
                        value={dirPath}
                        onChange={handleDirPathChange}                                         
                    />
                    </Form.Group>
                    </Modal.Body>
                <Modal.Footer>
                    <button className="button-secondary" type='button' onClick={hideModal}>{t('Shared.cancel')}</button>
                    <button className="button-primary" type='button' onClick={handleSubmitDir}>{t('Shared.ok')}</button>
                </Modal.Footer>
            </Modal>
    );
}