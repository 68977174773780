import { faCheck, faMinus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Checkmark.scss";

export type TSelected = "selected" | "unselected" | "indeterminate";
interface IProps {
    selected?: TSelected;
    onClick?: (e) => void;
}

export default function Checkmark(props: IProps) {
    return (
        <div onClick={props.onClick} 
        className={`checkmark-container ${props.selected === "selected" || props.selected === "indeterminate" ? "checkmark-selected" : ""}`}>
            {props.selected === "selected" ? <FontAwesomeIcon icon={faCheck} /> : null}
            {props.selected === "indeterminate" ? <FontAwesomeIcon icon={faMinus} /> : null}
        </div>
    );
}
