"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTableauPassport = exports.updatePowerbiPassport = exports.updateEpicServer = exports.updateEpicPassport = exports.removeTableauPassport = exports.removePowerbiPassport = exports.removeEpicServer = exports.removeEpicPassport = exports.getTableauJWTToken = exports.getStandaloneTableauWorkbooks = exports.getStandaloneTableauViews = exports.getStandaloneTableauPassports = exports.getStandalonePowerbiWorkspaces = exports.getStandalonePowerbiReports = exports.getStandalonePowerbiPassports = exports.getStandaloneEpicServers = exports.getStandaloneEpicPassports = exports.getPowerbiCodeChallange = exports.getEpicInstanceID = exports.addTableauPassport = exports.addPowerbiPassport = exports.addEpicServer = exports.addEpicPassport = void 0;
var _epic = require("../../models/integration/epic");
var _epicADT = require("../../models/integration/epicADT");
var _powerbi = require("../../models/integration/powerbi");
var _tableau = require("../../models/integration/tableau");
var _utils = require("./utils");
var _uuid = require("uuid");
// Epic
var getStandaloneEpicServers = exports.getStandaloneEpicServers = function getStandaloneEpicServers() {
  return (0, _utils.getStandaloneData)("epicServers");
};
var addEpicServer = exports.addEpicServer = function addEpicServer(update) {
  return (0, _utils.createStandaloneData)((0, _epic.initEpicServerFromHeader)(update), "epicServers");
};
var updateEpicServer = exports.updateEpicServer = function updateEpicServer(update) {
  return (0, _utils.updateStandaloneData)(update, "epicServers");
};
var removeEpicServer = exports.removeEpicServer = function removeEpicServer(id) {
  return (0, _utils.removeStandaloneData)(id, "epicServers");
};
var getStandaloneEpicPassports = exports.getStandaloneEpicPassports = function getStandaloneEpicPassports() {
  return (0, _utils.getStandaloneData)("epicPassports");
};
var addEpicPassport = exports.addEpicPassport = function addEpicPassport(update) {
  return (0, _utils.createStandaloneData)((0, _epicADT.initEpicADTPassportFromHeader)(update), "epicPassports");
};
var updateEpicPassport = exports.updateEpicPassport = function updateEpicPassport(update) {
  return (0, _utils.updateStandaloneData)(update, "epicPassports");
};
var removeEpicPassport = exports.removeEpicPassport = function removeEpicPassport(id) {
  return (0, _utils.removeStandaloneData)(id, "epicPassports");
};
var getEpicInstanceID = exports.getEpicInstanceID = function getEpicInstanceID() {
  return (0, _uuid.v4)();
};

// Power BI
var getStandalonePowerbiPassports = exports.getStandalonePowerbiPassports = function getStandalonePowerbiPassports() {
  return (0, _utils.getStandaloneData)("powerbiPassports");
};
var addPowerbiPassport = exports.addPowerbiPassport = function addPowerbiPassport(update) {
  return (0, _utils.createStandaloneData)((0, _powerbi.initPowerBIPasswordFromHeader)(update), "powerbiPassports");
};
var updatePowerbiPassport = exports.updatePowerbiPassport = function updatePowerbiPassport(update) {
  return (0, _utils.updateStandaloneData)(update, "powerbiPassports");
};
var removePowerbiPassport = exports.removePowerbiPassport = function removePowerbiPassport(id) {
  return (0, _utils.removeStandaloneData)(id, "powerbiPassports");
};
var getPowerbiCodeChallange = exports.getPowerbiCodeChallange = function getPowerbiCodeChallange(id) {
  return {
    id: id,
    codeChallenge: "hello",
    codeChallengeMethod: ""
  };
};
var getStandalonePowerbiWorkspaces = exports.getStandalonePowerbiWorkspaces = function getStandalonePowerbiWorkspaces() {
  return (0, _utils.getStandaloneData)("powerbiWorkspaces");
};
var getStandalonePowerbiReports = exports.getStandalonePowerbiReports = function getStandalonePowerbiReports() {
  return (0, _utils.getStandaloneData)("powerbiReports");
};

// Tableau
var getStandaloneTableauPassports = exports.getStandaloneTableauPassports = function getStandaloneTableauPassports() {
  return (0, _utils.getStandaloneData)("tableauPassport");
};
var addTableauPassport = exports.addTableauPassport = function addTableauPassport(update) {
  return (0, _utils.createStandaloneData)((0, _tableau.initTableauPassportFromHeader)(update), "tableauPassport");
};
var updateTableauPassport = exports.updateTableauPassport = function updateTableauPassport(update) {
  return (0, _utils.updateStandaloneData)(update, "tableauPassport");
};
var removeTableauPassport = exports.removeTableauPassport = function removeTableauPassport(id) {
  return (0, _utils.removeStandaloneData)(id, "tableauPassport");
};
var getStandaloneTableauWorkbooks = exports.getStandaloneTableauWorkbooks = function getStandaloneTableauWorkbooks() {
  return (0, _utils.getStandaloneData)("tableauWorkbooks");
};
var getStandaloneTableauViews = exports.getStandaloneTableauViews = function getStandaloneTableauViews() {
  return (0, _utils.getStandaloneData)("tableauViews");
};
var getTableauJWTToken = exports.getTableauJWTToken = function getTableauJWTToken() {
  return (0, _uuid.v4)();
};