import { useTranslation } from 'react-i18next'
import CreatableSelect from 'react-select/creatable';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import './DropdownWidget.scss';
import React from "react";
import { HelpInfo } from '../../../sourceassets';
interface IProps {
     options: Array<{ value: string, label: string, id: string }>;
     onChange: (value: { value: string, label: string, id: string }) => void;
     selectedValue: { value: string, label: string, id: string };
     width: number;
     title: string;
     showHelpInfo?: boolean;
     invalidState?: boolean;
     invalidText?: string;
     disabled?:boolean;
     placeholder: string;
     helpInfo?: string;
}
const DropdownWidget = (props: IProps) => {

     const { t } = useTranslation();

     const customStyles = {

          option: (provided, state) => ({
               ...provided,
               color: '#1F2747',
               maxHeight: '176px',
               height: '32px',
               padding: '0px 16px',
               background: state.isSelected ? '#F3F4F5' : state.isFocused ? '#F3F4F5' : undefined,
               display: 'flex',
               alignItems: 'center',
               "&:active": {
                    background: '#F3F4F5',
               },

          }),
          control: (base, state) => ({
               // none of react-select's styles are passed to <Control />
               ...base,
               width: "100%",
               color: '#1F2747',
               background: ' #FFFFFF',
               boxShadow: 'inset 0px 2px 4px rgba(31, 39, 71, 0.1)',
               borderRadius: '8px',
               transition: 'none',
               display: 'flex',
               border: state.menuIsOpen ? '1px solid #6C89E8' : '1px solid #C6C8CE',
               '&:hover': {
                    border: '1px solid #4B5168',
               },
          }),
          valueContainer: base => ({
               ...base,
               display: 'flex',
               padding: '0px 16px',
          }),
     }

     const handleTagChange = (newValue) => {
          props.onChange(newValue);
     }

     return (

          <div className='epicDropdownWidget' style={{ width: '100%' }}>
               <div className='epicDropdownWidgetHeader'>
                    <div className='epicDropdownWidgetHeaderOne'>
                         <div className='problemCanvasTitleOne'>
                              {props.title}
                              <HelpInfo className='powerBIHelpInfo' title={props.helpInfo} />
                         </div>
                    </div>
               </div>
               <CreatableSelect
                    styles={customStyles}
                    onChange={handleTagChange}
                    value={props.selectedValue}
                    options={props.options}
                    menuPosition="fixed"
                    components={{ IndicatorSeparator: () => null }}
                    inputId="dropdown"
                    isDisabled={props.disabled}
                    placeholder={props.placeholder}
                    className={`react-select-container ${props.disabled && 'disableCreateSelect'}`}
               />
               {props.invalidState && <div className="invalidInputOne"> {props.invalidText}</div>}
          </div>

     );

}

export default DropdownWidget;