"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.canExecuteChannel = exports.canEditChannel = exports.canAssignOwnerToChannel = void 0;
var _globalStates = require("../../globalstates/globalStates");
var _roles = require("../../authenticataion/roles");
// if user can edit the channel
var canEditChannel = exports.canEditChannel = function canEditChannel(securityData) {
  var hasClearanceLevelPermission = (0, _roles.hasClearanceLevelForAction)(securityData, (0, _globalStates.getGlobalStates)().userData.userID, (0, _globalStates.getGlobalStates)().userPermission, (0, _globalStates.getGlobalStates)().actions, 'edit') ? true : false;
  return hasClearanceLevelPermission;
};

// if user can execute the channel
var canExecuteChannel = exports.canExecuteChannel = function canExecuteChannel(securityData) {
  var hasClearanceLevelPermission = (0, _roles.hasClearanceLevelForAction)(securityData, (0, _globalStates.getGlobalStates)().userData.userID, (0, _globalStates.getGlobalStates)().userPermission, (0, _globalStates.getGlobalStates)().actions, 'execute') ? true : false;
  return hasClearanceLevelPermission;
};

// if channel can be assigned owner or not
var canAssignOwnerToChannel = exports.canAssignOwnerToChannel = function canAssignOwnerToChannel(securityData) {
  var ownershipPermissions = (0, _globalStates.getGlobalStates)().userPermission.canOverrideSecurityDataOwnerShip;
  // if there is no clearance level permissions, that means the content is open
  if (securityData.clearanceLevelPermissions.length === 0 || !ownershipPermissions) return false;
  return true;
};