import { Dropdown } from "react-bootstrap";
import Button from "../../../Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./../Multiselect/MultiActionDropdown.scss";
import { faSlidersSimple } from "@fortawesome/pro-regular-svg-icons";
import Checkmark from "../../../checkmark/Checkmark";
import React from "react";
interface IProps {
    columns: { [name: string]: boolean };
    onColumnSelectorChange?(columns: { [name: string]: boolean }): void;
}

export default function ColumnSelector(props: IProps) {


    return (
        <Dropdown className="multi-action-dropdown" autoClose="outside">
            <Dropdown.Toggle as={Button} bsPrefix="multi-select-container" id="dropdownToggle" style={{ width: 36 }}>
                <FontAwesomeIcon icon={faSlidersSimple} />
            </Dropdown.Toggle>

            <Dropdown.Menu className="multi-action-menu" onKeyDown={(e) => e.stopPropagation()} onKeyUp={(e) => e.stopPropagation()}>
                {Object.entries(props.columns).map(([key, value], index) => {
                    return <Dropdown.Item
                        key={key}
                        className="multi-action-dropdown-item"
                        onClick={() => {
                            const newColumns = { ...props.columns };
                            newColumns[key] = !value;
                            props.onColumnSelectorChange(newColumns);
                        }}
                    >
                        <Checkmark selected={value? "selected" : "unselected"} />
                        <div className="textInside">{key}</div>
                    </Dropdown.Item>
                })}
            </Dropdown.Menu>
        </Dropdown>


    );

}