import SAMSourceForm from "./SamSourceForm";
import React from "react";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { SamSourceDef } from "userful-chronos-app-common-js/dist/models/sam/SAMSource";

interface IProps {
    source?: SamSourceDef;
    buttonVariant?: string;
    appID?: string;
    noSourceBool?: boolean;
    sourcesData?: any;
    fromSourceBar?: boolean;
    disableSourceTypeSelection?: boolean;
    onCard?: boolean;
    borderTopRightZero?: boolean;
    duplicate?: boolean;
    dropdownItem?: boolean;
    noIconInDropdown?: boolean;
    onCreate?: (sourceID: StringID) => void;
}

export const SAMSourceFormWrapper = (props: IProps) => {
    return (
        <SAMSourceForm
            {...props}
        />
    )
}