import React from 'react';
import { Form } from 'react-bootstrap';
import { SourceSpecParam } from 'userful-chronos-app-common-js/dist/models/source';
import { useTranslation } from 'react-i18next';

interface IProps {
    param: SourceSpecParam,
}

export default function HelpWidget(props: IProps) {
    const { t } = useTranslation();

    if (!props.param) {
        return null;
    }
    const helpPath = props.param.additionalParams.map(additionalParam => additionalParam.value);
    if (helpPath.length < 1) {
        return null;
    }
    return (
        <Form.Group className="source-help" controlId="formSourceType">
            <Form.Label className="help-text">
                {/* <a href={helpPath[0]} target="_blank">{t('Shared.help')}</a> */}
                <a href='https://docs.userful.com/infinity/administrative-users/install/sources-and-destinations' target="_blank">{t('Shared.help')}</a>
            </Form.Label>
        </Form.Group>
    );
}