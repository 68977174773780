"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.powerBIThumbnailImages = exports.initPowerBIPasswordFromHeader = exports.generateOpenURL = exports.frameRateOptionsForEpicSourceForm = exports.PowerBIPassportHeaderDefault = exports.PowerBIPassportDefault = exports.NOT_SET = void 0;
var _uuid = require("uuid");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var NOT_SET = exports.NOT_SET = "NOT_SET";
var initPowerBIPasswordFromHeader = exports.initPowerBIPasswordFromHeader = function initPowerBIPasswordFromHeader(data) {
  return _objectSpread(_objectSpread({}, data), {}, {
    isConnected: true
  });
};
var PowerBIPassportHeaderDefault = exports.PowerBIPassportHeaderDefault = function PowerBIPassportHeaderDefault() {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "",
    description: "",
    authCode: ""
  };
};
var PowerBIPassportDefault = exports.PowerBIPassportDefault = function PowerBIPassportDefault() {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: "",
    description: "",
    isConnected: false
  };
};
var scope = "offline_access https://analysis.windows.net/powerbi/api/App.Read.All https://analysis.windows.net/powerbi/api/Capacity.Read.All https://analysis.windows.net/powerbi/api/Dashboard.Read.All https://analysis.windows.net/powerbi/api/Dataflow.Read.All https://analysis.windows.net/powerbi/api/Datamart.Read.All https://analysis.windows.net/powerbi/api/DataPipeline.Read.All https://analysis.windows.net/powerbi/api/Dataset.Read.All https://analysis.windows.net/powerbi/api/Eventhouse.Read.All https://analysis.windows.net/powerbi/api/Eventstream.Read.All https://analysis.windows.net/powerbi/api/Gateway.Read.All https://analysis.windows.net/powerbi/api/Item.Read.All https://analysis.windows.net/powerbi/api/PaginatedReport.Read.All https://analysis.windows.net/powerbi/api/Report.Read.All https://analysis.windows.net/powerbi/api/Workspace.Read.All openid profile";
var generateOpenURL = exports.generateOpenURL = function generateOpenURL(codeChallenge, currentURL) {
  return 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize' + '?client_id=12b8e2c9-13a2-4dcf-b8e3-237543ea245a' + '&response_type=code' + '&redirect_uri=https://integrations.userful.com/oauth2/microsoft/powerbi' + '&response_mode=query' + "&scope=".concat(scope) + "&state=".concat(currentURL) + "&code_challenge=".concat(codeChallenge) + '&code_challenge_method=S256';
};
var frameRateOptionsForEpicSourceForm = exports.frameRateOptionsForEpicSourceForm = [{
  value: "0.1",
  label: "0.1",
  id: "0.1"
}, {
  value: "0.5",
  label: "0.5",
  id: "0.5"
}, {
  value: "1",
  label: "1",
  id: "1"
}, {
  value: "5",
  label: "5",
  id: "5"
}, {
  value: "10",
  label: "10",
  id: "10"
}, {
  value: "25",
  label: "25",
  id: "25"
}, {
  value: "30",
  label: "30",
  id: "30"
}, {
  value: "45",
  label: "45",
  id: "45"
}, {
  value: "60",
  label: "60",
  id: "60"
}];
var powerBIThumbnailImages = exports.powerBIThumbnailImages = [{
  value: "/usr/share/userful-plugin-powerbi/icons/bi-thumbnail.png",
  fileName: "bi-thumbnail.png"
}, {
  value: "/usr/share/userful-plugin-powerbi/icons/system-logo.png",
  fileName: "system-logo.png"
}];