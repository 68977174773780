import { APP_IDS, EMPTY_USERFUL_SECURITY_DATA, StringID } from "userful-chronos-app-common-js/dist/models/common";
import { CompGroup } from "userful-chronos-app-common-js/dist/models/group/Groups";
import { faPlus, faPenToSquare, faMultiply, faCheck, faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Dropdown, Offcanvas, Modal } from "react-bootstrap";
import { generateUUID } from "userful-chronos-app-common-js/dist/utils";
import Button from "../../Button/Button";
import { objectEqual } from "./objectComparison";
import "./UserfulForm.scss";
import React from "react";
import { requestCreateGroup, requestUpdateGroup } from 'userful-chronos-app-common-js/dist/models/group/messages/GroupMsgSender';
import { useTranslation } from "react-i18next";
import { useGroupSelector } from 'userful-chronos-common-store/dist/group';
interface IProps {
    type: "SECONDARY" | "DROPDOWN";
    comp: CompGroup;
    onCreate?: (folderID: StringID) => void;
}

const initCreateGroup = (): CompGroup => {
    return {
        id: { value: generateUUID() },
        name: "",
        description: "",
        type: "SOURCE_FOLDER",
        appInstanceID: { value: generateUUID(), appID: { value: APP_IDS.Config } },
        comps: [],
        createTime: Date.now(),
        updateTime: Date.now(),
        userfulSecurityData: EMPTY_USERFUL_SECURITY_DATA(),
    }
}

export default function CreateFolder(props: IProps) {

    const { t } = useTranslation();

    const [showForm, setShowForm] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [registerChange, setRegisterChange] = useState<boolean>(false);

    const [originalFolder, setOriginalFolder] = useState<CompGroup>(props.comp ? props.comp : initCreateGroup());
    const [currentFolder, setCurrentFolder] = useState<CompGroup>(originalFolder);

    const allFolders = useGroupSelector(state => state.group.groups);

    const foundChanges = !objectEqual(originalFolder, currentFolder);
    const nameError = (allFolders.find((c) => c.name === currentFolder.name) &&
        currentFolder.name !== originalFolder.name) || currentFolder.name.length === 0
        ? "Please make sure the name is unique and is not empty."
        : "";


    let canCreate = !nameError && registerChange;

    function handleNameChange(event) {
        setRegisterChange(true);
        setCurrentFolder({ ...currentFolder, name: event.target.value });
    }

    const onFormShow = () => {
        setShowForm(true);
    };
    const onFormHide = () => {
        if (foundChanges) {
            setShowConfirmation(true);
        } else {
            onRestore();
            setShowForm(false);
        }
    };
    const onRestore = () => {
        setRegisterChange(false);
        setCurrentFolder(props.comp ? props.comp : initCreateGroup());
    };

    const onCreate = () => {

        if (!props.comp) {
            if(props.onCreate) props.onCreate(currentFolder.id);
            requestCreateGroup(currentFolder);
        }
        else {
            requestUpdateGroup(currentFolder);
        }

        onRestore();
        setShowForm(false);
    };

    return (
        <>
            {props.type === "SECONDARY" && (
                <Button variant="secondary" onClick={(e) => onFormShow()}>
                    <FontAwesomeIcon icon={faPlus} />
                    Create Folder
                </Button>
            )}

            {props.type === "DROPDOWN" && (
                <Dropdown.Item as="li" eventKey="1" id={`${props.comp.id.value}-source-delete`} onClick={(e) => onFormShow()}>
                    <div >{t("Shared.edit")}</div>
                </Dropdown.Item>
            )}

            <Offcanvas show={showForm} onHide={onFormHide} placement="end" >
                <div className="userfulFormBody" onClick={(e) => e.stopPropagation()}>
                    <div className="userfulFormHeader">
                        <div className="userfulFormRow">
                            <h1 className="userfulFormHeaderTitle">
                                {false ? "Edit Folder" : "Create Folder"}
                            </h1>
                            <button className="userfulIconButton" onClick={onFormHide}>
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="userfulFormRow">
                            <h3 className="userfulFormHeaderDescription">
                                A folder can be used to group and store similar content together for organizational purposes.
                            </h3>
                        </div>
                    </div>
                    <div className="userfulFormContents">
                        <div className="userfulFormColumn userfulFormSmallGap">
                            <h4 className="userfulFormContentsTitle">Folder Details</h4>
                            <h5 className="userfulFormContentsDescription">
                                Basic information about this folder.
                            </h5>
                        </div>
                        <div className="userfulFormColumn userfulFormSmallGap">
                            <div className="userfulFormFieldTitle">
                                <h5>Name</h5>
                                <h6>{currentFolder.name.length}/60</h6>
                            </div>
                            <input
                                className="userfulInputField"
                                placeholder="Enter name here..."
                                type="text"
                                maxLength={60}
                                value={currentFolder.name}
                                onChange={handleNameChange}
                            />
                            {nameError && registerChange && <div className="errorText">{nameError}</div>}
                        </div>
                    </div>
                </div>
                <div className="userfulFormFooter">
                    <button className="secondaryUserfulButton" onClick={onFormHide}>
                        Cancel
                    </button>
                    <button
                        className="primaryUserfulButton"
                        onClick={onCreate}
                        disabled={!canCreate}
                    >
                        {props.comp ? "Save Changes" : "Create"}
                    </button>
                </div>
            </Offcanvas>

            <Modal
                centered
                show={showConfirmation}
                onHide={() => {
                    setShowConfirmation(false);
                }}
            >
                <div className="confirmationDialogVerticalWrapper" style={{ gap: 24 }}>
                    <div className="confirmationDialogVerticalWrapper">
                        <div className="confirmationDialogHorizontalWrapper edged">
                            <div className="confirmationDialogHorizontalWrapper">
                                <FontAwesomeIcon icon={faCircleExclamation} />
                                <div className="title">Are you sure you want to leave?</div>
                            </div>
                            <button
                                className="userfulIconButton"
                                onClick={() => {
                                    setShowConfirmation(false);
                                }}
                            >
                                <FontAwesomeIcon icon={faMultiply} />
                            </button>
                        </div>
                        <div className="description">
                            Are you sure you want to leave without saving your changes? Any unsaved modifications will
                            be lost.
                        </div>
                    </div>
                    <div className="confirmationDialogHorizontalWrapper reversed">
                        <button
                            className="warningUserfulButton"
                            onClick={(e) => {
                                setShowForm(false);
                                setShowConfirmation(false);
                                onRestore();
                            }}
                        >
                            Confirm
                        </button>
                        <button
                            className="secondaryUserfulButton"
                            onClick={(e) => {
                                setShowConfirmation(false);
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
