import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClusterDeviceInfo } from "../../../model/CarbonExternalModels";
import "./DevicesCard.scss";
import { faMemory, faMicrochip, faSdCards, faServer, faTachographDigital } from "@fortawesome/pro-regular-svg-icons";
import { formatMemory, formatCPU, formatStorage, formatSingleGPU } from "../../../common/functions/formatters";
import { useCarbonDispatch } from "../../../store/carbonStore";
import { AppSliceActions } from "../../../store/app/appSlice";

interface IProps {
    device: ClusterDeviceInfo;
}

export default function DevicesCard(props: IProps) {

    const dispatch = useCarbonDispatch();

    if(!props.device.active) return null;

    return (
        <div className="devicesCard" onClick={() => { dispatch(AppSliceActions.setAppView({ type: "DEVICE", id: props.device.id })); }}>
            <div className="deviceCardHeader">
                <FontAwesomeIcon icon={faServer} />
                <p>{props.device.name}</p>
            </div>
            <div className="deviceCardBody">
                <div className="deviceCardBodyRow">
                    <div className="deviceCardBodyColumn">
                        <p>End-Points Status</p>
                    </div>
                    <div className="deviceCardBodyColumn">
                        <div className="greenBadge">
                            <p>{props.device.endpoint.online} Online</p>
                        </div>
                        <div className="redBadge">
                            <p>{props.device.endpoint.offline} Offline</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="deviceCardBody">
                {/* <div className="deviceCardBodyRow">
                    <div className="deviceCardBodyColumn">
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <p>Video Engine ID</p>
                    </div>
                    <div className="deviceCardBodyColumn">
                        <p className="limit">{props.device.id.value}</p>
                    </div>
                </div> */}
                <div className="deviceCardBodyRow">
                    <div className="deviceCardBodyColumn">
                        <FontAwesomeIcon className="card-icon" icon={faMemory} />
                        <p>RAM</p>
                    </div>
                    <div className="deviceCardBodyColumn">
                        <p className="limit">{formatMemory(props.device.deviceUsage.memory)}</p>
                    </div>
                </div>
                {props.device.deviceUsage.gpu.map((gpu, index) => {
                    let gpuName = "GPU";
                    if (props.device.deviceUsage.gpu.length > 1) {
                        gpuName = `GPU ${index + 1}`;
                    }
                    return (
                        <div className="deviceCardBodyRow">
                            <div className="deviceCardBodyColumn">
                                <FontAwesomeIcon className="card-icon" icon={faTachographDigital} />
                                <p>{gpuName}</p>
                            </div>
                            <div className="deviceCardBodyColumn">
                                <p className="limit">{formatSingleGPU(gpu)}</p>
                            </div>
                        </div>
                    )
                })}
                <div className="deviceCardBodyRow">
                    <div className="deviceCardBodyColumn">
                        <FontAwesomeIcon className="card-icon" icon={faMicrochip} />
                        <p>CPU</p>
                    </div>
                    <div className="deviceCardBodyColumn">

                        <p className="limit">{formatCPU(props.device.deviceUsage.cpu)}</p>
                    </div>
                </div>
                <div className="deviceCardBodyRow">
                    <div className="deviceCardBodyColumn">
                        <FontAwesomeIcon className="card-icon" icon={faSdCards} />
                        <p>Storage</p>
                    </div>
                    <div className="deviceCardBodyColumn">
                        <p className="limit">{formatStorage(props.device.deviceUsage.storage)}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
