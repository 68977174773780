import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { NOTIFY_VIDEO_ENGINE_ADDED, NOTIFY_VIDEO_ENGINE_REMOVED, NOTIFY_VIDEO_ENGINE_UPDATED, RESPONSE_LIST_VIDEO_ENGINES } from 'userful-chronos-app-common-js/dist/message/messageTypeDefinitions/ve/VEMsgDefinations';
import { VideoEngineComp } from 'userful-chronos-app-common-js/dist/models/ve/videoEngines';
import { videoEngineActions } from "../videoEngineSlice";
import videoEngineStore from "../videoEngineStore";

// 
registerMsgHandler(RESPONSE_LIST_VIDEO_ENGINES, (payload) => {
    console.debug('receive RESPONSE_LIST_VIDEO_ENGINES');
    const data = JSON.parse(payload) as VideoEngineComp[];
    videoEngineStore.dispatch(videoEngineActions.setVEs(data));
})
registerMsgHandler(NOTIFY_VIDEO_ENGINE_ADDED, (payload) => {
    console.debug('receive NOTIFY_VIDEO_ENGINE_ADDED');
    const data = JSON.parse(payload) as VideoEngineComp;
    videoEngineStore.dispatch(videoEngineActions.addOrUpdateVE(data));
})
registerMsgHandler(NOTIFY_VIDEO_ENGINE_REMOVED, (payload) => {
    console.debug('receive NOTIFY_VIDEO_ENGINE_REMOVED');
    const data = JSON.parse(payload) as VideoEngineComp;
    videoEngineStore.dispatch(videoEngineActions.deleteVE(data));
})
registerMsgHandler(NOTIFY_VIDEO_ENGINE_UPDATED, (payload) => {
    console.debug('receive NOTIFY_VIDEO_ENGINE_UPDATED');
    const data = JSON.parse(payload) as VideoEngineComp;
    videoEngineStore.dispatch(videoEngineActions.addOrUpdateVE(data));
})
