import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import {
    RESPOND_LIST_PASSPORTS, RESPOND_ADD_PASSPORT, RESPOND_UPDATE_PASSPORT,
    RESPOND_UPDATE_PASSPORT_WITH_OAUTH, RESPOND_REMOVE_PASSPORT, NOTIFY_PASSPORT_UPDATE, RESPOND_CODE_CHALLENGE,
    RESPOND_LIST_REPORT, RESPOND_LIST_WORKSPACE
} from 'userful-chronos-app-common-js/dist/message/messageTypeDefinitions/integration/powerbiMsgDefinitions';
import {
    PowerBIPassport, PowerbiPassportHeader, PowerbiPassportID, PowerbiReport,
    PowerBIPassportAuthCode, PowerBICodeChallenge, PowerBIWorkspace
} from "userful-chronos-app-common-js/dist/models/integration/powerbi";
import integrationStore from "../integrationStore";
import { powerbiActions } from "../powerbiSlice";
import { registerErrorMsgHandlerByMsgDefinition } from "userful-chronos-app-common-js/dist/message/messageRegistery";

registerMsgHandler(RESPOND_LIST_PASSPORTS, (payload) => {
    console.warn("receive POWERBI RESPOND_LIST_PASSPORTS");
    const data = JSON.parse(payload) as PowerBIPassport[];
    integrationStore.dispatch(powerbiActions.setPassports(data));
});

registerMsgHandler(RESPOND_ADD_PASSPORT, (payload) => {
    console.warn("receive RESPOND_ADD_PASSPORT");
    const data = JSON.parse(payload) as PowerbiPassportHeader;
    integrationStore.dispatch(powerbiActions.changeLoadState("RENDER"));
    integrationStore.dispatch(powerbiActions.setOpenForm(false));
});

registerErrorMsgHandlerByMsgDefinition(RESPOND_ADD_PASSPORT, (data) => {
    integrationStore.dispatch(powerbiActions.changeLoadState("RENDER"));
});

registerMsgHandler(RESPOND_UPDATE_PASSPORT, (payload) => {
    console.warn("receive RESPOND_UPDATE_PASSPORT");
    const data = JSON.parse(payload) as PowerbiPassportHeader;
    integrationStore.dispatch(powerbiActions.changeLoadState("RENDER"));
    integrationStore.dispatch(powerbiActions.setOpenForm(false));
});

registerErrorMsgHandlerByMsgDefinition(RESPOND_UPDATE_PASSPORT, (data) => {
    integrationStore.dispatch(powerbiActions.changeLoadState("RENDER"));
});

registerMsgHandler(RESPOND_UPDATE_PASSPORT_WITH_OAUTH, (payload) => {
    console.warn("receive RESPOND_UPDATE_PASSPORT_WITH_OAUTH");
    const data = JSON.parse(payload) as PowerbiPassportHeader;
    integrationStore.dispatch(powerbiActions.changeLoadState("RENDER"));
    integrationStore.dispatch(powerbiActions.setOpenForm(false));
});

registerErrorMsgHandlerByMsgDefinition(RESPOND_UPDATE_PASSPORT_WITH_OAUTH, (data) => {
    integrationStore.dispatch(powerbiActions.changeLoadState("RENDER"));
});

registerMsgHandler(RESPOND_REMOVE_PASSPORT, (payload) => {
    console.warn("receive RESPOND_REMOVE_PASSPORT");
    const data = JSON.parse(payload) as PowerbiPassportID;
    integrationStore.dispatch(powerbiActions.removePassport(data));
});

registerMsgHandler(NOTIFY_PASSPORT_UPDATE, (payload) => {
    console.warn("receive NOTIFY_PASSPORT_UPDATE");
    const data = JSON.parse(payload) as PowerBIPassport;
    integrationStore.dispatch(powerbiActions.addOrUpdatePassport(data));
});

registerMsgHandler(RESPOND_CODE_CHALLENGE, (payload) => {
    console.warn("receive RESPOND_CODE_CHALLENGE");
    const data = JSON.parse(payload) as PowerBICodeChallenge;
    integrationStore.dispatch(powerbiActions.setCodeChallenge(data.codeChallenge));
});

registerMsgHandler(RESPOND_LIST_WORKSPACE, (payload) => {
    console.warn("receive RESPOND_LIST_WORKSPACE");
    const data = JSON.parse(payload) as PowerBIWorkspace[];
    integrationStore.dispatch(powerbiActions.setWorkspaces(data));
});

registerMsgHandler(RESPOND_LIST_REPORT, (payload) => {
    console.warn("receive RESPOND_LIST_REPORT");
    const data = JSON.parse(payload) as PowerbiReport[];
    integrationStore.dispatch(powerbiActions.setReports(data));
});