import momentTZ from 'moment-timezone';

const ALL_TIME_ZONES = momentTZ.tz.names();

interface ZoneGroups {
    [key: string]: {
        location: string;
        zoneID: string;
        abbr: string;
        offsetString: string;
    }[];
}

export const GROUPED_ZONES: ZoneGroups = ALL_TIME_ZONES.reduce((acc, curr) => {
    const splitedValues = curr.split("/");
    const region = splitedValues[0];
    const location = splitedValues[1] || splitedValues[0];
    const zoneID = curr;
    const abbr = momentTZ.tz(curr).zoneAbbr();
    const offsetString = momentTZ.tz(curr).format("Z z");
    if (acc[region]) {
        if (!acc[region].find(i => i.location === location)) {
            acc[region].push({ location, zoneID, abbr, offsetString, })
        }
    } else {
        acc[region] = [{ location, zoneID, abbr, offsetString, }]
    }
    return acc;
}, {})

export const REGION_OPTIONS: {value: string, label: string}[] = Object.keys(GROUPED_ZONES).map(i => ({ value: i, label: i }))
export const getLocationOptions = (zoneID: string): {value: string, label: string}[] => {
    const splitedValues = zoneID.split("/");
    if (!GROUPED_ZONES[splitedValues[0]]) {
        return [];
    }
    return GROUPED_ZONES[splitedValues[0]].map(i => ({
        value: i.zoneID,
        label: `${i.location.replaceAll("_", " ")} (${i.offsetString})`,
    }))
}

export const getSelectedRegion = (zoneID: string): {value: string, label: string} => {
    const splitedValues = zoneID.split("/");
    return REGION_OPTIONS.find(i => i.value === splitedValues[0]);
}

export const getSelectedLocation = (zoneID: string): {value: string, label: string} => {
    const selectedRegion = getSelectedRegion(zoneID);
    if (!selectedRegion) {
        return null;
    }
    return getLocationOptions(selectedRegion.value).find(i => i.value === zoneID);
}