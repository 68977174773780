import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import failoverInProgressStore, { ConfigContext } from './failoverInProgressStore';

interface IProps {
  appID?: string;
  children: ReactNode,
}

function FailoverInProgressProvider(props: IProps) {

  return (
    <Provider store={failoverInProgressStore} context={ConfigContext}>
      {props.children}
    </Provider>
  );
}

export default FailoverInProgressProvider;