import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DiagnosticData, DiagnosticNotificationData } from 'userful-chronos-app-common-js/dist/models/notifications/diagnostics';


const initialState: {
    diagnostics: DiagnosticData[];
} = {
    diagnostics: [],
};

export const diagnosticSlice = createSlice({
    name: 'diagnosticSlice',
    initialState,
    reducers: {
        updateDiagnostics: (state, action: PayloadAction<DiagnosticNotificationData>) => {
            for (const data of action.payload.diagnosticData) {
                const index = state.diagnostics.findIndex((l) => l.name === data.name);
                if (index >= 0) {
                    state.diagnostics = [...state.diagnostics.slice(0, index), { ...data }, ...state.diagnostics.slice(index + 1)];
                } else {
                    state.diagnostics = [...state.diagnostics, { ...data }];
                }
            }
        },
    },
})

export const diagnosticActions = diagnosticSlice.actions;

export default diagnosticSlice.reducer

