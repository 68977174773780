import React, { useEffect, useState } from "react";
import { IRowData, ITable } from "./userfulTableInterface";
import "./UserfulTable.scss";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import UserfulTablePagination, { IPagination } from "./Pagination/UserfulTablePagination";
import UserfulTableRow from "./Row/UserfulTableRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCube } from "@fortawesome/pro-regular-svg-icons";
import { faSort, faSortAsc, faSortDesc } from "@fortawesome/free-solid-svg-icons";
import UserfulTableMultiselect from "./Row/Multiselect/UserfulTableMultiselect";
import { getComponentsValue } from "./utils/component";
import ColumnSelector from "./Row/ColumnSelector/ColumnSelector";
import Checkmark from "../checkmark/Checkmark";
import { set } from "js-cookie";
import UserfulTableColumn from "./Row/UserfulTableColumn";
import Cookies from "js-cookie";
import "./UserfulTable.scss";
import "./UserfulTableColumns.scss";
import ColumnResizeBar from "./components/ColumnResizeBar";

interface IProps {
    table: ITable;
    replace?: React.ReactNode;
    reset?: boolean;
    onRowClick?(id: StringID): void;
    onDrop?(rows: IRowData[], target: IRowData): void;
    onActions?(ids: StringID[]): void;
    nonInteractive?: boolean; // Do not allow interaction with the row
    stretch?: boolean; // Stretch the width
    draggable?: boolean; // Allows dragging
    pagination?: boolean; // Add pagination
    foldering?: boolean // Add foldering
    sorting?: boolean; // Add sorting
    multiSelect?: boolean; // Allow to multiselect
}
export default function UserfulTable(props: IProps) {

    const [data, setData] = useState<IRowData[]>(props.table.data);

    const [columnSize, setColumnSize] = useState<{ [index: number]: number }>({});
    const [columnSelector, setColumnSelector] = useState<{ [name: string]: boolean }>({});
    const [sort, setSort] = useState<{ column: number, direction: "asc" | "desc" }>(undefined);
    const [dragging, setDragging] = useState<{ x: number, y: number }>(undefined);
    const [selectedRows, setSelectedRows] = useState<IRowData[]>([])
    const [tempSelected, setTempSelected] = useState<IRowData>(null);

    const [pagination, setPagination] = useState<IPagination>({
        dataPerPage: 15,
        pages: Math.ceil(props.table.data.length / 15),
        currentPage: 1
    });

    useEffect(() => {
        props.onActions(selectedRows.map((i) => i.id));
    }, [selectedRows]);

    useEffect(() => {
        if (sort) {
            const data: IRowData[] = [...props.table.data].sort((a, b) => {
                const columnA = a.columns[sort.column].sortBy ? a.columns[sort.column].sortBy : getComponentsValue(a.columns[sort.column].component);
                const columnB = b.columns[sort.column].sortBy ? b.columns[sort.column].sortBy : getComponentsValue(b.columns[sort.column].component);

                // Assuming columnA and columnB are either numbers or strings
                if (columnA < columnB) {
                    return sort.direction === "asc" ? -1 : 1;
                }
                if (columnA > columnB) {
                    return sort.direction === "asc" ? 1 : -1;
                }
                return 0;
            });
            setData(data);
        } else {
            setData(props.table.data);
        }
    }, [props.table.data, sort]);

    useEffect(() => {
        const savedSelector = Cookies.get(props.table.name + "TableColumnSelector");
        const columnSelector = savedSelector ? JSON.parse(savedSelector) : {};

        if (!columnSelector || Object.keys(columnSelector).length === 0) {
            for (let i = 0; i < props.table.columns.length; i++) {
                columnSelector[props.table.columns[i].header] = !props.table.columns[i].hidden;
            }
        }

        setColumnSelector(columnSelector);

        // -----------------------------

        const savedSizes = Cookies.get(props.table.name + "TableColumnSizes");
        const columnSize = savedSizes ? JSON.parse(savedSizes) : {};

        if (!savedSizes) {
            for (let i = 0; i < props.table.columns.length; i++) {
                if (!columnSize[i]) {
                    columnSize[i] = 240;
                }
            }
        }

        setColumnSize(columnSize);
    }, [])

    useEffect(() => {
        Cookies.set(props.table.name + "TableColumnSizes", JSON.stringify(columnSize), { expires: 365 });
    }, [columnSize])
    useEffect(() => {
        Cookies.set(props.table.name + "TableColumnSelector", JSON.stringify(columnSelector), { expires: 365 });
    }, [columnSelector])

    useEffect(() => {
        setSelectedRows([]);
    }, [props.reset])

    const onRowMouseUp = (event, row: IRowData) => {
        if (props.onDrop) {
            props.onDrop(tempSelected ? [tempSelected] : selectedRows, row);
        }
        if (!event) setDragging(undefined);
    };

    const onColumnSizeChange = (width, index) => {
        setColumnSize({ ...columnSize, [index]: width });
    };

    const onHeaderClick = (index) => {
        let direction = sort && sort.column === index ? sort.direction === "asc" ? "desc" : undefined : "asc";
        if (direction) {
            setSort({ column: index, direction: sort && sort.column === index ? sort.direction === "asc" ? "desc" : "asc" : "asc" });
        } else {
            setSort(undefined);
        }
    };

    const onDraggingUpdate = (e, row) => {
        if (row && row.isDraggable) {
            setDragging(e);
            if (e) {
                const findRow = selectedRows.find((i) => i.id.value === row.id.value);
                if (!findRow) {
                    setTempSelected(row);
                }
            }
            else {
                setTempSelected(undefined);
            }
        }
    }

    const onRowCheckboxClicked = (event, row: IRowData, checkbox?: boolean) => {
        if (!checkbox && row.isDroppable && props.onRowClick) { props.onRowClick(row.id); return; }

        const isCtrlPressed = event?.ctrlKey;

        const items: IRowData[] = [];

        if (isCtrlPressed || checkbox) {
            for (let i = 0; i < selectedRows.length; i++) {
                if (selectedRows[i].id.value !== row.id.value) items.push(selectedRows[i]);
            }
            if (!selectedRows.find((i) => i.id.value === row.id.value)) { items.push(row); }
        } else {
            items.push(row);
        }
        setSelectedRows(items);
    };

    useEffect(() => {
        const handleClick = (event) => {
            setSelectedRows([]);
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    const onMouseDown = (e, row) => {
        e.preventDefault();

        if (e.button === 0) {
            const onMouseMove = (e) => {
                onDraggingUpdate({ x: e.clientX, y: e.clientY }, row);
            };

            const onMouseUp = () => {

                onDraggingUpdate(null, row);

                document.removeEventListener("mousemove", onMouseMove);
                document.removeEventListener("mouseup", onMouseUp);
            };

            document.addEventListener("mousemove", onMouseMove);
            document.addEventListener("mouseup", onMouseUp);
        }
    };

    return (
        <>
            <div className="userful-table-wrapper">
                <div className="userful-table">
                    <div className="userful-table-column border-on-the-right">
                        {data.length > 0 && <div className="userful-table-multiselect"
                            style={{
                                borderBottom: data.length === 0 ? "none" : "1px solid #c6c8ce",
                                borderBottomLeftRadius: data.length === 0 ? 8 : 0,
                            }}>
                            <UserfulTableMultiselect
                                rows={props.table.data}
                                selectedRows={selectedRows}
                                pagination={pagination}
                                onMultiselectChange={(e) => {
                                    setSelectedRows(e)
                                }}
                            />
                        </div>}
                        {data.map((row, index) => {
                            const indexLowerBoundary = index + 1 >= (pagination.currentPage - 1) * pagination.dataPerPage + 1;
                            const indexUpperBoundary = index + 1 <= pagination.currentPage * pagination.dataPerPage;
                            const selected = tempSelected ? tempSelected?.id.value === row.id.value ? true : false : selectedRows.find((i) => i.id.value === row.id.value) ? true : false;

                            if (indexLowerBoundary && indexUpperBoundary) {
                                return <div
                                    key={"multiselect_" + index}
                                    onClick={(e) => onRowCheckboxClicked(e, row, true)}
                                    className={`userful-table-multiselect-element ${selected ? "row-selected" : ""}`}>
                                    <Checkmark selected={selected ? "selected" : "unselected"} onClick={(e) => {
                                        e.stopPropagation();
                                        onRowCheckboxClicked(e, row, true)
                                    }} />
                                </div>
                            }
                        })}

                    </div>
                    <div className="userful-table-content">

                        {props.replace ? props.replace : null}

                        {data.length > 0 && props.table.columns.map((c, index) => {
                            const sortable = props.sorting;

                            if (columnSelector && !columnSelector[c.header]) return null;

                            return (

                                <UserfulTableColumn
                                    index={index}
                                    key={"header_" + c.header}
                                    width={columnSize[index]}
                                    // HEADERS:
                                    header={c.header}
                                    onHeaderClick={onHeaderClick}
                                    sort={sort} sortable={sortable}
                                    // ROWS:
                                    rows={data}
                                    pagination={pagination}
                                    tempSelected={tempSelected}
                                    selectedRows={selectedRows}
                                    columnSelector={columnSelector}
                                    // ROW FUNCTIONS:
                                    onWidthChange={(width) => onColumnSizeChange(width, index)}
                                    onDraggingUpdate={(e, row) => onDraggingUpdate(e, row)}
                                    onRowClick={(e, r) => onRowCheckboxClicked(e, r)}
                                    onCheckboxClick={(e, r) => onRowCheckboxClicked(e, r, true)}
                                    onMouseUp={onRowMouseUp}
                                    dragging={dragging ? true : false}
                                />

                            );
                        })}
                        {data.length > 0 && <div className="userful-table-content-row-wrapper-stretch">
                            <div className="userful-table-header-stretch" />
                            {data.map((row, rowIndex) => {

                                const indexLowerBoundary = rowIndex + 1 >= (pagination.currentPage - 1) * pagination.dataPerPage + 1;
                                const indexUpperBoundary = rowIndex + 1 <= pagination.currentPage * pagination.dataPerPage;
                                const selected = tempSelected ? tempSelected?.id.value === row.id.value ? true : false : selectedRows.find((i) => i.id.value === row.id.value) ? true : false;


                                if (indexLowerBoundary && indexUpperBoundary) {
                                    return <div onClick={(e) => {
                                        e.stopPropagation();
                                        onRowCheckboxClicked(e, row);
                                    }} onMouseDown={(e) => onMouseDown(e, row)}
                                        key={rowIndex} className={`userful-table-content-row-stretch ${selected ? "row-selected" : ""}`} />
                                }
                            })}
                        </div>}
                    </div>
                    <div className="userful-table-column border-on-the-left">
                        {data.length > 0 && <div className="userful-table-column-selector"
                            style={{
                                borderBottom: data.length === 0 ? "none" : "1px solid #c6c8ce",
                                borderBottomLeftRadius: data.length === 0 ? 8 : 0,
                            }}>
                            <ColumnSelector
                                columns={columnSelector}
                                onColumnSelectorChange={(e) => setColumnSelector(e)}
                            />
                        </div>}
                        {data.map((row, index) => {
                            const indexLowerBoundary = index + 1 >= (pagination.currentPage - 1) * pagination.dataPerPage + 1;
                            const indexUpperBoundary = index + 1 <= pagination.currentPage * pagination.dataPerPage;
                            const selected = tempSelected ? tempSelected?.id.value === row.id.value ? true : false : selectedRows.find((i) => i.id.value === row.id.value) ? true : false;

                            if (indexLowerBoundary && indexUpperBoundary) {
                                return <div
                                    key={"column_selector_" + index}
                                    className={`userful-table-column-selector-element ${selected ? "row-selected" : ""}`}>
                                    {row.actionsComponent}
                                </div>
                            }
                        })}
                    </div>
                </div>

                {props.pagination &&
                    <UserfulTablePagination
                        onPaginationUpdate={(pagination) => { setPagination(pagination) }}
                        {...props} />}
            </div >
            {
                props.draggable && dragging && <div className="common-userful-table-dragging-wrapper">
                    <div className="common-userful-table-dragging-container" style={{ top: dragging.y + 2, left: dragging.x + 2 }}>
                        <FontAwesomeIcon icon={faCube} />
                        <p>Move {tempSelected ? 1 : selectedRows.length}
                            {tempSelected || selectedRows.length === 1 ? props.table.org.singular : props.table.org.plural}</p>
                    </div>
                </div>
            }
        </>
    );
}
