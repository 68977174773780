import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CreatePassportWarning.scss';
import { faWarning } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
interface IProps {
    preText: string;
    onClick: Function;
}

const CreatePassportWarning = (props: IProps) => {

    const { t } = useTranslation();

    return (
        <div className="createPassportWarning">
            <FontAwesomeIcon icon={faWarning} className='warningIcon' />
            <span className='warningText'>
                {props.preText}
                <span onClick={() => props.onClick()}>{t('Integrations.Common.WarningClick')}</span>
                {t('Integrations.Common.WarningFirst')}
            </span>
        </div>
    );
}

export default CreatePassportWarning;