import { FailoverInProgressProvider } from 'userful-chronos-common-store/dist/config';
import React, { useEffect } from "react";
import { Bounce, ToastContainer, ToastOptions, toast } from "react-toastify";
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import { useDiagnosticSelector } from 'userful-chronos-common-store/dist/diagnostics';
import { ALL_DIAGNOSTIC_CHECKS } from 'userful-chronos-app-common-js/dist/models/notifications/diagnostics';

const CONTAINER_ID = "customContainer";

const toastOptions: ToastOptions = {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: false,
    style: { width: "500px" },
    className: "notificationTostify",
    containerId: CONTAINER_ID,
};

const COOKIE_KEY_PREFIX = "userful-notification-";
const shouldShow = (key: string): boolean => {
    return !Cookies.get(`${COOKIE_KEY_PREFIX}${key}`)
};
const hideFor1Day = (key: string) => Cookies.set(`${COOKIE_KEY_PREFIX}${key}`, "hide", { expires: 1 });
const hide = (key: string) => Cookies.set(`${COOKIE_KEY_PREFIX}${key}`, "hide", { expires: 365 });

const ToastContent = ({ cookieKey, content }) => {
    const { t } = useTranslation();

    const handleHideFor1Day = (e) => {
        e.preventDefault();
        hideFor1Day(cookieKey);
    }

    const handleHide = (e) => {
        e.preventDefault();
        hide(cookieKey);
    }

    return <div className="notificationContentContainer">
        {content}
        <div className="notificationHideActions">
            <>
                <a href="#" onClick={handleHideFor1Day}>{t('Error.hideForADay')}</a>
                <a href="#" onClick={handleHide}>{t('Error.hideForever')}</a>
            </>

        </div>
    </div>
}


export const DiagnosticNotifications = (props) => {
    const { t } = useTranslation();

    const diagnostics = useDiagnosticSelector(state => state.diagnosticStore.diagnostics);
    useEffect(() => {
        for (const check of ALL_DIAGNOSTIC_CHECKS) {
            if (!shouldShow(check)) {
                return;
            }
            const failed = diagnostics.find(d => d.name === check && d.hasIssue);
            const isShowing = toast.isActive(check);
            if (!isShowing && failed) {
                toast.error(<ToastContent
                    cookieKey={check}
                    content={<><p>{t(`Diagnostic.${check}_failed`)}</p>
                    </>}
                />

                    , {
                        ...toastOptions,
                        toastId: check,
                        autoClose: false
                    });
            } else if (isShowing && !failed) {
                toast.dismiss(check);
            }
        }
    }, [diagnostics])
    return <>
        {/* <ToastContainer enableMultiContainer transition={Bounce} containerId={CONTAINER_ID} /> */}
    </>
}