import React, { ReactNode, useEffect } from 'react';
import { Provider } from 'react-redux';
import diagnosticStore, { DiagnosticContext } from './diagnosticStore';
import './msg/MsgReceiver';
import { getGlobalStates } from 'userful-chronos-app-common-js/dist/globalstates/globalStates';
import { REQUEST_DIAGNOSTIC } from 'userful-chronos-app-common-js/dist/message/messageTypeDefinitions/notifications/notificationMsgs';
import { ALL_DIAGNOSTIC_CHECKS, RunDiagnosticRequest } from 'userful-chronos-app-common-js/dist/models/notifications/diagnostics';

interface IProps {
  children: ReactNode,
}

function DiagnosticProvider(props: IProps) {

  useEffect(() => {
    getGlobalStates().eventBus.sendMsg(REQUEST_DIAGNOSTIC, {
      videoEngineID: { value: getGlobalStates().systemID },
      checks: ALL_DIAGNOSTIC_CHECKS,
    } as RunDiagnosticRequest)
  }, [])

  return (
    <Provider store={diagnosticStore} context={DiagnosticContext}>
      {props.children}
    </Provider>
  );
}

export default DiagnosticProvider;