import React from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SamSourceSpec } from 'userful-chronos-app-common-js/dist/models/sam/SAMSourceSpec';
import { SourceSpecParam } from 'userful-chronos-app-common-js/dist/models/source';
import { getTranslatedParamName } from '../SourceFormUtil';
import ParamHelpInfo from './ParamHelpInfo';

interface IProps {
    param: SourceSpecParam,
    selectedSourceSpec?: SamSourceSpec,
    input: any;
    onChange: any;
    requiredErrors: object;
}

export default function TextAreaWidget(props: IProps) {
    const { t } = useTranslation();

    return (
        <>
            <Form.Group className="input-wrapper">
                <Form.Label className="heading1">
                    {getTranslatedParamName(props.param, props.selectedSourceSpec, t)}
                    <ParamHelpInfo param={props.param} selectedSourceSpec={props.selectedSourceSpec} className="textAreaWidgest" />
                </Form.Label>
                <Form.Control
                    as="textarea"
                    name={props.param.name}
                    value={props.input[props.param.name]}
                    type={props.param.type}
                    onChange={props.onChange}
                    isInvalid={!!props.requiredErrors[props.param.name]}
                    maxLength={1000}
                />
                <Form.Control.Feedback type="invalid">{`${t('CommonUI.Sources.selectError')} ${getTranslatedParamName(props.param, props.selectedSourceSpec, t)}`}</Form.Control.Feedback>
            </Form.Group>
        </>
    );
}