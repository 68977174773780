import { configureStore } from '@reduxjs/toolkit'
import React from 'react'
import { TypedUseSelectorHook, createDispatchHook, createSelectorHook } from 'react-redux'
import failoverInProgressSlice from './failoverInProgressSlice'

export const ConfigContext = React.createContext(null);

const FailoverInProgressStore = configureStore({
  reducer: {
      failoverInProgressStore: failoverInProgressSlice,
  },
})
export default FailoverInProgressStore;

export type State = ReturnType<typeof FailoverInProgressStore.getState>;
export type Dispatch = typeof FailoverInProgressStore.dispatch;
export const useFailoverSelector: TypedUseSelectorHook<State> = createSelectorHook(ConfigContext);
export const useFailoverDispatch = createDispatchHook<Dispatch>(ConfigContext)
