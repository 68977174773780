"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestUpdateGroup = exports.requestRemoveGroup = exports.requestCreateGroup = exports.requestAllGroups = void 0;
var _globalStates = require("../../../globalstates/globalStates");
var _msgTypes = require("./msgTypes");
var requestAllGroups = exports.requestAllGroups = function requestAllGroups() {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_LIST_GROUPS);
};
var requestCreateGroup = exports.requestCreateGroup = function requestCreateGroup(group) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_CREATE_GROUP, group);
};
var requestUpdateGroup = exports.requestUpdateGroup = function requestUpdateGroup(group) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_UPDATE_GROUP, group);
};
var requestRemoveGroup = exports.requestRemoveGroup = function requestRemoveGroup(id) {
  (0, _globalStates.getGlobalStates)().eventBus.sendMsg(_msgTypes.REQUEST_REMOVE_GROUP, id);
};