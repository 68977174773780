import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CdmPhysicalVideoDest, CdmPhysicalVideoDestCreator } from 'userful-chronos-app-common-js/dist/models/cdm/CdmPhysical';
import { DestStreamType, HasCdmOrgData } from 'userful-chronos-app-common-js/dist/models/cdm/Common';
import { HasID, HasNameAndDescription, StringID, HasUserfulSecurityData, UpdateSecurityData } from 'userful-chronos-app-common-js/dist/models/common';
import { addOrUpdateReducer, removeReducer, sanitizeCdmOrgData, sanitizeCdmOrgDatas } from './CdmStoreUtils';
import { requestCreatePhysicalVideoDest, requestRemovePhysicalVideoDest, requestUpdatePhysicalVideoDestHeader, requestUpdatePhysicalVideoDestOrgData, requestUpdatePhysicalVideoDestSecurityData, requestUpdatePhysicalVideoDestStreamType } from './msgs/MsgSender';
import { sortPhysicalDestinations } from 'userful-chronos-app-common-js/dist/models/cdm/DestinationsUtils';


const initialState: {
    displayDestinations: CdmPhysicalVideoDest[],
    ready: boolean;
} = {
    displayDestinations: [],
    ready: false,
};

export const displayDestinationsSlice = createSlice({
    name: 'displayDestinationsSlice',
    initialState,
    reducers: {
        setDisplayDestinations: (state, action: PayloadAction<CdmPhysicalVideoDest[]>) => {
            state.ready = true;
            state.displayDestinations = sortPhysicalDestinations(sanitizeCdmOrgDatas(action.payload));
        },
        addOrUpdateDisplayDestination: (state, action: PayloadAction<CdmPhysicalVideoDest>) => {
            addOrUpdateReducer(state, sanitizeCdmOrgData(action.payload), 'displayDestinations');
            state.displayDestinations = sortPhysicalDestinations(state.displayDestinations);
        },
        removeDisplayDestination: (state, action: PayloadAction<CdmPhysicalVideoDest>) => {
            removeReducer(state, action.payload, 'displayDestinations');
            state.displayDestinations = sortPhysicalDestinations(state.displayDestinations);
        },
        createDisplayDestinationToServer: (state, action: PayloadAction<CdmPhysicalVideoDestCreator>) => {
            requestCreatePhysicalVideoDest({ ...action.payload });
        },
        updateDisplayDestinationToServer: (state, action: PayloadAction<HasID & HasNameAndDescription &
            HasCdmOrgData & HasUserfulSecurityData & { destStreamType: DestStreamType}>) => {
            const { id, name, description, orgData, userfulSecurityData, destStreamType } = action.payload;
            const foundExisting = state.displayDestinations.find(i => i.id.value === id.value);
            if (foundExisting) {
                if (foundExisting.name !== name || foundExisting.description !== description) {
                    requestUpdatePhysicalVideoDestHeader({ id, name, description });
                }
                requestUpdatePhysicalVideoDestOrgData({ id, orgData, });
                requestUpdatePhysicalVideoDestSecurityData({ id, userfulSecurityData });
                if (foundExisting.destStreamType !== destStreamType) {
                    requestUpdatePhysicalVideoDestStreamType({ id, destStreamType });
                }
            }
        },
        removeDisplayDestinationToServer: (state, action: PayloadAction<StringID>) => {
            requestRemovePhysicalVideoDest({ ...action.payload });
        },
        updateDisplayDestinationSecurityDataToServer: (state, action: PayloadAction<UpdateSecurityData>) => {
            requestUpdatePhysicalVideoDestSecurityData(action.payload);
        }

    },
})

export const displayDestinationsSliceActions = displayDestinationsSlice.actions;

export default displayDestinationsSlice.reducer