import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates"
import {
    REQUEST_ADD_UCLIENT_ACTION, REQUEST_LIST_UCLIENT_ACTIONS,
    REQUEST_REMOVE_UCLIENT_ACTION, REQUEST_UPDATE_UCLIENT_ACTION
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/displays/UClientsMsgDefinations";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";

import { UClientActionComp } from "userful-chronos-app-common-js/dist/models/uclient";


export const requestListUCAction = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_UCLIENT_ACTIONS);
};

export const requestCreateUCAction = (data: UClientActionComp) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_ADD_UCLIENT_ACTION, data);
};

export const requestUpdateUCAction = (data: UClientActionComp) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_UCLIENT_ACTION, data);
};

export const requestRemoveUCAction = (data: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_UCLIENT_ACTION, data);
};