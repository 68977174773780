import React from "react";
import "./ColumnResizeBar.scss";

interface IProps {
    width: number;
    onResize?(width: number): void;
}
export default function ColumnResizeBar(props: IProps) {

    const onMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const initialX = e.clientX;
        const initialWidth = props.width;

        if (e.button === 0) {
            const onMouseMove = (e) => {
                const width = initialWidth + e.clientX - initialX;

                if (props.onResize && width >= 160 && width <= 800) props.onResize(width);
            };

            const onMouseUp = () => {
                document.removeEventListener("mousemove", onMouseMove);
                document.removeEventListener("mouseup", onMouseUp);
            };

            document.addEventListener("mousemove", onMouseMove);
            document.addEventListener("mouseup", onMouseUp);
        }
    };
    return <div className="userful-table-column-resize-bar" onMouseDown={onMouseDown}>
        <div className="userful-table-column-resize-bar-element"></div>
    </div>
}