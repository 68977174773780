import { EpicServer } from "userful-chronos-app-common-js/dist/models/integration/epic";
import { EpicADTPassport } from "userful-chronos-app-common-js/dist/models/integration/epicADT";
import { PowerBIPassport, PowerBIWorkspace, PowerbiReport } from "userful-chronos-app-common-js/dist/models/integration/powerbi";

export const GetListPassportForDropdown = (passports: EpicServer[] | PowerBIPassport[] | EpicADTPassport[]): Array<{ value: string, label: string, id: string }> => {

    const list = passports.map((passport: EpicServer | PowerBIPassport | EpicADTPassport) => {
        return {
            value: passport.name,
            label: passport.name,
            id: passport.id.value
        }

    })

    return list;

}

export const GetListWorkspacesForDropdown = (powerbiWorkspaces: PowerBIWorkspace[]): Array<{ value: string, label: string, id: string }> => {

    const list = powerbiWorkspaces.map((workspace: PowerBIWorkspace) => {
        return {
            value: workspace.name,
            label: workspace.name,
            id: workspace.id
        }

    })

    return list;

}

export const GetListReportsForDropdown = (powerbiReports: PowerbiReport[]): Array<{ value: string, label: string, id: string }> => {

    const list = powerbiReports.map((report: PowerbiReport) => {
        return {
            value: report.name,
            label: report.name,
            id: report.id
        }

    })

    return list;

}

export const GetSelectedPassport = (passports: EpicServer[] | PowerBIPassport[], id: string): { value: string, label: string, id: string } => {
    const selected = passports.filter((passport) => passport.id.value === id);
    if (selected.length === 0) {
        return null
    }

    return {
        value: selected[0].name,
        label: selected[0].name,
        id: selected[0].id.value
    }
}


export const GetSelectedWorkspace = (workspaces: PowerBIWorkspace[], id: string): { value: string, label: string, id: string } => {
    const selected = workspaces.filter((workspace) => workspace.id === id);
    if (selected.length === 0) {
        return null
    }

    return {
        value: selected[0].name,
        label: selected[0].name,
        id: selected[0].id
    }
}

export const GetSelectedReport = (reports: PowerbiReport[], id: string): { value: string, label: string, id: string } => {
    const selected = reports.filter((passport) => passport.id === id);
    if (selected.length === 0) {
        return null
    }

    return {
        value: selected[0].name,
        label: selected[0].name,
        id: selected[0].id
    }
}



export const GetSelectedFramerate = (value: string): { value: string, id: string, label: string } => {

    return {
        value: value,
        id: value,
        label: value
    }

}

export const GetIDTypePayload = (data: Array<{ departmentID: string, departmentType: string, id: number }>): Array<{ id: string, type: string }> => {
    return data.map((item) => ({ "id": item.departmentID, "type": item.departmentType }))
}

export const FetchDepartmentsFromString = (data: string): Array<{ departmentID: string, departmentType: string, id: number }> => {

    const dataJson = JSON.parse(data);
    const departmentList = dataJson.map((item, index) => ({ departmentID: item.id, departmentType: item.type, id: index + 1 }))
    return departmentList;

}