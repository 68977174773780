import { Form } from "react-bootstrap";
import './EpicSourceForm.scss';
import { useTranslation } from "react-i18next";
import DropdownWidget from "../shared/DropdownWidget";
import { GetListPassportForDropdown, GetSelectedFramerate } from "../shared/Computation";
import { frameRateOptionsForEpicSourceForm } from 'userful-chronos-app-common-js/dist/models/integration/epic';
import DepartmentIDType from "../shared/DepartmentIDType";
import CheckboxWidget from "../shared/CheckboxWidget";
import EpicNumberWidget from "../shared/EpicNumberWidget";
import { useIntegrationSelector } from "userful-chronos-common-store/dist/integration";
import { SourceDefParam } from "userful-chronos-app-common-js/dist/models/source";
import { HelpInfo } from "../../../sourceassets";

interface IProps {
    params: SourceDefParam[];
    handleParamChange: (name: string, value) => void,
    requiredErrors,
}

export default function EpicSourceFormInner(props: IProps) {

    const passports = useIntegrationSelector((state) => state.epicStore.epicServers);
    const adtPassports = useIntegrationSelector((state) => state.epicStore.epicADTPassports);
    const passportsForDropdown = [...GetListPassportForDropdown(passports), ...GetListPassportForDropdown(adtPassports)];
    const passportType = passports.findIndex((pass) => pass.id.value === props.params.filter((para) => para.name === "EPIC passport")[0].value) > -1 ? 'waitTime' : 'adt';

    const { t } = useTranslation();

    const onPassportChange = (value:string) => {
        props.handleParamChange("EPIC passport", value);
        const pType = passports.findIndex((pass) => pass.id.value === value) > -1 ? 'Department Wait Time' : 'Bed Status';
        props.handleParamChange("Passport Type", pType);
    }

    return (
        <>
            <DropdownWidget
                options={passportsForDropdown}
                onChange={(value) => onPassportChange(value.id)}
                selectedValue={passportsForDropdown.filter((pass) => pass.id === props.params.filter((para) => para.name === "EPIC passport")[0].value)[0]}
                width={528}
                title={t(`Integrations.Epic.SelectPassport`)}
                placeholder={t('Integrations.Epic.Select')}
                disabled={passportsForDropdown.length === 0}
                showHelpInfo={false}
                helpInfo={t('Integrations.Epic.SourceForm.CreatePassportTooltip')}
                invalidState={!!props.requiredErrors["EPIC passport"]}
                invalidText={t('Integrations.Epic.SourceForm.SelectPassport')}
            />
            <Form.Group className="input-wrapper" controlId="formSourceName">
                <div className="text-char-wrapper">
                    <Form.Label className="heading1">{t("Integrations.Epic.SourceForm.GraphTitle")}
                        <HelpInfo className='powerBIHelpInfo' title={t("Integrations.Epic.GraphInfoText")} />
                    </Form.Label>
                    <span>{props.params.find((param) => param.name === "Graph title").value.length}/60</span>
                </div>
                <Form.Control
                    as="input"
                    name="Graph title"
                    placeholder={t("Integrations.Epic.SourceForm.TitlePlaceholder")}
                    onChange={(e) => props.handleParamChange(e.target.name, e.target.value)}
                    value={props.params.find((param) => param.name === "Graph title").value}
                    maxLength={60}
                />
                <Form.Control.Feedback type="invalid">{t("CommonUI.Sources.SourceNameRequired")}</Form.Control.Feedback>
            </Form.Group>
            <EpicNumberWidget
                label={t('Integrations.Epic.SourceForm.Datarefreshrate')}
                name="Data refresh rate"
                onChange={(e) => { props.handleParamChange(e.target.name, e.target.value) }}
                isInvalid={!!props.requiredErrors["Data refresh rate"]}
                value={props.params.filter((item) => item.name === "Data refresh rate")[0].value}
                error={t("Integrations.Epic.SourceForm.RefreshError")}
                helpInfo={t("Integrations.Epic.DataRefreshRateInfoText")}
                min={10}
            />
            {passportType === "waitTime" && <>

                <EpicNumberWidget
                    label={t('Integrations.Epic.SourceForm.Minutestolookback')}
                    name="Minutes to look back"
                    onChange={(e) => { props.handleParamChange(e.target.name, e.target.value) }}
                    isInvalid={!!props.requiredErrors["Minutes to look back"]}
                    value={props.params.filter((item) => item.name === "Minutes to look back")[0].value}
                    error={t("Integrations.Epic.SourceForm.MinutesError")}
                    min={1}
                    max={1440}
                    helpInfo={t("Integrations.Epic.MinutesToLookBackInfoText")}
                />
                <CheckboxWidget
                    name="Include longest current wait time"
                    label="Include longest current wait time"
                    onChange={(e) => { props.handleParamChange("Include longest current wait time", e.target.checked ? "true" : "false") }}
                    description={t("Integrations.Epic.SourceForm.CheckboxDescription")}
                    checked={props.params.filter((item) => item.name === "Include longest current wait time")[0].value}
                    helpInfo={t("Integrations.Epic.InclueLongestCurrentWaitTimeInfoText")}
                />
                <DepartmentIDType
                    formValue={props.params.filter((item) => item.name === "Departments")[0].value}
                    onChange={(value) => props.handleParamChange("Departments", value)}
                    helpInfo={t("Integrations.Epic.DepartmentID/TypeListInfoText")}
                    requiredErrors={props.requiredErrors}
                />
            </>}
            <DropdownWidget
                options={frameRateOptionsForEpicSourceForm}
                onChange={(value) => props.handleParamChange("framerate", value.id)}
                selectedValue={GetSelectedFramerate(props.params.filter((item) => item.name === "framerate")[0].value)}
                width={528}
                title={t(`Integrations.Epic.Framerate`)}
                placeholder={t('Integrations.Epic.Select')}
                helpInfo={t("Integrations.Epic.FrameRateInfoText")}
            />
        </>
    );
}
