import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IConstellationInfo } from "../../../../model/CarbonExternalModels";
import ConstellationDropdown from "../dropdown/ConstellationDropdown";
import "./ConstellationCard.scss";
import { useCarbonDispatch } from "../../../../store/carbonStore";
import { AppSliceActions } from "../../../../store/app/appSlice";
import { faSparkles } from "@fortawesome/pro-regular-svg-icons";


interface IProps {
    constellation: IConstellationInfo;
}

export default function ConstellationCard(props: IProps) {
    const dispatch = useCarbonDispatch();

    const filteredCLusterLength = props.constellation.cluster.filter((cluster) => {
        if(cluster.license.failOver){
            const foundCluster = cluster.license.clusterGroup.find((clusterGroup) => clusterGroup.id.value === cluster.id.value);

            if(foundCluster?.primary){
                return cluster;
            } else {
                return;
            }
        } else {
            return cluster;
        }
    }).length;

    return (
        <div
            className="constellationCard"
            onMouseUp={(e) => {
                if (e.button === 0) {
                    dispatch(AppSliceActions.setAppView({ type: "CONSTELLATION", id: props.constellation.definition.id }));
                }
            }}
        >
            <div style={{ position: "absolute", top: 8, right: 8 }} onMouseUp={(e) => e.stopPropagation()}>
                <ConstellationDropdown constellation={props.constellation} />
            </div>
            <FontAwesomeIcon icon={faSparkles} />
            <div className="constellationCardTextWrapper">
                <h1>{props.constellation.definition.name}</h1>
                <h2>{props.constellation.definition.description}</h2>
                <h3>
                    {filteredCLusterLength}
                    {filteredCLusterLength === 1 ? " Cluster" : " Clusters"}
                </h3>
            </div>
        </div>
    );
}
