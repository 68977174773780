import React, { useEffect, useState } from "react";
import { MixerMapperComp } from "userful-chronos-app-common-js/dist/models/mapping/MappingGroups";
import { useTranslation } from "react-i18next";
import { Offcanvas } from "react-bootstrap";
import "./MapperHLSStreamForm.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignalStream } from "@fortawesome/pro-regular-svg-icons";
import MapperHLSStreamForm from "./MapperHLSStreamForm";
import UserfulTooltip from "../Widgets/UserfulTooltip";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { APP_IDS } from "userful-chronos-app-common-js/dist/models/common";
import { getHLSStreamDataForMapper } from "userful-chronos-app-common-js/dist/models/mapping/Streams";
import DropdownItemUI from "../Widgets/Dropdown/DropdownItemUI";

interface IProps {
    mapper: MixerMapperComp;
    iconOnly?: boolean;
    disabled?: boolean;
    newIcon?: boolean;
    unifyCardStreaming?: boolean;
    show?: boolean;
    setShow?: Function;
    actionItem?: boolean;
}

const ALLOWED_APPS = [APP_IDS.Artistic, APP_IDS.Decisions, APP_IDS.Trends];

export default function MapperHLSStreamDialog(props: IProps) {
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show || false);
    const stream = getHLSStreamDataForMapper(props.mapper);

    const handleClose = (e?: React.MouseEvent) => {
        e?.stopPropagation();
        props.setShow && props.setShow(false);
        !props.setShow && setShow(false);
    };
    const handleShow = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (!props.disabled) {
            setShow(true);
        }
    };
    if (!ALLOWED_APPS.includes(getGlobalStates().appID.value)) {
        return null;
    }

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);

    // tooltips are added separately to all components because they only render if the parent component is div tag, so <></> was meesing the tooltips
    return (
        <>
            {!props.unifyCardStreaming && !props.actionItem && (
                <UserfulTooltip
                    title={
                        !!stream
                            ? t("CommonUI.Stream.titleStreaming")
                            : props.disabled
                                ? t("CommonUI.Stream.disable")
                                : t("CommonUI.Stream.title")
                    }
                    placement="bottom"
                >
                    <div
                        className={`hlsStreamLink ${props.disabled ? "hlsStreamLinkDisabled" : ""}`}
                        onClick={handleShow}
                    >
                        <FontAwesomeIcon icon={faSignalStream} className="fontAwesomeCustomIcon" />
                        {!props.iconOnly &&
                            (!!stream ? t("CommonUI.Stream.titleStreaming") : t("CommonUI.Stream.title"))}
                    </div>
                </UserfulTooltip>
            )}

            {/** this is to display the streaming icon in Unify Card */}
            {props.unifyCardStreaming && !!stream && (
                <UserfulTooltip title={t("CommonUI.Stream.HLSStreamEnabled")}>
                    <div className="unifyCardStreaming">
                        <FontAwesomeIcon icon={faSignalStream} className="unifyCardIcon" />
                    </div>
                </UserfulTooltip>
            )}

            {props.actionItem && (
                <UserfulTooltip
                    title={
                        !!stream
                            ? t("CommonUI.Stream.titleStreaming")
                            : props.disabled
                                ? t("CommonUI.Stream.disable")
                                : t("CommonUI.Stream.title")
                    }
                    placement="bottom"
                >
                    <DropdownItemUI
                        disabled={props.disabled}
                        text={!!stream ? t("CommonUI.Stream.titleStreaming") : t("CommonUI.Stream.title")}
                        icon={faSignalStream}
                    />
                </UserfulTooltip>
            )}

            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Body>
                    <div className="overlay-main-container">
                        {show && <MapperHLSStreamForm mapper={props.mapper} handleClose={handleClose} />}
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}
