import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CdmPhysicalEndPointData, CdmVideoWallEndPointData } from 'userful-chronos-app-common-js/dist/models/cdm/CdmEndPoints';
import { addOrUpdatePhysicalEndpointsReducer, addOrUpdateReducer, addOrUpdateVideoWall, removePhysicalEndpointsReducer, removeReducer, removeVideoWall } from './CdmStoreUtils';




const initialState: {
    displayEndPoints: CdmPhysicalEndPointData[], // standalone displays
    videowallEndPoints: CdmVideoWallEndPointData[],
} = {
    displayEndPoints: [], // standalone displays
    videowallEndPoints: [],
};

export const endpointsSlice = createSlice({
    name: 'endpointsSlice',
    initialState,
    reducers: {
        setDisplayEndPoints: (state, action: PayloadAction<CdmPhysicalEndPointData[]>) => {
            state.displayEndPoints = [...action.payload];
        },
        addOrUpdateDisplayEndPoint: (state, action: PayloadAction<CdmPhysicalEndPointData>) => {
            addOrUpdatePhysicalEndpointsReducer(state, action.payload, 'displayEndPoints');
        },
        removeDisplayEndPoint: (state, action: PayloadAction<CdmPhysicalEndPointData>) => {
            removePhysicalEndpointsReducer(state, action.payload, 'displayEndPoints');
        },

        setVideoWallEndPoints: (state, action: PayloadAction<CdmVideoWallEndPointData[]>) => {
            state.videowallEndPoints = [...action.payload];
        },
        addOrUpdateVideoWallEndPoint: (state, action: PayloadAction<CdmVideoWallEndPointData>) => {
            addOrUpdateVideoWall(state, action.payload);
        },
        removeVideoWallEndPoint: (state, action: PayloadAction<CdmVideoWallEndPointData>) => {
            removeVideoWall(state, action.payload, 'videowallEndPoints');
        },
    },
})

export const endpointsSliceActions = endpointsSlice.actions;

export default endpointsSlice.reducer