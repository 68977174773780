import { faGear, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Dropdown, Offcanvas } from "react-bootstrap";
import { Button, Input } from "userful-chronos-common-ui/dist";
// import { ClusterRemoteManagement, IClusterInfo } from "../../../../model/CarbonExternalModels";
import { TAppStatus } from "../../../../store/app/appUtils";
import { apiGetClusterRemoteManagement, apiPostUpdateClusterRemoteManagement } from '../../../../messages/api/gcmAPICallers';
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates";
import { ClusterRemoteManagement } from "../../../../model/CarbonExternalModels";

interface IProps {
    clusterID: StringID;
    constellationID: StringID;

}

export default function RemoteManagementSetup(props: IProps) {

    const [showForm, setShowForm] = useState(false);

    const [publicIp, setPublicIp] = useState("");
    const [port, setPort] = useState(-1);
    const [status, setStatus] = useState<TAppStatus>("IDLE");

    const [publicIpError, setPublicIpError] = useState("");
    const [portError, setPortError] = useState("");

    const onGetMessage = async () => {
        setStatus("LOADING");
        const data = await apiGetClusterRemoteManagement({ clusterID: props.clusterID, constellationID: props.constellationID }, getGlobalStates().keycloak.token);

        setPublicIp((data as ClusterRemoteManagement)?.address || "");
        setPort((data as ClusterRemoteManagement)?.port || -1);
        setStatus("IDLE");
    }

    const onPostMessage = async () => {
        setStatus("LOADING");
        const data = await apiPostUpdateClusterRemoteManagement({ id: props.clusterID, constellationID: props.constellationID, address: publicIp, port: port }, getGlobalStates().keycloak.token);
        setStatus("IDLE");
    }

    useEffect(() => {
        if (showForm) {
            onGetMessage();
        }
    }, [showForm]);

    const handlePublicIpChange = (e) => {
        const input = e.target.value;

        // Regular expression for basic IPv4 format
        const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

        // Regular expression for basic URL format
        const urlRegex = /^(https?:\/\/)?((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})|(localhost))(:\d+)?(\/.*)?$/;

        if (ipRegex.test(input) || urlRegex.test(input)) {
            setPublicIpError(""); // Clear error if input is valid
        } else {
            setPublicIpError("Invalid IP address or URL format");
        }

        setPublicIp(input);
    };

    const handlePortChange = (e) => {
        const port = e.target.value;

        const portNumber = parseInt(port, 10);
        if (!isNaN(portNumber) && portNumber > 0 && portNumber <= 65535) {
            setPortError("");
        } else {
            setPortError("Invalid port number. Must be between 1 and 65535");
        }

        setPort(port);
    };

    const onSave = async () => {
        await onPostMessage();
        onRestore();
    }
    const onCancel = () => {
        onRestore();
    }

    const onRestore = () => {
        setPublicIpError("");
        setPortError("");
    }

    const canSaveForm = publicIp.length > 0 && port > 0 && publicIpError.length === 0 && portError.length === 0;

    return <>
        <Dropdown.Item
            className="dropdownItem"
            onClick={(e) => { setShowForm(true) }}
        >
            <FontAwesomeIcon icon={faGear} />
            <div className="textInside">Remote Management Setup</div>
        </Dropdown.Item>

        <Offcanvas show={showForm} onHide={onCancel} placement="end">
            <div className="userfulFormBody">
                <div className="userfulFormHeader">
                    <div className="userfulFormRow">
                        <h1 className="userfulFormHeaderTitle">Remote Management Setup</h1>
                    </div>
                    <div className="userfulFormRow">
                        <h3 className="userfulFormHeaderDescription">
                            To connect this local system to an external network, please enter the following details.
                        </h3>
                    </div>
                </div>
                <div className="userfulFormContents">
                    <div className="userfulFormColumn userfulFormSmallGap">
                        <h4 className="userfulFormContentsTitle">Network Information</h4>
                        <h5 className="userfulFormContentsDescription">Provide the public IP address/URL and port number of the network you want to connect to.</h5>
                    </div>
                    <div className="userfulFormColumn userfulFormNormalGap">
                        <Input type="text" placeholder="Enter IP Address here..." value={publicIp} onChange={handlePublicIpChange}
                            filedTitle={<p>Public IP Address/URL</p>} postChildren={<p>{publicIpError}</p>} error={publicIpError.length > 0} />
                    </div>
                    <div className="userfulFormColumn userfulFormNormalGap">
                        <Input type="text" placeholder="Enter port number here..." value={port} onChange={handlePortChange}
                            filedTitle={<p>Port Number</p>} postChildren={<p>{portError}</p>} error={portError.length > 0} />
                    </div>
                </div>
            </div>
            <div className="userfulFormFooter">
                {/* <Button variant="secondary" onClick={(e) => { onCancel() }}>Cancel</Button> */}
                <Button variant="secondary" onClick={(e) => { onCancel() }}>Cancel</Button>
                <Button variant="primary" onClick={(e) => { onSave() }} disabled={!canSaveForm || status === "LOADING"}>
                    {status === "LOADING" && <div className="spinner" />}
                    Save
                </Button>
            </div>
        </Offcanvas>
    </>
}