"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RESPONSE_LIST_ONBOARDS_FOR_VE = exports.RESPONSE_LIST_ONBOARDS = exports.REQUEST_LIST_ONBOARDS_FOR_VE = exports.REQUEST_LIST_ONBOARDS = exports.NOTIFY_ONBOARD_UPDATED = exports.NOTIFY_ONBOARD_REMOVED = exports.NOTIFY_ONBOARD_ADDED = void 0;
var _messageModel = require("../../messageModel");
var ONBOARD = 3021;
var REQUEST_LIST_ONBOARDS = exports.REQUEST_LIST_ONBOARDS = new _messageModel.RequestMessageDefinition(ONBOARD, 0);
var REQUEST_LIST_ONBOARDS_FOR_VE = exports.REQUEST_LIST_ONBOARDS_FOR_VE = new _messageModel.RequestMessageDefinition(ONBOARD, 1);
var RESPONSE_LIST_ONBOARDS = exports.RESPONSE_LIST_ONBOARDS = new _messageModel.RespondMessageDefinition(ONBOARD, 1);
var RESPONSE_LIST_ONBOARDS_FOR_VE = exports.RESPONSE_LIST_ONBOARDS_FOR_VE = new _messageModel.RespondMessageDefinition(ONBOARD, 2);
var NOTIFY_ONBOARD_ADDED = exports.NOTIFY_ONBOARD_ADDED = new _messageModel.RespondMessageDefinition(ONBOARD, 3);
var NOTIFY_ONBOARD_UPDATED = exports.NOTIFY_ONBOARD_UPDATED = new _messageModel.RespondMessageDefinition(ONBOARD, 4);
var NOTIFY_ONBOARD_REMOVED = exports.NOTIFY_ONBOARD_REMOVED = new _messageModel.RespondMessageDefinition(ONBOARD, 5);