import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates"
import { REQUEST_LIST_VIDEO_ENGINES, REQUEST_UPDATE_GPU } from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/ve/VEMsgDefinations";
import { GPU } from "userful-chronos-app-common-js/dist/models/ve/videoEngines";
// 
export const requestListVideoEngines = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_VIDEO_ENGINES);
}

export const requestUpdateGPU = (gpu: GPU) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_GPU, gpu);
}