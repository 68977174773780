import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StringID } from 'userful-chronos-app-common-js/dist/models/common';
import { Resolution } from 'userful-chronos-app-common-js/dist/models/display';
import { ZeroClientData } from 'userful-chronos-app-common-js/dist/models/zeroClient';
import { displayHasSameIDOrVEAsUpdates, expectZCStatus, getVEIDForClient } from './displayDispatchUtils';
import { sortDisplays } from './displaysUtils';
import { requestUpdateNetworkZC, requestLockZC, requestResetZC, requestUnlockZC, requestUpdateZCNameAndLocation, requestUpdateZCResolution } from './msgs/ZeroClientsMsgSender';



let updating: { [key: string]: Array<Function>; } = {};

const setDisplayToUpdating = (state, index: number) => {
    state.displays = [
        ...state.displays.slice(0, index),
        { ...state.displays[index], updating: true },
        ...state.displays.slice(index + 1),
    ]
}

const setDisplayToNotUpdating = (state, index: number) => {
    state.displays = [
        ...state.displays.slice(0, index),
        { ...state.displays[index], updating: false },
        ...state.displays.slice(index + 1),
    ]
}

const initialState: {
    displays: Array<{ data: ZeroClientData, updating: boolean }>;
    ready: boolean;
    selectedDisplays: StringID[];
} = {
    displays: [],
    selectedDisplays: [],
    ready: false,
};
export const zeroclientsSlice = createSlice({
    name: 'zeroclientsSlice',
    initialState,
    reducers: {
        setDisplays: (state, action: PayloadAction<Array<ZeroClientData>>) => {
            if (state.displays.length > 0) {
                return;
            }
            state.displays = sortDisplays(action.payload.map(item => ({ data: item, updating: false })));
            state.ready = true;
            updating = {};
        },
        setSelectedDisplays: (state, action: PayloadAction<Array<StringID>>) => {
            state.selectedDisplays = [...action.payload];
        },
        setDisplaysForVE: (state, action: PayloadAction<Array<ZeroClientData>>) => {
            if (state.displays.length > 0) {
                return;
            }
            const filteredDisplays = state.displays.filter(display => !displayHasSameIDOrVEAsUpdates(display.data, action.payload));
            state.displays = sortDisplays([...filteredDisplays, ...action.payload.map(item => ({ data: item, updating: false }))]);
            updating = {};
            state.ready = true;
        },

        addOrUpdateDisplays: (state, action: PayloadAction<ZeroClientData>) => {
            const updateIDValue = action.payload.id.value;
            const existingIndex = state.displays.findIndex(item => item.data.id.value === updateIDValue);
            if (existingIndex >= 0) {
                // handle updating status
                if (updating[updateIDValue]
                    && updating[updateIDValue][0](action.payload)) {
                    if (updating[updateIDValue].length > 1) {
                        updating[updateIDValue] = updating[updateIDValue].slice(1);
                    } else {
                        delete updating[updateIDValue];
                    }
                }

                state.displays = [
                    ...state.displays.slice(0, existingIndex),
                    { data: action.payload, updating: updating.hasOwnProperty(updateIDValue) },
                    ...state.displays.slice(existingIndex + 1),
                ];
            } else {
                state.displays = sortDisplays([
                    ...state.displays,
                    { data: action.payload, updating: false, },
                ]);
            }
        },

        deleteDisplay: (state, action: PayloadAction<StringID>) => {
            state.displays = state.displays.filter(item => item.data.id.value !== action.payload.value);
            delete updating[action.payload.value];
        },

        requestResetDisplay: (state, action: PayloadAction<StringID>) => {
            const existingIndex = state.displays.findIndex(item => item.data.id.value === action.payload.value);
            if (existingIndex >= 0) {
                const connected = state.displays[existingIndex].data.lockStatus === 'LOCKED';
                updating[action.payload.value] = connected ?
                    expectZCStatus(['LOCKED_NOT_CONNECTED'], ['CONNECTED']) : expectZCStatus(['NO_DEVICE'], ['FREE', 'ORPHAN']);
                setDisplayToUpdating(state, existingIndex)
                requestResetZC(action.payload);
            }
        },

        requestLockDisplay: (state, action: PayloadAction<StringID>) => {
            const existingIndex = state.displays.findIndex(item => item.data.id.value === action.payload.value);
            if (existingIndex >= 0) {
                updating[action.payload.value] = expectZCStatus(['CONNECTED']);
                setDisplayToUpdating(state, existingIndex)
                requestLockZC(action.payload, getVEIDForClient(state.displays[existingIndex].data));
            }
        },

        requestUpdateDisplayResolution: (state, action: PayloadAction<{
            id: StringID,
            resolution: Resolution,
        }>) => {
            requestUpdateZCResolution(action.payload.id, action.payload.resolution);
        },

        requestUnlockDisplay: (state, action: PayloadAction<StringID>) => {
            const existingIndex = state.displays.findIndex(item => item.data.id.value === action.payload.value);
            if (existingIndex >= 0) {
                updating[action.payload.value] = expectZCStatus(['FREE']);
                setDisplayToUpdating(state, existingIndex)
                requestUnlockZC(action.payload, getVEIDForClient(state.displays[existingIndex].data));
            }
        },

        requestUnsetDisplay: (state, action: PayloadAction<StringID>) => {
            const existingIndex = state.displays.findIndex(item => item.data.id.value === action.payload.value);
            if (existingIndex >= 0) {

                delete updating[action.payload.value];
                setDisplayToNotUpdating(state, existingIndex);

            }
        },

        requestUpdateNameAndLocation: (state, action: PayloadAction<{ id: StringID, name: string, location: string }>) => {
            const existingIndex = state.displays.findIndex(item => item.data.id.value === action.payload.id.value);
            if (existingIndex >= 0) {
                const connected = state.displays[existingIndex].data.lockStatus === 'LOCKED';
                if (connected) {
                    updating[action.payload.id.value] = [
                        ...expectZCStatus(['FREE']),
                        ((zcData: ZeroClientData) => zcData.name === action.payload.name && zcData.location === action.payload.location),
                        ...expectZCStatus(['CONNECTED']),
                    ];

                } else {
                    updating[action.payload.id.value] = [((zcData: ZeroClientData) => zcData.name === action.payload.name && zcData.location === action.payload.location)];
                }
                setDisplayToUpdating(state, existingIndex)
                requestUpdateZCNameAndLocation(action.payload.id, action.payload.name, action.payload.location);
            }
        },

        requestNetworkSettings: (state, action: PayloadAction<{ id: StringID, dhcpEnabled: boolean, ipAddress: string, mask: string }>) => {

            const existingIndex = state.displays.findIndex(item => item.data.id.value === action.payload.id.value);
            if (existingIndex >= 0) {
                const connected = state.displays[existingIndex].data.lockStatus === 'LOCKED';
                if (!action.payload.dhcpEnabled) {
                    if (connected) {

                        updating[action.payload.id.value] = [
                            ...expectZCStatus(['FREE']),
                            ((zcData: ZeroClientData) => zcData.transientData.usbcData.networking.dhcpEnabled === action.payload.dhcpEnabled && zcData.transientData.usbcData.networking.ipAddress.value === action.payload.ipAddress && zcData.transientData.usbcData.networking.mask.value === action.payload.mask),
                            ...expectZCStatus(['CONNECTED']),
                        ];

                    } else {
                        updating[action.payload.id.value] = [((zcData: ZeroClientData) => zcData.transientData.usbcData.networking.dhcpEnabled === action.payload.dhcpEnabled && zcData.transientData.usbcData.networking.ipAddress.value === action.payload.ipAddress && zcData.transientData.usbcData.networking.mask.value === action.payload.mask)];
                    }
                }

                else {

                    if (connected) {


                        updating[action.payload.id.value] = [
                            ...expectZCStatus(['FREE']),
                            ((zcData: ZeroClientData) => zcData.transientData.usbcData.networking.dhcpEnabled === action.payload.dhcpEnabled),
                            ...expectZCStatus(['CONNECTED']),
                        ];

                    } else {
                        updating[action.payload.id.value] = [((zcData: ZeroClientData) => zcData.transientData.usbcData.networking.dhcpEnabled === action.payload.dhcpEnabled)];
                    }

                }

                setDisplayToUpdating(state, existingIndex)
                requestUpdateNetworkZC(action.payload.id, action.payload.dhcpEnabled, action.payload.ipAddress, action.payload.mask);
            }


        }
    },
})

export const zeroclientsSliceActions = zeroclientsSlice.actions;

export default zeroclientsSlice.reducer