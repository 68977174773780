"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sumStorage = exports.sumMemoryValues = exports.sumDevice = exports.getMemoryValueInUnit = exports.getHumanizedMemoryValue = exports.INTERNAL_STORAGE_DEVICE = void 0;
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var memoryUnitScale = {
  'B': 1,
  'KB': 1024,
  'MB': 1024 * 1024,
  'GB': 1024 * 1024 * 1024
};
var getMemoryValueInUnit = exports.getMemoryValueInUnit = function getMemoryValueInUnit(data, unit, decimal) {
  if (unit === 'B') {
    if (!decimal) {
      return Number(data.stringValue);
    }
    var _rounding = Math.pow(10, decimal || 1);
    return Math.round(Number(data.stringValue) * _rounding) / _rounding;
  }
  var targetUnitScale = memoryUnitScale[unit] || 1;
  var rounding = Math.pow(10, decimal || 1);
  return Math.round(Number(data.stringValue) / targetUnitScale * rounding) / rounding;
};
var getHumanizedMemoryValue = exports.getHumanizedMemoryValue = function getHumanizedMemoryValue(data, decimal) {
  var bytes = getMemoryValueInUnit(data, 'B', 1);
  if (bytes < memoryUnitScale['KB']) {
    return "".concat(bytes, " B");
  }
  if (bytes < memoryUnitScale['MB']) {
    return "".concat(getMemoryValueInUnit(data, 'KB', decimal), " KB");
  }
  if (bytes < memoryUnitScale['GB']) {
    return "".concat(getMemoryValueInUnit(data, 'MB', decimal), " MB");
  }
  return "".concat(getMemoryValueInUnit(data, 'GB', decimal), " GB");
};
var sumMemoryValues = exports.sumMemoryValues = function sumMemoryValues(data) {
  return {
    stringValue: "".concat(data.reduce(function (acc, curr) {
      return acc + getMemoryValueInUnit(curr, 'B');
    }, 0))
  };
};
var sumStorage = exports.sumStorage = function sumStorage(data) {
  if (!data) {
    return null;
  }
  var total = {
    name: data.id.value,
    free: sumMemoryValues(data.storageDevices.map(function (s) {
      return s.free;
    })),
    total: sumMemoryValues(data.storageDevices.map(function (s) {
      return s.total;
    })),
    used: sumMemoryValues(data.storageDevices.map(function (s) {
      return s.used;
    }))
  };
  return _objectSpread(_objectSpread({}, total), {}, {
    usedPct: Number(total.used.stringValue) / Number(total.total.stringValue),
    freePct: Number(total.free.stringValue) / Number(total.total.stringValue)
  });
};
var sumDevice = exports.sumDevice = function sumDevice(data) {
  if (!data) {
    return null;
  }
  var total = {
    name: data.name,
    free: sumMemoryValues([data.free]),
    total: sumMemoryValues([data.total]),
    used: sumMemoryValues([data.used])
  };
  return _objectSpread(_objectSpread({}, total), {}, {
    usedPct: Number(total.used.stringValue) / Number(total.total.stringValue),
    freePct: Number(total.free.stringValue) / Number(total.total.stringValue)
  });
};
var INTERNAL_STORAGE_DEVICE = exports.INTERNAL_STORAGE_DEVICE = "internal";