"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isUclientVersionUpdateToDate = exports.isUClientOrphaned = exports.isUClientOffline = exports.isUClientLockedByOtherHost = exports.isUClientLocked = exports.isUClientFree = exports.isNewUclient = exports.isNewUClient = exports.initUClientAcion = exports.getUClientType = exports.getUClientStatus = exports.getUClientName = exports.getAppVersion = exports.doesUClientNeedFirmwareUpdate = exports.canLockUClient = exports.UCLIENT_REQUIRED_VERSION = void 0;
var _lt = _interopRequireDefault(require("semver/functions/lt"));
var _eq = _interopRequireDefault(require("semver/functions/eq"));
var _uuid = require("uuid");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
var UCLIENT_REQUIRED_VERSION = exports.UCLIENT_REQUIRED_VERSION = '2.2.6';
var isNewUClient = exports.isNewUClient = function isNewUClient(uClient) {
  return uClient.id.value.split('-')[2] === 'uclient2';
};
var isUclientVersionUpdateToDate = exports.isUclientVersionUpdateToDate = function isUclientVersionUpdateToDate(currentVersion, requiredVersion) {
  if (!currentVersion) {
    return true;
  }
  var realVersion = typeof currentVersion === 'string' || currentVersion instanceof String ? currentVersion : currentVersion.version;
  var currentSemverVersion = realVersion.split(".").slice(0, 3).join(".");
  // console.log("currentVersion: " + currentVersion + " currentSemverVersion:" + currentSemverVersion);
  requiredVersion = requiredVersion ? requiredVersion : UCLIENT_REQUIRED_VERSION;
  var requiredSemverVersion = requiredVersion.split(".").slice(0, 3).join(".");
  // console.log("requiredVersion: " + requiredVersion + " requiredSemverVersion:" + requiredSemverVersion);

  var isUClientNeedUpdate = (0, _lt["default"])(currentSemverVersion, requiredSemverVersion) || (0, _eq["default"])(currentSemverVersion, requiredSemverVersion) && realVersion !== requiredVersion;
  return !isUClientNeedUpdate;
};
function findFirmware(model, firmwareMapping) {
  if (firmwareMapping) {
    for (var configModel in firmwareMapping) {
      if (model.toUpperCase().indexOf(configModel.toUpperCase()) >= 0) {
        return firmwareMapping[configModel];
      }
    }
  }
  return null;
}
var isNewUclient = exports.isNewUclient = function isNewUclient(uClient) {
  return uClient.id.value.split('-')[2] === "uclient2";
};
var getAppVersion = exports.getAppVersion = function getAppVersion(uClient, appVersion) {
  if (isNewUClient(uClient)) return appVersion.uClient2Version;
  return appVersion.uClient1Version;
};
var doesUClientNeedFirmwareUpdate = exports.doesUClientNeedFirmwareUpdate = function doesUClientNeedFirmwareUpdate(uClientFirmwareConfig, uClient) {
  var firmwareMapping = null;
  if (uClient.id.value.startsWith("seat-userful-uclient-webos") && uClientFirmwareConfig !== null && uClientFirmwareConfig !== void 0 && uClientFirmwareConfig.uClient1Config) {
    firmwareMapping = uClientFirmwareConfig.uClient1Config.webOSFirmwareMapping;
  } else if (uClient.id.value.startsWith("seat-userful-uclient-android") && uClientFirmwareConfig !== null && uClientFirmwareConfig !== void 0 && uClientFirmwareConfig.uClient1Config) {
    firmwareMapping = uClientFirmwareConfig.uClient1Config.androidFirmwareMapping;
  }
  if (uClient.id.value.startsWith("seat-userful-uclient2-webos") && uClientFirmwareConfig !== null && uClientFirmwareConfig !== void 0 && uClientFirmwareConfig.uClient2Config) {
    firmwareMapping = uClientFirmwareConfig.uClient2Config.webOSFirmwareMapping;
  } else if (uClient.id.value.startsWith("seat-userful-uclient2-android") && uClientFirmwareConfig !== null && uClientFirmwareConfig !== void 0 && uClientFirmwareConfig.uClient2Config) {
    firmwareMapping = uClientFirmwareConfig.uClient2Config.androidFirmwareMapping;
  }
  if (firmwareMapping) {
    var modelName = uClient.transientData.hardwareData.modelName;
    // console.log("needFirmwareUpdate - modelName=" + modelName); 
    var requiredFirmware = findFirmware(modelName, firmwareMapping);
    // console.log("needFirmwareUpdate - firmwareMapping=", modelName);
    if (modelName && requiredFirmware) {
      var currentFirmware = uClient.transientData.hardwareData.firmwareVersion;
      // For testing.
      //const currentFirmware = "0.0.1";
      // console.log("needFirmwareUpdate - requiredFirmware=" + requiredFirmware + " currentFirmware=" + currentFirmware);
      if (requiredFirmware !== currentFirmware) {
        return {
          currentFirmware: currentFirmware,
          requiredFirmware: requiredFirmware,
          requireUpdate: true
        };
      }
    }
  }
  return {
    currentFirmware: "",
    requiredFirmware: "",
    requireUpdate: false
  };
};
var isUClientOffline = exports.isUClientOffline = function isUClientOffline(uc) {
  if (!uc.transientData) {
    return true;
  }
  var status = uc.transientData.connectionStatus.connectionStatus;
  return status === 'OFFLINE';
};
var canLockUClient = exports.canLockUClient = function canLockUClient(uc) {
  if (!uc.transientData) {
    return false;
  }
  var status = uc.transientData.connectionStatus.connectionStatus;
  return status === 'FREE' || status === 'ORPHAN';
};
var isUClientOrphaned = exports.isUClientOrphaned = function isUClientOrphaned(uc) {
  if (!uc.transientData) {
    return false;
  }
  var status = uc.transientData.connectionStatus.connectionStatus;
  return status === 'ORPHAN';
};
var isUClientLocked = exports.isUClientLocked = function isUClientLocked(uc) {
  if (!uc.transientData) {
    return false;
  }
  var status = uc.transientData.connectionStatus.connectionStatus;
  return status === 'CONNECTED';
};
var isUClientLockedByOtherHost = exports.isUClientLockedByOtherHost = function isUClientLockedByOtherHost(uc) {
  if (isUClientOffline(uc)) {
    return true;
  }
  if (!uc.transientData) {
    return false;
  }
  var status = uc.transientData.connectionStatus.connectionStatus;
  return status === 'OCCUPY';
};
var isUClientFree = exports.isUClientFree = function isUClientFree(uc) {
  if (!uc.transientData) {
    return false;
  }
  var status = uc.transientData.connectionStatus.connectionStatus;
  return status === 'FREE';
};
var getUClientStatus = exports.getUClientStatus = function getUClientStatus(uc) {
  if (isUClientOrphaned(uc)) return 'ORPHAN';
  if (isUClientFree(uc)) return 'FREE';
  if (isUClientLocked(uc)) return 'CONNECTED';
  if (isUClientOffline(uc)) return 'OFFLINE';
  return 'UNKNOWN';
};
var getUClientType = exports.getUClientType = function getUClientType(uc) {
  if (uc.id.value.includes("-tizen")) {
    return "tizen";
  }
  if (uc.id.value.includes("-webos")) {
    return "webos";
  }
  return "android";
};
var getUClientName = exports.getUClientName = function getUClientName(uc) {
  return ["", null].includes(uc.transientData.name) ? "".concat(uc.transientData.hardwareData.manufacturer).concat(["", null].includes(uc.transientData.hardwareData.manufacturer) ? "uClient-" : "-").concat(uc.id.value.split("-")[4]) : uc.transientData.name;
};
var initUClientAcion = exports.initUClientAcion = function initUClientAcion() {
  return {
    id: {
      value: (0, _uuid.v4)()
    },
    name: 'UClient Action',
    description: 'UClient Action',
    displays: [],
    timeZone: {
      value: Intl.DateTimeFormat().resolvedOptions().timeZone
    },
    type: 'DISPLAY_OFF'
  };
};