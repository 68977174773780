import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Offcanvas, Tab, Tabs } from "react-bootstrap";
import { UClientProvisioningType, isUClientLocked, isUClientLockedByOtherHost, isUClientOffline } from "userful-chronos-app-common-js/dist/models/uclient";
import { uclientsSliceActions, useDisplaysDispatch, useDisplaysSelector } from 'userful-chronos-common-store/dist/destinations';
import EditStationIDForm from "./editStationIDForm";
import UClientDetailsForm from "./uClientDetailsForm";
import UClientDetailsFormSubmitButtons from "./uClientDetailsFormSubmitButtons";
import UClientNetworkCategory from "./uClientNetworkCategory";
import { DEFAULT_RESOLUTION, prettyPrintStationLabel, Resolution } from 'userful-chronos-app-common-js/dist/models/display';
import { requestUClientUpdateStationID } from "userful-chronos-app-common-js/dist/message/commonMsgSenders/displayMsgSender";
import { isIPAddress } from "ip-address-validator";
import shallowequal from "shallowequal";
import { useTranslation } from "react-i18next";
import EditResolutionForm from "../../EditResolutionForm";
import { UClientSettings } from "userful-chronos-app-common-js/dist/models/uclient-setting";
import React from 'react';
import Switch from "react-switch";
import SettingHeaderDes from "./SettingHeaderDes";
import EditTimeZoneForm from "./EditTimeZoneForm";
import UClientStorageManagement from "../storage/UClientStorageManagement";
import UClientGiadaConfig from "../giada/UClientGiadaConfig";
import CustomSwitch from "../../../CustomSwitch/CustomSwitch";

interface IProps {

};

interface IInput {
    name: string,
    stationLabel: string,
    location: string,
    ipAddress: string,
    maskAddress: string,
    dhcpEnabled: boolean,
    macAddress: string,
    seatID: string,
    lockTaskMode: boolean,
    modelName: string,
    timeZone: string,
    firmwareVersion: string,
    provitioningType: UClientProvisioningType,
}

const DEFAULT_INPUT: IInput = {
    name: '',
    stationLabel: "",
    location: '',
    ipAddress: "",
    maskAddress: "",
    dhcpEnabled: false,
    macAddress: "",
    seatID: "",
    lockTaskMode: true,
    modelName: "",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    firmwareVersion: "",
    provitioningType: 'MAPPING',
}

export const TABS = {
    uclientSettings: "uclientSettings",
    storage: "storage",
    giada: "giada",
}

export default function UClientSettingForm(props: IProps) {
    const { t } = useTranslation();

    const dispatch = useDisplaysDispatch();
    const allUClients = useDisplaysSelector(state => state.uclientsStore.displays);
    const uClientsTableData = allUClients.filter(({ data }) =>
        !isUClientLockedByOtherHost(data) &&
        !isUClientOffline(data) &&
        isUClientLocked(data));

    const [orgChanged, setOrgChanged] = useState(false);
    const [discardState, setDiscardState] = useState(false);
    const orgRef = useRef<any>();

    const currentUClientID = useDisplaysSelector(state => state.uclientsStore.currentData);
    const uClient = currentUClientID && uClientsTableData.find(d => d.data.id.value === currentUClientID.value);
    const uClientData = uClient?.data;
    const storageData = uClient?.storage;

    const storedObj = useRef<IInput>(DEFAULT_INPUT);
    const [input, setInput] = useState<IInput>(DEFAULT_INPUT);
    const [selectedResolution, setSelectedResolution] = useState<Resolution>(DEFAULT_RESOLUTION);
    const [activeTab, setActiveTab] = useState(TABS.uclientSettings)

    useEffect(() => {
        if (currentUClientID) {
            const uClient = currentUClientID && uClientsTableData.find(d => d.data.id.value === currentUClientID.value);
            const uClientData = uClient?.data;
            if (uClientData) {
                const id = uClientData.transientData.id;
                const transientData = uClientData.transientData;
                storedObj.current = {
                    name: transientData.name ? transientData.name : "",
                    stationLabel: prettyPrintStationLabel(uClientData?.stationLabel),
                    location: transientData.location ? transientData.location : "",
                    ipAddress: transientData.networkData.addressInfo.ipAddress.value,
                    maskAddress: transientData.networkData.addressInfo.mask.value,
                    dhcpEnabled: transientData.networkData.addressInfo.dhcpEnabled,
                    macAddress: transientData.networkData.macAddress.value,
                    lockTaskMode: uClientData.lockTaskMode,
                    seatID: id.value,
                    modelName: transientData.hardwareData ? transientData.hardwareData.manufacturer + " " + transientData.hardwareData.modelName : "",
                    timeZone: uClientData.timeZone?.value || Intl.DateTimeFormat().resolvedOptions().timeZone,
                    firmwareVersion: transientData.hardwareData.firmwareVersion,
                    provitioningType: uClientData.provisioningType,
                }
                setSelectedResolution(uClientData.currentResolution);
                setInput({
                    ...storedObj.current,
                });
                setOrgChanged(false)
            }
        }
    }, [currentUClientID])

    if (!uClientData) {
        return null;
    }

    const id = uClientData.transientData.id;
    function handleClose() {
        dispatch(uclientsSliceActions.setCurrentData(null));
    }

    const checkInvalidState = (value) => {
        let invalidStateInitial = false;
        if (value === "") {
            invalidStateInitial = true;
        }
        uClientsTableData.forEach(display => {
            const stationLabel = prettyPrintStationLabel(display.data.stationLabel);
            if (display.data.id.value !== id.value) {
                if (stationLabel === value) {
                    invalidStateInitial = true;
                }
            }
        })

        return invalidStateInitial;
    }

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInput({
            ...input,
            [e.target.name]: value,
        });
    };

    const onChangeDHCP = (e) => {
        const value = e.target.checked;
        if (value) {
            if (e.target.name === "primary") {
                setInput({
                    ...input,
                    dhcpEnabled: true
                });
            }
            else {
                setInput({
                    ...input,
                    dhcpEnabled: false
                });
            }
        }
    }

    const setTimeZone = (value: string) => {
        setInput({
            ...input,
            timeZone: value,
        });
    }

    const onToggleLockTaskMode = (e) => {
        setInput({
            ...input,
            lockTaskMode: input.lockTaskMode ? undefined : true,
        });
    }

    const onToggleMode = () => {
        setInput({
            ...input,
            provitioningType: input.provitioningType === 'MAPPING' ? 'OPERATOR' : 'MAPPING'
        });
    }

    const handleDiscard = () => {
        setInput({
            ...storedObj.current,
        });
        setSelectedResolution(uClientData.currentResolution);
        setDiscardState(!discardState);

    }
    const resolutionChanged = uClientData && selectedResolution &&
        (selectedResolution.width !== uClientData.currentResolution.width ||
            selectedResolution.height !== uClientData.currentResolution.height);


    const handleSubmit = () => {
        let settingChanged = false;
        const settings: UClientSettings = {
            nameSetting: { name: storedObj.current.name },
            locationSetting: { location: storedObj.current.location },
            networkSetting: {
                dhcpEnabled: storedObj.current.dhcpEnabled,
                ipAddress: storedObj.current.ipAddress,
                maskAddress: storedObj.current.maskAddress
            }
        };
        if (input.name !== storedObj.current.name) {
            settingChanged = true;
            settings.nameSetting = { name: input.name };
        }
        if (input.location !== storedObj.current.location) {
            settingChanged = true;
            settings.locationSetting = { location: input.location };
        }
        if (input.dhcpEnabled) {
            if (input.dhcpEnabled !== storedObj.current.dhcpEnabled) {
                settingChanged = true;
                settings.networkSetting = {
                    dhcpEnabled: input.dhcpEnabled,
                    ipAddress: null,
                    maskAddress: null
                };
            }
        } else {
            if (input.dhcpEnabled !== storedObj.current.dhcpEnabled ||
                input.ipAddress !== storedObj.current.ipAddress ||
                input.maskAddress !== storedObj.current.maskAddress) {
                settingChanged = true;
                settings.networkSetting = {
                    dhcpEnabled: input.dhcpEnabled,
                    ipAddress: input.ipAddress,
                    maskAddress: input.maskAddress
                };
            }
        }


        if (settingChanged) {
            dispatch(uclientsSliceActions.requestUpdateSettings({ id, settings }));
        }
        if (input.lockTaskMode !== storedObj.current.lockTaskMode) {
            dispatch(uclientsSliceActions.requestSetLockTaskMode({ id, value: input.lockTaskMode }));
        }
        if (input.stationLabel !== storedObj.current.stationLabel) {
            requestUClientUpdateStationID(id, { value: input.stationLabel });
        }
        if (resolutionChanged) {
            const isCustom = uClientData.transientData.displayData.displayResolutionData.availableResolutions.findIndex(
                item => item.height === selectedResolution.height && item.width === selectedResolution.width) < 0;
            dispatch(uclientsSliceActions.requestUpdateDisplayResolution({ id, resolution: selectedResolution, isCustom }));
        }
        if (orgChanged && orgRef.current) {
            orgRef.current.handleSave();
        }
        if (input.timeZone !== storedObj.current.timeZone) {
            dispatch(uclientsSliceActions.requestUpdateTimeZone({ id, value: input.timeZone }));
        }
        if (input.provitioningType !== storedObj.current.provitioningType) {
            dispatch(uclientsSliceActions.requestToggleProvisioningType({ id, value: input.provitioningType }));
        }
        handleClose();
    }

    let ipDisabled: boolean, ipInvalidState: boolean, ipInvalidText: string;
    let maskDisabled: boolean, maskInvalidState: boolean, maskInvalidText: string;
    let dhcpDisabled: boolean;

    if (!id.value.includes("-webos")) {
        ipDisabled = true;
        ipInvalidState = true;
        ipInvalidText = t("CommonUI.uClient.ipInvalidText1");
        maskDisabled = true;
        maskInvalidState = true;
        maskInvalidText = t("CommonUI.uClient.ipInvalidText1");
        dhcpDisabled = true;
    } else {
        if (input.dhcpEnabled) {
            ipDisabled = true;
            maskDisabled = true;
        }
        ipInvalidState = !isIPAddress(input.ipAddress);
        ipInvalidText = t("CommonUI.uClient.ipInvalidText2");
        maskInvalidState = !isIPAddress(input.maskAddress);
        maskInvalidText = t("CommonUI.uClient.maskInvalidText");
    }

    return (
        <Offcanvas show={true} onHide={handleClose} placement="end" className="zeroTableCanvas">
            <div className="zeroTableCanvasContainer uclient" style={{ paddingBottom: "1rem" }}>
                <div className='zeroTableContainer'>
                    <div className='zeroTableOverall'>
                        <div className='zeroTableHeader'>
                            {activeTab === TABS.uclientSettings ? t('CommonUI.uClient.settings') : null}
                            {activeTab === TABS.storage ? t('CommonUI.uClient.settings') : null}
                            {activeTab === TABS.giada ? t('CommonUI.uClient.giadaConfig') : null}
                        </div>
                        <div className='zeroTableText'>
                            {activeTab === TABS.uclientSettings ? t('CommonUI.uClient.settingsDesc') : null}
                            {activeTab === TABS.storage ? t('CommonUI.uClient.settingsDesc') : null}
                            {activeTab === TABS.giada ? t('CommonUI.uClient.giadaConfigDesc') : null}
                        </div>
                    </div>
                    <div className='zeroTableClose' onClick={handleClose}>
                        <div className='closeDivZeroTable'>
                            <FontAwesomeIcon icon={faXmark} className='closeIconZeroTable' />
                        </div>
                    </div>
                </div>
                <Tabs defaultActiveKey="uclientSettings" onSelect={setActiveTab}>
                    <Tab title={t('Shared.details')} eventKey={TABS.uclientSettings}>
                        <div className='zeroClientDetailsContainer gap36'>
                            <div className="overallAddTitleDes">
                                <SettingHeaderDes title={t('CommonUI.uClient.deviceSetting')} description={t('CommonUI.uClient.deviceSettingDes')} />
                                <CustomSwitch
                                    check={input.provitioningType === 'OPERATOR'}
                                    name={t('CommonUI.uClient.operatorMode')}
                                    description={t('CommonUI.uClient.operatorModeDesc')}
                                    handleClick={onToggleMode}
                                />
                                <UClientDetailsForm onInputChange={handleInputChange} whichCSS={false} keys="name" title="Name" displayLength={true} maxCharAllowed={60} placeholder={"ex. uClient"} formValue={input.name} invalidState={false} invalidText="Please enter a valid description" formWidth="528px !important" />
                                <EditStationIDForm onInputChange={handleInputChange} whichCSS={false} keys="stationLabel" title="ID" displayLength={true} maxCharAllowed={5} placeholder={"ex. 1"} formValue={input.stationLabel} invalidState={checkInvalidState(input.stationLabel)} invalidText="ID cannot be a duplicate or empty" formWidth="600px !important" />
                                <UClientDetailsForm onInputChange={handleInputChange} whichCSS={false} keys="location" title="Location" displayLength={true} maxCharAllowed={60} placeholder={"ex. Meeting Room"} formValue={input.location} invalidState={false} invalidText="Please enter a valid description" formWidth="528px !important" />
                                <EditTimeZoneForm onChange={setTimeZone} timeZone={input.timeZone} />
                                <EditResolutionForm
                                    uclientData={uClientData}
                                    selectedResolution={selectedResolution}
                                    onChange={setSelectedResolution}
                                    reset={discardState}
                                />
                                <UClientDetailsForm disabled={true} onInputChange={handleInputChange} whichCSS={false} keys="seatID" title="Seat ID" displayLength={false} maxCharAllowed={60} placeholder={"rockchip-00E04C023D97"} formValue={input.seatID} invalidState={false} invalidText="Please enter a valid Seat ID" formWidth="528px !important" />
                                <UClientDetailsForm disabled={true} onInputChange={handleInputChange} whichCSS={false} keys="modelName" title="Model Name" displayLength={false} maxCharAllowed={60} placeholder={"rockchip rk3399-all"} formValue={input.modelName} invalidState={false} invalidText="Please enter a valid Model Name" formWidth="528px !important" />
                                <UClientDetailsForm disabled={true} onInputChange={handleInputChange} whichCSS={false} keys="firmwareVersion" title={t('CommonUI.uClient.firmwareVersion')} displayLength={false} maxCharAllowed={60} placeholder={"IOT-FW-WTR39T1R200-TE3399-M-212308"} formValue={input.firmwareVersion} invalidState={false} invalidText="Please enter a valid firmware version" formWidth="528px !important" />

                            </div>
                            <div className="overallAddTitleDes">
                                <SettingHeaderDes title={t('CommonUI.uClient.lockedMode')} description={t('CommonUI.uClient.lockedModeDes')} />
                                <div className=" alertOverallBodyDiv">
                                    <div className="alertBodyRow">
                                        <div className="alertBodyRadio">
                                            <Switch
                                                onChange={onToggleLockTaskMode}
                                                checked={input.lockTaskMode}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                height={16}
                                                width={32}
                                                onHandleColor='#FFFFFF'
                                                onColor='#23A866'
                                                offColor='#C6C8CE'
                                                offHandleColor='#FFFFFF'
                                                className="toggle-switch"
                                            />
                                        </div>
                                        <div className="alertBodyBlackText">
                                            {input.lockTaskMode ? t('CommonUI.uClient.locked') : t('CommonUI.uClient.unlocked')}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="overallAddTitleDes">
                                <SettingHeaderDes title={t('CommonUI.uClient.networkConfiguration')} description={t('CommonUI.uClient.networkConfigurationDes')} />
                                <UClientDetailsForm disabled={true} onInputChange={handleInputChange} whichCSS={false} keys="macAddress" title="MAC Address" displayLength={false} maxCharAllowed={60} placeholder={"rockchip-00E04C023D97"} formValue={input.macAddress} invalidState={false} invalidText="Please enter a valid MAC Address" formWidth="528px !important" />
                                <UClientDetailsForm disabled={ipDisabled} onInputChange={handleInputChange} whichCSS={false} keys="ipAddress" title="IP Address" displayLength={false} maxCharAllowed={60} placeholder={"ex. 198.168.150.120"} formValue={input.ipAddress} invalidState={ipInvalidState} invalidText={ipInvalidText} formWidth="528px !important" />
                                <UClientDetailsForm disabled={maskDisabled} onInputChange={handleInputChange} whichCSS={false} keys="maskAddress" title="Subnet Mask" displayLength={false} maxCharAllowed={60} placeholder={"ex. 255.255.255.255"} formValue={input.maskAddress} invalidState={maskInvalidState} invalidText={maskInvalidText} formWidth="528px !important" />
                                <UClientNetworkCategory disabled={dhcpDisabled} dhcpEnabled={input.dhcpEnabled} onChangeDHCP={onChangeDHCP} />
                            </div>
                        </div>
                        <UClientDetailsFormSubmitButtons onDiscard={handleDiscard} onSubmit={handleSubmit}
                            discardState={shallowequal(input, storedObj.current) && !resolutionChanged}
                            saveState={(shallowequal(input, storedObj.current) && !resolutionChanged && !orgChanged)
                                || checkInvalidState(input.stationLabel)} />
                    </Tab>
                    {uClient?.data.id.value.includes("giada") &&
                        input.provitioningType === 'OPERATOR' &&
                        <Tab title={t('CommonUI.uClient.giadaConfig')} eventKey={TABS.giada}>
                            <UClientGiadaConfig
                                uclient={uClient?.data}
                                onClose={handleClose}
                                onSubmit={handleSubmit}
                            />
                        </Tab>}
                    <Tab title={t('CommonUI.uClient.storage')} eventKey={TABS.storage} >
                        <UClientStorageManagement storage={storageData} onClose={handleClose} activeTab={activeTab} />
                    </Tab>
                </Tabs>
            </div>
        </Offcanvas>
    );
}