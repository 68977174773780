import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { RESPONSE_LIST_ONBOARDS, RESPONSE_LIST_ONBOARDS_FOR_VE, NOTIFY_ONBOARD_ADDED, NOTIFY_ONBOARD_UPDATED, NOTIFY_ONBOARD_REMOVED } from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/displays/OnboardMsgDefinations";
import { OnboardComp } from "userful-chronos-app-common-js/dist/models/display";
import displaysStore from "../displaysStore";
import { onboardSliceActions } from "../onboardSlice";


registerMsgHandler(RESPONSE_LIST_ONBOARDS, (payload) => {
    console.debug('Receive RESPONSE_LIST_ONBOARDS', payload);
    const data = JSON.parse(payload) as Array<OnboardComp>;
    displaysStore.dispatch(onboardSliceActions.setDisplays(data));
})

registerMsgHandler(RESPONSE_LIST_ONBOARDS_FOR_VE, (payload) => {
    console.debug('Receive RESPONSE_LIST_ONBOARDS_FOR_VE');
    const data = JSON.parse(payload) as Array<OnboardComp>;
    displaysStore.dispatch(onboardSliceActions.setDisplays(data));
})

registerMsgHandler(NOTIFY_ONBOARD_ADDED, (payload) => {
    console.debug('Receive NOTIFY_ONBOARD_ADDED');
    const data = JSON.parse(payload) as OnboardComp;
    displaysStore.dispatch(onboardSliceActions.addDisplay(data));
})

registerMsgHandler(NOTIFY_ONBOARD_UPDATED, (payload) => {
    console.debug('Receive NOTIFY_ONBOARD_UPDATED');
    const data = JSON.parse(payload) as OnboardComp;
    displaysStore.dispatch(onboardSliceActions.updateDisplay(data));
})

registerMsgHandler(NOTIFY_ONBOARD_REMOVED, (payload) => {
    console.debug('Receive NOTIFY_ONBOARD_REMOVED');
    const data = JSON.parse(payload) as OnboardComp;
    displaysStore.dispatch(onboardSliceActions.deleteDisplay(data));
})
