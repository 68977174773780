import { TableauPassport, TableauView, TableauWorkbook } from "userful-chronos-app-common-js/dist/models/integration/tableau";

export const GetListPassportForDropdown = (passports: TableauPassport[]): Array<{ value: string, label: string, id: string }> => {

    const list = passports.map((passport: TableauPassport) => {
        return {
            value: passport.name,
            label: passport.name,
            id: passport.id.value
        }

    })

    return list;

}

export const GetListWorkbooksForDropdown = (powerbiWorkspaces: TableauWorkbook[]): Array<{ value: string, label: string, id: string }> => {

    const list = powerbiWorkspaces.map((workspace: TableauWorkbook) => {
        return {
            value: workspace.name,
            label: workspace.name,
            id: workspace.id
        }

    })

    return list;

}

export const GetListViewsForDropdown = (powerbiReports: TableauView[]): Array<{ value: string, label: string, id: string }> => {

    const list = powerbiReports.map((report: TableauView) => {
        return {
            value: report.name,
            label: report.name,
            id: report.id
        }

    })

    return list;

}

export const GetSelectedPassport = (passports: TableauPassport[], id: string): { value: string, label: string, id: string } => {
    const selected = passports.filter((passport) => passport.id.value === id);
    if (selected.length === 0) {
        return null
    }

    return {
        value: selected[0].name,
        label: selected[0].name,
        id: selected[0].id.value
    }
}

export const GetSelectedWorkbook = (workbooks: TableauWorkbook[], id: string): { value: string, label: string, id: string } => {
    const selected = workbooks.filter((workbook) => workbook.id === id);
    if (selected.length === 0) {
        return null
    }

    return {
        value: selected[0].name,
        label: selected[0].name,
        id: selected[0].id
    }
}

export const GetSelectedView = (views: TableauView[], id: string): { value: string, label: string, id: string } => {
    const selected = views.filter((passport) => passport.id === id);
    if (selected.length === 0) {
        return null
    }

    return {
        value: selected[0].name,
        label: selected[0].name,
        id: selected[0].id
    }
}