import { SamSourceDef } from "userful-chronos-app-common-js/dist/models/sam/SAMSource";
import { useTranslation } from "react-i18next";
import DropdownWidget from "../shared/DropdownWidget";
import { GetListPassportForDropdown, GetSelectedPassport, GetColumnRequestData, GetCodedListColumns, 
    GetSelectedColumn, GetStartAndEndDate, getDate } from "./ComputationServicenow";
import EpicNumberWidget from "../shared/EpicNumberWidget";
import { useIntegrationDispatch, useIntegrationSelector, servicenowActions } from "userful-chronos-common-store/dist/integration";
import { SourceDefParam } from "userful-chronos-app-common-js/dist/models/source";
import { useState, useEffect } from 'react';
import IntegrationDateRange from "../shared/IntegrationDateRange";
import { Form } from "react-bootstrap";
import { HelpInfo } from "../../../sourceassets";
import { ServicenowColumns, ServicenowColumnsRequest, getDataPayload } from "userful-chronos-app-common-js/dist/models/integration/servicenow";

interface IProps {
    params: SourceDefParam[];
    handleParamChange: (name: string, value) => void,
    requiredErrors,
    show: boolean;
}

export default function ServicenowFormInner(props: IProps) {

    const { t } = useTranslation();
    const dispatchServicenow = useIntegrationDispatch();
    const passports = useIntegrationSelector((state) => state.servicenowStore.servicenowPassports);
    // const columns : ServicenowColumns[] = useIntegrationSelector((state) => state.servicenowStore.columns);
    const plotOverlay = useIntegrationSelector((state) => state.servicenowStore.plotOverlay);

    const [dateRange, setDateRange] = useState<[Date, Date]>(getDate(props.params));

    const formatDate = (date: Date): string => {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleDateChange = (dates: [Date, Date]) => {
        setDateRange(dates);
        const formattedStartDate = formatDate(dates[0]);
        const formattedEndDate = formatDate(dates[1]);
        const dateRangeString = `${formattedStartDate}*${formattedEndDate}`;
        props.handleParamChange("Servicenow date", dateRangeString);
    };

    const onChangePassport = (value) => {
        const data: ServicenowColumnsRequest  = GetColumnRequestData(value);
        dispatchServicenow(servicenowActions.getColumns(data));
        props.handleParamChange("Servicenow passport", value);
    }

    const onColumnChange = (value) => {
        const serverid = props.params.filter((para) => para.name === "Servicenow passport")[0].value;
        const { startDate, endDate } = GetStartAndEndDate();
        const getPayload = getDataPayload(serverid, startDate, endDate,  'incident', value.id);
        dispatchServicenow(servicenowActions.getEmbedData(getPayload));
        props.handleParamChange("Column name", value.id);
    }

    const onHideOverlay = () => {
        dispatchServicenow(servicenowActions.setPlotOverlay(false));
    }

    return (
        <>
            <DropdownWidget
                options={GetListPassportForDropdown(passports)}
                onChange={(value) => props.handleParamChange("Servicenow passport", value.id)}
                selectedValue={GetSelectedPassport(passports, props.params.filter((para) => para.name === "Servicenow passport")[0].value)}
                width={528}
                title={t(`Integrations.Servicenow.SelectPassport`)}
                placeholder={t('Integrations.Servicenow.Select')}
                disabled={passports.length === 0}
                showHelpInfo={false}
                invalidState={!!props.requiredErrors["Servicenow passport"]}
                invalidText={t('Integrations.Servicenow.ServicenowPassportError')}
                helpInfo={t("Integrations.Servicenow.SelectPassportInfoText")}
            />
            <Form.Group className="input-wrapper" controlId="formSourceName">
                <div className="text-char-wrapper">
                    <Form.Label className="heading1">{t("Integrations.Servicenow.GraphTitle")}
                        <HelpInfo className='powerBIHelpInfo' title={t("Integrations.Servicenow.GraphInfoText")} />
                    </Form.Label>
                    <span>{props.params.find((param) => param.name === "Graph title").value.length}/60</span>
                </div>
                <Form.Control
                    as="input"
                    name="Graph title"
                    placeholder={t("Integrations.Servicenow.TitlePlaceholder")}
                    onChange={(e) => props.handleParamChange(e.target.name, e.target.value)}
                    value={props.params.find((param) => param.name === "Graph title").value}
                    maxLength={60}
                />
                <Form.Control.Feedback type="invalid">{t("CommonUI.Sources.SourceNameRequired")}</Form.Control.Feedback>
            </Form.Group>
            <DropdownWidget
                options={GetCodedListColumns()}
                onChange={(value) => props.handleParamChange("Column name", value.id)}
                selectedValue={GetSelectedColumn(props.params.filter((para) => para.name === "Column name")[0].value)}
                width={528}
                title={t(`Integrations.Servicenow.SelectColumn`)}
                placeholder={t('Integrations.Servicenow.Select')}
                showHelpInfo={false}
                invalidState={!!props.requiredErrors["Column name"]}
                invalidText={t('Integrations.Servicenow.ServicenowColumnError')}
                helpInfo={t("Integrations.Servicenow.SelectColumnInfoText")}
                disabled={false}
            />
            <IntegrationDateRange
                title={t('Integrations.Servicenow.DateRange')}
                width={528}
                handleDateChange={handleDateChange}
                dateRange={dateRange}
                requiredErrors={props.requiredErrors}
                helpText={t('Integrations.Servicenow.DateRangeHelp')}
                showHelpInfo={true}
            />
            <EpicNumberWidget
                label={t('Integrations.Servicenow.Datarefreshrate')}
                name="Data refresh rate"
                onChange={(e) => { props.handleParamChange(e.target.name, e.target.value) }}
                isInvalid={!!props.requiredErrors["Data refresh rate"]}
                value={props.params.filter((item) => item.name === "Data refresh rate")[0].value}
                error={t("Integrations.Servicenow.RefreshError")}
                min={10}
                helpInfo={t("Integrations.Servicenow.DataRefreshRateInfoText")}
            />
        </>
    );
}
