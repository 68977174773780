import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates"
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import { REQUEST_UPDATE_NETWORK_SETTINGS, REQUEST_LIST_ZERO_CLIENTS, REQUEST_RESET, REQUEST_UPDATE_DETAILS, REQUEST_UPDATE_LOCK_STATUS, REQUEST_UPDATE_ZERO_CLIENT_ID_VISIBILITY, REQUEST_LIST_ZERO_CLIENTS_FOR_VE, REQUEST_UPDATE_RESOLUTION } from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/displays/ZeroClientsMsgDefinations";
import { Resolution } from "userful-chronos-app-common-js/dist/models/display";

export const requestListZC = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_ZERO_CLIENTS);
};

export const requestListZCForVE = (veID: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_ZERO_CLIENTS_FOR_VE, veID);
};


export const requestResetZC = (seatID: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_RESET, seatID);
};

export const requestLockZC = (seatID: StringID, veID: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_LOCK_STATUS, {
        id: seatID,
        lockStatus: "LOCKED",
        lockedTo: veID,
    });
}

export const requestUpdateZCResolution = (seatID: StringID, resolution: Resolution) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_RESOLUTION, { id: seatID, resolution });
};


export const requestUnlockZC = (seatID: StringID, veID: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_LOCK_STATUS, {
        id: seatID,
        lockStatus: "UNLOCKED",
        lockedTo: veID,
    });
};

export const requestUpdateZCNameAndLocation = (seatID: StringID, name: string, location: string) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_DETAILS, {
        id: seatID,
        name: name,
        location: location,
    });
};


// add details for reseting the network
export const requestUpdateNetworkZC = (seatID: StringID, dhcpEnabled: boolean, ipAddress: string, mask: string) => {

    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_NETWORK_SETTINGS, {
        id: seatID,
        networkSettings: {dhcpEnabled: dhcpEnabled, ipAddress: {value: ipAddress}, mask: {value: mask}}
    });
};
