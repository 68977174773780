import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StringID } from 'userful-chronos-app-common-js/dist/models/common';
import { UClientActionComp } from 'userful-chronos-app-common-js/dist/models/uclient';
import { requestCreateUCAction, requestRemoveUCAction, requestUpdateUCAction } from './msgs/UClientActionsMsgSender';


const initialState: {
    actions: UClientActionComp[];
    ready: boolean;
} = {
    actions: [],
    ready: false,
};

export const uclientActionsSlice = createSlice({
    name: 'uclientActionsSlice',
    initialState,
    reducers: {
        setActions: (state, action: PayloadAction<UClientActionComp[]>) => {
            state.actions = [...action.payload];
            state.ready = true;
        },

        addOrUpdateActions: (state, action: PayloadAction<UClientActionComp>) => {
            const existingIndex = state.actions.findIndex(item => item.id.value === action.payload.id.value);
            if (existingIndex >= 0) {
                state.actions = [
                    ...state.actions.slice(0, existingIndex),
                    { ...action.payload },
                    ...state.actions.slice(existingIndex + 1),
                ];
            } else {
                state.actions = [
                    ...state.actions,
                    { ...action.payload },
                ]
            }
        },

        deleteAction: (state, action: PayloadAction<StringID>) => {
            state.actions = state.actions.filter(item => item.id.value !== action.payload.value);
        },

        requestCreateAction: (state, action: PayloadAction<UClientActionComp>) => {
            requestCreateUCAction({ ...action.payload });
        },

        requestUpdateAction: (state, action: PayloadAction<UClientActionComp>) => {
            requestUpdateUCAction({ ...action.payload });
        },

        requestRemoveAction: (state, action: PayloadAction<StringID>) => {
            requestRemoveUCAction({ ...action.payload });
        },

    },
})


export const uclientActionsActions = uclientActionsSlice.actions;

export default uclientActionsSlice.reducer
