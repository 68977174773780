import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDash } from "@fortawesome/pro-regular-svg-icons";
import './DateTimePickerCustom.scss';
interface IProps {
    value: [Date, Date];
    onChange: (dates: [Date, Date]) => void;
    valid: boolean;
    dateFormat?: string;
    showTimeSelectOnly?: boolean;
    hideYear?: boolean;
    showDateOnly?:boolean;
}

const DEFAULT_DATE_FORMAT = " EEE, MMM dd, yyyy, h:mm aa";

function DateTimePickerCustom(props: IProps) {

    const { t } = useTranslation();

    const [startDate, setStartDate] = useState(props.value[0]);
    const [endDate, setEndDate] = useState(props.value[1]);
    const [dateDifference, setDateDifference] = useState<number>((props.value[1].getTime() - props.value[0].getTime()) / (1000 * 60 * 60 * 24)); // Difference in days
    
    useEffect(() => {
        // Update the difference whenever the dates change
        setDateDifference((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24));
    }, [startDate, endDate]);


    const onChangeStart = (date: Date) => {
        let newEndDate = endDate;

        // If the selected start date is later than the current end date, adjust the end date
        if (date > endDate) {
            newEndDate = new Date(date.getTime() + dateDifference * 1000 * 60 * 60 * 24);
            setEndDate(newEndDate);
        }
        
        setStartDate(date);
        props.onChange([date, newEndDate]);
    }

    const onChangeEnd = (date: Date) => {
        let newStartDate = startDate;

        // If the selected end date is earlier than the current start date, adjust the start date
        if (date < startDate) {
            newStartDate = new Date(date.getTime() - dateDifference * 1000 * 60 * 60 * 24);
            setStartDate(newStartDate);
        }
        
        setEndDate(date);
        props.onChange([newStartDate, date]);
    }


    return (
        <>
            <div className="customDateRangePicker">
                <DatePicker
                    selected={startDate}
                    onChange={(date: any) => onChangeStart(date)}
                    timeInputLabel="Time:"
                    dateFormat={props.dateFormat || DEFAULT_DATE_FORMAT}
                    showTimeInput = {!props.showDateOnly}
                    className='customDatePickerRange'
                    showTimeSelectOnly={props.showTimeSelectOnly}
                    calendarClassName="eventCalendarPicker"
                />
                <FontAwesomeIcon icon={faDash} />
                <DatePicker
                    selected={endDate}
                    onChange={(date: any) => onChangeEnd(date)}
                    timeInputLabel="Time:"
                    dateFormat={props.dateFormat || DEFAULT_DATE_FORMAT}
                    showTimeInput = {!props.showDateOnly}
                    className='customDatePickerRange'
                    showTimeSelectOnly={props.showTimeSelectOnly}
                    calendarClassName="eventCalendarPicker"
                />
            </div>
            {startDate >= endDate && <div className="invalidInputClock"> {t('CommonUI.Schedule.invalidRange')} </div>}
        </>


    );
}

export default DateTimePickerCustom;