import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SourceSpecParam } from 'userful-chronos-app-common-js/dist/models/source';
import AddOrEditDir from './AddOrEditDir';
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { SamSourceSpec } from 'userful-chronos-app-common-js/dist/models/sam/SAMSourceSpec';
import { getTranslatedParamName } from '../../SourceFormUtil';

interface IProps {
    param: SourceSpecParam,
    selectedSourceSpec?: SamSourceSpec,
    input: any;
    onChange: any;
    requiredErrors: object;
}

export default function FileDirSelectWidget(props: IProps) {
    const { t } = useTranslation();

    const [modalShow, setModalShow] = useState(false);
    const [dirModalShow, setDirModalShow] = useState(false);
    const [editDirValue, setEditDirValue] = useState({ name: "", path: "", index: -1 });

    const handleOpenModal = () => {
        setModalShow(true);
    };

    const handleOpenDirModal = () => {
        setDirModalShow(true);
    };

    const isValid = () => {
        return !props.requiredErrors[props.param.name];
    }

    const handleFileSelect = (e) => {
        const name = e.target.name;
        const file = props.input[name].file || [];
        const dir = props.input[name].dir || []
        if (e.target.checked) {
            props.onChange({
                name,
                value: {
                    dir: [...dir],
                    file: [...file, e.target.value]
                }
            })
        } else {
            const result = file.filter(item => item !== e.target.value)
            props.onChange({
                name,
                value: {
                    dir: [...dir],
                    file: result
                }
            });
        }
    }

    const handleAddDir = (dirName, dirPath, name) => {
        const dir = props.input[name].dir || [];
        const file = props.input[name].file || [];

        props.onChange({
            name,
            value: {
                file: [...file],
                dir: [...dir, { name: dirName, path: dirPath }]
            }
        });
        setEditDirValue({ name: "", path: "", index: -1 });
        setDirModalShow(false);
    }

    const handleEditDir = (dirName, dirPath, name, index) => {
        const dir = props.input[name].dir || [];
        const file = props.input[name].file || [];

        const newArray = dir.splice(index, 1, { name: dirName, path: dirPath });

        props.onChange({
            name,
            value: {
                file: [...file],
                dir: dir,
            }
        })
        setEditDirValue({ name: "", path: "", index: -1 });
        setDirModalShow(false);
    }


    const handleCancel = (e) => {
        const name = e.target.name;
        props.onChange({
            name,
            value: {
                file: [],
                dir: [...props.input[name].dir]
            }
        });
        setModalShow(false);
    }

    const handleHideModal = () => {
        setModalShow(false);
    }

    const handleHideDirModal = () => {
        setDirModalShow(false);
    }

    const removeFile = (e) => {
        const id = e.target.id;
        const name = e.target.name;
        const items = props.input[name].file;
        const result = items.filter((item) => item !== id);
        props.onChange({
            name,
            value: {
                dir: [...props.input[name].dir],
                file: result
            }
        });
    }

    const removeDir = (e) => {
        const id = e.target.id;
        const name = e.target.name;
        const items = props.input[name].dir;
        const result = items.filter((item) => item.name !== id);
        props.onChange({
            name,
            value: {
                file: [...props.input[name].file],
                dir: result
            }
        })
    }

    const editDir = (name: string, path: string, index: number): void => {
        setDirModalShow(true);
        setEditDirValue({ name: name, path, index })
    };

    return (
        <Form.Group className="input-wrapper">
            <Form.Label className="heading1">{getTranslatedParamName(props.param, props.selectedSourceSpec, t)}</Form.Label>
            <Form.Control as="div" className='file-dir-select'>
                {
                    <>
                        <Modal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <div className='modal-header-title'>
                                <Modal.Header>
                                    <div className='modal-title'>{t('CommonUI.Sources.selectFiles')}</div>
                                    <div className='modal-close-btn'>
                                        <FontAwesomeIcon icon={faTimes} onClick={handleHideModal} className="custom-close-btn" />
                                    </div>
                                </Modal.Header>
                            </div>
                            <Modal.Body>
                                <div>
                                    {props.param.multiOptions.map((option, index) =>
                                        <span key={option.name}>
                                            <Form.Check
                                                type="checkbox"
                                                className="selection-item modal-form-check"
                                                name={props.param.name}
                                                id={`${option.name}-${index}`}
                                                label={option.value}
                                                value={option.name} // signage player has value/name reversed
                                                checked={props.input[props.param.name] && 
                                                    props.input[props.param.name].file && 
                                                    props.input[props.param.name].file.includes(option.name)}
                                                onChange={handleFileSelect}
                                            />
                                        </span>)
                                    }
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className='button-secondary' type="button" name={props.param.name} onClick={handleCancel}>{t('Shared.cancel')}</button>
                                <button className="button-primary" type='button' onClick={handleHideModal}>{t('Shared.ok')}</button>
                                {false && <button className="button-delete" type='button'>{t('Shared.delete')}</button>}
                            </Modal.Footer>
                        </Modal>

                        <AddOrEditDir name={props.param.name} dirModalShow={dirModalShow} hideDirModal={handleHideDirModal} handleAddDir={handleAddDir} handleEditDir={handleEditDir} data={props.input} editDirValue={editDirValue} />
                    </>
                }
                {/* For File */}

                {props.input[props.param.name] && props.input[props.param.name].file && (props.input[props.param.name].file).map((value, index) =>
                    <div key={`source-file-select-${index}`}>
                        {value}&nbsp;&nbsp;
                        <button name={props.param.name} id={value} onClick={removeFile} style={{ border: "none", background: "none" }}>
                            <FontAwesomeIcon icon={faTimes} style={{ pointerEvents: "none", color: "#4B5168" }} />
                        </button>
                    </div>
                )}

                {/* For Dir */}
                {props.input[props.param.name] && props.input[props.param.name].dir && (props.input[props.param.name].dir).map((value, index) =>
                    <div key={`source-dir-select-${index}`}>
                        {value.name}&nbsp;&nbsp;
                        <button name={props.param.name} id={value.name} onClick={() => editDir(value.name, value.path, index)} style={{ border: "none", background: "none" }}>
                            <FontAwesomeIcon icon={faEdit} style={{ pointerEvents: "none", color: "#4B5168" }} />
                        </button>&nbsp;&nbsp;
                        <button name={props.param.name} id={value.name} onClick={removeDir} style={{ border: "none", background: "none" }}>
                            <FontAwesomeIcon icon={faTimes} style={{ pointerEvents: "none", color: "#4B5168" }} />
                        </button>
                    </div>
                )}
            </Form.Control>
            {!isValid() && <span style={{'fontSize':'12px','color':'rgb(244, 67, 54)'}}>{`${t('CommonUI.Sources.fileError')} ${getTranslatedParamName(props.param, props.selectedSourceSpec, t)}`}</span>}
            <div className='handle-file-buttons'>
                <button className="button-primary" type="button" name="addFiles" onClick={handleOpenModal}>{t('CommonUI.Sources.Sources.addFiles')}</button>
                <button className="button-primary" type="button" name="addDir" onClick={handleOpenDirModal}>{t('CommonUI.Sources.Sources.addDir')}</button>
                <input type="file" id="myFile" name="filename" className='upload' />
            </div>
        </Form.Group>
    );
}