import { registerMsgHandler } from "userful-chronos-app-common-js/dist/message/messageRegistery";
import { CdmDestinationGroup, CdmDestinationSet } from "userful-chronos-app-common-js/dist/models/cdm/CdmGroup";
import { CdmPhysicalAudioDest, CdmPhysicalVideoDest } from "userful-chronos-app-common-js/dist/models/cdm/CdmPhysical";
import { CdmVideoWallDest } from "userful-chronos-app-common-js/dist/models/cdm/CdmVideoWall";
import { CdmPhysicalEndPointData, CdmVideoWallEndPointData } from "userful-chronos-app-common-js/dist/models/cdm/CdmEndPoints";
import {
    NOTIFY_DESTINATION_GROUP_ADDED,
    NOTIFY_DESTINATION_GROUP_REMOVED,
    NOTIFY_DESTINATION_GROUP_UPDATED,
    NOTIFY_PHYSICAL_AUDIO_DEST_ADDED,
    NOTIFY_PHYSICAL_AUDIO_DEST_REMOVED,
    NOTIFY_PHYSICAL_AUDIO_DEST_UPDATED,
    NOTIFY_PHYSICAL_ENDPOINT_ADDED,
    NOTIFY_PHYSICAL_ENDPOINT_REMOVED,
    NOTIFY_PHYSICAL_ENDPOINT_UPDATED,
    NOTIFY_PHYSICAL_VIDEO_DEST_ADDED,
    NOTIFY_PHYSICAL_VIDEO_DEST_REMOVED,
    NOTIFY_PHYSICAL_VIDEO_DEST_UPDATED,
    NOTIFY_VIDEOWALL_DEST_ADDED,
    NOTIFY_VIDEOWALL_DEST_REMOVED,
    NOTIFY_VIDEOWALL_DEST_UPDATED,
    NOTIFY_VIDEOWALL_ENDPOINT_ADDED,
    NOTIFY_VIDEOWALL_ENDPOINT_REMOVED,
    NOTIFY_VIDEOWALL_ENDPOINT_UPDATED,
    NOTIFY_VIDEO_WALL_CREATED,
    NOTIFY_VIDEO_WALL_REMOVED,
    NOTIFY_VIDEO_WALL_UPDATED,
    RESPONSE_LIST_DESTINATION_SET,
    RESPONSE_LIST_PHYSICAL_ENDPOINTS,
    RESPONSE_LIST_VIDEOWALL_ENDPOINTS,
    RESPONSE_QUERY_DESTINATION_SET,
} from "./CDMMsgDefinations";
import destinationStore from "../destinationStore";
import {
    NOTIFY_UCLIENT_ADDED,
    NOTIFY_UCLIENT_REMOVED,
    NOTIFY_UCLIENT_UPDATED,
    RESPONSE_LIST_UCLIENTS,
    RESPONSE_LIST_UCLIENTS_FOR_VE,
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/displays/UClientsMsgDefinations";
import { uclientsSliceActions } from "../../displays/uclientsSlice";
import { UclientData } from "userful-chronos-app-common-js/dist/models/uclient";
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import {
    NOTIFY_ZERO_CLIENT_ADDED,
    NOTIFY_ZERO_CLIENT_REMOVED,
    NOTIFY_ZERO_CLIENT_UPDATED,
    RESPONSE_LIST_ZERO_CLIENTS,
    RESPONSE_LIST_ZERO_CLIENTS_FOR_VE,
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/displays/ZeroClientsMsgDefinations";
import { zeroclientsSliceActions } from "../../displays/zeroclientsSlice";
import { ZeroClientData } from "userful-chronos-app-common-js/dist/models/zeroClient";
import { destinationGroupsSliceActions } from "../destinationGroupsSlice";
import { audioDestinationsSliceActions } from "../audioDestinationsSlice";
import { displayDestinationsSliceActions } from "../displayDestinationsSlice";
import { videowallDestinationsSliceActions } from "../videowallDestinationsSlice";
import { endpointsSliceActions } from "../endpointsSlice";
import {
    setReceivedInitialDestinations,
    setReceivedInitialDestinationsQuery,
    setReceivedInitialPhysicalEndPoints,
    setReceviedInitialVideoWallsEndPoints,
} from "../../InitialDataStates";
import { CalibrationVideoWall, OnboardComp } from "userful-chronos-app-common-js/dist/models/display";
import { RESPONSE_LIST_VIDEOWALLS } from "../../mapping/msgs/calibrationMsgDefinitions";
import { videoWallCalibrationActions } from "../videoWallCalibrationSlice";
import { ConfirmationToastContentStore } from '../../../Container/ConfirmationToastContentStore';
import { toast } from "react-toastify";
import { RESPONSE_LIST_ONBOARDS, RESPONSE_LIST_ONBOARDS_FOR_VE, NOTIFY_ONBOARD_ADDED, NOTIFY_ONBOARD_UPDATED, NOTIFY_ONBOARD_REMOVED } from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/displays/OnboardMsgDefinations";
import { onboardSliceActions } from "../../displays/onboardSlice";

// Video Wall Calibration:
registerMsgHandler(RESPONSE_LIST_VIDEOWALLS, (payload) => {
    const data = JSON.parse(payload) as CalibrationVideoWall[];
    console.debug(`receive RESPONSE_LIST_VIDEOWALLS. ${JSON.stringify(data)}`);
    destinationStore.dispatch(videoWallCalibrationActions.setVideoWallCalibrations(data));
});
registerMsgHandler(NOTIFY_VIDEO_WALL_CREATED, (payload) => {
    const data = JSON.parse(payload) as CalibrationVideoWall;
    console.debug(`receive NOTIFY_VIDEO_WALL_CREATED. ${JSON.stringify(data)}`);
    destinationStore.dispatch(videoWallCalibrationActions.addVideoWallCalibrations([data]));
    const customDisplay = ConfirmationToastContentStore(data.name, "Save");
    toast(customDisplay, { containerId: 'confirmationContainer' });

});
registerMsgHandler(NOTIFY_VIDEO_WALL_REMOVED, (payload) => {
    const data = JSON.parse(payload) as StringID;
    console.debug(`receive NOTIFY_VIDEO_WALL_REMOVED. ${JSON.stringify(data)}`);
    destinationStore.dispatch(videoWallCalibrationActions.removeVideoWallCalibrations([data]));
});
registerMsgHandler(NOTIFY_VIDEO_WALL_UPDATED, (payload) => {
    const data = JSON.parse(payload) as CalibrationVideoWall;
    console.debug(`receive NOTIFY_VIDEO_WALL_UPDATED. ${JSON.stringify(data)}`);
    destinationStore.dispatch(videoWallCalibrationActions.updateVideoWallCalibrations([data]));
    const customDisplay = ConfirmationToastContentStore(data.name, "Edit");
    toast(customDisplay, { containerId: 'confirmationContainer' });
});
//

registerMsgHandler(RESPONSE_LIST_DESTINATION_SET, (payload) => {
    setReceivedInitialDestinations();
    const data = JSON.parse(payload) as CdmDestinationSet;
    console.debug(`receive RESPONSE_LIST_DESTINATION_SET. ${JSON.stringify(data)}`);
    destinationStore.dispatch(displayDestinationsSliceActions.setDisplayDestinations(data.physicalVideo));
    destinationStore.dispatch(audioDestinationsSliceActions.setAudioDestinations(data.physicalAudio));
    destinationStore.dispatch(videowallDestinationsSliceActions.setVideoWallDestinations(data.videoWalls));
    destinationStore.dispatch(destinationGroupsSliceActions.setDestinationGroups(data.groups));
});

registerMsgHandler(RESPONSE_QUERY_DESTINATION_SET, (payload) => {
    setReceivedInitialDestinationsQuery();
    const data = JSON.parse(payload) as CdmDestinationSet;
    console.debug(`receive RESPONSE_QUERY_DESTINATION_SET. ${JSON.stringify(data)}`);
    destinationStore.dispatch(displayDestinationsSliceActions.setDisplayDestinations(data.physicalVideo));
    destinationStore.dispatch(audioDestinationsSliceActions.setAudioDestinations(data.physicalAudio));
    destinationStore.dispatch(videowallDestinationsSliceActions.setVideoWallDestinations(data.videoWalls));
    destinationStore.dispatch(destinationGroupsSliceActions.setDestinationGroups(data.groups));
});

registerMsgHandler(NOTIFY_DESTINATION_GROUP_ADDED, (payload) => {
    console.debug("receive NOTIFY_DESTINATION_GROUP_ADDED");
    const data = JSON.parse(payload) as CdmDestinationGroup;
    destinationStore.dispatch(destinationGroupsSliceActions.addOrUpdateDestinationGroup(data));
});
registerMsgHandler(NOTIFY_DESTINATION_GROUP_UPDATED, (payload) => {
    console.debug("receive NOTIFY_DESTINATION_GROUP_UPDATED");
    const data = JSON.parse(payload) as CdmDestinationGroup;
    destinationStore.dispatch(destinationGroupsSliceActions.addOrUpdateDestinationGroup(data));
});
registerMsgHandler(NOTIFY_DESTINATION_GROUP_REMOVED, (payload) => {
    console.debug("receive NOTIFY_DESTINATION_GROUP_REMOVED");
    const data = JSON.parse(payload) as CdmDestinationGroup;
    destinationStore.dispatch(destinationGroupsSliceActions.removeDestinationGroup(data));
});

registerMsgHandler(NOTIFY_PHYSICAL_AUDIO_DEST_ADDED, (payload) => {
    console.debug("receive NOTIFY_PHYSICAL_AUDIO_DEST_ADDED");
    const data = JSON.parse(payload) as CdmPhysicalAudioDest;
    destinationStore.dispatch(audioDestinationsSliceActions.addOrUpdateAudioDestination(data));
});
registerMsgHandler(NOTIFY_PHYSICAL_AUDIO_DEST_UPDATED, (payload) => {
    console.debug("receive NOTIFY_PHYSICAL_AUDIO_DEST_UPDATED");
    const data = JSON.parse(payload) as CdmPhysicalAudioDest;
    destinationStore.dispatch(audioDestinationsSliceActions.addOrUpdateAudioDestination(data));
});
registerMsgHandler(NOTIFY_PHYSICAL_AUDIO_DEST_REMOVED, (payload) => {
    console.debug("receive NOTIFY_PHYSICAL_AUDIO_DEST_REMOVED");
    const data = JSON.parse(payload) as CdmPhysicalAudioDest;
    destinationStore.dispatch(audioDestinationsSliceActions.removeAudioDestination(data));
});

registerMsgHandler(NOTIFY_PHYSICAL_VIDEO_DEST_ADDED, (payload) => {
    console.debug("receive NOTIFY_PHYSICAL_VIDEO_DEST_ADDED");
    const data = JSON.parse(payload) as CdmPhysicalVideoDest;
    destinationStore.dispatch(displayDestinationsSliceActions.addOrUpdateDisplayDestination(data));
});
registerMsgHandler(NOTIFY_PHYSICAL_VIDEO_DEST_UPDATED, (payload) => {
    console.debug("receive NOTIFY_PHYSICAL_VIDEO_DEST_UPDATED");
    const data = JSON.parse(payload) as CdmPhysicalVideoDest;
    destinationStore.dispatch(displayDestinationsSliceActions.addOrUpdateDisplayDestination(data));
});
registerMsgHandler(NOTIFY_PHYSICAL_VIDEO_DEST_REMOVED, (payload) => {
    console.debug("receive NOTIFY_PHYSICAL_VIDEO_DEST_REMOVED");
    const data = JSON.parse(payload) as CdmPhysicalVideoDest;
    destinationStore.dispatch(displayDestinationsSliceActions.removeDisplayDestination(data));
});

registerMsgHandler(NOTIFY_VIDEOWALL_DEST_ADDED, (payload) => {
    console.debug("receive NOTIFY_VIDEOWALL_DEST_ADDED");
    const data = JSON.parse(payload) as CdmVideoWallDest;
    destinationStore.dispatch(videowallDestinationsSliceActions.addOrUpdateVideoWallDestination(data));
});
registerMsgHandler(NOTIFY_VIDEOWALL_DEST_UPDATED, (payload) => {
    console.debug("receive NOTIFY_VIDEOWALL_DEST_UPDATED");
    const data = JSON.parse(payload) as CdmVideoWallDest;
    destinationStore.dispatch(videowallDestinationsSliceActions.addOrUpdateVideoWallDestination(data));
});
registerMsgHandler(NOTIFY_VIDEOWALL_DEST_REMOVED, (payload) => {
    console.debug("receive NOTIFY_VIDEOWALL_DEST_REMOVED");
    const data = JSON.parse(payload) as CdmVideoWallDest;
    destinationStore.dispatch(videowallDestinationsSliceActions.removeVideoWallDestination(data));
});

registerMsgHandler(RESPONSE_LIST_PHYSICAL_ENDPOINTS, (payload) => {
    setReceivedInitialPhysicalEndPoints();
    const data = JSON.parse(payload) as CdmPhysicalEndPointData[];
    console.debug("receive RESPONSE_LIST_PHYSICAL_ENDPOINTS. Size=" + data.length);
    destinationStore.dispatch(endpointsSliceActions.setDisplayEndPoints(data));
});
registerMsgHandler(NOTIFY_PHYSICAL_ENDPOINT_ADDED, (payload) => {
    console.debug("receive NOTIFY_PHYSICAL_ENDPOINT_ADDED");
    const data = JSON.parse(payload) as CdmPhysicalEndPointData;
    destinationStore.dispatch(endpointsSliceActions.addOrUpdateDisplayEndPoint(data));
});
registerMsgHandler(NOTIFY_PHYSICAL_ENDPOINT_UPDATED, (payload) => {
    console.debug("receive NOTIFY_PHYSICAL_ENDPOINT_UPDATED");
    const data = JSON.parse(payload) as CdmPhysicalEndPointData;
    destinationStore.dispatch(endpointsSliceActions.addOrUpdateDisplayEndPoint(data));
});
registerMsgHandler(NOTIFY_PHYSICAL_ENDPOINT_REMOVED, (payload) => {
    console.debug("receive NOTIFY_PHYSICAL_ENDPOINT_REMOVED");
    const data = JSON.parse(payload) as CdmPhysicalEndPointData;
    destinationStore.dispatch(endpointsSliceActions.removeDisplayEndPoint(data));
});

registerMsgHandler(RESPONSE_LIST_VIDEOWALL_ENDPOINTS, (payload) => {
    setReceviedInitialVideoWallsEndPoints();
    const data = JSON.parse(payload) as CdmVideoWallEndPointData[];
    console.debug("receive RESPONSE_LIST_VIDEOWALL_ENDPOINTS. Size=" + data.length);
    destinationStore.dispatch(endpointsSliceActions.setVideoWallEndPoints(data));
});
registerMsgHandler(NOTIFY_VIDEOWALL_ENDPOINT_ADDED, (payload) => {
    console.debug("receive NOTIFY_VIDEOWALL_ENDPOINT_ADDED");
    const data = JSON.parse(payload) as CdmVideoWallEndPointData;
    destinationStore.dispatch(endpointsSliceActions.addOrUpdateVideoWallEndPoint(data));
});
registerMsgHandler(NOTIFY_VIDEOWALL_ENDPOINT_UPDATED, (payload) => {
    console.debug("receive NOTIFY_VIDEOWALL_ENDPOINT_UPDATED");
    const data = JSON.parse(payload) as CdmVideoWallEndPointData;
    destinationStore.dispatch(endpointsSliceActions.addOrUpdateVideoWallEndPoint(data));
});
registerMsgHandler(NOTIFY_VIDEOWALL_ENDPOINT_REMOVED, (payload) => {
    console.debug("receive NOTIFY_VIDEOWALL_ENDPOINT_REMOVED");
    const data = JSON.parse(payload) as CdmVideoWallEndPointData;
    destinationStore.dispatch(endpointsSliceActions.removeVideoWallEndPoint(data));
});

registerMsgHandler(RESPONSE_LIST_UCLIENTS, (payload) => {
    console.debug("Receive RESPONSE_LIST_UCLIENTS");
    const data = JSON.parse(payload) as Array<UclientData>;
    destinationStore.dispatch(uclientsSliceActions.setDisplays(data));
});

registerMsgHandler(RESPONSE_LIST_UCLIENTS_FOR_VE, (payload) => {
    const data = JSON.parse(payload) as Array<UclientData>;
    console.debug("Receive RESPONSE_LIST_UCLIENTS_FOR_VE");
    destinationStore.dispatch(uclientsSliceActions.setDisplaysForVE(data));
});

registerMsgHandler(NOTIFY_UCLIENT_ADDED, (payload) => {
    console.debug("Receive NOTIFY_UCLIENT_ADDED");
    const data = JSON.parse(payload) as UclientData;
    destinationStore.dispatch(uclientsSliceActions.addOrUpdateDisplays(data));
});

registerMsgHandler(NOTIFY_UCLIENT_UPDATED, (payload) => {
    console.debug("Receive NOTIFY_UCLIENT_UPDATED");
    const data = JSON.parse(payload) as UclientData;
    destinationStore.dispatch(uclientsSliceActions.addOrUpdateDisplays(data));
});

registerMsgHandler(NOTIFY_UCLIENT_REMOVED, (payload) => {
    console.debug("Receive NOTIFY_UCLIENT_REMOVED");
    const data = JSON.parse(payload) as StringID;
    destinationStore.dispatch(uclientsSliceActions.deleteDisplay(data));
});

registerMsgHandler(RESPONSE_LIST_ZERO_CLIENTS, (payload) => {
    console.debug("Receive RESPONSE_LIST_ZERO_CLIENTS");
    const data = JSON.parse(payload) as Array<ZeroClientData>;
    destinationStore.dispatch(zeroclientsSliceActions.setDisplays(data));
});

registerMsgHandler(RESPONSE_LIST_ZERO_CLIENTS_FOR_VE, (payload) => {
    console.debug("Receive RESPONSE_LIST_ZERO_CLIENTS_FOR_VE");
    const data = JSON.parse(payload) as Array<ZeroClientData>;
    destinationStore.dispatch(zeroclientsSliceActions.setDisplaysForVE(data));
});

registerMsgHandler(NOTIFY_ZERO_CLIENT_ADDED, (payload) => {
    console.debug("Receive NOTIFY_ZERO_CLIENT_ADDED");
    const data = JSON.parse(payload) as ZeroClientData;
    destinationStore.dispatch(zeroclientsSliceActions.addOrUpdateDisplays(data));
});

registerMsgHandler(NOTIFY_ZERO_CLIENT_UPDATED, (payload) => {
    console.debug("Receive NOTIFY_ZERO_CLIENT_UPDATED");
    const data = JSON.parse(payload) as ZeroClientData;
    destinationStore.dispatch(zeroclientsSliceActions.addOrUpdateDisplays(data));
});

registerMsgHandler(NOTIFY_ZERO_CLIENT_REMOVED, (payload) => {
    console.debug("Receive NOTIFY_ZERO_CLIENT_REMOVED");
    const data = JSON.parse(payload) as StringID;
    destinationStore.dispatch(zeroclientsSliceActions.deleteDisplay(data));
});


registerMsgHandler(RESPONSE_LIST_ONBOARDS, (payload) => {
    console.debug('Receive RESPONSE_LIST_ONBOARDS');
    const data = JSON.parse(payload) as Array<OnboardComp>;
    destinationStore.dispatch(onboardSliceActions.setDisplays(data));
})

registerMsgHandler(RESPONSE_LIST_ONBOARDS_FOR_VE, (payload) => {
    console.debug('Receive RESPONSE_LIST_ONBOARDS_FOR_VE');
    const data = JSON.parse(payload) as Array<OnboardComp>;
    destinationStore.dispatch(onboardSliceActions.setDisplays(data));
})

registerMsgHandler(NOTIFY_ONBOARD_ADDED, (payload) => {
    console.debug('Receive NOTIFY_ONBOARD_ADDED');
    const data = JSON.parse(payload) as OnboardComp;
    destinationStore.dispatch(onboardSliceActions.addDisplay(data));
})

registerMsgHandler(NOTIFY_ONBOARD_UPDATED, (payload) => {
    console.debug('Receive NOTIFY_ONBOARD_UPDATED');
    const data = JSON.parse(payload) as OnboardComp;
    destinationStore.dispatch(onboardSliceActions.updateDisplay(data));
})

registerMsgHandler(NOTIFY_ONBOARD_REMOVED, (payload) => {
    console.debug('Receive NOTIFY_ONBOARD_REMOVED');
    const data = JSON.parse(payload) as OnboardComp;
    destinationStore.dispatch(onboardSliceActions.deleteDisplay(data));
})
