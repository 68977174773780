import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UClientFirmwareConfigData } from 'userful-chronos-app-common-js/dist/models/uclient';

let updating: { [key: string]: Array<Function>; } = {};

const initialState: {
    config: UClientFirmwareConfigData;
} = {
    config: null,
};


export const UClientFirmwareConfigSlice = createSlice({
    name: 'UClientFirmwareConfigSlice',
    initialState,
    reducers: {
        setFirmwareConfig: (state, action: PayloadAction<UClientFirmwareConfigData>) => {
            state.config = action.payload;
        },
    },
})

export const uClientFirmwareConfigAction = UClientFirmwareConfigSlice.actions;

export default UClientFirmwareConfigSlice.reducer