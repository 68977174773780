import { getGlobalStates } from "userful-chronos-app-common-js/dist/globalstates/globalStates"
import { StringID } from "userful-chronos-app-common-js/dist/models/common";
import {
    REQUEST_ADD_CHANNEL, REQUEST_ADD_OR_UPDATE_CHANNEL_SOURCE,
    REQUEST_ADD_OR_UPDATE_CHANNEL_USER, REQUEST_LIST_CHANNELS, REQUEST_REMOVE_CHANNEL,
    REQUEST_REMOVE_CHANNEL_SOURCE, REQUEST_REMOVE_CHANNEL_USER,
    REQUEST_UPDATE_CHANNEL
} from "userful-chronos-app-common-js/dist/message/messageTypeDefinitions/ruby/rubyMsgDefinitions";
import { MutableChannel, UpdateChannelUserRequest, UpdateChannelSourceRequest } from "userful-chronos-app-common-js/dist/models/ruby/channel";
// 
export const requestListChannels = () => {
    getGlobalStates().eventBus.sendMsg(REQUEST_LIST_CHANNELS);
}

export const requestAddChannel = (data: MutableChannel) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_ADD_CHANNEL, data);
}

export const requestUpdateChannel = (data: MutableChannel) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_UPDATE_CHANNEL, data);
}

export const requestDeleteChannel = (data: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_CHANNEL, data);
}

export const requestAddOrUpdateChannelUser = (data: UpdateChannelUserRequest) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_ADD_OR_UPDATE_CHANNEL_USER, data);
}

export const requestRemoveChannelUser = (data: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_CHANNEL_USER, data);
}

export const requestAddOrUpdateChnnaleSource = (data: UpdateChannelSourceRequest) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_ADD_OR_UPDATE_CHANNEL_SOURCE, data);
}

export const requestRemoveChannelSource = (data: StringID) => {
    getGlobalStates().eventBus.sendMsg(REQUEST_REMOVE_CHANNEL_SOURCE, data);
}

